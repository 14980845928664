$qty-btn-color          : $button__text-color !default;
$qty-btn-bg-color       : $button__background !default;
$qty-btn-border-color   : $form-elements-border;
$qty-btn-height         : 46px !default;
$qty-btn-width          : 2rem !default;
$qty-btn-max-width      : 60px !default;
$qty-btn-min-width      : 30px !default;
$qty-btn-font-size      : $button__font-size !default;
$qty-input-color        : $input__field-color  !default;
$qty-input-bg-color     : $input__field-background-color !default;
$qty-input-border-color : $input__field-color !default;
$qty-input-font-size    : $font-size-base !default;
$qty-input-font-weight  : $font-weight-normal !default;


$qty-cart-list-item__color          : $battleship-grey !default;
$qty-cart-list-item__border         : $form-elements-border;
$qty-cart-list-item__border-radius  :  25px;
$qty-cart-list-item__background     : $white !default;
$qty-cart-list-item__btn-color      : $color-primary !default;
