@import "tab-variables";

.tab {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: $tab__margin;
    padding: $tab__padding;

    &__title {
        position: relative;
        display: $tab__title-display;
        width: $tab__title-width;
        height: $tab__title-size;
        padding: $tab__title-padding;
        border: $tab__title-border;
        border-width: $tab__title-border-width;
        background: $tab__title-background;
        color: $tab__title-color;
        font-family: $tab__title-font-family;
        font-size: $tab__title-font-size;
        font-weight: $tab__title-font-weight;
        text-align: $tab__title-text-align;
        text-transform: $tab__title-text-transform;
        transition: $tab__title-transition;
        cursor: pointer;
        border-radius: 0;
        &--active {
            border: $tab__title-border--active;
            border-width: $tab__title-border-width--active;
        }
        max-width: $tab__title-max-width\@large;
        flex: $tab__title-flex\@large;
        order: -1;

        .tab__icon {
            fill: $tab__icon-fill;
            pointer-events: none;
        }

        &:hover,
        &.focus-visible,
        &--active {
            background: $tab__title-background--active;
            color: $tab__title-color--active;
            font-weight: $tab__title-font-weight--active;
            z-index: $tab__title-z-index--active;

            @include mq($screen-l) {
                z-index: $tab__title-z-index--active\@large;
            }

            .tab__icon {
                fill: $tab__icon-fill--active;
            }
        }

        &--active {
            .tab__icon {
                transform: $tab__icon-transform--active;
            }
        }

        .counter {
            &:before {
                content: "(";
            }

            &:after {
                content: ")";
            }
        }
        &.address,
        &.contract {
            text-transform: capitalize;
        }
    }

    /* hacer compatible con mage/tabs */
    .active {
        .tab__title {
            border: $tab__title-border--active;
            border-width: $tab__title-border-width--active;
            background: $tab__title-background--active;
            color: $tab__title-color--active;
            font-weight: $tab__title-font-weight--active;
            z-index: $tab__title-z-index--active;

            @include mq($screen-l) {
                z-index: $tab__title-z-index--active\@large;
            }

            .tab__icon {
                fill: $tab__icon-fill--active;
                transform: $tab__icon-transform--active;
            }
        }
    }

    &__icon {
        display: $tab__icon-display;
        position: $tab__icon-position;
        top: 0;
        bottom: 0;
        right: $tab__icon-right;
        margin: $tab__icon-margin;

        @include mq($screen-l) {
            display: $tab__icon-display\@large;
        }
    }

    &__content {
        position: relative;
        display: block;
        z-index: $tab__content-z-index;
        width: $tab__content-width;
        padding: $tab__content-padding;
        line-height: $tab__content-line-height;
        opacity: 0;
        overflow: hidden;
        height: 0;

        @include mq($screen-m) {
            padding: $tab__content-padding\@medium;
            flex-basis: 100%;
            z-index: $tab__content-z-index\@medium;
        }

        @include mq($screen-l) {
            padding: $tab__content-padding\@large;
        }

        @include mq($screen-xl) {
            padding: $tab__content-padding\@extra-large;
        }

        /* &[aria-hidden="false"] hacer compatible con mage/tabs */
        &--active, &[aria-hidden="false"] {
            display: block;
            opacity: 1;
            z-index: 1;
            height: auto;
            &:not(:nth-last-child(1)) {
                border: $tab__content-border--active;
                border-width: $tab__content-border-width--active;

                @include mq($screen-l) {
                    border: $tab__content-border--active\@large;
                    border-width: $tab__content-border-width--active;
                }
            }
            &.address {
                margin: $spacer--extra-large;
            }
        }

        &--secondary {
            padding: 0;
        }

        // It's necessary for display .tooltip in tab in PDP
        &.additional {
            overflow: initial;
        }
    }

    &.calendar-container {
        ul {
            &.time-ranges {
                list-style: none;
                align-items: center;
                padding: 0;
            }
            &.ui-tabs-nav {
                background-color: $tab__calendar-container--bg-color;
                display: flex;
                justify-content: space-around;
                flex-flow: wrap;
                flex-direction: column;
                list-style: none;
                width: 100%;
                margin: $tab__calendar-container--margin;
                padding: $tab__calendar-container--padding;
                border-radius: $tab__calendar-container--border-radius;
                height: $tab__calendar-container--height;
                align-items: center;
                li {
                    &.ui-tabs-active {
                        background-color: $tab__title-background--active;
                    }
                    text-decoration: none;
                    width: 50%;
                    height: $tab__calendar-container--li-height;
                    margin: $tab__calendar-container--li-margin ;
                    padding: $tab__calendar-container--li-padding ;
                    border-radius: $tab__calendar-container--border-radius - 2;
                    text-align: center;
                    a {
                        text-decoration: none;
                        color: $tab__calendar-container--a-color;
                        font-weight: normal;
                        text-align: center;
                        margin-top: $tab__calendar-container--a-margin;
                        font-size: $tab__calendar-container--a-fontsize;
                    }
                }
            }
        }

        .ui-tabs-panel {
            margin: auto;
        }
    }

    &-tabs {
        .tab {
            &__title {
                font-size: $tab-tabs__title-font-size;
                text-transform: $tab-tabs__title-text-transform;
                &:hover {
                    text-decoration: $tab-tabs__title-text-hover-decoration;
                }
            }
        }
    }
}
