@import "datepicker-variables";
#ui-datepicker-div {
    &.ui-datepicker {
        border: $datepicker__border;
        background-color: $datepicker__background-color;
        width: $datepicker__width;
        border-radius: $datepicker__border-radius ;
        margin-top: $datepicker__border-radius ;
        .ui-datepicker-prev {
            top: $datepicker__arrow-top;

            .ui-icon {
                transform: rotate(-225deg);
            }
        }

        .ui-datepicker-next {
            top: $datepicker__arrow-top;

            .ui-icon {
                transform: rotate(-45deg);
            }
        }
        .ui-datepicker-prev,
        .ui-datepicker-next {
            .ui-icon {
                border: none;
                border-right: $datepicker__icon-border;
                width: 12px;
                height: 12px;
                border-bottom: $datepicker__icon-border;
            }
        }
        .ui-datepicker-title {
            display: $datepicker__title-display;
            justify-content: $datepicker__title-justify-content;
            align-items: $datepicker__title-align-items;
            flex-direction: $datepicker__title-flex-direction;

            .ui-datepicker-month,
            .ui-datepicker-year {
                background-color: $datepicker__select-background-color;
                height: $datepicker__select-height;
                margin-right: $datepicker__select-margin-right;
            }
            .ui-datepicker-month {
                order: 1;
            }
        }

        .ui-datepicker-calendar {
            border-collapse: $datepicker__calendar-border-collapse;
            border: $datepicker__calendar-border;
            th,
            td {
                border: none;
                background-color: $datepicker__background-color;
            }
            th {
                span {
                    font-weight: normal;
                    text-transform: lowercase;
                    color: $datepicker__th--color;
                }
            }
            td {
                padding: $datepicker__td--padding;
                a {
                    border-radius: $datepicker__today-border--radius;
                    width: $datepicker__today--size;
                    height: $datepicker__today--size;
                    margin: 0 auto;
                    padding: $datepicker__today--padding;
                }
            }
            .ui-datepicker-today {
                a {
                    background-color: $datepicker__today-background-color;
                    border: $datepicker__today-border;
                    color: $datepicker__today-color--active;
                }

                .ui-state-default {
                    &.ui-state-hover {
                        background-color: $datepicker__today-background-color--active;
                        color: $datepicker__today-color--active;
                    }

                    &.ui-state-active {
                        &.ui-state-hover {
                            background-color: $datepicker__today-background-color--active;
                            color: $datepicker__today-color--active;
                        }
                    }
                }
            }

            .ui-datepicker-current-day {
                a {
                    background-color: $datepicker__current-day-background-color;
                    border: $datepicker__current-day-border;
                    color: $datepicker__current-day-color--active;
                }

                .ui-state-active {
                    background-color: $datepicker__current-day-background-color--active;
                    color: $datepicker__current-day-color--active;
                }
            }

            .ui-state-default {
                text-align: $datepicker__ui-state-text-align;

                &.ui-state-hover {
                    background-color: $datepicker__ui-state-background-color--active;
                    color: $datepicker__ui-state-color--active;
                }
            }
        }

        .ui-datepicker-buttonpane {
            border: 0;

            button {
                @extend .button;
                @extend .button--secondary;
            }
        }
        .timer-content {
            display: flex;
            flex-direction: column;
            .time-ranges {
                li {
                    padding: $datepicker__timer-padding;
                }
            }
            span {
                width: 300px;
                font-weight: $datepicker__timer--span-font-weight;
                text-align: center;
            }
            input[name="bsmrange"] {
                display: none;

                &:not(:checked) + span {
                    border: $datepicker__timer--span-border;
                    color: $datepicker__timer--span-color;
                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }
    .calendar-close-icon {
        color: $color-primary;
        margin-left: auto;
        font-size: $font-size-large;
        cursor: pointer;
    }
}
