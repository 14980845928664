$auth__link-width               : 50% !default; //100% !default;
$auth__link-width\@medium       : 32.5% !default; //440px !default;
$auth__link-width\@large        : 28.5% !default; //440px !default;
$auth__link-height              : auto !default; //72px !default;
$auth__link-border              : $border-base !default;
$auth__link-border-width        : $border-width-base 0 !default;
$auth__link-color               : $battleship-grey !default;
$auth__link-color--active       : $color-primary !default;
$auth__link-background--active  : transparent !default;  //$gray-light !default;

$auth__link-padding             : 0 0 $spacer--small 0!default;

$auth__link-font-family            : $font-family-base !default;
$auth__link-font-size              : $font-size-large !default;
$auth__link-font-weight            : $font-weight-bold !default; //$font-weight-normal !default;
$auth__link-font-weight--active    : $font-weight-bold !default;
$auth__link-text-decoration        : none !default;
$auth__link-text-decoration-active : none !default;
$auth__link-text-transform         : unset !default; //uppercase !default;

$auth__icon-transform--active   : rotate(180deg) !default;

$auth__heading-margin           : $spacer--extra-large 0 $spacer--semi-large !default;
$auth__heading-margin\@medium   : 120px 0 72px !default;

$auth__container-max-width      : 880px !default;
$auth__container-margin         : 0 auto !default;

$auth__box-border               : solid 1px $lighter-gray;
$auth__box-border-radius        : 10px;
$auth__box-padding              : 32px;
$auth__box-margin               : 32px 0;
$auth__box-width                : 100%;
$auth__box-width\@medium        : 48%;

$auth__box--bg-lightest-blue-bg : $lightest-gray;
