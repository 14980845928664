.iframe-wrapper {
  height: 0;
  overflow: hidden;

  &._visible {
    height: auto;
    overflow: visible;
  }
}

.button-wrapper {
  &._open {
    margin-top: $spacer--large;
    text-align: center;
  }
}

.card-messages {
  margin: $spacer--extra-large-xl 0;
}