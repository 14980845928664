$logo__max-width           : 80px !default; //140px !default;
$logo__max-width\@medium   : 100px !default; //256px !default;
$logo__max-width\@large    : 100px !default; //256px !default;
$logo__image-height        : 32px !default;
$logo__image-height\@medium: 48px !default;
$logo__image-width\@medium : auto !default;

.logo {
    display: flex;
    align-items: center;
    flex: 1 $logo__max-width;
    justify-content: center;
    max-width: $logo__max-width;

    @include mq($screen-m) {
        flex: 1 $logo__max-width\@medium;
        max-width: $logo__max-width\@medium;
    }

    @include mq($screen-l) {
        justify-content: flex-start;
        max-width: $logo__max-width\@medium;
    }
    @include mq($screen-xl) {
        justify-content: flex-start;
        max-width: $logo__max-width\@large;
    }

    &__image {
        height: $logo__image-height;

        @include mq($screen-m) {
            height: $logo__image-height\@medium;
            width: $logo__image-width\@medium;
        }
    }
}
