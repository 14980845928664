@import 'side-menu-variables';

.side-menu {
    order: $side-menu__menu-order;
    @include mq($side-menu__bq-visibility-max) {
        display: none;
    }

    &__trigger {
        background-color: $side-menu__trigger-bg-color;

        @include mq($screen-m) {
            border: $side-menu__trigger-border\@medium;
            border-width: $side-menu__trigger-border-width\@medium;
            padding: $side-menu__trigger-padding\@medium;
            margin: $side-menu__trigger-margin\@medium;
        }
        &-button-text{
            color: $side-menu__trigger-button-text-color;
            margin-left: $side-menu__trigger-button-text-margin-left;
        }
    }

    &__modal {
        background: $side-menu__bg-color;
        max-width: $side-menu__max-width;
        @include mq($screen-m) {
            max-width: $side-menu__max-width\@medium;
        }
    }


    &__container {
        margin-top: $side-menu__modal-container-top;
        max-width: $side-menu__modal-container-max-width;
        box-shadow: $side-menu__modal-container-box-shadow;
        padding: $side-menu__modal-container-padding;
        max-height: $side-menu__modal-container-max-height;
        @include mq($screen-m) {
            max-height: $side-menu__modal-container-max-height\@medium;
            margin-top: $side-menu__modal-container-top\@medium;
        }

        .dropdown-list{
            &__item{
                &--level1,
                &--level2{
                    a{
                        color: $side-menu__modal-dropdown-label-sub-items-color;
                        padding: $side-menu__modal-dropdown-label-sub-items-padding;
                    }
                }
            }
            &__label{
                &:hover{
                    color: $side-menu__modal-dropdown-label-hover-color;
                }
            }
            &__icon{
                right: 0;
            }

            [aria-expanded="false"]{
                .dropdown-list__icon{
                    transform: rotate(-90deg);
                }
            }
            [aria-expanded="true"]{
                .dropdown-list__icon{
                    transform: rotate(0deg);
                }
            }
        }
    }

    &__content {
        max-width: $side-menu__modal-content-max-width;
        margin: $side-menu__modal-content-margin;
        overflow-y: auto;
        @include hide-scrollbar();

        #dropdown-default{
            .dropdown-list__item{
                .dropdown-list__link-label-link {
                    &:hover{
                        color: $side-menu__modal-dropdown-label-hover-color;
                        text-decoration: none;
                    }
                }
                &--collapse{
                    a{
                        &[data-css="arrow-down"],
                        &.arrow-down{
                            float: right;
                            height: $spacer--medium;
                            top: $spacer--small + 3;
                            width: 10%;
                        }
                    }
                }
            }
        }
    }

    &__content-middle {
        padding: $side-menu__modal-middle-padding;
        overflow: visible;
    }

    &__content-bottom {
        padding: $side-menu__modal-bottom-padding;
        border: $side-menu__modal-bottom-border;
    }

    &__bottom-actions {
        max-width: $side-menu__modal-bottom-actions-max-width;
        margin: $side-menu__modal-bottom-actions-margin;
    }

    &__bottom-action {
        width: $side-menu__modal-bottom-action-width;
        flex-basis: $side-menu__modal-bottom-action-width;
    }

    &__close-button {
        top: $side-menu__modal-close-button-top;
        left: $side-menu__modal-close-button-left;
        background: $side-menu__modal-close-button-bg;
        @include mq($screen-m) {
            top: $side-menu__modal-close-button-top\@medium;
            left: $side-menu__modal-close-button-left\@medium;
        }

    }

    &__close-button-text {
        margin: $side-menu__modal-close-button-text-margin;
        color: $side-menu__modal-close-button-text-color;
        font-family: $side-menu__modal-close-button-text-font-family;
        font-size: $side-menu__modal-close-button-text-font-size;
        font-weight: $side-menu__modal-close-button-text-font-weight;
    }
}
