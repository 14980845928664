$information-margin: $spacer !default;

.information {
    margin-bottom: $information-margin * 2;

    &__title {
        margin-bottom: $information-margin;
    }

    &__text {
        @extend .paragraph;
        margin-bottom: 0;
        font-style: normal;
    }
}
