@import 'pager-variables';

.pager {
    width: 100%;
    text-align: center;

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        padding: $pager__list-padding;
        margin: $pager__list-margin;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: $pager__item-size;
        height: $pager__item-size;
        margin: $pager__item-margin;
        font-size: $pager__item-font-size;
        padding: 0;

        &:first-child {
            margin: $pager__item-first-child-margin;
        }

        &:last-child {
            margin: $pager__item-last-child-margin;
        }

        &--current {
            position: relative;
            color: $pager__item--current;
            text-decoration: $pager__item-text-decoration--current;

            &:after {
                content: '';
                position: $pager__item-after-position--current;
                bottom: 0;
                left: 0;
                z-index: -1;
                display: $pager__item-after-display--current;
                width: $pager__item-after-width--current;
                height: $pager__item-after-height--current;
                background-color: $pager__item-after-background-color--current;
                transition: $pager__item-after-transition--current;
                transform-origin: $pager__item-after-transform-origin--current;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $pager__link-width;
        height: $pager__link-size;
        color: $pager__link-color;
        text-decoration: $pager__link-text-decoration;


        &[disabled] {
            background-color: $pager__link-bg-disabled;
            pointer-events: none;
            cursor: not-allowed;

            .icon {
                fill: $pager__link-fill-disabled;
            }
        }

        &--prev,
        &--next {
            .icon {
                fill: $pager__link-fill;
            }
        }
    }

    &__icon {
        width: $pager__icon-size;
        height: $pager__icon-size;
    }
}
