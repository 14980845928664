@import 'message-variables';

.message {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: $max-content-width;
    padding: $message__padding;
    margin: $message__margin;
    background-color: $message__background;
    border-color: $message__border-color;
    border-width: $message__border-width;
    border-style: $message__border-style;
    border-radius: $message__border-radius;
    color: $message__color;
    font-size: $message__font-size;
    transition: $message__transition;

    &[style="display: block;"] {
        display: flex !important; // sass-lint:disable-line no-important
    }

    p {
        color: $message__color;
        font-size: $message__font-size;
        line-height: inherit;
        margin: 0;
    }

    @include mq($screen-m) {
        padding: $message__padding\@medium;
    }

    &:after {
        content: '';
        position: relative;
        order: $message__icon-order;
        height: $message__icon-spacer;
        width: $message__icon-width;
        min-width: $message__icon-width;
        margin: $message__icon-margin;
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"><path fill="#{$message__icon-fill}" d="M14.522 2.333c1.223 0 2.358.64 2.995 1.69l9.892 16.513a3.5 3.5 0 0 1-3.006 5.25H4.627a3.5 3.5 0 0 1-2.983-5.266l9.882-16.496a3.503 3.503 0 0 1 2.996-1.69zm0 2.334c-.407 0-.785.212-.997.559l-9.87 16.477a1.167 1.167 0 0 0 .985 1.75h19.75a1.167 1.167 0 0 0 1.007-1.734L15.52 5.23a1.167 1.167 0 0 0-.997-.562zm-.825 13.295a1.167 1.167 0 1 1 1.65 1.65 1.167 1.167 0 0 1-1.65-1.65zm.825-8.509c.644 0 1.166.523 1.166 1.167v4.667a1.167 1.167 0 0 1-2.333 0V10.62c0-.644.522-1.167 1.167-1.167z"/></svg>');
        background-repeat: no-repeat;
        background-size: $message__icon-spacer;
    }

    &--success {
        background-color: $message__background--success;
        border-color: $message__border-color--success;
        color: $message__color--success;

        &:after {
            background-image: svg-uri('<svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title/><desc/><g fill="none" fill-rule="evenodd" id="Action-/-6---Action,-check,-circle,-checkmark,-button,-ok-icon" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"><circle cx="12" cy="12" id="Path" r="10" stroke="#{$message__icon-fill--success}" stroke-width="2"/><polyline id="Path" points="7 11.5593959 11.1176466 15.5 17 8.5" stroke="#{$message__icon-fill--success}" stroke-width="2"/></g></svg>');
        }
    }

    &--error {
        background-color: $message__background--error;
        border-color: $message__border-color--error;
        color: $message__color--error;

        &:after {
            background-image: svg-uri('<svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"><path d="M14 1.167c7.088 0 12.833 5.745 12.833 12.833S21.088 26.833 14 26.833 1.167 21.088 1.167 14 6.912 1.167 14 1.167zM14 3.5C8.201 3.5 3.5 8.201 3.5 14S8.201 24.5 14 24.5 24.5 19.799 24.5 14 19.799 3.5 14 3.5zm-.825 13.175a1.167 1.167 0 1 1 1.65 1.65 1.167 1.167 0 0 1-1.65-1.65zM14 8.167c.644 0 1.167.522 1.167 1.166V14a1.167 1.167 0 0 1-2.334 0V9.333c0-.644.523-1.166 1.167-1.166z" fill="#{$message__icon-fill--error}" fill-rule="evenodd"/></svg>');
        }
    }

    .link {
        text-decoration: underline;
    }
    &.warning {
        border: none;
        @extend .mage-error;
        padding: 0;
        justify-content: left;
        &::after {
            content: none;
        }
    }
}

//extend messages generated in backend or 3rd party modules
.message-success {
    @extend .message--success;
}

.message-error {
    @extend .message--error;
}
.input.zip{
    .message {
        @extend .message.warning;
    }
}
