// Colors
$deep-orange    : #e35205;
$brick          : #ae3e26;
$burnt-umber    : #a13e12;
$prussian-blue  : #004f71;
$blue-yonder    : #4869AD;
$lightest-blue  : #e6edf5;
$lightest-gray  : #d0d0cf;
$dark-teal      : #00374f;
$corporate-red  : #d00018;
$irish-green    : #009f1a;
$battleship-grey: #637180;
$gunmetal       : #4f5a66;
$gunmetal-2     : #454f5a;
$blue-table     : #ccdaea;
$blue-table-lighter: $lightest-blue;
$gray-table-lighter: $lightest-gray;

$bsm-green: #78cb9f;
$bsm-grey: #3b3b3b;

// Grayscale colors
$black          : #000;
$dark-grey      : #35353a;
$gray           : #696973;
$gray-medium    : #cdcdcd;
$light-gray     : #f3f3f3;
$lighter-gray   : #ebebeb;
$white          : #fff;

// Semantic color scheme
$color-primary   : $bsm-grey;
$color-primary-20: $battleship-grey;
$color-primary-30: $gunmetal-2;
$color-secondary : $bsm-green;
$color-secondary-light: $lightest-gray;
$color-secondary-30 : $dark-teal;

$color-error     : $corporate-red;

$color-text      : $black;

// Background
$bg-color-base : $white;

// Border
$border-width-base           : 1px !default;
$border-style-base           : solid !default;
$border-color-base           : $lighter-gray !default;
$border-base                 : $border-width-base $border-style-base $border-color-base !default;

// Spacers
$spacer--extra-small         : 4px;
$spacer                      : 8px;
$spacer--small               : 12px;
$spacer--medium              : 16px;
$spacer--semi-medium         : 24px;
$spacer--large               : 32px;
$spacer--semi-large          : 40px;
$spacer--extra-large         : 48px;
$spacer--extra-large-xl      : 72px;

// Typography
$font-color-base             : $color-text;

$font-family-sans-serif      : 'Museo Sans', sans-serif;
$font-family-serif           : 'Museo Sans', serif;
$font-family-base            : $font-family-sans-serif;
$font-family-secondary       : $font-family-sans-serif;
$body-font-family            : $font-family-sans-serif;

$font-size-mega-large                   : 36px;
$font-size-mega-large\@screen-m         : 54px;
$font-size-super-extra-large            : 28px;
$font-size-super-extra-large\@screen-m  : 32px;
$font-size-extra-large                  : 24px;
$font-size-extra-large\@screen-m        : 28px;
$font-size-large                        : 21px;
$font-size-medium                       : 18px;
$font-size-base                         : 16px;
$font-size-small                        : 14px;
$font-size-extra-small                  : 12px;

$font-weight-light           : 300;
$font-weight-normal          : 500;
$font-weight-medium          : 600;
$font-weight-medium-bold     : 700;
$font-weight-bold            : 900;


// - Heading
$headings-color                : $color-text;
$headings-font-weight          : $font-weight-normal;
$headings-text-transform       : none;
$headings-text-transform--page : $headings-text-transform;

// - Sizes
$heading-font-size--page\@screen-m        : $font-size-mega-large\@screen-m;
$heading-font-size--page                  : $font-size-mega-large;
$heading-font-size--first-level\@screen-m : $font-size-mega-large\@screen-m;
$heading-font-size--first-level           : $font-size-mega-large;
$heading-font-size--second-level\@screen-m: $font-size-super-extra-large\@screen-m;
$heading-font-size--second-level          : $font-size-super-extra-large;
$heading-font-size--third-level\@screen-m : $font-size-extra-large\@screen-m;
$heading-font-size--third-level           : $font-size-super-extra-large;
$heading-font-size--fourth-level          : $font-size-large;
$heading-font-size--fifth-level           : $font-size-medium;
$heading-font-size--sixth-level           : $font-size-base;

$heading-font-weight--third-level\@screen-m : $font-weight-medium;
$heading-font-weight--third-level           : $font-weight-medium;
$heading-font-weight--fourth-level          : $font-weight-medium;
$heading-font-weight--fifth-level           : $font-weight-medium;
$heading-font-weight--sixth-level           : $font-weight-medium;

// Layout
$max-content-width           : 1328px;


// - Paragraph
$paragraph-color            : $color-text;
$paragraph-font-size        : $font-size-base;

// - Label
$label-color                : $color-text;
$label-font-weight          : $font-weight-medium;

// - Link
$link__color                : inherit;
$link__color-hover          : $color-secondary;
$link__color--invert        : $link__color-hover;
$link__color-hover--invert  : $link__color;

// Badge
$badge__padding             : 12px 10px 10px;
$badge__border-radius       : 25px;
$badge__background          : $color-primary;
$badge__color--new          : $white;
$badge__background--new     : $color-primary-30;

// Breadcrumbs
$breadcrumbs__font-size     : $font-size-small;
$breadcrumbs__color         : $battleship-grey;
$breadcrumbs__color--active : $color-text;

// Buttons
$button__min-size               : 46px;
$button__text-color             : $white;
$button__background             : $color-primary;
$button__background-hover       : $lightest-gray;
$button__text-color-hover       : $color-primary;
$button__background-active      : $color-primary-30;
$button__border-radius          : 25px;
$button__text-transform         : none;

// - Secondary
$button__color--secondary       : $color-primary;
$button__background--secondary  : $white;
$button__border--secondary      : 1px solid $button__color--secondary;

$button__color-hover--secondary                 : $color-primary;
$button__background-hover--secondary            : $bsm-green;
$button__background-active--secondary           : $bsm-green;

// - Subtitle
$button__color--subtitle       : $color-primary;
$button__background--subtitle  : $white;
$button__border--subtitle      : 1px solid $lighter-gray;

// - Icon
$button__icon-fill-hover--icon  : $white;
$button__background-hover--icon : $button__background-hover;

$button__icon-fill-hover--rotate-icon  : $white;
$button__background-hover--rotate-icon : $button__background-hover;

$button__icon-fill--icon-fill                   : $white;
$button__text-color--icon--icon-fill            : $white;
$button__text-color-hover--icon--icon-fill      : $white;
$button__background--icon-fill                  : $button__background;
$button__background-hover--icon-fill            : $button__background-hover;

$button__icon-fill-hover--icon-border  : $white;
$button__background-hover--icon-border : $button__background-hover;
$button__border--icon-border           : 1px solid $lighter-gray;
$button__border-hover--icon-border     : 1px solid $button__background-hover;

$button__background-hover--add-to      : $button__background-hover;

// shadow
$shadow                                         : 0 4px 10px 0 rgba(1, 72, 151, 0.1)  !default;

// - Link
$button__color-hover--link                      : $color-primary-20;
$button__color-active--link                     : $color-primary-30;


// - Disabled
$button__color-disabled             : $gray;
$button__background-disabled        : $light-gray;
$button__background-active--icon    : $button__background-active;

// Form
$form-elements-border           : 1px solid $lighter-gray;
$form-elements-border-focus     : 1px solid $color-primary;
$form-elements-radius           : 24px;
$form-elements-radius--small    : 18px;

// - Fieldset
$fieldset__legend-font-size     : $font-size-medium;
$fieldset__legend-font-weight   : $font-weight-medium;

// - Checkbox
$checkbox__label-color          : $label-color;
$checkbox__label-color--active  : $label-color;
$checkbox__label-font-weight    : $font-weight-normal;
$checkbox__label-font-weight--active: $checkbox__label-font-weight;
$checkbox__icon-size            : 20px;
$checkbox__before-size          : $checkbox__icon-size - 4px;

// - Radio
$radio__label-font-weight       : $checkbox__label-font-weight;
$radio__label-color             : $checkbox__label-color;
$radio__label-color-active      : $checkbox__label-color--active;
$radio__label-font-weight       : $checkbox__label-font-weight;
$radio__label-font-weight-active: $checkbox__label-font-weight--active;
$radio__size                    : 20px;
$radio__dot-size                : 12px;
$radio__icon-border             : 2px solid $gray;
$radio__icon-margin-right       : $spacer--small;

// - Input
$input__error-color             : $color-error;

// - Textarea
//$input__field-padding--textarea : $spacer--small $form-elements-radius;

// - Select
$select__label-margin           : 0 0 $spacer--extra-small $form-elements-radius;;
$select__label-color-focus      : $label-color;
$select__padding                : 0 $spacer--extra-large 0 $form-elements-radius;
$select__field-padding          : $select__padding;
$select__option-padding         : 0 $form-elements-radius;
$select__dropdown-border-width  : 1px;
$select__dropdown--search-input-border-color: $light-gray;

// - Toggles
$toggle__background-border      : 2px solid $light-gray;
$toggle__background--success    : $white;
$toggle__background--default    : $white;
$toggle__height                 : 26px;
$toggle__button-box-shadow      : unset;
$toggle__button-color--default  : $color-secondary;
$toggle__button-color--success  : $color-primary;
$toggle__button-border          : 2px solid #fff;
$toggle__button-width           : 22px;
$toggle__button-height          : 22px;

// TODO: Terminar de definir estilos componentes form
// - Input + icon before
// - Input + icon after
// - Calendar
// - Input + select before

// Tooltip
$tooltip__content-wrapper-width     : 480px;
$tooltip__content-background        : $dark-grey;
$tooltip__content-color             : $white;
$tooltip__content-font-size         : $font-size-extra-small;
$tooltip__heading-font-size         : $font-size-small;
$tooltip__content-wrapper-padding   : $spacer;
$tooltip__content-wrapper-border-radius: 5px;

// Message
$message__font-size             : $font-size-base;
$message__color                 : $color-text;
$message__background            : $white;
$message__border-width          : 1px;
$message__border-radius         : 10px;
$message__border-color          : $light-gray;
$message__icon-fill             : $bsm-green;

$message__color--success        : $message__color  ;
$message__background--success   : $message__background ;
$message__border-color--success : $message__border-color;
$message__icon-fill--success    : $irish-green;
$message__border-color--success : $message__icon-fill--success;

$message__color--error          : $message__color  ;
$message__background--error     : $message__background ;
$message__border-color--error   : $message__border-color;
$message__icon-fill--error      : $corporate-red;
$message__border-color--error   : $message__icon-fill--error;
// Header
// - Contact bar
$contact-bar__background        : $bg-color-base;

// - Marketing bar
$marketing-bar__background-color : $blue-yonder;
$marketing-bar__text-font-weight : $font-weight-normal;
$marketing-bar__text-font-color  : $white;
$marketing-bar__text-transform   : initial;

//Footer
$footer__background-color           : $bsm-grey;

// - Footer Menu
$footer__links-dropdown-background  : $footer__background-color;
$footer__links-label-background     : $footer__background-color;

// - Bottom bar
$footer__bottom-bar-background      : $bg-color-base;
$footer__bottom-bar-color           : $gray;
$footer__copywrite-font-size        : $font-size-small;
$footer__copywrite-color            : $footer__bottom-bar-color;

/*
TODO: Terminar de definir estilos Design Syetem
//Link -Auth
$auth__link-color             : $color-primary;
$auth__link-color--active     : $white;
$auth__link-background--active: $color-primary;
$auth__link-border-width      : 1px solid $color-primary;

// Table
$table__th-border-right\@medium : 0;
$table__td-border-right\@medium : 0;
$table__td-border-bottom\@medium: 1px solid $gray-dark;
$table__tfoot-td-border-top\@medium: $table__td-border-bottom\@medium;

//Dashboard
$dashboard-table__header-background : $white-bgr;
$actions-group__link-font-weight : $font-weight-normal;
$dashboard-table__header-padding : $spacer--medium 0;
$dashborad-form__title-padding : $spacer--medium 0;

$toolbar__amount-color: $black;
*/
