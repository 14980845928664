.checkout-index-index {
    .checkout-container {
        .checkbox__label {
            line-height: 1.2;
        }
    }
    .billing-address-form {
        .tab.address-container {
            margin-bottom: 0;
        }

        .field.street {
            .control {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .field:not(.additional) {
                width: 100%;
            }

            .additional {
                width: 32%;
            }

        }
        div[name="billingAddressshared.telephone"] {
            label {
                &:after {
                    content: '*';
                    color: #e02b27;
                    font-size: 1.2rem;
                    margin: 0 0 0 3px;
                }
            }
        }
        .action-help {
            display: none;
        }
    }
    .checkout-agreements-block {
        .checkout-agreement {
            .checkbox__label {
                span.link {
                    font-weight: $font-weight-bold;
                }
            }
        }
    }
}
