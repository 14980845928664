$checkbox__line-height                       : $font-line-height !default;
$checkbox__top                               : 50% !default;
$checkbox__left                              : 0 !default;
$checkbox__transform                         : translateY(-$checkbox__top) !default;

$checkbox__label-margin                      : 0 !important;
$checkbox__label-padding                     : $spacer--small 0 $spacer--small $spacer--large !default;
$checkbox__label-color                       : $color-secondary !default;
$checkbox__label-color--active               : $color-primary !default;
$checkbox__label-font-size                   : $font-size-base !default;
$checkbox__label-font-weight                 : $font-weight-normal !default;
$checkbox__label-font-weight--active          : $font-weight-normal !default;
$checkbox__label-text-decoration--no-icon    : underline !default;
$checkbox__label-padding-left--no-icon       : 0 !default;

$checkbox__icon-transition                   : $transition-base !default;
$checkbox__icon-size                         : 24px !default;
$checkbox__icon-fill                         : $white !default;

$checkbox__label-text-decoration-active--link: none !default;
$checkbox__color--link                       : $color-secondary !default;

$checkbox__text-width                        : calc(100% - #{$spacer--large}) !default;

// active
$checkbox__transition                        : all 0.5s ease-in-out !default;

// before used in active state
$checkbox__before-size                       : 20px !default;
$checkbox__before-position-left              : 2px !default;
$checkbox__before-position-top               : $checkbox__top !default;
$checkbox__before-border-radius              : 2px !default;
$checkbox__before-background                 : $color-primary !default;
$checkbox__before-transform                  : scaleX(0) translateY(-$checkbox__top) !default;
$checkbox__before-transform-origin           : 0 50% !default;
$checkbox__before-transform-hover            : scaleX(1) translateY(-$checkbox__top) !default;

// error
$checkbox__error-bottom                      : -#{$spacer} !default;

$checkbox__link-inner-text-decoration        : underline !default;
