$banner__width                                              : 100% !default;
$banner__text-decoration                                    : none !default;

$banner__image-width                                        : 100% !default;

$banner__content-padding                                    : $spacer--medium $spacer--extra-small $spacer--small !default;
$banner__content-padding\@medium                            : $spacer--medium 0 $spacer--small $spacer--semi-medium !default;
$banner__content-padding--category                          : $spacer--medium !default;
$banner__content-padding--category\@medium                  : $spacer--semi-large $spacer--semi-medium !default;
$banner__content-padding--category\@large                   : $spacer--semi-large $spacer--semi-large * 2 !default;
$banner__content-border                                     : $spacer--extra-small solid $gray-light !default;
$banner__content-background                                 : $bg-color-base !default;
$banner__content-after-height                               : $spacer--extra-small !default;
$banner__content-after-background                           : $color-primary !default;
$banner__content-after-transition                           : $transition-base !default;
$banner__content-after-left                                 : $spacer !default;
$banner__content-after-width-hover                          : calc(100% - #{2 * $spacer}) !default;
$banner__content-after-min-width                            : 0 !default;

$banner__text-margin                                        : 0 !default;
$banner__text-color                                         : $color-primary !default;
$banner__text-font-size                                     : $font-size-base !default;
$banner__text-font-size\@medium                             : $font-size-large !default;
$banner__text-font-weight                                   : $font-weight-bold !default;
$banner__text-text-transform                                : uppercase !default;

$banner__icon-size                                          : 48px !default;

$banner__button-collapse-transform                          : rotate(180deg) !default;
$banner__button-padding--category                           : 0 !default;
$banner__button-text-color-hover--category                  : $color-primary !default;
$banner__button-icon-fill-hover--category                   : $color-primary !default;
$banner__button-text-decoration--category                   : underline !default;
$banner__button-collapse-position--category\@large          : absolute !default;
$banner__button-collapse-right--category\@large             : 0 !default;
$banner__button-collapse-position--category-image\@large    : relative !default;
$banner__button-text-margin--category-image                 : 0 $spacer 0 0 !default;

$banner__actions-wrapper-padding                            : $spacer--medium 0 0 0 !default;

$banner__heading-wrapper-padding--category-image            : 0 0 0 $spacer--medium !default;
$banner__heading-wrapper-position--category-image\@medium   : absolute !default;
$banner__heading-wrapper-bottom--category-image\@medium     : 32px !default;
$banner__heading-wrapper-bottom--category-image\@large      : 40px !default;
$banner__heading-wrapper-bottom--category-image\@xl         : 48px !default;
$banner__heading-wrapper-left--category-image\@medium       : 0 !default;
$banner__heading-wrapper-left--category-image\@large        : 48px !default;
$banner__heading-wrapper-left--category-image\@xl           : 56px !default;

$banner__heading-wrapper-top--category-image\@medium        : 30% !default; //80px !default;
$banner__heading-wrapper-top--category-image\@large         : 88px !default;
$banner__heading-wrapper-top--category-image\@xl            : 96px !default;
$banner__heading-wrapper-top--category-image\@small         : 25px !default;

$banner__heading-wrapper-background--category-image\@medium : transparent;
$banner__heading-wrapper-width--category-image\@medium      : 100% !default;
$banner__heading-wrapper-max-width--category-image\@medium  : 375px !default;
$banner__heading-wrapper-padding--category-image\@medium    : $spacer--semi-medium $spacer--large $spacer--medium !default;

$banner__heading-margin--category                           : 0 !default;
$banner__heading-margin--category-image                     : $spacer--semi-medium 0 0 !default;
$banner__heading-margin--category-image\@medium             : 0 !default;
$banner__image-height                                       : 600px;
