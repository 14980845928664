@import 'yotpo-reviews-variables';
@import 'yotpo-reviews-important';

.page-main {
    .product-view,
    .product-item-info {
        .standalone-bottomline {
            &.star-clickable,
            .star-clickable {
                align-items: $yotpo-reviews__listing-star-clickable-align-items;
            }

            .yotpo-icon {
                font-size: $yotpo-reviews__listing-icon-size;
            }

            // show or not X reviews on product listings
            a {
                display: $yotpo-reviews__listing-review-display;
                font-size: $yotpo-reviews__listing-review-font-size;
                line-height: $yotpo-reviews__listing-review-line-height;
                margin-left: $yotpo-reviews__listing-review-margin-left;
            }
        }

        .yotpo {
            .yotpo-bottomline {
                .yotpo-icon-star,
                .yotpo-icon-half-star,
                .yotpo-icon-empty-star {
                    font-size: $yotpo-reviews__inline-icon-font-size;
                }
            }
        }
    }

    .product-view {
        .yotpo {
            div,
            span,
            p,
            a,
            img,
            i,
            strong,
            sup,
            ul,
            li,
            form,
            label,
            .yotpo-default-button,
            input[type="button"].yotpo-default-button {
                font-size: $yotpo-reviews__base-styles-font-size;
            }

            .yotpo-default-button span,
            input[type="button"].yotpo-default-button,
            .yotpo-icon-btn span {
                color: $yotpo-reviews__base-styles-button-color;
            }

            a {
                font-size: $yotpo-reviews__inline-link-font-size;
            }

            button,
            input,
            .yotpo-dropdown-button,
            .more-filters-btn {
                border-radius: $border-radius;
            }

            input {
                color: $yotpo-reviews__base-styles-input-color;
            }

            .write-question-review-button-text {
                width: $yotpo-reviews__write-question-review-button-text-width;
            }

            .yotpo-pager {
                display: $yotpo-reviews__pager-display;
                align-items: $yotpo-reviews__pager-align-items;
                justify-content: $yotpo-reviews__pager-justify-content;

                .goTo {
                    @extend .link;

                    position: $yotpo-reviews__pager-page-position;
                    width: $yotpo-reviews__pager-page-icon-size;
                    height: $yotpo-reviews__pager-page-icon-size;
                    display: $yotpo-reviews__pager-page-display;
                    align-items: $yotpo-reviews__pager-page-align-items;
                    justify-content: $yotpo-reviews__pager-page-justify-content;
                    margin: $yotpo-reviews__pager-page-margin;
                    color: $yotpo-reviews__pager-page-color;
                    font-size: $yotpo-reviews__pager-page-font-size;

                    + .goTo {
                        margin-left: $yotpo-reviews__pager-page-next-element-margin-left;
                    }

                    &.yotpo-active {
                        &:after {
                            @extend .pager__item--current:after;
                        }
                    }
                }

                .yotpo-icon {
                    @extend .link;

                    color: $yotpo-reviews__pager-arrow-icon-color;
                    font-weight: $yotpo-reviews__pager-arrow-font-weight;
                    align-items: $yotpo-reviews__pager-arrow-icon-align-items;
                    justify-content: $yotpo-reviews__pager-arrow-icon-justify-content;
                    position: $yotpo-reviews__pager-arrow-icon-position;

                    &:before {
                        opacity: $yotpo-reviews__pager-arrow-icon-opacity-before;
                        z-index: $yotpo-reviews__pager-arrow-icon-z-index-before;
                    }

                    &:hover {
                        &:after {
                            opacity: $yotpo-reviews__pager-arrow-icon-hover-opacity-after;
                            z-index: $yotpo-reviews__pager-arrow-icon-hover-z-index-after;
                        }
                    }

                    &.yotpo-icon-left-arrow {
                        margin-right: $yotpo-reviews__pager-icon-left-arrow-margin-right;
                    }

                    &.yotpo-icon-right-arrow {
                        margin-left: $yotpo-reviews__pager-icon-right-arrow-margin-left;
                    }
                }
            }

            .yotpo-regular-box {
                border:  $yotpo-reviews__regular-box-border;

                &.bottom-line-items-container {
                    overflow: $yotpo-reviews__main-bottom-container-overflow;
                    padding: $yotpo-reviews__main-bottom-container-padding;
                    margin: $yotpo-reviews__main-bottom-container-margin;
                    text-align: $yotpo-reviews__main-bottom-container-text-align;
                    width: $yotpo-reviews__main-bottom-container-width;

                    @include mq($screen-m) {
                        margin: $yotpo-reviews__main-bottom-container-margin\@medium;
                        width: $yotpo-reviews__main-bottom-container-width\@medium;
                    }

                    .bottom-line-items {
                        position: $yotpo-reviews__main-bottom-line-items-position;
                        overflow: $yotpo-reviews__main-bottom-line-items-overflow;
                        min-height: $yotpo-reviews__main-bottom-line-items-min-height;
                        margin-bottom: $yotpo-reviews__main-bottom-line-items-margin-bottom;
                    }

                    .avg-score {
                        position: $yotpo-reviews__main-average-score-position;
                        left: $yotpo-reviews__main-average-score-left;
                        top: $yotpo-reviews__main-average-score-top;
                        color: $yotpo-reviews__main-average-score-color;
                        height: $yotpo-reviews__main-average-score-height;
                        width: $yotpo-reviews__main-average-score-width;
                        margin: $yotpo-reviews__main-average-margin;
                        padding: $yotpo-reviews__main-average-score-padding;
                        line-height: $yotpo-reviews__main-average-score-line-height;

                        @include mq($yotpo-reviews__yotpo-vendor-large-breakpoint) {
                            position: $yotpo-reviews__main-average-score-position\@medium;
                            margin: $yotpo-reviews__main-average-margin\@medium;
                        }
                    }

                    .rating-stars-container,
                    .reviews-qa-labels-container {
                        margin-left: $yotpo-reviews__container-margin-left;

                        @include mq($yotpo-reviews__yotpo-vendor-large-breakpoint) {
                            margin-left: $yotpo-reviews__container-margin-left\@medium;
                        }
                    }

                    .reviews-qa-labels-container {
                        position: $yotpo-reviews__reviews-labels-container-position;
                        clear: $yotpo-reviews__reviews-labels-container-clear;
                        padding-top: $yotpo-reviews__reviews-labels-container-padding-top;
                    }

                    .yotpo-icon-star {
                        color: $yotpo-reviews__main-average-score-icon-color;
                    }

                    .reviews-qa-label {
                        color: $yotpo-reviews__main-average-reviews-count-color;
                        line-height: $yotpo-reviews__main-average-reviews-count-line-height;
                    }
                }
            }

            button.yotpo-default-button,
            div.yotpo-default-button,
            input[type="button"].yotpo-default-button,
            .more-filters-btn,
            .yotpo-filter-tag {
                &:disabled,
                &[disabled] {
                    &:hover {
                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            .filters-dropdown .yotpo-dropdown-button {
                height: $yotpo-reviews__base-styles-input-height;
                padding: $yotpo-reviews__dropdown-button-padding;
                border: $form-elements-border;

                .selected {
                    width: $yotpo-reviews__filter-selected-width;
                    font-weight: $yotpo-reviews__filter-selected-font-weight;
                }

                .selected:not(.non-default-item-selected) {
                    color: $yotpo-reviews__filter-selected-color;
                }
            }

            .more-filters-btn {
                .more-filters-icon {
                    margin-right: $yotpo-reviews__filter-more-filters-icon-margin-right;
                }

                .more-filters-text {
                    width: $yotpo-reviews__filter-more-filters-text-width;
                }
            }

            .yotpo-drop-down-layout .yotpo-dropdown {
                .list-category {
                    color: $yotpo-reviews__filter-list-category-color;
                }
            }

            .yotpo-nav-content {
                .yotpo-icon.yotpo-icon-magnifying-glass.magnifying-glass-icon {
                    left: $yotpo-reviews__magnifying-glass-icon-left;
                    top: $yotpo-reviews__magnifying-glass-icon-top;
                }

                .free-text-search-input {
                    padding-left: $yotpo-reviews__nav-search-input-padding-left;
                }
            }

            .yotpo-nav {
                display: $yotpo-reviews__nav-display;

                ul {
                    margin-top: $yotpo-reviews__nav-display-ul-margin-top;

                    .yotpo-nav-tab {
                        span {
                            color: $yotpo-reviews__nav-tab-color;
                        }

                        &.yotpo-active {
                            span {
                                color: $yotpo-reviews__nav-tab-color--active;
                            }

                            .yotpo-nav-wrapper {
                                border-color: $yotpo-reviews__nav-tab-border-color;
                            }
                        }
                    }
                }
            }

            .yotpo-review,
            .yotpo-comment,
            .yotpo-question,
            .yotpo-onsite-upload {
                .yotpo-header {
                    .yotpo-icon-profile {
                        width: $yotpo-reviews__icon-profile-size;
                        height: $yotpo-reviews__icon-profile-size;
                        background: $yotpo-reviews__icon-profile-background;
                    }

                    .yotpo-user-letter {
                        color: $yotpo-reviews__icon-profile-letter-color;
                        font-size: $yotpo-reviews__icon-profile-letter-font-size;
                    }

                    .yotpo-header-element {
                        .y-label.yotpo-user-name {
                            color: $yotpo-reviews__username-color;
                            font-size: $yotpo-reviews__username-font-size;
                            text-transform: $yotpo-reviews__username-text-transform;
                            font-weight: $yotpo-reviews__username-font-weight;
                        }

                        .yotpo-review-date {
                            display: $yotpo-reviews__date-display;
                            color: $yotpo-reviews__date-color;
                            font-size: $yotpo-reviews__date-font-size;
                            font-weight: $yotpo-reviews__date-font-weight;

                            @include mq($yotpo-reviews__footer-breakpoint) {
                                display: $yotpo-reviews__date-display\@large;
                            }
                        }
                    }
                }

                .content-review,
                .content-question {
                    color: $yotpo-reviews__content-review-color;
                    font-size: $yotpo-reviews__content-review-font-size;
                    line-height: $yotpo-reviews__content-review-line-height;
                }

                .yotpo-footer {
                    display: $yotpo-reviews__footer-display;
                    flex-wrap: $yotpo-reviews__footer-flex-wrap;
                    border-bottom: $yotpo-reviews__footer-border-bottom;
                    padding: $yotpo-reviews__footer-padding;

                    @include mq($screen-l) {
                        justify-content: $yotpo-reviews__footer-justify-content\@large;
                        border: $yotpo-reviews__footer-border\@large;
                    }

                    .footer-actions {
                        order: $yotpo-reviews__footer-actions-order;
                        margin-bottom: $yotpo-reviews__footer-actions-margin-bottom;

                        @include mq($screen-l) {
                            margin-right: $yotpo-reviews__footer-actions-margin-right\@large;
                        }
                    }

                    .yotpo-grouping-reference {
                        width: $yotpo-reviews__grouping-reference-width;
                        margin: $yotpo-reviews__grouping-reference-margin;
                    }

                    .footer-additional-actions {
                        order: $yotpo-reviews__footer-add-actions-order;
                        width: $yotpo-reviews__footer-add-actions-width;
                        display: $yotpo-reviews__footer-add-actions-display;

                        @include mq($screen-l) {
                            display: $yotpo-reviews__footer-add-actions-display\@large;
                        }
                    }

                    .yotpo-helpful {
                        order: $yotpo-reviews__action-helpful-order;
                        width: $yotpo-reviews__action-helpful-width;
                        margin-left: $yotpo-reviews__action-helpful-margin-left;
                        text-align: $yotpo-reviews__action-helpful-text-align;

                        @include mq($screen-l) {
                            margin-left: $yotpo-reviews__action-helpful-margin-left\@large;
                        }

                        .yotpo-icon {
                            font-size: $yotpo-reviews__action-helpful-icon-size;
                            color: $yotpo-reviews__action-helpful-icon-color;
                            padding: $yotpo-reviews__action-helpful-icon-padding;
                        }

                        .vote-sum {
                            font-size: $yotpo-reviews__action-helpful-vote-font-size;
                            color: $yotpo-reviews__action-helpful-vote-color;
                        }

                        .vote-btn {
                            margin: $yotpo-reviews__action-helpful-vote-btn-margin;
                        }
                    }

                    .label-helpful {
                        color: $yotpo-reviews__action-helpful-color;
                        text-transform: $yotpo-reviews__action-helpful-text-transform;
                    }

                    .yotpo-action,
                    .yotpo-action .social-link {
                        font-size: $yotpo-reviews__action-font-size;
                        text-transform: $yotpo-reviews__action-text-transform;
                    }
                }

                .yotpo-main .content-title {
                    font-size: $yotpo-reviews__content-title-font-size;
                    color: $yotpo-reviews__content-title-color;
                    font-weight: $yotpo-reviews__content-title-font-weight;
                }
            }


            .yotpo-bottomline {
                .yotpo-icon-star,
                .yotpo-icon-half-star,
                .yotpo-icon-empty-star {
                    font-size: $yotpo-reviews__inline-icon-font-size;
                }
            }

            &.yotpo-main-widget,
            .yotpo-modal-dialog {
                .yotpo-thank-you .yotpo-thankyou-header {
                    span {
                        color: $yotpo-reviews__thank-you-text-color;
                    }

                    .yotpo-icon {
                        color: $yotpo-reviews__thank-you-heart-icon-color;
                    }
                }

                .yotpo-logo-link-new {
                    > *,
                    &:hover > * {
                        color: $yotpo-reviews__logo-icon-color;
                    }
                }

                .yotpo-label .yotpo-logo-title {
                    color: $yotpo-reviews__logo-text-color;
                }

                .yotpo-stars-and-sum-reviews {
                    display: $yotpo-reviews__stars-and-sum-reviews-display;
                    align-items: $yotpo-reviews__stars-and-sum-reviews-align-items;
                    min-height: $yotpo-reviews__stars-and-sum-reviews-min-height;

                    .yotpo-sum-reviews,
                    .based-on {
                        padding: $yotpo-reviews__sum-reviews-padding;
                    }
                }

                .yotpo-first-review-stars {
                    display: $yotpo-reviews__first-review-icons-display;
                }

                .write-first-review-button {
                    display: $yotpo-reviews__first-review-button-display;
                }

                .write-first-question-button {
                    display: $yotpo-reviews__first-question-button-display;
                }
            }

            &.yotpo-main-widget {
                .yotpo-icon-write-no-frame:before {
                    color: $yotpo-reviews__review-button-icon-color;
                }

                .yotpo-bottomline.yotpo-bottomline-empty-state .write-question-button,
                .yotpo-bottomline.yotpo-bottomline-empty-state .write-review-button,
                .yotpo-icon-btn {
                    display: inline-flex;
                    align-items: center;
                }

                .yotpo-bottomline.yotpo-bottomline-2-boxes .yotpo-bottomline-box-1 {
                    .write-review-button,
                    .write-question-button {
                        display: none;
                    }
                }

                .yotpo-thank-you {
                    .yotpo-icon-btn {
                        .yotpo-icon-button-text {
                            display: none;
                        }
                    }
                }
            }

            .yotpo-review {
                .yotpo-header {
                    .yotpo-review-stars {
                        .yotpo-icon {
                            color: $yotpo-reviews__review-icon-color;
                            font-size: $yotpo-reviews__review-icon-size;
                        }
                    }
                }

                .yotpo-read-more {
                    @extend .link;
                }
            }

            .write-question-button:not(.write-first-question-button),
            .write-button:not(.write-first-review-button) {
                .yotpo-icon {
                    margin-right: $yotpo-reviews__review-write-button-icon-not-first;
                }
            }

            .write-review {
                .error-box {
                    background: $yotpo-reviews__write-review-error-box-bg;
                }

                .yotpo-default-button.primary-color-btn.yotpo-submit {
                    display: block;
                    width: auto;
                    float: none;
                }

                .yotpo-icon {
                    &:hover {
                        cursor: pointer;
                    }
                }

                .yotpo-footer {
                    @include mq($yotpo-reviews__footer-breakpoint) {
                        flex-direction: row;
                        justify-content: space-between;
                    }

                    @include mq($yotpo-reviews__footer-breakpoint) {
                        justify-content: flex-start;
                    }

                    &.visible {
                        flex-direction: column;

                        @include mq($yotpo-reviews__footer-breakpoint) {
                            flex-direction: row;
                        }
                    }

                    .socialize-wrapper.visible {
                        flex-wrap: $yotpo-reviews__soc-wrapper-visible-flex-wrap;
                        justify-content: $yotpo-reviews__soc-wrapper-visible-justify-content;
                        flex-direction: $yotpo-reviews__soc-wrapper-visible-flex-direction;
                        order: $yotpo-reviews__soc-wrapper-visible-order;
                        margin-top: $yotpo-reviews__soc-wrapper-visible-margin-top;

                        @include mq($yotpo-reviews__footer-breakpoint) {
                            left: $yotpo-reviews__soc-wrapper-visible-left\@large;
                            right: $yotpo-reviews__soc-wrapper-visible-right\@large;
                            width: $yotpo-reviews__soc-wrapper-visible-width\@large;
                            margin-left: $yotpo-reviews__soc-wrapper-visible-margin-left\@large;
                            margin-top: $yotpo-reviews__login-username-top-offset;
                            padding-top: $yotpo-reviews__soc-wrapper-visible-padding-top\@large;
                        }

                        .connected {
                            width: $yotpo-reviews__soc-wrapper-connected-width;
                            margin: $yotpo-reviews__soc-wrapper-connected-margin;

                            @include mq($screen-m) {
                                padding-left: $yotpo-reviews__soc-wrapper-connected-padding-l\@medium;
                            }
                        }
                    }

                    .socialize {
                        order: $yotpo-reviews__socialize-order;
                        margin-bottom: $yotpo-reviews__socialize-margin-bottom;

                        .y-label {
                            @include mq($screen-m) {
                                padding-top: $yotpo-reviews__socialize-padding-top\@medium;
                            }
                        }

                        > div {
                            display: flex;

                            .yotpo-icon-btn {
                                width: $yotpo-reviews__icon-btn-width;
                                height: $yotpo-reviews__icon-btn-height;
                                margin: $yotpo-reviews__icon-btn-margin;
                            }

                            div[data-network="facebook"] {
                                order: 1;
                            }

                            div[data-network="twitter"] {
                                order: 2;
                            }

                            div[data-network="linkedin"] {
                                order: 3;
                            }
                        }
                    }
                }

                .connect-wrapper {
                    padding: $yotpo-reviews__connect-wrapper-padding;

                    @include mq($screen-m) {
                        width: $yotpo-reviews__connect-wrapper-width\@medium;
                    }

                    .form-element {
                        padding: $yotpo-reviews__connect-wrapper-form-el-padding;
                        width: $yotpo-reviews__connect-wrapper-form-el-width;

                        .form-input-error {
                            @extend %error;
                        }
                    }
                }

                .yotpo-header {
                    .y-label {
                        .form-input-error {
                            @extend %error;
                        }
                    }

                    .yotpo-icon {
                        color: $yotpo-reviews__header-icon-color;
                    }
                }

                .submit-button {
                    float: none;

                    .form-input-error {
                        @extend %error;

                        margin: $yotpo-reviews__form-input-error-margin;
                    }
                }

                .socialize-wrapper {
                    position: $yotpo-reviews__login-socialize-wrapper-position;
                    width: $yotpo-reviews__login-socialize-wrapper-width;

                    .yotpo-or {
                        margin: $yotpo-reviews__login-soc-wrapper-or-margin;
                        text-align: $yotpo-reviews__login-socialize-wrapper-or-text-align;

                        @include mq($screen-m) {
                            margin: $yotpo-reviews__login-soc-wrapper-or-margin\@medium;
                            width : $yotpo-reviews__login-soc-wrapper-or-width\@medium;
                        }
                    }
                }
            }

            .form-group .form-element .form-input-error {
                @extend %error;
            }

            .yotpo-regular-box {
                margin: $yotpo-reviews__regular-box-margin;
            }

            input,
            textarea {
                @extend .input__field;
            }
        }

        .yotpoBottomLine {
            order: -1;

            a {
                margin-right: $yotpo-reviews__bottom-line-links-margin-right;
            }

            .yotpo-stars {
                margin-right: $yotpo-reviews__bottom-line-stars-margin-right;
            }

            .yotpo-icon-double-bubble {
                margin-right: $yotpo-reviews__bottom-line-double-bubble-margin-right;
            }
        }

        .bottomLine,
        .QABottomLine {
            margin-bottom: $spacer;
        }

        .yotpo-empty-search-results {
            display: $yotpo-reviews__empty-search-results-display;
            height: $yotpo-reviews__empty-search-results-height;
        }

        .empty-search-results-text,
        .empty-search-results-clear-all-btn {
            width: auto;
        }

        .empty-search-results-clear-all-btn {
            position: $yotpo-reviews__empty-search-results-clear-all-btn-position;
            height: $yotpo-reviews__empty-search-results-clear-all-btn-height;
            margin: $yotpo-reviews__empty-search-results-clear-all-btn-margin $spacer--medium;
        }

        .yotpo-reviews-filters .yotpo-default-button.desktop-clear-all-btn {
            position: $yotpo-reviews__desktop-clear-all-btn-position;
        }

        .yotpo-filter-tag .yotpo-icon-cross {
            top: $yotpo-reviews__filter-tag-icon-cross-top;
            right: $yotpo-reviews__filter-tag-icon-cross-right;
        }
    }
}

// styles for modal added before body closing tag
.catalog-product-view {
    .mobile-filters-modal {
        .mobile-filters-footer-btn {
            background-color: $yotpo-reviews__mobile-filter-btn-bg-color;

            .footer-text {
                background-color: $yotpo-reviews__mobile-filter-btn-bg-color;
                color: $yotpo-reviews__mobile-filter-btn-text-color;
                font-size: $yotpo-reviews__mobile-filter-btn-font-size;
            }
        }

        .mobile-filters-modal-title {
            .title-text {
                font-size: $yotpo-reviews__mobile-filter-title-font-size;
                color: $yotpo-reviews__mobile-filter-title-text-color;
            }
        }

        .mobile-filters-container {
            .mobile-single-filter {
                .mobile-filter-radio-buttons .mobile-filter-radio-button {
                    .radio-selected-color {
                        color: $yotpo-reviews__mobile-filter-text-color;

                        .yotpo-icon {
                            color : $yotpo-reviews__star-non-selected-color;
                        }
                    }

                    .radio-selected-star-color {
                        &.yotpo-icon-star {
                            color : $yotpo-reviews__star-selected-color;
                        }
                    }
                }

                .mobile-filter-title {
                    color: $yotpo-reviews__mobile-filter-title-color;
                    font-weight: $yotpo-reviews__mobile-filter-title-font-weight;
                }
            }
        }
    }
}
