@import 'input-variables';

.input {
    margin-bottom: $input__margin-bottom;
    &:focus-within {
        .input__label {
            color: $input__label-focus-color;
        }

        .input__field {
            background-color: $input__field-background-color;
            &::placeholder {
                color: $input__placeholder-color;
            }
        }
    }

    &--label-big {
        .input__label {
            font-size: $input__label-font-size--big;
        }
    }

    &--inline {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .input__label {
            @extend .label--inline;

            margin: $input__label-margin;
            white-space: $input__white-space--inline;
        }
    }

    &--password {
        position: relative;

        &:focus-within {
            .input__field {
                @include focus-input();
            }
        }
    }

    &__field {
        color: $input__field-color;
        width: 100%;
        height: $input__field-spacing;
        padding: $input__field-padding;
        border: $input__field-border;
        border-radius: $input__field-border-radius;
        line-height: normal;
        transition: $input__transition;

        &::placeholder {
            color: $input__placeholder-color;
            font-size: $input__placeholder-font-size;
            font-style: $input__placeholder-font-style;
        }

        &.focus-visible {
            @include focus-input();
        }

        &:disabled, &__disabled {
            background-color: $input__background-disabled !important;
            cursor: not-allowed;
        }

        &--textarea {
            display: block;
            min-width: 100%;
            max-width: 100%;
            min-height: $input__min-height--textarea;
            padding: $input__field-padding--textarea;
            border-radius: $input__field-border-radius--textarea;
            line-height: $input__field-line-height--textarea;
        }
    }

    &__label {
        margin: $input__label-margin;
        transition: $input__transition;
    }

    &__button-pass {
        position: absolute;
        top: $input__button-pass-top;
        right: $input__button-pass-right;
        height:  $input__button-pass-height;
        background: $input__button-pass-bg;

        & > .icon {
            fill: $input__button-pass-icon-fill;

        }

        .input__button-pass-icon-hide {
            display: none;
        }

        &--active {
            .input__button-pass-icon-hide {
                display: block;
            }
            .input__button-pass-icon-view {
                display: none;
            }
        }
        &:hover{
            background-color: unset;
            .icon {
                fill: $input__button-pass-icon-fill;

            }
        }

        &.--icon-css {
            &::before {
                background-image: $input__button-pass-before-icon-view-off;
                background-position: center center;
                background-repeat: no-repeat;
                content: '';
                height: $input__button-pass-before-icon-view-height;
                fill: $input__button-pass-before-icon-view-fill ;
                width: $input__button-pass-before-icon-view-width ;
            }
            &.__active {
                &::before {
                    background-image: $input__button-pass-before-icon-view-on;
                }
            }
        }
    }

    &--datepicker {
        .ui-datepicker-trigger {
            @include visually-hidden();
        }

        input {
            @extend .input__field;
        }
    }
}
.date, .date-field {
    position: relative;

    .datetime-picker,
    .datepicker-input {
        @extend .input__field;
        background-image: $input__datepicker-background-image;
        background-size: $input__datepicker-background-size ;
        background-repeat: no-repeat;
        background-position: $input__datepicker-background-position;
        cursor: pointer;
        text-align: center;
        font-size: $input__datepicker-font-size;
        &::placeholder {
            padding-left: $spacer--small;
            font-size: $input__datepicker-font-size;
        }
        &.contract {
            width: 362px;
        }
    }
    .datepicker-input {
        background-position: $input__datepicker-wrapped-background-position;
    }
    div.close {
        content: '';
        background-image: $input__datepicker-span-background-image;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 14px;
        cursor: pointer;
        height: 24px;
        position: absolute;
        right: 8px;
        width: 24px;
    }


    .focus-visible {
        +div.close{
            background-image: $input__datepicker-span-close-background-image;
        }
    }

    .ui-datepicker-trigger {
        position: absolute;
        height: $input__field-spacing;
        left: $input__date-trigger-left;
        bottom: $input__date-trigger-bottom;
        width: $input__date-trigger-width;
        background: $input__date-trigger-background;
        box-shadow: $input__date-trigger-box-shadow;
        border: $input__date-trigger-border;

        span, img {
            display: none;
        }
    }
    &.contract {
        justify-content: space-between;
    }
}
.fieldset.col.name {
    .input {
        flex-basis: 100%;
    }
}
.field {
    .label {
        @extend .input__label;
        &__radio {
            padding: $input__label-radio-padding;
            border-bottom: $input__label-radio-border;
            font-size: $font-size-base !important;
            margin: 0;
            &:last-of-type {
                border-bottom: none;
            }
            .radio {
                accent-color: $color-primary;
            }
        }
    }
}
.cms-home,
.amlocator-index-index {
    .datepicker-input {
        @include mq($screen-m) {
            background-image: $input__datepicker-background-images;
            background-size: 100%;
        }
        @include mq($screen-l) {
            background-image: $input__datepicker-background-image\@large;
            background-size: $input__datepicker-background-size\@large;
        }
    }
    div.close {
        bottom: 18px;
        @include mq($screen-m) {
            display: none;
        }
    }
}
.cms-home {
    .date-field .datepicker-input {
        @include mq($screen-xl) {
            background-position: 4px center;
            min-width: 260px;
        }

        &:lang(en) {
            @include mq($screen-xl) {
                min-width: 315px;
            }
        }
        &:lang(fr) {
            @include mq($screen-xl) {
                min-width: 305px;
            }
        }
    }
}
.amlocator-index-index {

    .date-field{
        .datepicker-input {
            @include mq($max-screen: $screen-m - 1) {
                background-color: transparent;
            }
            padding-left: $spacer--large + 4;
            padding-right: $spacer--large;
        }
        div.close {
            z-index: -1;
        }
    }
}
