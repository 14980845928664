$steps__margin                      : $spacer--semi-large $spacer--semi-large $spacer--extra-large !default;

$steps__line-width                  : 100% !default;
$steps__line-border                 : 2px $border-style-base $border-color-base !default;

$steps__status-border               : 2px $border-style-base $blue !default;
$steps__status-width--1             : 33% !default;
$steps__status-width--2             : 66% !default;
$steps__status-width--3             : 100% !default;

$steps__circle-size                 : 20px !default;
$steps__circle-bg                   : $gray !default;

$steps__list-width                  : 100% !default;
$steps__list-padding                : 0 !default;

$steps__item-after-content          : attr(data-label) !default;
$steps__item-after-top              : 32px !default;
$steps__item-after-white-space      : nowrap !default;

$steps__circle-bg--failed           : $color-error !default;
$steps__circle-bg--completed        : $blue !default;

$steps__icon-size--failed           : 12px !default;
$steps__icon-after-height--failed   : 12px !default;
$steps__icon-after-width--failed    : 2px !default;
$steps__icon-bg--failed             : $white !default;

$steps__item-before-content--checked: attr(data-number) !default;
$steps__item-before-z-index--checked: 1 !default;
$steps__item-before-color--checked  : $white !default;

$steps__circle-size--checked        : 30px !default;

%check-icon {
    top: -1px;
    transform: rotate(45deg);
    height: 10px;
    width: 7px;
    border-bottom: 2px $border-style-base $white;
    border-right: 2px $border-style-base $white;
}
