@import "post-variables";

.widget {
    &-card {
        background-color: $widget-card--background-color;
        border: $widget-card--border;
        border-radius: $widget-card--radius;
        padding: $widget-card--padding;
        margin: $widget-card--margin;
        width: 100%;

        @include mq($screen-m) {
            padding: $widget-card--padding\@medium;
            margin: $widget-card--margin\@medium;
        }

        &:nth-of-type(even) {
            @include mq($screen-m) {
                margin: $widget-card-even--margin;
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            @include mq($screen-m) {
                flex-direction: row;
            }

            &.card-x2 {
                .widget-card {
                    @include mq($screen-m) {
                        width: $widget-card-x2-width\@medium;
                    }
                }
            }

            &.card-x3 {
                .widget-card {
                    @include mq($screen-m) {
                        width: $widget-card-x3-width\@medium;
                    }
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        &__image {
            img {
                max-width: $widget-card-image--max-width;
            }
        }

        &__title {
            margin: $widget-card-title--margin;

            h3 {
                font-size: $widget-card-title--font-size;
                font-weight: $widget-card-title--font-weight;
                margin: 0;

                @include mq($max-screen: $screen-m - 1) {
                    line-height: normal;
                }
            }

            .sub-title {
                font-size: $widget-card-sub-title--font-size;
                font-weight: $widget-card-sub-title--font-weight;
            }
        }

        &__text {
            font-size: $widget-card-text--font-size;
            font-weight: $widget-card-text--font-weight;
            min-height: $widget-card-text--min-height;
        }

        &__button {
            margin: $widget-card-button-margin;

            a {
                width: $widget-card-button-a-width\@small;

                @include mq($screen-m) {
                    width: auto;
                }

                &.link-primary {
                    color: $widget-card-button-a-primary;
                    font-weight: $widget-card-button-a-primary--font-weight;

                    &:hover {
                        color: $widget-card-button-a-primary-hover;
                    }
                }
            }
        }
    }

    &-header-landing {
        position: relative;
        min-height: 120px;

        &:before {
            background-color: $widget-header-landing-before-bg;
            content: ' ';
            height: $widget-header-landing-before-height;
            left: 50%;
            overflow: hidden;
            position: $widget-header-landing-before-position;
            top: $widget-header-landing-before-top;
            transform: translateX(-50%);
            width: 99.5vw;
            z-index: -1;
        }

        &-title {
            h2 {
                font-size: $widget-header-landing-title;
                margin: $widget-header-landing-title-margin;

                @include mq($screen-m) {
                    font-size: $widget-header-landing-title\@medium;
                }
            }
        }

        &-description {
            margin: $widget-header-landing-description-margin;
        }
    }

    &.block.block-categories {
        .block-title {
            display: none;
        }

        ul {
            li {
                color: $widget-sidebar--list-color;
            }

        }
    }


    &-card--horizontal {
        margin: $widget-card--card--horizontal-margin;

        &.txt_img {
            flex-direction: row-reverse;
            .widget-card--horizontal__title,
            .content,
            .action {
                @include mq($screen-m) {
                    padding-right: $widget-card--card--horizontal-content-padding-right;
                }
            }
        }

        &.img_txt {
            .widget-card--horizontal__title,
            .content,
            .action {
                @include mq($screen-m) {
                    padding-left: $widget-card--card--horizontal-content-padding-left;
                }
            }
        }

        &__title {
            @include mq($max-screen: $screen-m - 1) {
                margin: $widget-card--card--horizontal-title-margin\@medium;
            }
        }

    }

    &-card--mini {
        background-color: $widget-card--mini-background-color;
        border-radius: $widget-card--mini-border-radius;
        border: $widget-card--mini-border;
        display: inline-flex;
        flex-direction: column;
        margin: $widget-card--mini-margin;
        padding: $widget-card--mini-padding;
        position: relative;
        width: 100%;
        @include mq($screen-m) {
            margin: $widget-card--mini-margin\@medium;
        }
        @include mq($max-screen: $screen-l -1) {
            width: auto !important;
        }
        @include mq($max-screen: $screen-m - 1) {
            width: 100% !important;
        }
        &__image {
            margin-bottom: $widget-card--mini__image-margin-bottom;
        }

        &__title {
            font-size: $widget-card--mini__title-font-size;
            padding-right: $widget-card--mini__title-padding-right;
            @include mq($screen-l) {
                font-size: $widget-card--mini__title-font-size\@medium;
                padding-right: $widget-card--mini__title-padding-right\@large;
            }
            @include mq($screen-xl) {
                font-size: $widget-card--mini__title-font-size\@large;
            }
            &-wrapper {
                align-items: flex-start;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                text-align: left;
                width: 100%;                
            }
        }
        &.__has_link {
            .widget-card--mini__title {
                font-size: $widget-card--mini__title-font-size;
                font-weight: $widget-card--mini__title__has-link-font-weight;
                padding-right: $widget-card--mini__title__has-link-padding-right;
                @include mq($screen-m) {
                    font-size: $widget-card--mini__title-font-size\@medium;
                    padding-right: $widget-card--mini__title__has-link-padding-right\@medium;
                }
            }
        }
        &.__has_text {
            .widget-card--mini__title {
                font-size: $widget-card--mini__title_has_text-font-size;
            }
        }
        a {
            text-decoration: none;
        }
    }

    &-card-mini-group {
        margin-bottom: $widget-card-mini-group-margin-bottom;
        @include mq($screen-m) {
            margin-bottom: $widget-card-mini-group-margin-bottom\@large ;
        }
        position: relative;
        &__image {
            img {
                max-width: $widget-card-mini-group__image-max-width;
                @include mq($screen-l) {
                    max-width: $widget-card-mini-group__image-max-width\@large;
                }
            }
        }
        &__content {
            margin-right: $widget-card-mini-group__content-margin-right;
            max-width: $widget-card-mini-group__content-max-width ;
            position: relative;
            top: $widget-card-mini-group__content-top ;
            @include mq($screen-m) {
                margin-right: $widget-card-mini-group__content-margin-right\@medium ;
                max-width: $widget-card-mini-group__content-max-width\@medium;
                position: absolute;
                top: $widget-card-mini-group__content-top\@medium ;
                right: 0;
            }
            
            .card-mini {
                background-color: $widget-card-mini-group__minicard-background-color ;
                border-radius: $widget-card-mini-group__minicard-border-radius ;
                border: $widget-card-mini-group__minicard-border;
                max-width: $widget-card-mini-group__minicard-max-width;   
                min-height: $widget-card-mini-group__minicard-min-height;
                padding: $widget-card-mini-group__minicard-padding; 
                position: relative;
                width: $widget-card-mini-group__minicard-width;
                &:nth-of-type(odd) {
                    margin: $widget-card-mini-group__minicard-margin-odd;
                }
                &:nth-of-type(even) {
                    margin: $widget-card-mini-group__minicard-margin-even;
                }
                @include mq($screen-s) {
                    max-width: $widget-card-mini-group__minicard-max-width\@small;
                }
                @include mq($screen-m) {
                    max-width: $widget-card-mini-group__minicard-max-width\@medium;
                    min-height: $widget-card-mini-group__minicard-min-height\@medium;
                    padding: $widget-card-mini-group__minicard-padding\@medium; 

                    &:nth-of-type(odd) {
                        margin: $widget-card-mini-group__minicard-margin-odd\@medium;
                    }
                    &:nth-of-type(even) {
                        margin: $widget-card-mini-group__minicard-margin-even\@medium;
                    }

                }
                @include mq($screen-l) {
                    max-width: $widget-card-mini-group__minicard-max-width\@large;   
                    min-height: $widget-card-mini-group__minicard-min-height\@large;
                }

                &__image {
                    img {
                        max-width: $widget-card-mini-group__minicard__image-img-max-width;
                    }
                }

                &__title {
                    color: $widget-card-mini-group__minicard__title-color;
                    font-size: $widget-card-mini-group__minicard__title-font-size;
                    @include mq($screen-m) {
                        font-size: $widget-card-mini-group__minicard__title-font-size\@medium;
                    }
                }
                .content {
                    font-size: $widget-card-mini-group__minicard-font-size;
                    font-weight: $widget-card-mini-group__minicard-font-weight;
                }
            }

        }
    }

    &-contact-commercial {
        background-color: $widget-contact-commercial-background-color;
        left:$widget-contact-commercial-left;
        margin-left: $widget-contact-commercial-margin-left;
        margin-top: $widget-contact-commercial-margin-top;
        padding: $widget-contact-commercial-padding;
        position: $widget-contact-commercial-position;
        width: $widget-contact-commercial-width ;
        @include mq($screen-m) {
            padding: $widget-contact-commercial-padding\@medium;
        }
        &__wrapper {
            align-items: $widget-contact-commercial__wrapper-align-items;
            border-radius: $widget-contact-commercial__wrapper-border-radius;
            border: $widget-contact-commercial__wrapper-border;
            display: $widget-contact-commercial__wrapper-display;
            flex-direction: $widget-contact-commercial__wrapper-flex-direction;
            justify-content: $widget-contact-commercial__wrapper-justify-content;
            margin: $widget-contact-commercial__wrapper-margin;
            max-width: $widget-contact-commercial__wrapper-max-width;
            overflow: $widget-contact-commercial__wrapper-overflow;
            @include mq($screen-m) {
                flex-direction: $widget-contact-commercial__wrapper-flex-direction\@medium;
            }
        }

        &__image {
            width: $widget-contact-commercial__image-width;
            @include mq($screen-m) {
                width: $widget-contact-commercial__image-width\@medium;
            }
        }

        &__content {
            background-color: $widget-contact-commercial__content-background-color;
            padding: $widget-contact-commercial__content-padding;
            width: $widget-contact-commercial__content-width;
            @include mq($screen-m) {
                padding: $widget-contact-commercial__content-padding\@medium ;
                width: $widget-contact-commercial__content-width\@medium;
            }
        }
        &__title {
            font-size: $widget-contact-commercial__title-font-size;
            margin-bottom: $widget-contact-commercial__title-margin-bottom  ;
        }
        &__text {
            margin-bottom: 8px;
        }
    }
}


// Adapta la imagen para que ocupe todo el div
.full-img {
    .widget-card--mini {
        padding: 0;
        .widget-card--mini__title-wrapper,
        .content,
        .actions {
            padding: $full-img-widget-card--mini-padding;
        }
    }
}

//convierte el boton en light
.btn-primary-light {
    .button {
        @extend .button--secondary; 
    }
}