$contact-us__max-width               : 500px !default;
$contact-us__margin                  : 0 auto !default;

$contact-us__heading-margin          : 0 0 $spacer--medium !default;
$contact-us__heading-font-family     : $font-family-base !default;
$contact-us__heading-font-size       : $font-size-large !default;
$contact-us__heading-font-weight     : $font-weight-bold !default;

$contact-us__description-margin      : 0 0 $spacer--extra-large !default;
$contact-us__description-color       : $color-secondary !default;

$contact-us__button-margin           : $spacer--medium 0 0 !default;

$contact-us__tips-color              : $color-secondary !default;

$contact-us__details-padding\@large  : 0 0 0 72px !default;
$contact-us__details-padding\@xl     : 0 0 0 72px !default;

$contact-us__list-max-width\@large   : 320px !default;

$contact-us__day--special-color      : $color-error !default;

$contact-us__hour--special-color     : $color-error !default;

$contact-us__phone-number-font-size  : $font-size-large !default;
$contact-us__phone-number-font-weight: $font-weight-medium !default;

$contact-us__address-item-color      : $color-secondary !default;

$contact-us__fieldset-padding        : 0 !default;
