$rating__size                   : 120px !default;
$rating__item-size              : $rating__size / 5 !default;
$rating__size--secondary        : 80px !default;
$rating__item-size--secondary   : $rating__size--secondary / 5 !default;
$rating__size--rate             : 160px !default;
$rating__margin--rate           : 0 0 $spacer--semi-medium !default;
$rating__padding--rate          : $spacer 0 !default;
$rating__item-size--rate        : $rating__size--rate / 5 !default;
$icon__active-color             : $color-primary !default;
$icon__inactive-color           : $gray !default;
$rating__error-margin-bottom    : $spacer !default;
$rating__error-color            : $red !default;
$rating__start-before           : svg-uri("<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path transform='translate(2 2)' d='M10 15.27L16.18 19l-1.64-7.03L20 7.24l-7.19-.61L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19z' fill='#{$icon__inactive-color}'/></svg>") !default;
$rating__indicator-before       : svg-uri("<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path transform='translate(2 2)' d='M10 15.27L16.18 19l-1.64-7.03L20 7.24l-7.19-.61L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19z' fill='#{$icon__active-color}'/></svg>") !default;
