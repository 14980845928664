.customer-invoice {
    &-index {
        span.label {
            height: $select__height;
            padding: 0;
            line-height: $select__line-height;
            white-space: nowrap;
            @include mq($screen-m) {
                justify-content: flex-end;
            }
        }

        .container-select {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            text-align: right;
            @include mq($screen-m) {
                justify-content: flex-end;
            }


            .select2 {
                width: fit-content !important;
                &-selection {
                    &__rendered {
                        line-height: $select__line-height;
                        padding-left: $spacer--small;
                    }
                }

                &-selection--single {
                    border: 0px solid;
                }
            }
        }

        .box {
            &__invoice-amount {
                @include mq($max-screen: $screen-s - 1) {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}