$image__width                       : auto !default;
$image__max-width                   : 100% !default;
$image__transition                  : $transition-fade !default;
$image__wrapper-background-color    : $white !default;

$image__picture-lazyload-width      : 100% !default;
$image__picture-lazyload-min-height : 56px !default;
$image__picture-lazyload-bg-image   : url('../images/loader-svg.svg') !default;

.image {
    display: block;
    width: $image__width;
    max-width: $image__max-width;
    transition: $image__transition;

    &.lazyload,
    &.lazyloading {
        opacity: 0;
    }

    &.lazyloaded {
        opacity: 1;
    }
}

img,
picture {
    @extend .image;
}

.lazyload-wrapper {
    position: relative;
    background-color: $image__wrapper-background-color;
    transition: $transition-base;
}

picture {
    &.lazyloaded {
        width: $image__picture-lazyload-width;
    }

    & > img.lazyload,
    & > img.lazyloaded,
    & > img.lazyloading {
        min-height: $image__picture-lazyload-min-height;
        opacity: 1;
        background-size: $image__picture-lazyload-min-height;
        background-repeat: no-repeat;
        background-position: center;
    }

    & > img.lazyloading {
        background-image: $image__picture-lazyload-bg-image;
    }
}
