$link__display               : inline-flex !default;
$link__align-items           : flex-start !default;
$link__font-family           : $font-family-base !default;
$link__font-weight           : $font-weight-normal !default;
$link__line-height           : $font-line-height !default;
$link__decoration            : none !default;
$link__decoration-inline     : underline !default;
$link__decoration-hover      : underline !default;
$link__color                 : $color-primary !default;
$link__color-hover           : $color-info !default;
$link__color--invert         : $color-info !default;
$link__decoration--invert    : none !default;
$link__color-hover--invert   : $color-primary !default;
$link__color--secondary      : $color-secondary !default;
$link__decoration--secondary : none !default;
$link__color-hover--secondary: $color-primary !default;
$link__transition            : $transition-base !default;

.link {
    display: $link__display;
    align-items: $link__align-items;
    color: $link__color;
    font-family: $link__font-family;
    font-weight: $link__font-weight;
    line-height: $link__line-height;
    text-decoration: $link__decoration;
    transition: $link__transition;

    &:hover {
        color: $link__color-hover;
        text-decoration: $link__decoration-hover;
        cursor: pointer;
    }

    &--invert {
        color: $link__color--invert;
        text-decoration: $link__decoration--invert;

        &:hover {
            color: $link__color-hover--invert;
        }
    }

    &--secondary {
        color: $link__color--secondary;
        text-decoration: $link__decoration--secondary;

        &:hover {
            color: $link__color-hover--secondary;
        }
    }

    // visible only for screen readers and when focused
    &--visually-hidden {
        @include visually-hidden($usePseudo: true);
    }

    &--button {
        &:hover {
            color: inherit;
        }
    }

    p &,
    cite & {
        background-clip: content-box;
        text-decoration: $link__decoration-inline;
        &.focus-visible:not(.banner) {
            @include focus-inline();
        }
    }
}

a {
    @extend .link;
}
