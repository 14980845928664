$product-reviews__image-margin         : $spacer auto !default;
$product-reviews__image-max-width      : 300px !default;

.product-reviews {
    &__image {
        display: block;
        margin: $product-reviews__image-margin;
        max-width: $product-reviews__image-max-width;
    }
}
