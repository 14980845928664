@import "toggle-variables";

.toggle {
    margin: 0;
    padding: $toggle__input-padding\@small;
    @include mq($screen-m){
        padding: $input__label-margin;
    }
    &__control {
        transition: $toggle__transition;
        transition-timing-function: $toggle__transition-timing-function;
        width: $toggle__width;
        height: $toggle__height;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: $toggle__background-border;
        border-radius: $toggle__height;
        background-color: $toggle__background--default;
        position: relative;
        &:after{
            content: "";
            width: $toggle__button-width;
            height: $toggle__button-height;
            display: block;
            background-color: $toggle__button-color--default;
            border-radius: 50%;
            border: $toggle__button-border;
            box-shadow: $toggle__button-box-shadow;
        }
    }

    &__input {
        display: none;
        &:checked + .toggle__control{
            transition: $toggle__transition;
            transition-timing-function: $toggle__transition-timing-function;
            border: $toggle__background-border;
            background-color: $toggle__background--success;
            justify-content: flex-end;
            &:after{
                background-color: $toggle__button-color--success;
            }
        }
    }
}
