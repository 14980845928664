@import "compare-variables";

.compare {
    position: relative;
    margin: $compare__margin;
    border: $compare__border;
    overflow-y: hidden;

    &__inner {
        overflow-x: auto;
        width: $compare__inner-width;
        margin: $compare__inner-margin;

        @include mq($screen-m) {
            width: $compare__inner-width\@medium;
            margin: $compare__inner-margin\@medium;
        }
    }

    &__table {
        border-collapse: collapse;
        border-spacing: 0;
        table-layout: fixed;
        height: 100%;
    }

    &__label {
        position: absolute;
        left: 0;
        width: $compare__label-width;
        height: $compare__label-height;
        padding: $compare__label-padding;
        background-color: $compare__label-background;
        border: $compare__border;
        border-width: $compare__label-border-width;

        @include mq($screen-m) {
            width: $compare__label-width\@medium;
            padding: $compare__label-padding\@medium;
        }
    }

    &__row {
        height: 100%;

        &:first-child {
            .compare__label {
                border-width: $compare__row-label-border-width--first-child;
            }
        }

        &:last-child {
            .compare__cell {
                border-bottom: none;
            }
        }
    }

    &__cell {
        position: relative;
        padding: $compare__cell-padding;
        border-left: $compare__border;
        border-bottom: $compare__border;
        min-width: $compare__cell-min-width;
        vertical-align: top;
        height: 100%;

        @include mq($screen-m) {
            min-width: $compare__cell-min-width\@medium;
        }

        &:first-of-type {
            border-left: none;
        }

        ul {
            padding-left: $compare__cell-list-padding-left;
        }

        li {
            line-height: $compare__cell-item-line-height;
        }
    }

    &__product {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__caption {
        @include visually-hidden();
    }

    &__rating {
        min-height: $compare__rating-min-height;
    }

    &__link {
        min-height: $compare__link-min-height;
    }

    .image {
        max-width: $compare__image-max-size;
    }

    .price {
        font-weight: $font-weight-bold;
    }

    &__link,
    &__rating,
    .price {
        display: block;
        margin-bottom: $compare__attributes-margin-bottom;
    }

    &__link {
        &--image {
            display: flex;
            justify-content: center;
            max-width: $compare__link-image-max-width;
        }
    }

    &__buttons-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: $compare__buttons-container-width;

        @include mq($screen-m) {
            flex-direction: row;
        }
    }

    &__secondary-buttons {
        display: flex;
        margin: $compare__secondary-buttons-margin;

        @include mq($screen-m) {
            margin: $compare__secondary-buttons-margin\@medium;
        }
    }

    &__wishlist {
        &:after {
            content: $button__wishlist-after-content;
        }
    }

    &__remove {
        &:after {
            content: $button__remove-after-content;
            fill: $white;
        }
    }
}
