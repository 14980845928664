$password-strength__font-size                        : $font-size-base !default;
$password-strength__color                            : $color-secondary !default;
$password-strength__font-weight                      : $font-weight-normal !default;

$password-strength__indicator-width                  : 128px !default;
$password-strength__indicator-margin                 : 0 0 0 $spacer !default;
$password-strength__indicator-padding                : 3px 0 !default;
$password-strength__indicator-border                 : 1px solid $gray-light !default;
$password-strength__indicator-border-radius          : 32px !default;
$password-strength__indicator-color                  : $color-primary !default;
$password-strength__indicator-text-align             : center !default;
$password-strength__transition                       : $transition-base !default;

$password-strength__indicator-background--none       : $gray-light !default;
$password-strength__indicator-color--none            : $color-primary !default;
$password-strength__indicator-width--none            : 100% !default;
$password-strength__indicator-background--weak       : $red !default;
$password-strength__indicator-color--weak            : $white !default;
$password-strength__indicator-width--weak            : 100% !default;
$password-strength__indicator-background--medium     : $color-secondary !default;
$password-strength__indicator-color--medium          : $white !default;
$password-strength__indicator-width--medium          : 100% !default;
$password-strength__indicator-background--strong     : $color-info !default;
$password-strength__indicator-color--strong          : $white !default;
$password-strength__indicator-width--strong          : 100% !default;
$password-strength__indicator-background--very-strong: $color-success !default;
$password-strength__indicator-color--very-strong     : $color-primary !default;
$password-strength__indicator-width--very-strong     : 100% !default;
