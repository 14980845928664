.swal2-container {
    .swal2-popup {
        border-radius: 0;

        .swal2-title {
            color: $color-primary;
        }

        .swal2-content {
            p {
                font-size: $font-size-base;
            }
        }

        .swal2-close {
            color: $color-primary;

            &:hover,
            &.focus-visible {
                color: $color-primary;
            }
        }

        .swal2-actions {
            flex-wrap: nowrap;
        }

        .swal2-cancel,
        .swal2-confirm {
            flex: 1 0 50%;
            margin: 0 $spacer;
        }
    }
}
