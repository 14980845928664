$store-locator__border                            : $border-width-base $border-style-base $color-primary !default;

$store-locator__input-border--active              : $border-base !default;
$store-locator__action-height                     : 48px !default;
$store-locator__action-icon-size                  : 24px !default;

$store-locator__button-bg                         : $color-primary !default;
$store-locator__button-bg--active                 : $color-primary !default;
$store-locator__button-font-weight                : $font-weight-bold !default;
$store-locator__button-min-size                   : 48px !default;
$store-locator__button-padding                    : 0 $spacer--large !default;
$store-locator__button-color                      : $white !default;

$store-locator__label-font-size                   : $font-size-small !default;
$store-locator__label-font-size\@medium           : $font-size-base !default;

$store-locator__filter-action-justify             : flex-end !default;
$store-locator__filter-action-align               : center !default;
$store-locator__filter-action-padding             : 0 !default;
$store-locator__filter-action-margin              : $spacer 0 0 0 !default;
$store-locator__filter-margin                     : 0 0 $spacer--medium 0 !default;
$store-locator__filter-padding                    : 0 0 $spacer--medium 0 !default;

$store-locator__search-bg                         : transparent !default;
$store-locator__search-border                     : $border-base !default;
$store-locator__search-border-width               : $border-width-base !default;
$store-locator__search-margin                     : 0 0 $spacer--medium 0 !default;
$store-locator__search-padding                    : 0 0 $spacer--medium 0 !default;
$store-locator__search-title-margin               : 0 0 $spacer--medium 0 !default;

$store-locator__input-margin                      : $spacer 0 !default;

$store-locator__route-creator-bg                  : $color-primary !default;
$store-locator__route-creator-label-bg            : $color-secondary !default;
$store-locator__route-creator-icon-opacity        : 0.5 !default;
$store-locator__route-creator-icon-bg             : $white !default;

$store-locator__rating-row-align                  : center !default;
$store-locator__rating-radio-margin               : 0 $spacer $spacer 0 !default;

$store-locator__store-description-bg              : $gray-lighter !default;
$store-locator__store-description-bg--active      : $gray-lighter !default;
$store-locator__store-description-color--active   : $color-primary !default;
$store-locator__store-description-margin          : $spacer 0 0 0 !default;
$store-locator__store-description-border--active  : 2px $border-style-base $border-color-base !default;

$store-locator__store-schedule-margin             : $spacer--medium 0 0 0 !default;
$store-locator__store-schedule-color--active      : $color-primary !default;
$store-locator__store-schedule-bg--active         : $gray !default;
$store-locator__store-schedule-label-bg--active   : $gray !default;

$store-locator__range-slider-margin               : 60px 0 $spacer--medium 0 !default;
$store-locator__range-slider-bg-color             : $gray !default;
$store-locator__range-slider-font-size            : $font-size-base !default;
$store-locator__left-subtitle-padding             : 40px 0 !default;
$store-locator__left-list-padding                 : $spacer 0 !default;
$store-locator__left-list-padding-large           : $spacer--medium 0 !default;
$store-locator__left-list-span-padding            : $spacer--small !default;
$store-locator__slider-heading-margin             : 0 0 21px 0 !default;
$store-locator__main-padding                      : 0 $spacer !default;
$store-locator__table-padding                     : $spacer--semi-medium 0 !default;
$store-locator__table-padding-heading             : $spacer--medium !default;
$store-locator__table-border                      : 1px solid $lighter-gray;
$store-locator__table-cell-min-width              : 115px !default;
$store-locator__table-cell-min-width\@medium      : 240px !default;
$store-locator__table-cell-border                 : none !default;

