@import 'password-strength-variables';

.password-strength {
    color: $password-strength__color;
    font-size: $password-strength__font-size;
    font-weight: $password-strength__font-weight;

    &__handle {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    &__indicator {
        position: relative;
        display: inline-block;
        min-width: $password-strength__indicator-width;
        margin: $password-strength__indicator-margin;
        padding: $password-strength__indicator-padding;
        border: $password-strength__indicator-border;
        border-radius: $password-strength__indicator-border-radius;
        background-color: transparent;
        color: $password-strength__indicator-color;
        text-align: $password-strength__indicator-text-align;
        overflow: hidden;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            z-index: -1;
            height: 100%;
            border-top-left-radius: $password-strength__indicator-border-radius;
            border-bottom-left-radius: $password-strength__indicator-border-radius;
            transition: $password-strength__transition;

        }
        .password-none & {
            color: $password-strength__indicator-color--none;
            &:before {
                width: $password-strength__indicator-width--none;
                background-color: $password-strength__indicator-background--none;
            }
        }

        .password-weak & {
            color: $password-strength__indicator-color--weak;
            &:before {
                width: $password-strength__indicator-width--weak;
                background-color: $password-strength__indicator-background--weak;
            }
        }

        .password-medium & {
            color: $password-strength__indicator-color--medium;
            &:before {
                width: $password-strength__indicator-width--medium;
                background-color: $password-strength__indicator-background--medium;
            }
        }

        .password-strong & {
            color: $password-strength__indicator-color--strong;
            &:before {
                width: $password-strength__indicator-width--strong;
                background-color: $password-strength__indicator-background--strong;
            }
        }

        .password-very-strong & {
            color: $password-strength__indicator-color--very-strong;
            &:before {
                width: $password-strength__indicator-width--very-strong;
                background-color: $password-strength__indicator-background--very-strong;
            }
        }
    }
}
