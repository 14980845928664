// Table
$table__width                                                : 100% !default;
$table__padding                                              : 0 !default;
$table__background                                           : $white !default;
$table__margin                                               : $spacer 0 !default;
$table__border-width-base                                    : $border-width-base !default;
$table__border                                               : $table__border-width-base $border-style-base $gray-light !default;
$table__border-radius                                        : $border-radius !default;
$table__border-collapse                                      : separate !default;
$table__border-spacing                                       : 0 !default;
$table__color                                                : $black !default;
$table__line-height                                          : $font-line-height !default;
$table__font-size                                            : $font-size-base !default;
$table__font-size\@medium                                    : $font-size-medium !default;

// Clean variant
$table__th-font-weight--clean                                : $font-weight-bold !default;
$table__th-background--clean                                 : transparent !default;
$table__background-odd--clean                                : $gray-lighter !default;
$table__background-even--clean                               : $white !default;

// Table heading
$table__th-background                                        : $gray-lightest !default;
$table__th-padding                                           : 0 !default;
$table__th-padding\@medium                                   : $spacer--medium !default;
$table__th-border-bottom\@medium                             : $table__border !default;
$table__th-border-right\@medium                              : $table__border !default;
$table__th-text-align                                        : left !default;
$table__th-background-row                                    : $white !default;
$table__th-padding-row                                       : $spacer--medium !default;
$table__th-border-width-row                                  : $table__border-width-base 0 !default;
$table__th-text-align-row                                    : left !default;
$table__th-width-row                                         : 50% !default;

// Table data
$table__td-background                                        : $white !default;
$table__td-padding                                           : $spacer $spacer--medium !default;
$table__td-padding\@medium                                   : $spacer--medium !default;
$table__td-border-bottom\@medium                             : $table__border !default;
$table__td-border-right\@medium                              : $table__border !default;
$table__td-font-weight-before                                : bold !default;
$table__td-margin-right-before                               : $spacer !default;
$table__td-width-before                                      : 25% !default;
$table__td-max-width-before                                  : 25% !default;

// Table row
$table__tr-padding                                           : $spacer--medium 0 !default;
$table__tr-padding\@medium                                   : 0 !default;
$table__tr-border-bottom                                     : $table__border !default;
$table__tr-border-bottom\@medium                             : 0 !default;

// Table footer
$table__tfoot-tr-border-top-first-child                      : $table__border !default;
$table__tfoot-td-padding                                     : $spacer $spacer--medium !default;
$table__tfoot-td-padding\@medium                             : $spacer--medium !default;
$table__tfoot-td-background                                  : $table__background !default;
$table__tfoot-td-background\@medium                          : $table__background !default;
$table__tfoot-td-border-right\@medium                        : $table__border !default;
$table__tfoot-td-border-top\@medium                          : $table__border !default;

// Odd even variant
$table__background-odd--odd-even                             : $color-secondary !default;
$table__background-even--odd-even                            : $gray-table-lighter !default;
$table__td-background--odd-even                              : transparent !default;
$table__border-top-left-radius-first-child--odd-even         : 0 !default;
$table__border-top-left-radius-first-child--odd-even\@medium : 0 !default;
$table__border-top-right-radius-first-child--odd-even        : 0 !default;
$table__border-top-right-radius-first-child--odd-even\@medium: 0 !default;

// Scope row variant
$table__tr-padding--scope-row                                : 0 !default;
$table__border-width--scope-row                              : $table__border-width-base 0 0 0 !default;
$table__tr-background-odd--scope-row                         : $table__background-even--odd-even !default;
$table__th-font-wieght--scope-row                            : $font-weight-normal !default;

// Table footer scope row variant
$table__tfoot-th-border-right-width--tfoot-scope-row         : $border-width-base !default;
$table__tfoot-th-border-top--tfoot-scope-row                 : $table__border !default;


//table

$table-acordion-border                                        : none;
$table-acordion-margin                                        : auto;
$table-acordion-width                                         : 100%;
$table-acordion-width\@large                                 : 75%;

$table-acordion__tr-border                                    : 1px solid #e6e6e6;
$table-acordion__tr-border-radius                             : 10px;
$table-acordion__tr-display                                   : flex;
$table-acordion__tr-flex-direction                            : column;
$table-acordion__tr-height                                    : auto !important;
$table-acordion__tr-margin-bottom                             : 16px;

$table-acordion__td-border                                    : 0;
$table-acordion__td-height                                    : auto !important;
$table-acordion__td-padding                                   : 16px;
$table-acordion__td-position                                  : relative;
$table-acordion__td-width                                     : 100% !important;

$table-acordion__td-first-child-font-weight                   : 700;

$table-acordion__td-first-child-after-content                  : url("../images/icons/chevron-down.svg");
$table-acordion__td-first-child-after-height                   : 16px;
$table-acordion__td-first-child-after-position                 : absolute;
$table-acordion__td-first-child-after-right                    : 20px;
$table-acordion__td-first-child-after-width                    : 16px;

$table-acordion__td-last-child-display                         : none;
$table-acordion__td-last-child-opacity                         : 0;
$table-acordion__td-last-child-padding-top                     : 0;
$table-acordion__td-last-child-transition                      : all 0.5s ease-in-out;


$table-acordion__active-td-first-child-right                   : 12px;
$table-acordion__active-td-first-child-top                     : 20px;
$table-acordion__active-td-first-child-transform               : rotate(180deg);

$table-acordion__active-td-last-child-display                  : block;
$table-acordion__active-td-last-child-opacity                  : 1;
