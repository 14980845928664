@import 'article-item-variables';

.article-item {
    display: flex;
    flex-direction: column;
    margin: $article-item__margin;

    &--single {
        margin: $article-item__margin--single;

        @include mq($screen-l) {
            margin: $article-item__margin--single\@large;
        }

        .article-item__header {
            @include mq($screen-l) {
                margin: $article-item__header-margin--single\@large;
            }
        }

        .article-item__container {
            flex-direction: column;

            @include mq($screen-l) {
                margin: $article-item__container-margin--single\@large;
            }
        }

        .article-item__body {
            @include mq($screen-xl) {
                padding: $article-item__body-padding\@xlarge;
            }
        }

        .article-item__title {
            font-size: $article-item__title-font-size--single;
            font-weight: $article-item__title-font-weight--single;
            margin: $article-item__title-margin--single;
            text-transform: $article-item__title-text-transform--single;

            @include font-padding(
                $article-item__title-font-size--single,
                $article-item__title-text-height--single
            );

            @include mq($screen-m) {
                font-size: $article-item__title-font-size--single\@medium;
                letter-spacing: $article-item__title-letter-spacing\@medium;

                @include font-padding(
                    $article-item__title-font-size--single\@medium,
                    $article-item__title-text-height--single\@medium
                );
            }
        }

        .article-item__media {
            margin: $article-item__media-margin--single;

            @include mq($screen-l) {
                margin: $article-item__media-margin--single\@large;
            }
        }

        .article-item__content {
            margin: $article-item__content-margin--single;
        }

        .article-item__content {
            p {
                margin: $article-item__content-text-margin--single;
            }

            * {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__header {
        margin: $article-item__header-margin;
    }

    &__title {
        font-size: $article-item__title-font-size;
        font-weight: $article-item__title-font-weight;
        margin: $article-item__title-margin;
        text-transform: $article-item__title-text-transform;

        @include font-padding(
            $article-item__title-font-size,
            $article-item__title-text-height
        );
    }

    &__sub-title {
        font-size: $article-item__subtitle-font-size;
        line-height: $article-item__subtitle-line-height;
        font-weight: $article-item__subtitle-font-weight;
        margin: $article-item__subtitle-margin;
        text-transform: $article-item__subtitle-text-transform;
    }

    &__container {
        display: flex;
        flex-flow: column nowrap;

        @include mq($screen-l) {
            flex-direction: row;
        }
    }

    &__media {
        margin: $article-item__media-margin;

        @include mq($screen-l) {
            margin: $article-item__media-margin\@large;
        }
    }

    &__body {
        margin: $article-item__body-margin;

        @include mq($screen-l) {
            display: flex;
            flex-flow: column nowrap;
            justify-content: $article-item__body-justify-content\@large;
        }
    }

    &__content,
    &__content p {
        margin: $article-item__content-text-margin;
        font-size: $article-item__content-text-font-size;
        line-height: $article-item__content-text-line-height;
    }

    &__content {
        margin: $article-item__content-margin;
    }

    &__footer-link {
        display: block;
        font-size: $article-item__footer-link-font-size;
        font-weight: $article-item__footer-link-font-weight;

        @include font-padding(
            $article-item__footer-link-font-size,
            $article-item__footer-link-height
        );
    }

    &__links-list {
        flex-wrap: wrap;

        .list__item,
        .list__item:first-child,
        .list__item:last-child {
            font-size: $article-item__links-list-font-size;

            @include font-padding(
                $article-item__links-list-font-size,
                $article-item__links-list-height,
                $article-item__links-list-padding-right
            );
        }
    }
}
