@mixin add-to-button($iconDisplay) {
    @if ($iconDisplay == block) {
        padding: 0 $spacer;
        &:after {
            content: url("../images/icons/plus-light.svg");
        }
        .button__icon {
            display: $iconDisplay;
        }
        .button__text {
            display: none;
        }
    }
    @else {
        padding: 0 $spacer--large;
        &:after {
            content: url("../images/icons/shopping-cart-light.svg");
        }
        .button__icon {
            display: $iconDisplay;
        }
        .button__text {
            display: block;
        }
    }
}
