@import "static-block-variables";
.body {
    margin: 0;
    overflow-x: hidden;
}
.homepage-services {
    background-color: $static-block-services-bg-color;
    width: $static-block-services-wider;
    position: relative;
    left: $static-block-services-left;
    margin-top: $static-block--wrapper-margin-top;
    &__header {
        padding: $static-block-services-header-padding-mobile;
        @include mq($screen-m) {
            padding: $static-block-services-header-padding;
        }
    }
    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__list {
        display: flex;
        flex-direction: column;
        padding-left: 5px;
        margin-bottom: $static-block-services-spacer--extra-large;
        gap: $static-block-services-card-gap ;
        @include mq($screen-m) {
            flex-direction: row;
            width: 100%;
            justify-content: space-between;

            max-width: $static-block-services-list-max-width;
            //padding: 0 40px;
        }
        li {
            background: $static-block-services-card-bg-color;
            padding: $spacer--large;
            list-style: none;
            width: auto;
            @include mq($screen-m) {
                width: 356px;
            }
            .content {
                color: $static-block-heading-color;
                .image {
                    width: 80px;
                    height: 80px;
                }
                .body {
                    @extend .heading--third-level;
                    margin: $static-block-services-body-margin;
                }
                .service-footer {
                    @extend .paragraph;
                    a {
                        color: $static-block-heading-color;
                        font-weight: $font-weight-medium-bold;
                    }
                }
            }
        }
    }
}

.destacados-products-slider {
    margin-top: $spacer--extra-large;
    h2 {
        padding: $static-block-h2-padding;
    }
    margin-top: $static-block--wrapper-margin-top;
    @include mq($screen-m) {
        padding: $static-block-padding--extra--large;
    }
}
.block-image-wrapper {
    padding: $static-block-block-image-wrapper-padding;
    margin-top: $static-block--wrapper-margin-top;
    margin-left: auto;
    margin-right: auto;
    display: none;
    @include mq($screen-m) {
        display: block;
    }
    &.mobile {
        display: block;
        padding: $static-block-block-image-wrapper-padding\@small;

        @include mq($screen-m) {
            display: none;
        }
    }

}
