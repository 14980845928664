$input__field-color                              : $color-text;
$input__field-background-color                   : $white !default;
//$input__field-padding                            : 0 $spacer--medium !default;
$input__field-padding                            : 0 $form-elements-radius;

$input__field-spacing                            : 46px;

$input__field-border                             : $form-elements-border !default;
$input__field-border-radius                      : $form-elements-radius !default;
$input__margin-bottom                            : $spacer--medium !default;

//$input__placeholder-color                        : $color-secondary !default;
$input__placeholder-color                        : $gray;

$input__placeholder-font-size                    : $font-size-base !default;
//$input__placeholder-font-style                 : normal !default;
$input__placeholder-font-style                   : italic;

//$input__label-margin                             : 0 $spacer--medium 0 0 !default;
$input__label-margin                             : 0 0 $spacer--extra-small $form-elements-radius;

//$input__label-focus-color                      : $color-primary !default;
$input__label-focus-color                        : $label-color;

$input__label-font-size--big                     : $font-size-medium !default;
$input__date-trigger-left                        : 0 !default;
$input__date-trigger-bottom                      : 0 !default;
$input__date-trigger-width                       : 100% !default;
$input__date-trigger-border                      : 0 !default;
$input__date-trigger-background                  : transparent !default;
$input__date-trigger-box-shadow                  : none !default;
$input__transition                               : $transition-base !default;
$input__white-space--inline                      : nowrap !default;

// Input password
$input__button-pass-bg                           : transparent !default;
$input__button-pass-top                          : 29px !default;
$input__button-pass-right                        : $form-element-border-width !default;
$input__button-pass-height                       : $input__field-spacing - (4 * $form-element-border-width) !default;
$input__button-pass-icon-fill                    : $color-primary !default; //$color-secondary !default;

$input__button-pass-before-icon-view-on          : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill-rule="evenodd"><path fill-rule="nonzero" d="M12 6a9.77 9.77 0 0 1 8.82 5.5A9.77 9.77 0 0 1 12 17a9.77 9.77 0 0 1-8.82-5.5A9.77 9.77 0 0 1 12 6zm0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5zm0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"/></g></svg>') !default;
$input__button-pass-before-icon-view-off         : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill-rule="evenodd"><path d="M0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0z"/><path fill-rule="nonzero" d="M12 6a9.77 9.77 0 0 1 8.82 5.5 9.647 9.647 0 0 1-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6zm-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14zM2.01 3.87l2.68 2.68A11.738 11.738 0 0 0 1 11.5C2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L3.42 2.45 2.01 3.87zm7.5 7.5l2.61 2.61c-.04.01-.08.02-.12.02a2.5 2.5 0 0 1-2.5-2.5c0-.05.01-.08.01-.13zm-3.4-3.4l1.75 1.75a4.6 4.6 0 0 0-.36 1.78 4.507 4.507 0 0 0 6.27 4.14l.98.98c-.88.24-1.8.38-2.75.38a9.77 9.77 0 0 1-8.82-5.5c.7-1.43 1.72-2.61 2.93-3.53z"/></g></svg>') !default;
$input__button-pass-before-icon-view-height      : $input__button-pass-height !default;
$input__button-pass-before-icon-view-width       : $input__button-pass-height !default;
$input__button-pass-before-icon-view-fill        : $input__button-pass-icon-fill !default;

//textarea
//$input__field-padding--textarea                  : $spacer !default;
$input__field-padding--textarea                  : $spacer--small $form-elements-radius;
$input__field-border-radius--textarea            : $form-elements-radius--small !default;
$input__field-line-height--textarea              : $font-line-height !default;
$input__min-height--textarea                     : 72px !default;

// disbaled
$input__background-disabled                      : $gray-light !default;

//datepicker
$input__datepicker-background-position           : calc(100% - #{$spacer}) center;
$input__datepicker-wrapped-background-position   : $spacer center;
$input__datepicker-font-size                     : $font-size-small;
$input__datepicker-background-image              : url("../images/icons/calendar/calendar.svg");
$input__datepicker-background-size               : 18px;

$input__datepicker-background-images      : url("../images/icons/calendar/calendar-icons-m.svg");
$input__datepicker-background-image\@large       : url("../images/icons/calendar/calendar-icons.svg");
$input__datepicker-background-size\@large        : 100%;

$input__datepicker-span-background-image         : url("../images/icons/calendar/calendar-arrow.svg");
$input__datepicker-span-close-background-image   : url("../images/icons/calendar/calendar-close.svg");

//radio
$input__label-radio-padding                      : $spacer--small 0;
$input__label-radio-border                       : $button__border--subtitle;
