$dashboard__handler-margin                : 0 0 $spacer--large !default;

$dashboard__title-margin                  : 0 0 $spacer--semi-medium !default;
$dashboard__title-padding                 : $spacer--medium 0 !default; //$spacer--medium !default;
$dashboard__title-border                  : $border-secondary !default;
$dashboard__title-background              : $bg-color-secondary !default;
$dashboard__title-font-family             : $font-family-base !default;
$dashboard__title-font-size               : $font-size-medium !default; //$font-size-large !default;
$dashboard__title-font-weight             : $font-weight-bold !default;
$dashboard__title-border-width            : 0 0 $border-width-secondary !default;

$dashboard__subtitle-font-size            : $font-size-medium !default;

$dashboard__status-padding                : $spacer $spacer--medium !default;
$dashboard__status-border                 : $border-base !default;
$dashboard__status-border-radius          : $border-radius !default;
$dashboard__status-font-size              : $font-size-small !default;

$dashboard__order-date-font-weight        : $font-weight-bold !default;

$dashboard__form-padding                  : 0 $spacer--medium !default;
$dashboard__form-padding-left--first-child: $spacer !default;
$dashboard__form-padding-right--last-child: $spacer !default;

$dashboard__product-padding               : 0 !default;
$dashboard__product-border                : none !default;

$dashboard__sidebar-border                : $border-secondary !default;
$dashboard__sidebar-border-width          : 0 !default; //0 $border-width-secondary 0 0 !default;
$dashboard__sidebar-padding\@large        : 0 $spacer--semi-medium 0 $spacer !default; //0 $spacer--medium 0 $spacer !default;
$dashboard__sidebar-padding\@extra-large  : 0 $spacer--semi-medium 0 $spacer !default; //0 64px 0 $spacer !default;

$dashboard__content-padding\@large        : 0 $spacer 0 $spacer--semi-medium !default; //0 $spacer 0 $spacer--extra-large !default;
$dashboard__content-padding\@extra-large  : 0 $spacer 0 $spacer--semi-medium !default; //0 $spacer 0 64px !default;
$dashboard__content-heading-padding       : 0 !default; //0 0 $spacer--extra-large !default;

$dashboard__content-sections-title-font-size : $font-size-super-extra-large\@screen-m;
