// sass-lint:disable-all
@mixin hide-scrollbar($hide: true) {
    @if ($hide) {
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;  /* Safari and Chrome */
        }
    }
    @else {
        -ms-overflow-style: auto;
        scrollbar-width: auto;

        &::-webkit-scrollbar {
            display: block;
        }
    }
}
// sass-lint:enable-all
