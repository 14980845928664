@import "cards_variable";

.cards{

    .card{
        display: flex;
        padding: $card-padding;
        margin-bottom: $card-margin-bottom;
        border: $card-border;
        @include mq($screen-m) {
            padding: $card-padding\@medium;
        }

        &__col-icon{
            padding: $card__col-icon-padding;
            @include mq($screen-m) {
                padding: $card__col-icon-padding\@medium;
            }
        }
        &__col-wrapper{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            padding: $card__col-wrapper-padding;
            @include mq($screen-m) {
                padding: $card__col-wrapper-padding\@medium;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
        }

        &__col-footer{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 26px;
            @include mq($screen-m) {
                flex-direction: row;
            }
        }

        &__col-text{
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1 ; //limita el numero de lineas a mostrar
            -webkit-box-orient: vertical;
        }
        &__col-actions{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            @include mq($screen-m) {
                flex-direction: column;
                justify-content: flex-start;
                width: auto;
            }
        }
        &__col-links{
            display: inline-flex;

            a{
                color: $card__col-links-a-color;
                font-size: $card__col-links-a-font-size;
                white-space: nowrap;
                &:nth-child(even) {
                    &:before{
                        content: '|';
                        margin: $card__col-links-a-before-margin;
                    }
                }
            }
        }
        &__col-qr{
            img{
                margin-left: $card__col-qr-img-margin-left ;
                width: $card__col-qr-img-width;
                padding: $card__col-qr-img-padding;
                border: $card__col-qr-img-border;
            }
        }


        &.__design--one{
            flex-direction: row;
            border-radius: $card-border-radius;
            .card {
                &__col-sub-title{
                    font-weight: $card-design-one-subtitle-font-weight;
                    font-size: $card-design-one-subtitle-font-size;
                }
                &__col-content{
                    font-size: $card-design-one-col-content-font-size;
                }
            }
        }

        &.__design--two {
            font-size: $card-design-two-font-size;
            flex-direction: column;

            @include mq($screen-m) {
                border-width: $card-design-two-border-width;
                padding: $card-padding\@medium;
            }
            .card{
                &__col-total,
                &__col-title{
                    font-size: $card-design-two-col-title-font-size;
                    font-weight: $card-design-two-col-title-font-weight;
                }
                &__col-sub-title{
                    font-size: $card-design-two-col-subtitle-font-size;
                    font-weight: $card-design-two-col-subtitle-font-weight;
                }
                &__col-content{
                   width: 100%;
                    padding: $card-design-two-col-content-padding;
                    @include mq($screen-m) {
                        padding: $card-design-two-col-content-padding\@medium;
                    }

                }
                &__col-wrapper{
                    padding: 0;
                    flex-wrap: nowrap;

                }
            }
        }

        &.__design--three {
            flex-direction: row;
            border-radius: $card-border-radius;
            .card {
                &__col-text {
                    font-size: $card-design-three-font-size;
                    font-weight: $card-design-three-font-weight;
                }
                &__col-total,
                &__col-title {
                    font-size: $card-design-three-col-title-font-size;
                    font-weight: $card-design-three-col-title-font-weight;
                }
                &__col-title {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                &__col-wrapper{
                    width: 100%;
                    flex-direction: column;
                }
                &__col-content{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    @include mq($screen-m) {
                        flex-direction: row;
                    }
                }
                &__col-sub-content {
                    margin: $card-design-three-col-sub-content-margin;
                    @include mq($screen-m) {
                       margin: 0;
                    }
                }
                &__col-actions {
                    justify-content: start;
                    @include mq($screen-m) {
                        justify-content: end;
                    }
                }
            }
        }
    }

    &.--accordion {
        .flipIn {
            animation: flipdown 0.5s ease both;
        }
        .transition {
            transition: all 0.25s ease-in-out
        }
        @keyframes flipdown {
            0% {
                opacity: 0;
                transform-origin: top center;
                transform: rotateX(-90deg);
            }
            5% {
                opacity: 1;
            }
            80% {
                transform: rotateX(8deg);
            }
            83% {
                transform: rotateX(6deg);
            }
            92% {
                transform: rotateX(-3deg);
            }
            100% {
                transform-origin: top center;
                transform: rotateX(0deg);
            }
        }
        .--accordion-ul {
            list-style: none;
            margin: 0;
            padding: 0;
            perspective: 900;
            .--accordion-li {
                @extend .flipIn;
                border-bottom: $--accordion-li-border-bottom;
                margin-bottom: $--accordion-li-margin-bottom;
                padding: $--accordion-li-padding;
                position: relative;

                .--accordion-wrapper {
                    @extend .transition;
                    color: $--accordion-wrapper-color ;
                    font-size: $--accordion-wrapper-font-size;
                    letter-spacing: $--accordion-wrapper-letter-spacing;
                    line-height: $--accordion-wrapper-line-height;
                    margin-top: $--accordion-wrapper-margin-top ;
                    max-height: $--accordion-wrapper-max-height;
                    opacity: $--accordion-wrapper-opacity;
                    overflow: $--accordion-wrapper-overflow;
                    position: $--accordion-wrapper-position;
                    transform: $--accordion-wrapper-transform;
                    width: $--accordion-wrapper-width;
                }
                .--accordion-action {
                    display: inline-flex;
                    font-size: $--accordion-action-font-size;
                    float: $--accordion-action-float;
                    font-weight: $--accordion-action-font-weight;
                    &.more {
                        color: $--accordion-action-more-color;
                    }
                    &.less {
                        color: $--accordion-action-less-color;
                        display: none;
                    }
                }

                input[type=checkbox] {
                    cursor: pointer;
                    height: $--accordion-input-height;
                    opacity: $--accordion-input-opacity;
                    position: absolute;
                    right: $--accordion-input-right;
                    width: $--accordion-input-width;

                    &:checked {
                        & ~ div.--accordion-wrapper {
                            margin-top: $--accordion-wrapper-checked-margin-top;
                            max-height: 100%;
                            opacity: $--accordion-input-checked-opacity;
                            transform: translate( 0 );
                        }
                        & ~ span.less{
                            display: inline-flex !important;
                        }
                        & ~ span.more{
                            display: none;
                        }

                    }
                }
            }
        }

        .card {
            &-title {
                display: inline-flex;
                float: $--accordion-card-title-float;
                font-size: $--accordion-card-title-font-size ;
                font-weight: $--accordion-card-title-font-weight;
            }

        }
    }

}
