@import 'base-variables';

*,
*::after,
*::before {
    box-sizing: $all-box-sizing;
}

html,
body {
    height: $body-height;
    min-width: $body-min-width;
    font-family: $body-font-family;
    font-weight: $body-font-weight;
    font-size: $body-font-size;
    line-height: $body-line-height;
    color: $body-color;
    scroll-behavior: $body-scroll-behavior;
}

body {
    display: $body-display;
    flex-direction: $body-flex-direction;
    overflow-y: scroll;
}

.page-wrapper {
    flex: $page-wrapper-flex;
    margin-bottom: $page-wrapper-margin-bottom;
}

#maincontent { // sass-lint:disable-line no-ids
    margin-top: $maincontent-margin-top;

    @include mq($screen-l) {
        margin-top: $maincontent-margin-top\@large;
    }

    &.homepage {
        margin-top: $maincontent-margin-top--homepage;
    }

    .catalog-product-view & {
        margin-top: $maincontent-margin-top--product;
    }

    .catalog-category-view & {
        margin-top: $maincontent-margin-top--category;
    }
}

dl,
dt,
dd {
    margin: $description-margin;
}

form {
    margin: $form-margin;
}
