$company-profile__title-margin       : 0 0 $spacer--medium !default;
$company-profile__title-margin\@small: 0 0 $spacer--extra-large !default;
$company-profile__edit-button-top    : -92px !default;

.company-profile {
    &__edit-button {
        width: 100%;
        @include mq($screen-s) {
            width: auto;
            float: right;
            top: $company-profile__edit-button-top;
        }
    }

    .dashboard__content-heading {
        margin: $company-profile__title-margin;

        @include mq($screen-s) {
            margin: $company-profile__title-margin\@small;
        }
    }
}
