@import 'badge-variables';

.badge {
    display: inline-flex;
    padding: $badge__padding;
    margin: $badge__margin;
    border-radius: $badge__border-radius;
    color: $badge__color;
    background: $badge__background;
    font-family: $badge__font-family;
    font-size: $badge__font-size;
    font-weight: $badge__font-weight;
    text-transform: $badge__text-transform;
    line-height: $badge__line-height;
    justify-content: $badge__justify-content;

    &--new {
        color: $badge__color--new;
        background: $badge__background--new;
    }
}
