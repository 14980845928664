.customer-detail-index{

    .detail-password-modal{
        h2 {
            text-align: center;
            margin-bottom: 1.5em;
        }
        .actions-group{
            justify-content: center;
        }
        .detail-modal-action{
            display: flex;
            justify-content: center;
            button{
                width: 20%;
            }
        }

    }
    .dashboard-items{
        &__subtitle{
            font-size: $font-size-medium;
        }
    }
    .details-down{
        display: inline-flex;
        .icon{
            align-self: center;
        }
    }
    .toggle{
        padding-left: 0;
        padding-right: 10px;
    }
    .deactivate-account-container{
        button{
            color: $bsm-grey;
            background: $white;
            border: 1px solid $gray-light;
        }
    }
    .col-xs-12.col-sm-8.item{
        justify-content: center;
    }
    .item.actions-group__handler{
        margin-bottom: 0px;
    }
    .change-pass-modal{
        cursor: pointer;
    }
}
