$block-info__title-font-size       : $font-size-medium !default;
$block-info__title-font-weight     : $font-weight-bold !default;
$block-info__title-text-transform  : uppercase !default;

$block-info__content-text-font-size: $font-size-base !default;

$block-info__actions-margin        : 0 $spacer--medium !default;
$block-info__actions-link-padding  : 0 !default;

$block-info__link-text-decoration  : underline !default;
$block-info__link-text-transform   : none !default;


.block-info {
    display: flex;
    flex-direction: column;

    &__title {
        display: flex;
        flex-direction: column;
        font-size: $block-info__title-font-size;
        text-transform: $block-info__title-text-transform;
        font-weight: $block-info__title-font-weight;
        @include mq($screen-m) {
            flex-direction: row;
        }
    }

    &__actions {
        width: auto;
        @include mq($screen-m) {
            margin: $block-info__actions-margin;
        }

        .link {
            text-decoration: $block-info__link-text-decoration;
            text-transform: $block-info__link-text-transform;
            @include mq($screen-m) {
                padding: $block-info__actions-link-padding;
            }
        }
    }

    &__content-text {
        font-size: $block-info__content-text-font-size;
    }
}
