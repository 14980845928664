$icons-pagebuilder__font-name: 'pagebuilder-font';
$icons-pagebuilder__font-path: '../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons';

$icon-pagebuilder-caret-up__content: '\f104';
$icon-pagebuilder-caret-down__content: '\f101';
$icon-pagebuilder-caret-next__content: '\f102';
$icon-pagebuilder-caret-prev__content: '\f103';

@import 'icons';
@import 'layout';
@import 'slick/slick';
@import 'content-type/import';

.cms-content-important {
    background-color: $gray-lightest;
    color: $gray-darkest;
    font-size: $font-size-extra-large;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 1.1;
    margin: 0 0 $spacer--large -$spacer--medium;
    padding: $spacer--medium;
}

.pagebuilder-full-width {
    float: left;
    width: 100%;
}

.pagebuilder-content-type {
    margin-bottom: $spacer--medium;
}

//
//  Responsive Classes
//  _____________________________________________

@include mq($screen-m) {
    .pagebuilder-mobile-only {
        display: none !important; // sass-lint:disable-line no-important
    }
}

@include mq($max-screen: $screen-m - 1) {
    .pagebuilder-mobile-hidden {
        display: none !important; // sass-lint:disable-line no-important
    }
}

