@import 'review-variables';

.review {
    width: $review__width;

    @include mq($screen-m) {
        display: flex;
        flex-flow: row nowrap;
    }

    &--add {
        padding: $review__padding--add;
        margin: $review__margin--add;
        border: $review__border--add;
        border-width: $review__border-width--add;

        @include mq($screen-m) {
            display: block;
        }
    }

    &--summary {
        width: $review-width--summary;

        .rating {
            margin: $review__rating-margin--summary;
            padding: $review__rating-padding--summary;
        }
    }

    &__details {
        max-width: $review__details-max-width;
        flex-basis: $review__details-flex-basis;
        padding: $review__details-padding;
    }

    &__image {
        max-width: $review__image-max-width;
        flex-basis: $review__image-flex-basis;

        .image {
            max-width: $review__image-max-width;
            width: $review__image-width;
            height: $review__image-height;
            border-radius: $review__image-border-radius;
        }
    }

    &__rating-legend {
        color: $review__rating-legend-color;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: $review__wrapper-height;
        margin: $review__wrapper-margin;
    }

    &__title {
        font-weight: $review__title-font-weight;
        text-transform: $review__title-text-transform;
        font-family: $review__title-font-family;
    }

    &__title-product {
        display: block;
        margin: $review__title-product-margin;
        text-transform: $review__title-text-transform;
    }

    &__author-name {
        font-weight: $review__author-font-weight;
        text-transform: $review__author-text-transform;
        font-family: $review__author-font-family;
        margin: $review__author-margin;
    }

    &__author-description {
        color: $review__author-description-color;
        font-size: $review__author-description-font-size;
    }

    &__date {
        display: block;
        color: $review__date-color;
        font-size: $review__date-font-size;
    }

    &__fieldset {
        max-width: $review__fieldset-max-width;
        margin: $review__fieldset-margin;
        padding: $review__fieldset-padding;
    }

    &__field {
        margin: $review__field-margin;
    }

    &__submit-btn {
        width: $review__submit-btn-width;

        @include mq($screen-m) {
            width: $review__submit-btn-width\@medium;
        }
    }

    &__required-info {
        margin: $review__required-info-margin;
        font-size: $review__required-info-font-size;
    }

    &__summary-handle {
        display: $review__summary-handle-display;
        flex-flow: $review__summary-handle-flex-flow;
        align-items: $review__summary-handle-align-items;
        justify-content: $review__summary-handle-justify-content;
        width: $review__summary-handle-width;
    }

    &__amount {
        margin: $review__amount-margin;
        padding: $review__amount-padding;
        letter-spacing: $review__amount-letter-spacing;
        text-decoration: $review__amount-text-decoration;
        color: $review__amount-color;
        white-space: nowrap;

        @include mq($screen-m) {
            margin: $review__amount-margin\@medium;
        }

        &.focus-visible {
            @include focus-inline();
        }
    }

    &__summary-add {
        width: $review__summary-add-width;
        padding: $review__summary-add-padding;
        text-decoration: $review__summary-add-text-decoration;
        text-align: $review__summary-add-text-align;
        color: $review__summary-add-color;

        &.focus-visible {
            @include focus-inline();
        }
        .review-customer-view & {
            display: none;
        }
    }

    &__rating {
        margin: $review__rating-margin;
    }

    &__description {
        margin: $review__description-margin;

        p {
            font-size: $review__description-paragraph-font-size;
            color: $review__description-paragraph-color;
        }
    }

    &__links {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: $review__links-margin;

        .button {
            padding: 0;
        }
    }

    &__links-column {
        display: flex;
        align-items: center;

        &:first-child {
            margin: $review__links-column-margin-first-child;
        }
    }

    &__links-btn {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        cursor: pointer;
        border-radius: 0;
        color: $review__links-btn-color;
        text-decoration: $review__links-btn-text-decoration;
        padding: $review__links-btn-padding;

        &:last-child {
            padding-right: 0;
        }

        .icon {
            fill: $review__links-btn-icon-fill;
            margin: $review__links-btn-icon-margin;
        }
    }

    &__links-legend {
        color: $review__links-legend-color;
        margin: $review__links-legend-margin;
    }
}
