@import 'store-locator-variables';

.store-locator {
    &__button-search {
        top: 0;
        &.-hidden {
            display: none;
        }
    }

    &__today-label {
        font-size: $store-locator__label-font-size;
        @include mq($screen-m) {
            font-size: $store-locator__label-font-size\@medium;
        }
    }

    &__stores {
        @include mq($screen-m) {
            flex-direction: column;
        }
        @include mq($screen-xl) {
            flex-direction: row;
        }
    }

    // adjust store-locator styles
    .amlocator-search-container
    .amlocator-title {
        margin: $store-locator__search-title-margin;
        .amlocator-link {
            word-break: keep-all;
        }
    }

    .amlocator-search-container {
        border: $store-locator__search-border-width;
        background-color: $store-locator__search-bg;
        border-width: $store-locator__search-border-width;
        padding: $store-locator__search-padding;
        margin: $store-locator__search-margin;
    }

    .amlocator-filters-container {
        background-color: transparent;
        padding: $store-locator__filter-padding;
        margin: $store-locator__filter-margin;
    }

    .amlocator-store-desc {
        background-color: $store-locator__store-description-bg;
        &.-active {
            background-color: $store-locator__store-description-bg--active;
            color: $store-locator__store-description-color--active;
            border: $store-locator__store-description-border--active;

            .amlocator-title {
                .amlocator-link {
                    color: $store-locator__store-description-color--active;
                }

            }

            .amlocator-schedule-table {
                color: $store-locator__store-schedule-color--active;
            }

            & + .amlocator-schedule-container {
                background-color: $store-locator__store-schedule-bg--active;
            }

            .amlocator-today {
                color: $store-locator__store-schedule-color--active;
                background-color: $store-locator__store-schedule-label-bg--active;
                @include mq($screen-m, $screen-xl) {
                    flex-wrap: wrap;
                }

                .amlocator-time {
                    @include mq($screen-m, $screen-xl) {
                        width: 100%;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    .amlocator-attribute-wrapper
    .amlocator-input {
        margin: $store-locator__input-margin;
    }

    .amlocator-schedule-table {
        margin: $store-locator__store-schedule-margin;
    }

    // maps popup
    .amlocator-description {
        margin: $store-locator__store-description-margin;
    }
    .amlocator-info-popup {
        font-weight: $font-weight-base;
        font-size: $font-size-base;
        line-height: $font-line-height;
    }
    .amlocator-description {
        p {
            font-size: $font-size-base;
        }
    }

    .input__field {
        font-size: $font-size-base;
    }
    // to overwrite amasty styles loaded in php script :/
    &.amlocator-main-container {
        .amlocator-search-container,
        .amlocator-filters-container {
            .amlocator-button {
                color: $store-locator__button-color;
                background-color: $store-locator__button-bg;
                border: $store-locator__border;
                font-weight: $store-locator__button-font-weight;
                min-height: $store-locator__button-min-size;
                padding: $store-locator__button-padding;

                &:hover,
                &:focus,
                &.focus-visible {
                    background-color: $store-locator__button-bg--active;
                }
            }
        }

        .amlocator-search-container .amlocator-button.-nearby {
            color: $store-locator__button-color;
            background-color: $store-locator__button-bg;

            &:hover,
            &:focus,
            &.focus-visible {
                background-color: $store-locator__button-bg--active;
            }

            &:disabled,
            &[disabled] {
                @extend .button:disabled;
            }
        }

        .amlocator-filters-container {
            .amlocator-select[multiple] {
                background-image: none;
            }

            .amlocator-clear {
                color: $color-primary;

                &:hover,
                &:focus,
                &.focus-visible {
                    color: $store-locator__button-color;
                }
            }
        }
    }

    .amlocator-current-location .amlocator-text {
        height: $store-locator__action-height;

        &:hover,
        &:focus,
        &.ficus-visible {
            border: $store-locator__input-border--active;
        }
    }
}
.amlocator-location-main {
    padding: $store-locator__main-padding;
    .amlocator-location-container {
        @include mq($screen-m){
            flex-direction: column !important; //sobreescribiendo estilo externo
        }
        .amlocator-modes {
            border: none;
        }

        .amlocator-column .amlocator-button {
            color: $store-locator__button-color;
            border: $store-locator__border;
            font-weight: $store-locator__button-font-weight;
            background-color: $store-locator__button-bg;

            &:hover,
            &:focus,
            &.focus-visible {
                color: $store-locator__button-color;
                background-color: $store-locator__button-bg--active;
            }
        }

        .amlocator-route-creator {
            background-color: $store-locator__route-creator-bg;

            .amlocator-mode,
            .amlocator-swap {
                display: unset;
                @include visually-hidden;

                &+ .amlocator-label {
                    opacity: $store-locator__route-creator-icon-opacity;
                }

                &:focus-visible + .amlocator-label {
                    opacity: 1;
                    @include focus;
                }
            }

            .amlocator-mode:checked + .amlocator-label,
            .amlocator-mode:focus + .amlocator-label,
            .amlocator-mode + .amlocator-label:hover {
                background-color: $store-locator__route-creator-label-bg;
            }

            .amlocator-route-icon .amlocator-icon.-dot {
                opacity: $store-locator__route-creator-icon-opacity;
                background-color: $store-locator__route-creator-icon-bg;
            }
        }

        .amlocator-location-gallery {
            .amlocator-image {
                object-fit: cover;
            }
        }

        .amlocator-review-popup {
            .review-field-text textarea {
                width: 100%;
            }

            .review-form-actions .action.submit.primary {
                background-color: $store-locator__button-bg;

                &:hover,
                &:focus,
                &.focus-visible {
                    color: $store-locator__button-color;
                    background-color: $store-locator__button-bg--active;
                }
            }

            .review-rating-row {
                display: flex;
                align-items: $store-locator__rating-row-align;

                .radio {
                    margin: $store-locator__rating-radio-margin;
                }
            }
        }

        .amlocator-list-block {
            ul {
                list-style: none;
                padding-left: 0;

                &.amlocator-list {
                    li {
                        display: flex;
                        padding: $store-locator__left-list-padding;
                            
                        span {
                            font-weight: $font-weight-medium-bold;
                            padding-right: $store-locator__left-list-span-padding
                        }
                    }
                    &.services {
                        @include mq($screen-m) {
                            column-count: 2;
                        }
                        li {
                            padding: $store-locator__left-list-padding-large;
                        }
                    }
                }

            }
        }
        .amlocator-description {
            p {
                @extend .heading--second-level;
            }
        }
        .amlocator-subtitle {
            @extend .heading--third-level;
            padding: $store-locator__left-subtitle-padding;
        }
        .amlocator-location-columns {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
        }
        .amlocator-column.-left {
            @include mq($screen-m) {
                flex-basis: 40%;
            }
        }
        .amlocator-column.-right {
            order: 3;
            @include mq($screen-m) {
                flex: 1 0;
                order: 2;
            }
        }
        .amlocator-column.-bottom {
            order: 2;
            .table {
                padding: $store-locator__table-padding;
                border: none;

                tr th {
                    display: table-cell;
                    background-color: $white;
                    border-top: $store-locator__table-border;
                    border-right:none;
                    padding: $store-locator__table-padding-heading;
                    min-width: $store-locator__table-cell-min-width;

                    @include mq($screen-m) {
                        min-width: $store-locator__table-cell-min-width\@medium;
                    }

                    &:last-child {
                        width: 100%;
                    }
                }
                tr td {
                    display: table-cell;
                    border: $store-locator__table-cell-border;
                    min-width: $store-locator__table-cell-min-width;

                    @include mq($screen-m) {
                        min-width: $store-locator__table-cell-min-width\@medium;
                    }

                    &:last-child {
                        width: 100%;
                    }
                }
            }
            @include mq($screen-m) {
                order: 3;
            }
        }
        .amlocator-block.-map {
            height: 60vh;
            @include mq($screen-m) {
                height: 68vh;
            }
        }
    }
    .section__heading {
        justify-content: flex-start;
        margin: $store-locator__slider-heading-margin;
        .heading--first-level {
            @extend .heading--third-level;
        }
    }

}
.amlocator-schedule-container {
    background-color: $gray-lighter;
    .amlocator-today {
        min-height: $store-locator__action-height;
        align-items: center;
        &:before {
            display: none;
        }
    }
}

.amlocator-range-slider {
    margin: $store-locator__range-slider-margin;
    background-color: $store-locator__range-slider-bg-color !important; // sass-lint:disable-line no-important
    .noUi-tooltip {
        display: block;
        font-size: $store-locator__range-slider-font-size;
    }
    .ui-slider-handle {
        display: none;
    }
}

.amlocator-results-carousel {
    .slick-slider {
        padding: 0;
    }
}