@import 'contact-us-variables';
@import '../cms-page/cms-page-variables';

.contact-index-index {
    #maincontent{
        margin-top: 0;
    }
    .column.main {
        max-width: $cms-page__max-width;
        margin: $cms-page__margin;

        @include mq($screen-m) {
            max-width: $cms-page__max-width\@medium;
        }

        @include mq($screen-l) {
            max-width: $cms-page-contact__max-width\@medium;
        }

        .cms-contact &,
        .cms-contact-us & {
            max-width: $cms-contact__max-width;
        }

        .card-form{
            margin-top: $cms-page-contact-wrapper-form-card-margin-top ;
            iframe{
                border: unset;
                max-width: 100%;
            }
            &-title{
                font-size: $cms-page-contact-wrapper-form-card-title-font-size;
                font-weight: $cms-page-contact-wrapper-form-card-title-font-weight;
            }
        }
        .widget-card{
            cursor: pointer;

            &__wrapper {
                margin: 0;
                @include mq($screen-l) {
                    margin: $cms-page-contact-wrapper-card-margin;
                }
            }

            .icon{
                width: $cms-page-contact-card-icon-size-w;
                height: $cms-page-contact-card-icon-size-h;
            }

            &.__active{
                border-color: $cms-page-contact-card-active-border-color;
            }
        }
    }
}

.contact-us {
    max-width: $contact-us__max-width;
    margin: $contact-us__margin;

    @include mq($screen-l) {
        max-width: 100%;
    }

    &__heading {
        margin: $contact-us__heading-margin;
        font-family: $contact-us__heading-font-family;
        font-size: $contact-us__heading-font-size;
        font-weight: $contact-us__heading-font-weight;
    }

    &__legend {
        @include visually-hidden();
    }

    &__description {
        margin: $contact-us__description-margin;
        color: $contact-us__description-color;
    }

    &__description p {
        color: $contact-us__description-color;
    }

    &__button {
        margin: $contact-us__button-margin;
    }

    &__tips {
        margin-top: $spacer--semi-medium;
        color: $contact-us__tips-color;
    }

    &__details {
        margin-top: $spacer--extra-large;

        @include mq($screen-l) {
            padding: $contact-us__details-padding\@large;
            margin-top: 0;
        }

        @include mq($screen-xl) {
            padding: $contact-us__details-padding\@xl;
        }
    }

    &__list {
        padding: 0;

        @include mq($screen-l) {
            max-width: $contact-us__list-max-width\@large;
        }
    }

    &__list-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacer--medium;
    }

    &__day {
        font-weight: $font-weight-bold;

        @include mq($screen-m) {
            font-size: $font-size-large;
        }

        &--special {
            color: $contact-us__day--special-color;
        }
    }

    &__hour {
        color: $color-secondary;

        @include mq($screen-m) {
            font-size: $font-size-medium;
        }

        &--special {
            color: $contact-us__hour--special-color;
            font-weight: $font-weight-bold;
        }
    }

    &__phone,
    &__address {
        margin-bottom: $spacer--extra-large;
    }

    &__phone-number {
        display: flex;
        font-size: $contact-us__phone-number-font-size;
        font-weight: $contact-us__phone-number-font-weight;
    }

    &__address-item {
        margin-bottom: 0;
        color: $contact-us__address-item-color;
    }

    // Override webforms styles
    .webforms {
        .fieldset {
            padding: $contact-us__fieldset-padding;
        }
    }
}
