@import "email-to-friend-variables";

.email-to-friend {
    @include mq($screen-s) {
        max-width: $email-to-friend__max-width;
        margin: $email-to-friend__margin;
    }

    &__form {
        width: 100%;
    }

    &__legend {
        font-family: $email-to-friend__legend-font-family;
        font-weight: $email-to-friend__legend-font-weight;
    }

    &__sender-wrapper {
        margin: $email-to-friend__sender-wrapper-margin;
    }

    &__fieldset {
        margin: $email-to-friend__fieldset-margin;
    }

    &__inner-fieldset {
        margin: $email-to-friend__inner-fieldset-margin;
    }

    &__options {
        .fields {
            &:not(.additional) {
                .email-to-friend__inner-fieldset {
                    margin-top: 0;
                }
            }
        }
    }

    &__message {
        resize: vertical;
    }

    &__remove {
        margin: $email-to-friend__remove-margin;
    }

    &__back {
        margin: $email-to-friend__back-margin;
        cursor: pointer;
    }

    &__submit {
        margin: $email-to-friend__submit-margin;
    }
}
