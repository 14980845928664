$side-menu__bq-visibility-max                  : 99999px !default;
$side-menu__menu-order                         : 1 !default;

$side-menu__trigger-bg-color                   : $white !default;
$side-menu__trigger-padding\@medium            : 0 $spacer 0 0 !default;
$side-menu__trigger-margin\@medium                          : 0 !default;
$side-menu__trigger-border\@medium             : 0px solid $gray !default;
$side-menu__trigger-border-width\@medium       : 0 !default;

$side-menu__trigger-button-text-color          : $color-text !default;
$side-menu__trigger-button-text-margin-left    : $spacer--small !default;

$side-menu__bg-color                           : $white !default;
$side-menu__max-width                          : 392px !default;
$side-menu__max-width\@medium                  : 376px !default;


$side-menu__modal-container-top                : 50px !default; //112px !default;
$side-menu__modal-container-top\@medium        : 50px !default;
$side-menu__modal-container-padding            : 0 $spacer--semi-medium !default;
$side-menu__modal-container-max-width          : 100% !default;
$side-menu__modal-container-max-height         : calc(100vh - #{$side-menu__modal-container-top}) !default;
$side-menu__modal-container-max-height\@medium : calc(100vh - #{$side-menu__modal-container-top\@medium}) !default;
$side-menu__modal-container-box-shadow         : none !default;

$side-menu__modal-content-max-width            : 100% !default; //496px !default;
$side-menu__modal-content-margin               : 0 auto !default;

$side-menu__modal-middle-padding               : $spacer--medium 0 0 !default;

$side-menu__modal-bottom-border                : 0 !default;
$side-menu__modal-bottom-padding               : $spacer--semi-large 0 0 !default;
$side-menu__modal-bottom-actions-max-width     : 100% !default;
$side-menu__modal-bottom-actions-margin        : 0 !default;
$side-menu__modal-bottom-action-width          : 100% !default;

$side-menu__modal-close-button-top             : -50px !default; //-56px !default;
$side-menu__modal-close-button-left            : unset !default;
$side-menu__modal-close-button-top\@medium     : -50px !default; //-65px !default;
$side-menu__modal-close-button-left\@medium    : unset !default;
$side-menu__modal-close-button-bg              : $white !default;
$side-menu__modal-close-button-fill            : $black !default;


$side-menu__modal-close-button-text-margin     :  0 0 0 $spacer--medium !default;
$side-menu__modal-close-button-text-color      : $color-primary !default;
$side-menu__modal-close-button-text-font-family: $font-family-secondary !default;
$side-menu__modal-close-button-text-font-size  : $font-size-medium !default;
$side-menu__modal-close-button-text-font-weight: $font-weight-normal !default;

$side-menu__modal-dropdown-label-hover-color    : $color-primary !default;
$side-menu__modal-dropdown-label-sub-items-color: $battleship-grey !default;
$side-menu__modal-dropdown-label-sub-items-padding : $spacer--extra-small 0px !default;