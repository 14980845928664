$section__margin-bottom                     : 72px !default;
$section__margin                            : 0 0 $section__margin-bottom 0 !default;
$section__margin--secondary\@medium         : 0 !default;
$section__margin--fix-spacer                : 0 0 ($section__margin-bottom - $spacer) 0 !default;
$section__margin--fix-spacer-small          : 0 0 ($section__margin-bottom - $spacer--small) 0 !default;
$section__margin--fix-spacer-medium         : 0 0 ($section__margin-bottom - $spacer--medium) 0 !default;
$section__margin--fix-spacer-medium\@medium : 0 $spacer--extra-large ($section__margin-bottom - $spacer--medium) !default;
$section__margin--fix-spacer-medium\@large  : 0 0 ($section__margin-bottom - $spacer--medium) !default;
$section__margin--fix-spacer-semi-medium    : 0 0 ($section__margin-bottom - $spacer--semi-medium) 0 !default;
$section__margin--fix-spacer-large          : 0 0 ($section__margin-bottom - $spacer--large) 0 !default;
$section__margin--fix-spacer-large\@medium  : 0 $spacer--extra-large ($section__margin-bottom - $spacer--large) !default;
$section__margin--fix-spacer-large\@large   : 0 0 ($section__margin-bottom - $spacer--large) !default;
$section__margin--fix-spacer-semi-large     : 0 0 ($section__margin-bottom - $spacer--semi-large) 0 !default;
$section__margin--fix-spacer-extra-large    : 0 0 ($section__margin-bottom - $spacer--extra-large) 0 !default;

$section__heading-justify-content           : center !default;
$section__heading-min-height                : 48px !default;
$section__heading-margin                    : 0 0 $spacer--extra-large 0 !default;

$section__content-margin--products          : (-$spacer) (-$spacer) 0 !default;
