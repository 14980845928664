$account-form__padding                              : $spacer 0 0 !default;

$account-form__width                                : 100% !default;
$account-form__width\@medium                        : 70% !default;
$account-form__width\@large                         : 58% !default;
$account-form__margin                               : $spacer--large  auto 0 auto !default; //auto !default;

$account-form__actions-margin                       : 0 0 $spacer--medium 0  !default; //$spacer--medium 0 !default;
$account-form__actions-width                        : 100% !default;
$account-form__actions-width\@medium                : 100% !default;//auto !default;

$account-form__button-min-width                     : 208px !default;

$account-form__form-margin                          : 0 !default;

$account-form__fieldset-max-width\@medium           : 100% !default; //432px !default;
$account-form__fieldset-width                       : 100% !default;
$account-form__fieldset-margin                      : $spacer--medium 0 0 0 !default; //0 0 $spacer--semi-large !default;
$account-form__fieldset-margin-head                 : 0 !default; //0 0 $spacer--semi-large !default;


$account-form__legend-font-family                   : $font-family-base !default;
$account-form__legend-font-size                     : $font-size-large !default;
$account-form__legend-font-weight                   : $font-weight-bold !default;
$account-form__legend-margin                        : 0 !default;
$account-form__legend-padding                       : 0 0 $spacer--medium 0 !default;
$account-form__legend-width                         : 100% !default;
$account-form__legend-text-transform                : uppercase !default;

$account-form__forgot-password-margin               : 0 !default; //$spacer--medium 0 0 0 !default;
$account-form__forgot-password-color                : $battleship-grey !default; //$gray-darker !default;
$account-form__forgot-password-font-weight          : $font-weight-bold !default;
$account-form__forgot-password-text-decoration      : none !default;
$account-form__forgot-password-text-decoration-hover: underline !default;
$account-form__forgot-password-line-height          : 48px !default;

$account-form__info-width                           : unset !default; //656px !default;
$account-form__info-margin                          : 0 0 $spacer--large 0 !default; //$spacer--extra-large auto !default;
$account-form__info-font-size                       : $font-size-base !default;

$account-form__validation-tips-margin               : 0 0 $spacer--semi-medium !default;
$account-form__validation-tips-font-size            : $font-size-medium !default;

$account-form-nologin__form-border                  : 1px solid #ebebeb !default;
$account-form-nologin__form-border-radius           : 10px !default;
$account-form-nologin__form-padding\@medium         : 0 0 $spacer--small 0 !default;
$account-form-nologin__form-padding                 : $spacer !default;
$account-form-nologin__form-margin\@medium          : 0 $spacer--extra-large-xl !default;
$account-form-nologin__form-margin                  : 0 !default;


$account-form__actions-extra-margin-top              : 32px;
$account-form__actions-extra-text-align              : left;
$account-form__actions-extra-width                   : 100%;
$account-form__actions-extra-display                 : inline-flex;
$account-form__actions-extra-font-weight             : $font-weight-medium-bold;
$account-form__actions-extra-action-color            : $color-primary;