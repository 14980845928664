@import "qty_increment-variables";

.box-tocart-qty,
.product-item-actions .actions-primary .box-tocart-qty,
td.qty .control.qty,
.details-qty,
.cart-list-item__qty,
.product-view__qty,
.quantity-update {
    align-items: stretch;
    display: flex;
    flex-basis: 50%;
    flex-direction: row;
    height: $qty-btn-height;
    max-height: $qty-btn-height;
    min-height: $qty-btn-width;
    padding-right: 4px;
    justify-content: space-between;

    label {
        margin-bottom: 0;
        margin-top: 0;
        align-self: center;
        margin-right: .5rem;
        order: 0;
    }
    button[class*="qty-"] {
        @extend .button;
        color: $qty-btn-color;
        background-color: $qty-btn-bg-color;
        border-color: $qty-btn-border-color;
        width: $qty-btn-width;
        max-width: $qty-btn-max-width;
        min-width: $qty-btn-min-width;
        height: calc(100% - 2px);
        min-height: unset;
        padding: 0;
        font-size: $qty-btn-font-size;
        cursor: pointer;
    }

    button.qty-min {
        border-right: 0;
        order: 1;
    }

    button.qty-max {
        border-left: 0;
        order: 3;
    }

    input#qty, input.qty, input.cart-item-qty, input[name*=qty] {
        width: calc(100% - #{$qty-btn-width * 2});
        max-width: $qty-btn-max-width;
        min-width: $qty-btn-min-width;
        height: calc(100% - 2px);
        border-radius: 0;
        background-color: transparent;
        font-size: $qty-input-font-size;
        font-weight: $qty-input-font-weight;
        color: $qty-input-color;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        -moz-appearance: textfield;
        order: 2;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &:focus{
            box-shadow: none;
        }
    }
}
.cart-list-item, .product-view {
    &__qty {
        color: $qty-cart-list-item__color;
        background: $qty-cart-list-item__background;
        border: $qty-cart-list-item__border;
        border-radius: $qty-cart-list-item__border-radius;
        button[class*="qty-"],
        button[class*="qty-"]:hover{
            background-color: transparent;
            color: $qty-cart-list-item__btn-color;
        }
        button.qty-min.remove {
            i{
                display: flex;
                align-items: center;
                padding: $spacer--medium;
            }
        }
    }
    &__qty {
        .input__field {
            border: none;
            outline: none;
            &.focus-visible {
                outline: none;
            }
        }
    }
}
.product-view {
    &__qty {
        border: unset;
        flex-basis: unset;
        justify-content: end;

        input {
            min-width: 54px !important;
            text-align: center;
        }
    }
}
