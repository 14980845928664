.table-grid-filter-items-wrapper {
    .col {
        @extend .col-sm-12;
    }
    .form-group {
        @extend .input;
    }
    .form-control {
        @extend .input__field;
    }
    .form-select {
        @extend .select;
    }
    select.form-control {
        @extend .select__field;
    }
    .ui-datepicker-trigger {
        display: none;
    }
    .btn {
        @extend .button;
    }
    .btn-secondary {
        @extend .button--secondary;
    }
}
