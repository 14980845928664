@import 'header-variables';
.bcnbrand{
    &-main-container{
        .bcnbrand-main{
            @extend .container;
            width: 100% !important;
            //max-width: $bcnbrand-main-max-width;
        }
    }
}
