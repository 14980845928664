@import "cart-variables";

.cart-grid {
    &__header {
        display: none;

        @include mq($screen-m) {
            display: flex;
            border-bottom: $cart-grid__header-border-bottom;
        }
    }

    &__header-label {
        display: block;
        padding: $cart-grid__header-label-padding;
    }

    &__header-items {
        flex-basis: 50%;

        @include mq($screen-xl) {
            flex-basis: 60%;
        }
    }

    &__header-price,
    &__header-subtotal {
        flex-basis: 20%;

        @include mq($screen-xl) {
            flex-basis: 14%;
        }
    }

    &__header-qty {
        flex-basis: 10%;

        @include mq($screen-xl) {
            flex-basis: 12%;
        }
    }

    &__button {
        width: 100%;

        @include mq($screen-m) {
            width: auto;
        }
    }

    &__footer {
        @include mq($screen-m) {
            display: flex;
            justify-content: $cart-grid__footer-justify-content\@medium;
            flex-direction: $cart-grid__footer-flex-direction\@medium;
            align-items: center;
        }
    }
}
