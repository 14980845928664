$search-block__label-color                      : $white;
$search-block__search--padding                  : 0 $spacer--large - 2;
$search-block__search-mobile--padding           : $spacer--large - 2 0 0;
$search-block__search-label--margin             : 0;
$search-block__container--padding\@medium       : $spacer--semi-medium $spacer--medium;
$search-block__container--padding\@small        : $spacer--medium;

$search-block__container-tab--title-height      : 30px;
$search-block__container-tab--title-max-width   : 98px;
$search-block__container-tab--title-border      : 1px solid $lighter-gray;
$search-block__container-tab--title-color       : $battleship-grey;

$search-block__container-tab--title-active-border : 1px solid $bsm-grey;
$search-block__container-tab--title-active-color  : $bsm-grey;

$search-block__container-fieldset--margin         : 0 0 $spacer--extra-small $spacer--semi-medium;

$search-block__container-fieldset--input--height  : $spacer--extra-large - 2;

$search-block__datepicker--bg-position            : $spacer center;
$search-block__datepicker--bg-size                : 18px;
$search-block__datepicker--bg-padding             : 50px;


$search-block--bottom-bar-width                   : 19px;
$search-block--bottom-bar-height                  : $spacer--semi-medium;
$search-block--bottom-bar-span-padding            : 0 $spacer + 2;

$search-block__select-close-icon-size             : $spacer--semi-medium;

$search-block--bottom                             : 88px;
$search-block__margin                             : $spacer--semi-large 0 0 0; //80% 0 30px 0;

$search-block__padding                            : 0;//0 $spacer;

$search-block-toggle__margin                      : 0 $spacer;

// theme variables
$search-block__light-theme--bg                    : $color-secondary-light;
$search-block__container-full-width--padding\@medium : $spacer--semi-medium 0;
$search--block__full-width--padding               : 0 94px;

$search-block__full-width--margin                 : 0;

$amlocator-search-icon__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iOC41IiBjeT0iOC41IiByPSI3LjUiIHN0cm9rZT0iI0Q3RDdENyIgc3Ryb2tlLXdpZHRoPSIyIi8+PHBhdGggZmlsbD0iI0Q3RDdENyIgZD0iTTEzIDE1LjEyMUwxNS4xMiAxM2w4LjY3NSA4LjY3NS0yLjEyMSAyLjEyeiIvPjwvc3ZnPg==) no-repeat center;
$amlocator-reset-icon__background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDIwLjU0N0wyMC41NDcgMCAyMiAxLjQ1MyAxLjQ1MyAyMiAwIDIwLjU0N3oiIGZpbGw9IiNFQjU3NTciLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIyIDIwLjU0N0wxLjQ1MyAwIDAgMS40NTMgMjAuNTQ3IDIyIDIyIDIwLjU0N3oiIGZpbGw9IiNFQjU3NTciLz48L3N2Zz4=');
