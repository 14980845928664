.pb-force-flex-row {
    display: flex;
    flex: 1;

    [data-content-type="row"] {
        display: flex;
        flex: 1;
    }

    [data-element="inner"] {
        display: flex;
        flex-direction: row !important; // sass-lint:disable-line no-important
        flex: 1;
    }

    [data-content-type="html"] {
        display: flex;
    }
}
