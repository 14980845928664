@import 'page-builder-variables';

.builder__static {
    margin         : 0;
    
    &-full-width {
        position   : relative;
        width      : $builder-static__full-width-width;
        height     : 100vh;
        left       : $builder-static__full-width-left;
        margin-left: 0;
        transform  : translateX(-$builder-static__full-width-left);
        max-height : $builder-static__full-width-max-height;
        overflow   : hidden;

        figure, img {
            margin: 0;
            width : 100%;
        }
    }
}