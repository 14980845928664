@import 'active-filters-variables';

.active-filters {
    border: $active-filters__border;
    border-width: $active-filters__border-width;
    padding: $active-filters__padding;

    @include mq($screen-l) {
        padding: $active-filters__padding\@large;
        border-width: $active-filters__border-width\@large;
    }

    &__heading {
        font-family: $active-filters__heading-font-family;
        font-weight: $active-filters__heading-font-weight;
        margin: $active-filters__headin-margin;
    }
    &__item {
        display: flex;
        align-items: center;
        padding: $active-filters__item-padding;
    }

    &__description {
        display: flex;
        flex-flow: row wrap;
        width: $active-filters__description-width;
    }

    &__remove {
        margin: $active-filters__remove-margin;
        background-color: transparent;

        &:hover {
            .icon {
                fill: $active-filters__icon-fill--hover;
            }
        }

        .icon {
            fill: $active-filters__icon-fill;
        }
    }

    &__label {
        color: $active-filters__label-color;
        font-weight: $active-filters__label-font-weight;
        text-transform: $active-filters__label-text-transform;
        margin: $active-filters__label-margin;
    }

    &__value {
        color: $active-filters__value-color;
        font-weight: $active-filters__value-font-weight;
        text-transform: $active-filters__value-text-transform;
    }

    &__clear-all {
        @include mq($screen-m) {
            max-width: $active-filters__clear-all-max-width\@medium;
        }
    }
}
