$newsletter__margin                           : 0 !default;
$newsletter__padding                          : $spacer--large 0 !default;

$newsletter__heading-padding                  : $spacer 0 !default;
$newsletter__heading-margin\@medium           : 0 $spacer--large 0 0 !default;
$newsletter__heading-margin\@extra-large      : 0 $spacer--extra-large * 2 0 0 !default;
$newsletter__heading-padding\@extra-large     : 0 !default;

$newsletter__title-margin                     : 0 0 $spacer--medium !default;
$newsletter__title-font-family                : $font-family-base !default;
$newsletter__title-font-size                  : $font-size-large !default;
$newsletter__title-font-weight                : $font-weight-bold !default;
$newsletter__title-line-height                : 48px !default;
$newsletter__title-color                      : $color-primary !default;

$newsletter__subtitle-font-family             : $font-family-base !default;
$newsletter__subtitle-color                   : $color-secondary !default;

$newsletter__controls-margin                  : 0 0 $spacer !default;
$newsletter__controls-margin\@medium          : 0 0 $spacer--medium !default;

$newsletter__button-padding                   : 0 $spacer--medium !default;
$newsletter__button-min-width                 : 96px !default;

$newsletter__checkbox-label-padding           : $checkbox__label-padding !default;
$newsletter__checkbox-label-color             : $color-secondary !default;
$newsletter__checkbox-label-link-color        : $color-primary !default;
$newsletter__checkbox-label-link-color--hover : $color-focus !default;
$newsletter__checkbox-label-font-family       : $font-family-base !default;
$newsletter__checkbox-label-font-size         : $font-size-small !default;
$newsletter__checkbox-label-line-height       : 1.33 !default;

$newsletter__checkbox-before-background       : $color-primary !default;

$newsletter__checkbox-icon-fill               : $white !default;

$newsletter__checkbox-text-color              : $gray-dark !default;

$newsletter__agreements-margin                : 0 0 0 $spacer !default;
$newsletter__agreements-max-width             : 450px !default;
