@import 'brief-info-variables';

.brief-info {
    display: none;

    @include mq($brief-info__mq-display) {
        display: block;
        padding: $brief-info__padding;
    }

    &--dividers {
        @include mq($brief-info__mq-display--dividers) {
            display: block;
            padding: $brief-info__padding--dividers;
            border: $brief-info__border--dividers;
            border-width: $brief-info__border-width--dividers;
        }

        .brief-info__item {
            padding: $brief-info__item-padding--dividers;
            margin: $brief-info__item-margin--dividers;
            flex: 1 1 100%;

            @include mq($screen-xl) {
                padding: $spacer $spacer--large;
                margin-right: $spacer--large;
            }

            &:not(:last-child) {
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: $brief-info__divider-width;
                    top: $brief-info__divider-top;
                    bottom: $brief-info__divider-bottom;
                    left: $brief-info__divider-left;
                    right: $brief-info__divider-right;
                    background-color: $brief-info__divider-color;
                }
            }
        }

        .brief-info__items-container {
            @include mq($brief-info__mq-display--dividers) {
                flex-flow: row nowrap;
            }
        }

        .brief-info__item {
            @include mq($brief-info__mq-display--dividers) {
                flex-flow: column wrap;
                align-items: flex-start;
            }

            @include mq($screen-l) {
                flex-flow: row nowrap;
            }
        }

        .brief-info__icon-wrapper {
            width: $brief-info__icon-size--dividers;
            height: $brief-info__icon-size--dividers;
            margin: $brief-info__icon-margin--dividers;

            @include mq($screen-l) {
                margin: $brief-info__icon-margin--dividers\@large;
            }
        }

        .brief-info__title {
            font-weight: $brief-info__title-font-weight--dividers;
            font-size: $brief-info__title-font-size--dividers;

            @include font-padding(
                $brief-info__title-font-size--dividers,
                $brief-info__title-height--dividers
            );
        }
    }

    &--link {
        .brief-info__icon-wrapper {
            position: absolute;
            left: 0;
        }

        .brief-info__title {
            display: block;
            color: $brief-info__title-color--link;
            font-size: $brief-info__title-font-size--link;
            font-weight: $brief-info__title-font-weight--link;
            line-height: $brief-info__title-line-heigh--link;
            margin: $brief-info__title-margin--link;
            padding: $brief-info__title-padding--link;
        }

        .brief-info__subtitle {
            display: block;
            font-size: $brief-info__subtitle-font-size--link;
            font-weight: $brief-info__subtitle-font-weight--link;
            line-height: $brief-info__subtitle-line-height--link;
            margin: $brief-info__subtitle-margin--link;
            padding: $brief-info__subtitle-padding--link;
        }
    }

    &__items-container {
        display: flex;
        justify-content: $brief-info__justyfi-content;
        flex-flow: column nowrap;

        @include mq($brief-info__mq-display) {
            flex-flow: row nowrap;
        }
    }

    &__list-item {
        flex-grow: 1;

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__item {
        display: flex;
        position: relative;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        padding: $brief-info__item-padding;

        &[type="button"] {
            background-color: $white;
            border: 0;
            text-align: left;
            cursor: pointer;
        }

        @include mq($brief-info__mq-display) {
            flex-flow: row nowrap;
        }
    }

    &__icon-wrapper {
        width: $brief-info__icon-size;
        height: $brief-info__icon-size;

        .icon {
            width: 100%;
            height: 100%;
            fill: $brief-info__icon-fill;
        }
    }

    &__title {
        margin: $brief-info__title-margin;
        color: $brief-info__title-color;
        font-family: $brief-info__title-font-family;
        font-size: $brief-info__title-font-size;
        font-weight: $brief-info__title-font-weight;
        text-transform: $brief-info__title-text-transform;
        text-align: $brief-info__title-text-align;
    }

    &__subtitle {
        margin: $brief-info__subtitle-margin;
        font-size: $brief-info__subtitle-font-size;
        font-weight: $brief-info__subtitle-font-wight ;
        text-transform: $brief-info__subtitle-text-transform;
        color: $brief-info__subtitle-color;
    }

    &__content {
        text-decoration: $vreif-info__content-text-decoration;
    }

    &__link {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: flex-start;
        padding: $brief-info__link-padding;

        &:before {
            max-width: $brief-info__icon-size;
        }

        &:hover,
        &.focus-visible {
            text-decoration: none;
            color: inherit;
        }
    }
}
