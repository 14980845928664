$tab__border-base                  :  $border-style-base $lighter-gray;
$tab__border-base--active          :  $border-style-base $color-secondary !default;
$tab__padding                      : 0 0 $spacer !default;
$tab__border                       : $tab__border-base;
$tab__border--active               : $tab__border-base--active;
$tab__margin                       : 0 0 $spacer--semi-medium  0 !default;

$tab__title-display                : block !default;
$tab__title-size                   : 72px !default;
$tab__title-width                  : 100% !default;
$tab__title-max-width\@large       : none; //440px !default;
$tab__title-flex\@large            : 1 !default;
$tab__title-padding                : $spacer--medium !default;
$tab__title-border                 : 1px solid $lighter-gray !default;
$tab__title-border--active         : $tab__border--active !default;
$tab__title-border-width           : 0 0 $border-width-base 0 !default;
$tab__title-border-width--active   : 0 0 3px 0 !default;
$tab__title-color                  : $battleship-grey !default;
$tab__title-color--active          : $color-primary !default;
$tab__title-background             : $bg-color-base !default;
$tab__title-background--active     : $white !default;
$tab__title-font-family            : $font-family-base !default;
$tab__title-font-size              : $font-size-large !default;
$tab__title-font-weight            : $font-weight-medium !default;
$tab__title-font-weight--active    : $font-weight-bold !default;
$tab__title-text-align             : center !default;
$tab__title-text-transform         : uppercase !default;
$tab__title-transition             : $transition-base !default;
$tab__title-z-index--active        : $z-index-initial !default;
$tab__title-z-index--active\@large : $z-index-low !default;

$tab__content-z-index              : $z-index-lowest !default;
$tab__content-z-index\@medium      : $z-index-low !default;
$tab__content-padding              : 64px 0 !default;
$tab__content-padding\@medium      : 72px 104px !default;
$tab__content-padding\@large       : 72px 80px !default;
$tab__content-padding\@extra-large : 72px 112px !default;
$tab__content-padding--secondary   : 64px 0 !default;
$tab__content-width                : 100% !default;
$tab__content-line-height          : 32px !default;
$tab__content-border--active       : $tab__title-border !default;
$tab__content-border--active\@large: none !default;
$tab__content-border-width--active : 0 0 $border-width-base 0 !default;

$tab__icon-display                 : none !default;
$tab__icon-display\@large          : none !default;
$tab__icon-position                : absolute !default;
$tab__icon-margin                  : auto !default;
$tab__icon-right                   : $spacer--medium !default;
$tab__icon-fill                    : $color-secondary !default;
$tab__icon-fill--active            : $color-primary !default;
$tab__icon-transform--active       : unset; //rotate(180deg) !default;


$tab__calendar-container--bg-color      : $light-gray;
$tab__calendar-container--margin        : 0 $spacer $spacer--semi-medium $spacer;
$tab__calendar-container--padding       : $spacer $spacer--semi-large $spacer $spacer;
$tab__calendar-container--border-radius : $badge__border-radius;

$tab__calendar-container--height        : $spacer--semi-large + 10;
$tab__calendar-container--li-height     : 34px;
$tab__calendar-container--li-padding    : 0 $spacer--large 0 0;
$tab__calendar-container--li-margin     : 2px $spacer--small 2px $spacer--small;

$tab__calendar-container--a-margin      : $spacer--extra-small;
$tab__calendar-container--a-fontsize    : $font-size-small;
$tab__calendar-container--a-color       : $battleship-grey;


$tab-tabs__title-font-size              : $font-size-medium !default;
$tab-tabs__title-text-transform         : none !default;
$tab-tabs__title-text-hover-decoration  : none !default;
