@import 'bundle-option-variables';

.bundle-option {
    &__title {
        margin: $bundle-option__title-margin;
        color: $bundle-option__title-color;
        font-family: $bundle-option__title-font-family;
        font-size: $bundle-option__title-font-size;
        font-weight: $bundle-option__title-font-weight;
        text-transform: $bundle-option__title-text-transform;

        &--required {
            &:after {
                content: '*';
                color: $bundle-option__title-color--required;
                font-weight: $bundle-option__title-font-weight--required;
            }
        }
    }

    &__select {
        max-width: $bundle-option__select-max-width;
    }

    &__qty {
        display: flex;
        align-items: center;
    }

    &__qty-input {
        max-width: $bundle-option__qty-input-max-width;

        &:disabled {
            color: $bundle-option__qty-input-color--disabled;

            &:hover {
                cursor: $bundle-option__qty-input-cursor--disabled;
            }
        }
    }
}
