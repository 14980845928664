@import 'blog-variables';

.blog {
    &__articles {
        margin: $blog__articles-margin;

        @include mq($screen-m) {
            margin: $blog__articles-margin\@medium;
        }
    }

    &__articles-list {
        margin: $blog__articles-list-margin;
    }

    &__article-item {
        padding: $blog__article-item-padding;
        margin: $blog__article-item-margin;
        border: $blog__article-item-border;
        border-width: $blog__article-item-border-width;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__nav-heading {
        font-size: $blog__nav-heading-font-size;
        font-weight: $blog__nav-heading-font-weight;
        margin: $blog__nav-heading-margin;
        text-transform: $blog__nav-heading-text-transform;

        @include font-padding($blog__nav-heading-font-size, $blog__nav-heading-height);
    }

    &__nav-list {
        .list__item {
            padding: $blog__nav-list-item-padding;
            margin: $blog__nav-list-item-margin;
        }

        .link {
            display: block;
            text-transform: $blog__nav-list-link-text-transform;
            font-size: $blog__nav-list-link-font-size;

            @include font-padding($blog__nav-list-link-font-size, $blog__nav-list-link-height);
        }
    }

    &__recommended {
        margin: $blog__recommended-margin;

        @include mq($screen-m) {
            margin: $blog__recommended-margin\@medium;
        }

        @include mq($screen-l) {
            margin: $blog__recommended-margin\@large;
        }
    }

    &__categories {
        margin: $blog__categories-margin;

        @include mq($screen-m) {
            margin: $blog__categories-margin\@medium;
        }

        @include mq($screen-l) {
            margin: $blog__categories-margin\@large;
        }
    }
}
