.sales-order-view {

    .dashboard {

        &__heading {
            background-color: $lightest-gray;
            border-radius: $dashboard__heading-border-radius;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            padding: $dashboard__heading-padding;

            h1.heading {
                font-size: $dashboard__heading-font-size;
                font-weight: $dashboard__heading-font-weight;
                width: $dashboard__heading-width;
            }
            .dashboard__order-date {
                text-align: right;
                width: $dashboard__heading-width;
                .date-order-date{
                    color: $dashboard__paymentinfo-color;
                    font-size: $dashboard__paymentinfo-font-size;
                }
            }
        }

        &__content {
            .card{
                &__col-title {
                    ul li{
                        font-weight: $font-weight-normal;
                    }
                }
            }
            .actions-group {
                flex-direction: row;
                justify-content: center;
                text-align: center;
                width: 100%;
            }
            .totals {

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: $dashboard__totals-gran-totals-margin-top;
                > div:not(.grand_total) {
                    display: none;
                }
                .grand_total {
                    display: flex;
                    flex-direction: row;
                    font-size: $dashboard__totals-gran-totals-font-size;
                    justify-content: space-between;
                }
                >span,
                .label-properties {
                    display: none;
                }
                > strong {
                    display: flex;
                    flex-direction: row;
                    font-size: $dashboard__totals-gran-totals-font-size;
                    justify-content: space-between;
                    &:last-child {
                        text-align: right;
                    }
                }
            }

        }

        &-table__header {
            border-width: 0;
        }

        &__order-info {
            display: flex;
            flex-direction: column;
        }

        &__order-number {
            font-weight: $font-weight-medium-bold;

            .order {
                position: relative;
                left: $dashboard__order-number-left;
            }
        }

        &__paymentinfo {
            color: $dashboard__paymentinfo-color;
            display: flex;
            font-size: $dashboard__paymentinfo-font-size;
        }

    }
}
