

/*var sales view (page - order detail)*/
$dashboard__heading-border-radius           : 10px;
$dashboard__heading-padding                 : $spacer--medium;

$dashboard__heading-font-size               : $font-size-medium;
$dashboard__heading-font-weight             : $font-weight-medium-bold;
$dashboard__heading-width                   : 45%;

$dashboard__order-number-left               : -4px;

$dashboard__totals-gran-totals-font-size    : $font-size-large;
$dashboard__totals-gran-totals-margin-top   : $spacer--medium;

$dashboard__paymentinfo-color                : $dark-grey;
$dashboard__paymentinfo-font-size            : $font-size-small;