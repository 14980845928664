$post-border-radius             : $spacer + 2;
$post-holder-border             : 1px solid $lighter-gray;
$post-read-more-margin          : $spacer--semi-medium 0;
$button_mobile-border           : 1px solid $bsm-grey;
$post-heading-color             : $gray;
$post-card-padding-extra-large  : 88px;
$post-wrapper-margin            : $spacer--extra-large-xl;
$post-category-heading-padding  : 0 $post-card-padding-extra-large;
$post-list-padding-top          : $spacer--extra-large;
$post-wrapper-background        : $light-gray;
$post-card-wrapper-background   : $white;
$post-card-icon-color-background: $lighter-gray;
$post-card-button-border        : $light-gray;
$post-card-width                : 356px;
$post-card-width\@small         : 270px;
$post-card-width-extra-small    : 212px;
$post-card-list-wrapper-padding : 0 75px;

$post-category-content-padding  : 0 $post-card-padding-extra-large 40px $post-card-padding-extra-large;
$post-card-button-view-other-size   : 311px;
$post-card-button-view-other-margin : $spacer--large auto 0  auto;

//WIDGET - CARD
$widget-card--background-color      : $bg-color-base !default;
$widget-card--border                : $post-holder-border !default;
$widget-card--radius                : $post-border-radius !default;
$widget-card--padding               : $spacer--large  !default;
$widget-card--padding\@medium       : $spacer--semi-large !default;
$widget-card--margin\@medium        : 0 3% 3% 0 !default;
$widget-card--margin                : 0 0 5% 0 !default;
$widget-card-even--margin           : 0 0% 3% 0 !default;

$widget-card-x2-width\@medium       : 48% !default;
$widget-card-x3-width\@medium       : 33.33% !default;

$widget-card-title--font-size       : $font-size-super-extra-large\@screen-m !default;
$widget-card-title--font-weight     : $font-weight-normal !default;
$widget-card-title--margin          : $spacer--medium 0 !default;

$widget-card-sub-title--font-size   : $font-size-large !default;
$widget-card-sub-title--font-weight : $font-weight-medium-bold !default;

$widget-card-image--max-width       : 96px !default;

$widget-card-text--font-size        : $font-size-base !default;
$widget-card-text--font-weight      : $font-weight-normal !default;
$widget-card-text--min-height       : 70px !default;
$widget-card-button-margin          : $spacer--medium 0 0 0 !default;
$widget-card-button-a-width\@small  : 100% !default;

$widget-card-button-a-primary--font-weight  : $font-weight-medium-bold !default;
$widget-card-button-a-primary               : $color-primary !default;
$widget-card-button-a-primary-hover         : $color-primary-20 !default;


//WIDGET - HEADING

$widget-header-landing-before-bg           : $color-secondary !default;
$widget-header-landing-before-height       : 350px !default;
$widget-header-landing-before-position     : absolute!default;
$widget-header-landing-before-top          : -80px!default;

$widget-header-landing-title\@medium       : $font-size-mega-large\@screen-m !default;
$widget-header-landing-title               : $font-size-mega-large !default;
$widget-header-landing-title-margin        : $spacer--medium 0 !default;

$widget-header-landing-description-margin  :  0 0 $spacer--large  0  !default;

$widget-sidebar--list-color                : $battleship-grey;


//WIDGET - CARD HORIZONTAL

$widget-card--card--horizontal-margin      : 75px auto;

$widget-card--card--horizontal-title-margin\@medium : $spacer--medium 0 $spacer 0;;

$widget-card--card--horizontal-content-padding-right: $spacer--small;
$widget-card--card--horizontal-content-padding-left : $spacer--small;



//WIDGET - CARD MINI
$widget-card--mini-background-color                : $white !default;
$widget-card--mini-border                          : 1px solid $lighter-gray;
$widget-card--mini-border-radius                   : $spacer + 2;
$widget-card--mini-padding                         : 35px 25px;
$widget-card--mini-margin                          : $spacer--medium 0 0 0;
$widget-card--mini-margin\@medium                  : 0 $spacer--medium $spacer--medium 0;

$widget-card--mini__image-margin-bottom            : $spacer--medium;
$widget-card--mini__title-font-size                : $font-size-extra-large;
$widget-card--mini__title-font-size\@medium        : $font-size-extra-large\@screen-m;
$widget-card--mini__title-font-size\@large         : $font-size-super-extra-large\@screen-m;
$widget-card--mini__title-padding-right            : 0;
$widget-card--mini__title-padding-right\@large     : 5%;
$widget-card--mini__title_has_text-font-size       : $font-size-large;

$widget-card--mini__title__has-link-padding-right           : 5%;
$widget-card--mini__title__has-link-padding-right\@medium   : 25%;
$widget-card--mini__title__has-link-font-weight             : $font-weight-medium-bold;

//WIDGET -- Block Contact Comercial


$widget-contact-commercial-background-color                 : $lightest-gray;
$widget-contact-commercial-left                             : 50%;
$widget-contact-commercial-margin-left                      : -50vw;
$widget-contact-commercial-margin-top                       : 100px;
$widget-contact-commercial-padding                          : 90px 30px;
$widget-contact-commercial-position                         : relative;
$widget-contact-commercial-width                            : 99.8vw;
$widget-contact-commercial-padding\@medium                  : 90px 0;

$widget-contact-commercial__wrapper-align-items             : center;
$widget-contact-commercial__wrapper-border-radius           : 10px;
$widget-contact-commercial__wrapper-border                  : 1px solid $gray-medium;
$widget-contact-commercial__wrapper-display                 : flex;
$widget-contact-commercial__wrapper-flex-direction          : column;
$widget-contact-commercial__wrapper-justify-content         : center;
$widget-contact-commercial__wrapper-margin                  : auto;
$widget-contact-commercial__wrapper-max-width               : 920px;
$widget-contact-commercial__wrapper-overflow                : hidden;
$widget-contact-commercial__wrapper-flex-direction\@medium  : row;

$widget-contact-commercial__image-width                     : 100%;
$widget-contact-commercial__image-width\@medium             : 40%;

$widget-contact-commercial__content-background-color        : $white;
$widget-contact-commercial__content-padding                 : $spacer--semi-medium ;
$widget-contact-commercial__content-width                   : 100%;
$widget-contact-commercial__content-padding\@medium         : $spacer--semi-large + 5;
$widget-contact-commercial__content-width\@medium           : 60%;

$widget-contact-commercial__title-font-size                 : $font-size-extra-large\@screen-m ;
$widget-contact-commercial__title-margin-bottom             : $spacer;

$widget-contact-commercial__text-margin-bottom              : $spacer;


//WIDGET -- Block CARD MINI GROUP


$widget-card-mini-group-margin-bottom                    : 0;
$widget-card-mini-group-margin-bottom\@large             : 350px;

$widget-card-mini-group__image-max-width                 : 100%;
$widget-card-mini-group__image-max-width\@large          : 845px;

$widget-card-mini-group__content-max-width               : none;
$widget-card-mini-group__content-max-width\@medium       : 810px;
$widget-card-mini-group__content-margin-right            : auto;
$widget-card-mini-group__content-margin-right\@medium    : 0;
$widget-card-mini-group__content-top                     : unset;
$widget-card-mini-group__content-top\@medium             : $widget-card-mini-group-margin-bottom\@large / 3;

$widget-card-mini-group__minicard-background-color       : $widget-card--mini-background-color;
$widget-card-mini-group__minicard-border-radius          : $widget-card--mini-border-radius;
$widget-card-mini-group__minicard-border                 : $widget-card--mini-border;

$widget-card-mini-group__minicard-margin-odd             : $spacer--medium $spacer--medium $spacer--medium 0;
$widget-card-mini-group__minicard-margin-odd\@medium     : $spacer--medium;
$widget-card-mini-group__minicard-margin-even            : $spacer--medium 0 $spacer--medium $spacer--medium;
$widget-card-mini-group__minicard-margin-even\@medium    : $spacer--medium;

$widget-card-mini-group__minicard-padding                : 16px;
$widget-card-mini-group__minicard-padding\@medium        : 24px;

$widget-card-mini-group__minicard-min-height             : unset;
$widget-card-mini-group__minicard-min-height\@medium     : 200px;
$widget-card-mini-group__minicard-min-height\@large      : 300px;

$widget-card-mini-group__minicard-max-width              : 45%;
$widget-card-mini-group__minicard-max-width\@small       : 47%;
$widget-card-mini-group__minicard-max-width\@medium      : 250px;
$widget-card-mini-group__minicard-max-width\@large       : 350px;

$widget-card-mini-group__minicard-width                  : 100%;

$widget-card-mini-group__minicard__image-img-max-width   : 64px;

$widget-card-mini-group__minicard__title-color            : $black;
$widget-card-mini-group__minicard__title-font-size        : $font-size-super-extra-large;
$widget-card-mini-group__minicard__title-font-size\@medium: $font-size-extra-large\@screen-m;

$widget-card-mini-group__minicard-font-size               : $font-size-large;
$widget-card-mini-group__minicard-font-weight             : $font-weight-medium ;



$full-img-widget-card--mini-padding                        : 5px 25px;
