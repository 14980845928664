$slider__margin                               : 0 !default;
$slider__width                                : 100% !default;
$slider__min-height                           : 56px !default; //loader size

$slider__heading-justify-content              : center !default;
$slider__heading-min-height                   : 48px !default;
$slider__heading-margin                       : 0 0 $spacer--semi-medium !default;

//slider products
$slider__heading-margin--products             : 0 0 $spacer--extra-large !default;
$slider__item-padding--products               : $spacer !default;
//vars used to avaiod jumping content before js is loaded
$slider__hidden-item-number--products         : 3 !default;
$slider__hidden-item-number\@medium--products : 4 !default;
$slider__hidden-item-number\@large--products  : 5 !default;

//slider brands
$slider__item-width--brands                   : 96px !default;
$slider__item-to-show--brands                 : 3 !default;
$slider__item-width\@medium--brands           : 144px !default;
$slider__item-to-show\@medium--brands         : 4 !default;
$slider__item-width\@large--brands            : 144px !default;
$slider__item-to-show\@large--brands          : 6 !default;
$slider__item-width\@max-width--brands        : 208px !default;
$slider__item-to-show\@max-width--brands      : 6 !default;

$slider__handler-max-width--brands            : $slider__item-width--brands * $slider__item-to-show--brands !default;
$slider__handler-max-width\@medium--brands    : $slider__item-width\@medium--brands * $slider__item-to-show\@medium--brands !default;
$slider__handler-max-width\@large--brands     : $slider__item-width\@large--brands * $slider__item-to-show\@large--brands !default;
$slider__handler-max-width\@max-width--brands : $slider__item-width\@max-width--brands * $slider__item-to-show\@max-width--brands !default;

//slider catalog events
$slider__max-width--catalog-events            : $max-content-width !default;
$slider__margin--catalog-events               : 0 auto !default;

//navigation
$slider__navigation-display                   : none !default;
$slider__navigation-display-breakpoint        : $screen-m !default;
$slider__navigation-width                     : 100% !default;
$slider__navigation-top                       : 100% !default;
$slider__navigation-IE-width                  : calc(50% - #{$slider__navigation-top}) !default;

$slider__prev-left                            : 0 !default;
$slider__next-right                           : 0 !default;
$slider__nav-button-border-radius             : 0 !default;
$slider__nav-button-size                      : 100px !default;
$slider__nav-icon-size                        : 68px !default;

//dots
$slider__dots-bottom                          : 0 !default;
$slider__dots-margin                          : 0 10px 0 0 !default;
$slider__dots-margin\@small                   : 0 5px 0 0 !default;
$slider__dots-padding                         : 0 !default;
$slider__dot-size                             : 13px !default;
$slider__dot-size\@small                      : 10px !default;
$slider__dot-background-color                 : $white !default;
$slider__dot-background-color--active         : $bsm-grey !default;
$slider__dot-border                           : 1px solid $gray !default;

$slider__link-font-weight                     : $font-weight-bold !default;
$slider__link-min-height                      : 48px !default;
$slider__link-text-align                      : center !default;
$slider__link-font-size                       : $font-size-base !default;

$slider__after-padding                        : $spacer--medium 0 !default;
$slider__after-text-align                     : center !default;

//breakpoint overflow items styles
$slider__container-before-bg                  : linear-gradient(to left, rgba($white, 0), $white 50%) !default;
$slider__container-after-bg                   : linear-gradient(to right, rgba($white, 0), $white 50%) !default;

// picture image in slider
$slider__picture-lazyload-min-height          : initial !default;
$slider__picture-lazyload-bg-image            : none !default;
