$recaptcha__badge-z-index: 100 !default;
$recaptcha__image-margin: $spacer 0 !default;
$recaptcha__align: space-between !default;

.grecaptcha-badge {
    z-index: $recaptcha__badge-z-index;
}

.captcha-image {
    display: flex;
    width: 100%;
    justify-content: $recaptcha__align;
    margin: $recaptcha__image-margin;
}

.captcha-img {
    width: auto;
}

.captcha-reload {
    &.action {
        @extend .button--secondary;
    }
}
