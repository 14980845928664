$headings-margin                          : 0 0 0.5em 0 !default;
$headings-margin--page                    : $spacer--medium 0 !default;

$headings-font-family                     : $font-family-secondary !default;
$headings-font-family--secondary          : $font-family-base !default;
$headings-font-weight                     : $font-weight-medium !default;
$headings-line-height                     : $font-line-height !default;
$headings-letter-spacing                  : 0px !default;
$headings-color                           : $color-primary !default;

$headings-text-transform                  : uppercase !default;
$headings-text-transform--page            : uppercase !default;

$heading-font-size--page\@screen-m        : $font-size-super-extra-large !default;
$heading-font-size--page                  : $font-size-extra-large !default;
$heading-font-size--first-level\@screen-m : $font-size-super-extra-large !default;
$heading-font-size--first-level           : $font-size-extra-large !default;
$heading-font-size--second-level\@screen-m: $font-size-extra-large !default;
$heading-font-size--second-level          : $font-size-large !default;
$heading-font-size--third-level\@screen-m : $font-size-large !default;
$heading-font-size--third-level           : $font-size-medium !default;
$heading-font-size--fourth-level          : $font-size-medium !default;
$heading-font-size--fifth-level           : $font-size-medium !default;
$heading-font-size--sixth-level           : $font-size-medium !default;

$heading-font-weight--page\@screen-m        : $headings-font-weight !default;
$heading-font-weight--page                  : $headings-font-weight !default;
$heading-font-weight--first-level\@screen-m : $headings-font-weight !default;
$heading-font-weight--first-level           : $headings-font-weight !default;
$heading-font-weight--second-level\@screen-m: $headings-font-weight !default;
$heading-font-weight--second-level          : $headings-font-weight !default;
$heading-font-weight--third-level\@screen-m : $headings-font-weight !default;
$heading-font-weight--third-level           : $headings-font-weight !default;
$heading-font-weight--fourth-level          : $headings-font-weight !default;
$heading-font-weight--fifth-level           : $headings-font-weight !default;
$heading-font-weight--sixth-level           : $headings-font-weight !default;

$heading-letter-spacing--page\@screen-m        : $headings-letter-spacing !default;
$heading-letter-spacing--page                  : $headings-letter-spacing !default;
$heading-letter-spacing--first-level\@screen-m : $headings-letter-spacing !default;
$heading-letter-spacing--first-level           : $headings-letter-spacing !default;
$heading-letter-spacing--second-level\@screen-m: $headings-letter-spacing !default;
$heading-letter-spacing--second-level          : $headings-letter-spacing !default;
$heading-letter-spacing--third-level\@screen-m : $headings-letter-spacing !default;
$heading-letter-spacing--third-level           : $headings-letter-spacing !default;
$heading-letter-spacing--fourth-level          : $headings-letter-spacing !default;
$heading-letter-spacing--fifth-level           : $headings-letter-spacing !default;
$heading-letter-spacing--sixth-level           : $headings-letter-spacing !default;
