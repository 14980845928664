@import 'quicksearch-variables';

.quicksearch {
    position: absolute;
    top: $quicksearch__top;
    left: $quicksearch__left;
    display: none;
    padding: $quicksearch__padding;
    width: $quicksearch__width;
    background: $quicksearch__background;
    box-shadow: $quicksearch__box-shadow;
    transition: $transition-base;

    @include mq($screen-m) {
        top: $quicksearch__top\@medium;
        left: $quicksearch__left\@medium;
        right: $quicksearch__right\@medium;
        width: $quicksearch__width\@medium;
        padding: $quicksearch__padding\@medium;
    }


    @include mq($quicksearch__mq-large) {
        padding: $quicksearch__padding\@large;
        left: $quicksearch__left\@large;
        width: $quicksearch__width\@large;
    }

    @include mq($screen-xl) {
        padding: $quicksearch__padding\@xl;
        left: $quicksearch__left\@xl;
        width: $quicksearch__width\@xl;
    }

    &--visible {
        display: block;
    }

    &__wrapper {
        position: $quicksearch__wrapper-position;
    }

    &__results {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $quicksearch__results-padding;
        border-bottom: $quicksearch__results-border;

        @include mq($screen-m) {
            padding: $quicksearch__results-padding\@medium;
        }

        @include mq($screen-l) {
            padding: $quicksearch__results-padding\@large;
        }
    }

    &__empty {
        margin: $quicksearch__empty-margin;
    }

    &__empty,
    &__results-heading {
        font-family: $quicksearch__results-heading-font-family;
        font-size: $quicksearch__results-heading-font-size;
        font-weight: $quicksearch__results-heading-font-weight;
        text-transform:  $quicksearch__results-heading-text-transform;
    }

    &__typed {
        display: inline-flex;
        align-items: center;
        min-height: $quicksearch__typed-min-height;
        font-weight: $quicksearch__typed-font-weight;
    }

    &__close-button {
        position: $quicksearch__close-position;
        right: $quicksearch__close-right;
        top: $quicksearch__close-top;
        background-color: $quicksearch__close-background;

        @include mq($screen-m) {
            top: $quicksearch__close-top\@medium;
            right: $quicksearch__close-right\@medium;
        }

        @include mq($screen-l) {
            top: $quicksearch__close-top\@large;
            right: $quicksearch__close-right\@large;
        }

        .quicksearch__close-icon {
            fill: $quicksearch__close-fill;
        }
    }

    &__content {
        padding: $quicksearch__content-padding;

        @include mq($screen-m) {
            display: flex;
        }
    }

    &__list {
        margin: $quicksearch__list-margin;
        padding: $quicksearch__list-padding;
        list-style-type: none;

        &--column {
            flex: 2;
        }
    }

    &__product {
        display: flex;
        padding: $quicksearch__product-padding;
        border-bottom: $quicksearch__product-border;
    }

    &__product-image-wrapper {
        min-width: $quicksearch__product-image-size;
        min-height: $quicksearch__product-image-size;
        margin-right: $quicksearch__product-image-margin-right;
    }

    &__product-image {
        max-width: $quicksearch__product-image-size;
    }

    &__link {
        display: block;
        text-decoration: none;

        &--sidebar {
            color: $quicksearch__sidebar-link-color;
            font-size: $quicksearch__sidebar-link-font-size;
            font-weight: $quicksearch__sidebar-link-font-weight;
        }
    }

    &__name {
        color: $quicksearch__name-color;
        font-family: $quicksearch__name-font-family;
        font-size: $quicksearch__name-font-size;
        text-transform: $quicksearch__name-text-decoration;
        font-weight: $quicksearch__name-font-weight;
        margin: $quicksearch__name-margin;
        padding: $quicksearch__name-padding;

        @include mq($screen-m) {
            font-size: $quicksearch__name-font-size\@medium;
        }
    }

    &__sidebar-column {
        flex: 1;
        padding: $quicksearch__sidebar-column-padding;

        @include mq($screen-m) {
            padding: $quicksearch__sidebar-column-padding\@medium;
        }

        @include mq($screen-l) {
            padding: $quicksearch__sidebar-column-padding\@large;
        }

        @include mq($screen-xl) {
            padding: $quicksearch__sidebar-column-padding\@xl;
        }
    }

    &__sidebar-list {
        &:first-of-type {
            margin: $quicksearch__sidebar-list-top-margin;
        }
    }

    &__sidebar-item {
        padding: $quicksearch__sidebar-item-padding;
    }

    &__sidebar-title {
        font-family: $quicksearch__sidebar-title-font-family;
        font-size: $quicksearch__sidebar-title-font-size;
        font-weight: $quicksearch__sidebar-title-font-weight;
        text-transform: $quicksearch__sidebar-title-text-transform;
    }

    &__more-link {
        margin: $quicksearch__more-link-margin;
        border-top: $quicksearch__more-link-border-top;

        @include mq($screen-l) {
            margin: $quicksearch__more-link-margin\@large;
        }

        &:hover {
            color: inherit;
        }
    }
}
