$amlocator-parkings-grid-columns                      : 1;
$amlocator-parkings-grid-columns\@small               : 2;
$amlocator-parkings-grid-columns\@large               : 4;
$amlocator-parkings-map-margin                        : $spacer--extra-large 0 !default;
$amlocator-parkings-map-height                        : 80vh !default;
$amlocator-parkings-map-max-height                    : 800px !default;

$amlocator-parkings-grid-item-content-padding         : $spacer--medium  !default;
$amlocator-parkings-grid-item-content-padding\medium  : $spacer--semi-medium !default;

$amlocator-parkings-grid-item-name-font-size          : $font-size-large !default;
$amlocator-parkings-grid-item-name-font-weight        : 700 !default;
$amlocator-parkings-grid-item-description-font-size   : $font-size-base !default;
$amlocator-parkings-grid-item-subtitle-font-size      : $font-size-medium !default;
$amlocator-parkings-grid-item-subtitle-font-weight    : 700 !default;

$amlocator-parkings-filter-margin-right\@small        : $spacer--semi-medium !default;
$amlocator-parkings-filter-margin-right               : $spacer !default;