    @import 'cms-page-variables';
.cms-page {
    &__content {
        max-width: $cms-page__max-width;
        margin: $cms-page__margin;

        @include mq($screen-m) {
            max-width: $cms-page__max-width\@medium;
        }

        @include mq($screen-l) {
            max-width: $cms-page__max-width\@large;
        }

        @include mq($screen-xl) {
            max-width: $cms-page__max-width\@xlarge;
        }

        .cms-contact &,
        .cms-contact-us & {
            max-width: $cms-contact__max-width;
        }
    }
    &-view{
        #maincontent{
            margin-top: 0;
        }
    }
}

/*  PAG CMS CATÁLOGO */
[class^="cms-catal"] {
    .page-wrapper{
        margin-bottom: 0;
    }
}

[class^="cms-contac"] {
    .cms-page {
        &__content {
            @include mq($screen-l) {
                max-width: $cms-page-contact__max-width\@medium;
            }
            .card-form{
                margin-top: $cms-page-contact-wrapper-form-card-margin-top ;
                iframe{
                    border: unset;
                    max-width: 100%;
                }
                &-title{
                    font-size: $cms-page-contact-wrapper-form-card-title-font-size;
                    font-weight: $cms-page-contact-wrapper-form-card-title-font-weight;
                }
            }
            .widget-card{
                cursor: pointer;

                &__wrapper {
                    margin: 0;
                    @include mq($screen-l) {
                        margin: $cms-page-contact-wrapper-card-margin;
                    }
                }

                .icon{
                    width: $cms-page-contact-card-icon-size-w;
                    height: $cms-page-contact-card-icon-size-h;
                }

                &.__active{
                    border-color: $cms-page-contact-card-active-border-color;
                }
            }

        }
    }
}

/*  PAG CMS EMPRESAS - DUM - SMOU*/
.cms-page-view  {
    .page-wrapper {
        margin-bottom: 0;
    }
    footer {
        margin-top: 0 !important;
    }

    &.cms-smou {
        .cms-page__content {
            .row {
                > div:nth-child(1),
                > div:nth-child(2) {
                    .__empty {
                        padding-right: 0;
                        @include mq($screen-m) {
                            padding-right: 35%;
                        }
                    }
                }
            }
        }
    }

    &.cms-servicios-de-valor {
        .width-force-full {
            @include mq($screen-m) {
                padding: 60px 30px;
            }

            h2 {
                margin-bottom: 30px;
            }
        }
        .widget-card--mini {
            height: auto;
            @include mq($screen-m) {
                min-height: 390px ;
            }
            &__title {
                font-size: $cms-page-contact-wrapper-form-card-title-font-size;
                font-weight: $cms-page-contact-wrapper-form-card-title-font-weight;
                margin-bottom: $spacer--extra-small;
            }
            &.__has_button {
                .actions {
                    bottom: auto;
                    position: relative;
                    width: auto;
                    @include mq($screen-m) {
                        bottom: 30px;
                        position: absolute;
                        width: 75%;
                    }

                    a {
                        width: auto;
                        @include mq($screen-m) {
                            width: 100%;
                        }
                    }

                }
            }
            &__image {
                .responsive {
                    @include mq($screen-m) {
                        width: $cms-page-servicios-widget-card-image-size-w;
                        height: $cms-page-servicios-widget-card-image-size-h;
                    }
                }
            }

        }
    }

    &.cms-servicios {
        .width-force-full {
            @include mq($screen-m) {
                padding: 60px 30px;
            }

            h2 {
                margin-bottom: 30px;
            }
        }
        .widget-card--mini__title {
            color: $bsm-green;
            font-size: $font-size-large;
            font-weight: $font-weight-medium-bold;
            margin: 0;
        }
        .__has_button {
            .actions {
                padding-bottom: $spacer--semi-medium ;
                a {
                    width: 100%;
                }
            }
        }

    }
    &.cms-about-us,
    &.cms-sobre-nosotros {

        .widget-card--mini {
            &__title {
                font-size: $font-size-large;
                color: $bsm-green;
            }
        }
    }

    &.cms-click-collect,
    &.cms-alquiler-sharing {
        .widget-card {
            &--horizontal {
                &__title {
                    margin-bottom: 0;
                }
                &__image{
                    &.col-lg-2 {
                        @include mq($screen-l) {
                            flex-basis: 20%;
                            max-width: 20%;
                        }
                    }
                }
            }
            &--wrapper {
                &.col-lg-10 {
                    @include mq($screen-l) {
                        flex-basis: 80%;
                        max-width: 80%;
                    }
                }
            }
        }
    }
    &[class*="cms-en"]{
        .amlocator-popup {
            padding-left: $spacer--semi-large;
            position: relative;
            &:before {
                background-image: url('../images/enchufe.png');
                background-repeat: no-repeat;
                background-size: contain;
                content: '';
                height: 39px;
                left: 0;
                position: absolute;
                width: 32px;
            }
        }
    }
    &.cms-alquiler-sharing,
    &[class*="cms-en"] {

        .amlocator-main-container {
            .amlocator-map-wrapper {
                left: 50%;
                margin-left: -50vw;
                padding: 0;
                position: relative;
                width: 99.8vw;
                .amlocator-map-container {
                    height: auto;
                    .amlocator-block {
                        &.-map {
                            padding: 0;
                            width: 100%;
                        }
                    }
                }
            }

        }
    }

    &.cms-faqs {
        .widget-card__wrapper {
            margin: $spacer--extra-large  auto;
            max-width: 100%;
            @include mq($screen-m) {
                max-width: 75%;
            }
            @include mq($screen-l) {
                max-width: 68%;
            }
        }
        .card-form {
            width: 100%;
            max-width: 100%;
            iframe {
                border-width: 1px;
                padding: $spacer;
                width: 100%;
            }
        }
    }
}

/*  PAG CMS PROMOCIONES */
[class^="cms-promo"] {
    .widget-card--mini {
        padding: 0;
        &__title {
            font-weight: $font-weight-normal;
            @include mq($screen-m) {
                font-size: $font-size-medium ;
            }

        }

        &__title-wrapper,
        .content,
        .actions {
            padding: 0 $spacer--small;
            @include mq($screen-m) {
                padding: 0 $spacer--semi-medium;
            }
        }
        .content {
            padding-bottom: $spacer--small;
            padding-top: $spacer--small;
        }
        .actions {
            padding-bottom: $spacer--semi-medium;
        }
        .button {
            margin-top: 0;
            width: 100%;
        }
    }
    .product-grid-item {
        background-color: $white;
    }
}
/*  PAG CMS Accesibility */
[class^="cms-acce"] {
    h4 {
        width: 100%;
    }
    .width-force-full {
        margin-bottom: 0;
        margin-top: 0;
    }
}
[class^="cms-aviso-legal-"] {
    a {
        color: $bsm-green;
    }
}
