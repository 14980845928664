$quote-comments__item-margin        : 0 0 $spacer--large 0 !default;
$quote-comments__item-padding       : 0 0 $spacer--large 0 !default;
$quote-comments__item-border        : $border-base !default;
$quote-comments__item-border-width  : 0 0 $border-width-base 0 !default;

$quote-comments__date-font-size     : $font-size-medium !default;
$quote-comments__date-line-height   : 32px !default;
$quote-comments__date-margin        : 0 !default;
$quote-comments__date-padding       : 0 !default;

$quote-comments__author-font-size   : $font-size-medium !default;
$quote-comments__author-font-weight : $font-weight-bold !default;
$quote-comments__author-line-height : 32px !default;
$quote-comments__author-margin      : 0 0 $spacer !default;
$quote-comments__author-padding     : 0 !default;

$quote-comments__content-font-size  : $font-size-medium !default;
$quote-comments__content-line-height: 32px !default;
$quote-comments__content-margin     : 0 !default;
$quote-comments__content-padding    : 0 !default;

.quote-comments {
    &__item {
        display: flex;
        flex-direction: column;
        margin: $quote-comments__item-margin;
        padding: $quote-comments__item-padding;
        border: $quote-comments__item-border;
        border-width: $quote-comments__item-border-width;
    }

    &__date {
        font-size: $quote-comments__date-font-size;
        line-height: $quote-comments__date-line-height;
        margin: $quote-comments__date-margin;
        padding: $quote-comments__date-padding;
    }

    &__author {
        font-size: $quote-comments__author-font-size;
        font-weight: $quote-comments__author-font-weight;
        line-height: $quote-comments__author-line-height;
        margin: $quote-comments__author-margin;
        padding: $quote-comments__author-padding;
    }

    &__content {
        font-size: $quote-comments__content-font-size;
        line-height: $quote-comments__content-line-height;
        margin: $quote-comments__content-margin;
        padding: $quote-comments__content-padding;

        p {
            margin: 0;
        }
    }
}
