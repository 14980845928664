$add-to-cart__sticky-always-mq                           : $screen-m !default;
$add-to-cart__display-always-max-screen                  : calc(#{$add-to-cart__sticky-always-mq} - 1px);
$add-to-cart__z-index                                    : $z-index-high !default;
$add-to-cart__min-height                                 : 2 * $spacer--extra-large !default;
$add-to-cart__border                                     : $border-base !default;
$add-to-cart__border-width                               : $border-width-base 0 0 0 !default;
$add-to-cart__background                                 : $white !default;

$add-to-cart__shadow                                     : 0 -2px 6px 0 rgba(0, 0, 0, 0.1) !default;

$add-to-cart__wrapper-max-width                          : $max-content-width !default;
$add-to-cart__wrapper-margin                             : 0 auto !default;
$add-to-cart__wrapper-padding                            : 0 $spacer--medium $spacer !default;
$add-to-cart__wrapper-padding\@medium                    : $spacer--medium !default;
$add-to-cart__wrapper-padding\@large                     : $spacer--medium $spacer--semi-large !default;
$add-to-cart__wrapper-padding\@xxl                       : $spacer--medium 0 !default;

$add-to-cart__title-width                                : calc(100% - 70px) !default;
$add-to-cart__title-width\@medium                        : calc(100% - 420px) !default;
$add-to-cart__title-width\@large                         : calc(100% - 490px) !default;
$add-to-cart__title-font-size                            : $font-size-base !default;
$add-to-cart__title-font-family                          : $font-family-base !default;
$add-to-cart__title-font-size\@large                     : $font-size-medium !default;
$add-to-cart__title-text-transform                       : initial !default;
$add-to-cart__title-margin                               : 0 !default;

$add-to-cart__button-padding\@medium                     : $spacer--medium $spacer--semi-medium !default;
$add-to-cart__button-height\@medium                      : 56px !default;
$add-to-cart__button-add-to-min-width\@large             : 300px !default;
$add-to-cart__button-additional-margin                   : $spacer 0 !default;
$add-to-cart__button-additional-margin\@medium           : 0 !default;
$add-to-cart__button-additional-bg                       : inherit !default;
$add-to-cart__button-additional-bg\@medium               : $gray-lighter !default;
$add-to-cart__button-additional-margin-last-child\@medium: 0 0 0 $spacer !default;
$add-toc-cart__button-more-icon-size                     : $icon-size !default;

$add-to-cart__action-flex-flow                           : column-reverse nowrap !default;
$add-to-cart__action-flex-flow\@medium                   : row nowrap !default;
$add-to-cart__action-secondary-position                  : absolute !default;
$add-to-cart__action-secondary-flex-flow                 : row nowrap !default;

$add-to-cart__action-secondary-width                     : calc(100vw + #{$spacer--medium}) !default;
$add-to-cart__action-secondary-width\@medium             : $spacer--extra-large + $spacer !default;
$add-to-cart__action-secondary-height                    : $spacer--extra-large + $spacer !default;
$add-to-cart__action-secondary-position-right            : -$spacer--medium !default;
$add-to-cart__action-secondary-background                : $white !default;
$add-to-cart__action-secondary-shadow                    : $add-to-cart__shadow, inset $add-to-cart__shadow !default;
$add-to-cart__action-secondary-transition                : $transition-base !default;
$add-to-cart__action-secondary-flex-flow\@medium         : row nowrap !default;
$add-to-cart__action-secondary-position\@medium          : relative !default;
$add-to-cart__action-secondary-margin\@medium            : 0 0 0 $spacer !default;
$add-to-cart__button-secondary-flex                      : 1 0 50% !default;
$add-to-cart__button-secondary-flex\@medium              : auto !default;

$add-to-cart__button-wishlist-filled-after-left          : calc(50% - #{$spacer--extra-large}) !default;
$add-to-cart__button-wishlist-filled-after-left\@medium  : auto !default;
