@import "search-block-variables";

.search-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: $search-block__padding;
    margin: $search-block__margin;

    @include mq($screen-l) {
        max-width: $max-content-width;
        margin: 0 auto;
        padding: 0 $static-block-spacer--extra--large;
        padding-bottom: $search-block--bottom;
        margin-top: auto;

    }
    &.full--width {
        margin: $search-block__full-width--margin;
        @include mq($screen-l) {
            padding: $search--block__full-width--padding;
        }
    }
    .input__label {
        color: $search-block__label-color;
    }
    .input:focus-within {
        .input__label {
            color: $search-block__label-color;
        }
    }
    .search {
        &-toggle {
            display: flex;
            padding: $search-block__search-mobile--padding;
            justify-content: center;
            width: 100%;
            .toggle {
                padding: $search-block__padding;
                margin: $search-block-toggle__margin;
            }
            @include mq($screen-l) {
                position: absolute;
                right: 0;
                padding: $search-block__search--padding;
                width: auto;

            }
            .input__label {
                margin: $search-block__search-label--margin;
                font-weight: $font-weight-normal;
                font-size: $font-size-small;
                @include mq($screen-l) {
                    font-size: $font-size-base;
                }
            }
        }
        &-container {
            position: relative;
            justify-content: space-between;
            padding: $search-block__container--padding\@small;
            background-color: $color-secondary;
            border-radius: 10px;
            margin-bottom: 0;
            @include mq($screen-l) {
                justify-content: flex-start;
                margin: 0 auto;
                padding: $search-block__container--padding\@medium;
            }
            &.light {
                background: $white;
                @include mq($screen-m) {
                    background: $search-block__light-theme--bg;
                }
            }
            &.full--width {
                @include mq($screen-l) {
                    padding: $search-block__container-full-width--padding\@medium;
                    width: $max-content-width;
                }
            }
            .tab__title {
                @extend .button;
                @extend .button--secondary;
                border-width: 1px;
                height: $search-block__container-tab--title-height;
                min-height: $search-block__container-tab--title-height + 4;
                max-width: $search-block__container-tab--title-max-width;
                border: $search-block__container-tab--title-border;
                font-weight: 500;
                font-size: $font-size-small;
                color: $search-block__container-tab--title-color;
                @include mq($screen-l) {
                    margin-right: $spacer--semi-medium;
                }
                &--active {
                    background-color: $white;
                    border: none;
                    color: $search-block__container-tab--title-active-color;
                    outline-color: $color-primary;
                    outline-width: 1px;
                    outline-style: solid;
                }
            }
            .tab__content {
                border: none;
                margin-top: $spacer;
                display: none;
                overflow: visible;
                &--active {
                    display: block;
                }
                .fieldset {
                    padding: $spacer 0;
                    &.date-field {
                        label {
                            margin: $search-block__container-fieldset--margin;
                        }
                        .input__field {
                            height: $search-block__container-fieldset--input--height;
                        }
                    }
                    @include mq($screen-l) {
                        padding: 0;
                    }
                }
            }
            .bottom-bar {
                display: flex;
                align-items: center;
                margin-top: $spacer--medium;
                color: $bsm-grey;
                font-size: $font-size-small;
                a {
                    &:hover {
                        color: $bsm-grey;
                    }
                }
                picture {
                    width: $search-block--bottom-bar-width;
                    height: $search-block--bottom-bar-height;
                }
                span {
                    padding: $search-block--bottom-bar-span-padding;
                }
            }
        }

        &-form {
            &-group {
                .button,
                .input__field {
                    width: 100%;
                }
            }

            &--row {
                .fieldset.search-button {
                    button {
                        width: 100%;
                        margin-left: 0;
                    }
                }
                @include mq($screen-l) {
                    display: flex;
                    justify-content: space-between;
                    margin-top: $spacer--small;
                    .fieldset.__single {
                        flex-grow: 1;

                        &.tipos_vehiculo .select2 {
                            width: 150px;
                            &:lang(fr), &:lang(es) {
                                width: 160px;
                            }
                            &:lang(en) {
                                width: 170px;
                            }
                        }
                    }
                    .fieldset.__double {
                        flex-grow: 2;
                    }
                    fieldset {
                        margin-right: 10px;
                    }
                    .fieldset.search-button {
                        align-self: center;
                        padding-top: $spacer--semi-medium;
                        flex-basis: 15%;
                        button {
                            margin: 0;
                            width: 100%;
                        }
                    }
                    .fieldset.multiparking-toggle {
                        position: absolute;
                        top: -38px;
                    }
                }
                fieldset{
                    .select {
                        margin-bottom: 0;
                    }
                }
                label {
                    color: $bsm-grey;
                    font-size: $font-size-small;
                    font-weight: normal;
                    white-space: nowrap;
                }
                .select2-selection {
                    &__rendered {
                        font-size: $font-size-small;
                        font-style: italic;
                        color: $search-block__container-tab--title-color;
                    }
                    &--single {
                        height: $search-block__container-fieldset--input--height;
                    }
                }
                .amlocator-block {
                    margin-bottom: 0;
                }
                .amlocator-search {
                    display: none;
                    position: absolute;
                    top: 11px;
                    right: 15px;
                    width: 23px;
                    height: 23px;
                    background: $amlocator-search-icon__background no-repeat center;
                    background-size: contain;
                    cursor: pointer;
                }

                .amlocator-reset {
                    position: absolute;
                    top: 11px;
                    right: 15px;
                    width: 22px;
                    height: 22px;
                    background: $amlocator-reset-icon__background no-repeat center;
                    cursor: pointer;
                }

                .amlocator-text {
                    @extend .input__field;
                    font-size: $font-size-small;
                }

                .-hidden {
                    display: none;
                }
            }
        }
    }
    &.light {
        .search{

            &-form--row {
                .input__label, label {
                    color: $font-color-base;
                }
                .input:focus-within {
                    .input__label {
                        color: $font-color-base;
                    }
                }
            }
            &-toggle {
                .input__label {
                    color: $search-block__container-tab--title-color;
                }
            }
        }
    }
    .dates {
        @include mq($screen-l){
            display: flex;
        }
        .fieldset {
            &.date-field {
                @include mq($screen-l){
                    width: 50%;
                }
                span.close:after {
                    bottom: $spacer;
                }
            }
        }
    }

}
.banner-top-fullsize {
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    @include mq($screen-l) {
        display: grid;
    }
    .banner,
    .search-block {
        grid-area: 1/2;
    }
}
@include mq($max-screen: $screen-s) {
    .noscroll {
        overflow: hidden;
        touch-action: none;
        -ms-touch-action: none;
        #ui-datepicker-div {
            position: fixed!important;
            top: 50%!important;
            left: 50%!important;
            transform: translate(-50%, -50%);
        }
    }
}
