@import "section-variables";

.section {
    margin: $section__margin;

    &--secondary {
        @include mq($screen-m) {
            margin: $section__margin--secondary\@medium;
        }
    }

    &--fix-spacer {
        margin: $section__margin--fix-spacer;
    }

    &--fix-spacer-small {
        margin: $section__margin--fix-spacer-small;
    }

    &--fix-spacer-medium {
        margin: $section__margin--fix-spacer-medium;

        @include mq($screen-m) {
            margin: $section__margin--fix-spacer-medium\@medium;
        }

        @include mq($screen-l) {
            margin: $section__margin--fix-spacer-medium\@large;
        }
    }

    &--fix-spacer-semi-medium {
        margin: $section__margin--fix-spacer-semi-medium;
    }

    &--fix-spacer-large {
        margin: $section__margin--fix-spacer-large;

        @include mq($screen-m) {
            margin: $section__margin--fix-spacer-large\@medium;
        }

        @include mq($screen-l) {
            margin: $section__margin--fix-spacer-large\@large;
        }
    }

    &--fix-spacer-semi-large {
        margin: $section__margin--fix-spacer-semi-large;
    }

    &--fix-spacer-extra-large {
        margin: $section__margin--fix-spacer-extra-large;
    }

    &__heading {
        display: flex;
        justify-content: $section__heading-justify-content;
        align-items: center;
        min-height: $section__heading-min-height;
        margin: $section__heading-margin;
    }

    &__content {
        &--products {
            margin: $section__content-margin--products;
        }
    }
}
