$content-block__padding                             : 0 $spacer--medium !default;
$content-block__padding\@medium                     : 0 $spacer--large !default;
$content-block__padding\@large                      : 0 $spacer--semi-large !default;
$content-block__padding\@xl                         : 0 56px !default;

$content-block__heading-padding                     : $spacer--medium 0 !default;
$content-block__heading-line-height                 : 48px !default;

$content-block__introduction-margin                 : $spacer--large 0 !default;
$content-block__introduction-margin\@medium         : 0 0 $spacer--large !default;
$content-block__introduction-font-size              : $font-size-medium !default;
$content-block__introduction-font-size\@medium      : $font-size-large !default;
$content-block__introduction-font-weight            : $font-weight-bold !default;

$content-block__description-font-size               : $font-size-base !default;
$content-block__description-font-size\@medium       : $font-size-medium !default;

$content-block__link-margin                         : 0 0 $spacer--medium !default;
$content-block__link-font-size                      : $font-size-base !default;
$content-block__link-font-weight                    : $font-weight-bold !default;
$content-block__link-line-height                    : 48px !default;
$content-block__link-text-decoration                : none !default;
$content-block__link-text-decoration--hover         : underline !default;

//secondary
$content-block__padding--secondary                  : 0 $spacer--semi-medium !default;
$content-block__padding--secondary\@medium          : 0 128px !default;
$content-block__padding--secondary\@large           : 0 !default;

$content-block__heading-max-width--secondary        : 70% !default;
$content-block__heading-max-width--secondary\@medium: 60% !default;
$content-block__heading-max-width--secondary\@large : 100% !default;
$content-block__heading-border--secondary           : $spacer--extra-small $border-style-base $color-primary !default;
$content-block__heading-border-width--secondary     : $spacer--extra-small 0 !default;
