$brief-info__mq-display                  : $screen-l !default;
$brief-info__padding                     : 0 !default;
$brief-info__border                      : $spacer--extra-small solid $gray-light !default;
$brief-info__justyfi-content             : space-between !default;

$brief-info__icon-size                   : 48px !default;
$brief-info__icon-fill                   : $color-primary !default;
$brief-info__item-padding                : $spacer !default;

$brief-info__title-text-transform        : uppercase !default;
$brief-info__title-font-family           : $font-family-base !default;
$brief-info__title-font-size             : $font-size-medium !default;
$brief-info__title-font-weight           : $font-weight-bold !default;
$brief-info__title-margin                : 0 !default;
$brief-info__title-color                 : $gray-darker !default;
$brief-info__title-text-align            : left !default;

$brief-info__subtitle-font-size          : $font-size-base !default;
$brief-info__subtitle-font-wight         : $font-weight-base !default;
$brief-info__subtitle-text-transform     : none !default;
$brief-info__subtitle-color              : $color-secondary !default;
$brief-info__subtitle-margin             : 0 !default;

$vreif-info__content-text-decoration     : none !default;

// dividers
$brief-info__divider-width               : $border-width-base !default;
$brief-info__divider-top                 : $spacer !default;
$brief-info__divider-bottom              : $spacer !default;
$brief-info__divider-left                : auto !default;
$brief-info__divider-right               : 0 !default;
$brief-info__divider-color               : $border-color-base !default;

$brief-info__mq-display--dividers        : $screen-m !default;

$brief-info__padding--dividers           : $spacer--semi-large 0 !default;
$brief-info__border--dividers            : $spacer--extra-small solid $gray-light !default;
$brief-info__border-width--dividers      : 0 0 $spacer--extra-small 0 !default;

$brief-info__item-padding--dividers      : 0 $spacer--semi-medium !default;
$brief-info__item-padding--dividers\@xl  : $spacer $spacer--large !default;
$brief-info__item-margin--dividers       : 0 !default;
$brief-info__item-margin--dividers\@xl   : 0 !default;

$brief-info__icon-size--dividers         : 64px !default;
$brief-info__icon-margin--dividers       : 0 0 $spacer !default;
$brief-info__icon-margin--dividers\@large: 0 $spacer--medium 0 0 !default;

$brief-info__title-font-weight--dividers : $font-weight-bold !default;
$brief-info__title-font-size--dividers   : $brief-info__title-font-size !default;
$brief-info__title-height--dividers      : 32px !default;

//link
$brief-info__link-padding                : 0 0 0 ($brief-info__icon-size + $spacer--medium) !default;

$brief-info__title-color--link           : $gray-darker !default;
$brief-info__title-font-size--link       : $font-size-medium !default;
$brief-info__title-font-weight--link     : $font-weight-bold !default;
$brief-info__title-line-heigh--link      : 24px !default;
$brief-info__title-margin--link          : 0 !default;
$brief-info__title-padding--link         : 0 !default;

$brief-info__subtitle-font-size--link    : $font-size-small !default;
$brief-info__subtitle-font-weight--link  : $font-weight-normal !default;
$brief-info__subtitle-line-height--link  : 16px !default;
$brief-info__subtitle-margin--link       : 0 !default;
$brief-info__subtitle-padding--link      : 0 !default;
