$skip-nav__padding         : $spacer--small $spacer--extra-large !default;
$skip-nav__margin          : $spacer !default;
$skip-nav__background-color: $color-primary !default;
$skip-nav__color           : $white !default;
$skip-nav__text-decoration : none !default;
$skip-nav__margin--relative: $spacer--medium !default;
$skip-nav__z-index         : $z-index-normal !default;

.skip-nav {
    @include visually-hidden('focus');
    padding: $skip-nav__padding;
    top: 0;
    left: 0;
    z-index: $skip-nav__z-index;

    &.focus-visible,
    &:focus,
    &:active,
    &:hover {
        position: absolute;
        margin: $skip-nav__margin;
        background-color: $skip-nav__background-color;
        color: $skip-nav__color;
        text-decoration: $skip-nav__text-decoration;
    }

    &--relative {
        &.focus-visible,
        &:active,
        &:focus,
        &:hover {
            position: relative;
            display: block;
            margin: $skip-nav__margin--relative;
        }
    }
}
