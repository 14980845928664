$chat__padding                          : $spacer--semi-medium !default;
$chat__background                       : $gray-lighter !default;

$chat__list-margin                      : 0 0 $spacer--medium !default;
$chat__list-padding                     : 0 !default;
$chat__list-max-height                  : 300px !default;
$chat__list-overflow-y                  : auto !default;

$chat__message-padding                  : $spacer $spacer--medium !default;
$chat__message-text-align--sent         : right !default;
$chat__message-align-self--incoming     : flex-start !default;

$chat__message-content-padding          : $spacer--medium !default;
$chat__message-content-background       : $gray !default;
$chat__message-content-background--sent : $gray-light !default;
$chat__message-content-border-radius    : 10px !default;

$chat__message-text-font-size           : $font-size-base !default;

$chat__message-date-text-align          : right !default;
$chat__message-date-color               : $gray-dark !default;
$chat__message-date-font-size           : $font-size-small !default;

$chat__message-send-margin              : 0 0 $spacer--medium !default;

$chat__message-field-width              : 100% !default;
$chat__message-field-margin             : 0 $spacer 0 0 !default;

$chat__textarea-min-height              : 48px !default;
$chat__username-color                   : $gray-dark !default;
