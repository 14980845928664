$toolbar__padding                               : $spacer 0 !default;
$toolbar__padding\@medium                       : $spacer--semi-medium 0 !default;
$toolbar__padding\@large                        : 0 0 $spacer--medium !default;
$toolbar__border-style                          : solid !default;
$toolbar__border-color                          : $gray !default;
$toolbar__border-width                          : 0 0 1px 0 !default;

$toolbar__mode-margin                           : 0 $spacer--medium 0 0 !default;
$toolbar__mode-button-icon-fill                 : $color-secondary !default;
$toolbar__mode-button-icon-fill--active         : $color-primary !default;
$toolbar__mode-button-icon-size                 : 18px !default;

$toolbar__amount-color                          : $color-secondary !default;
$toolbar__amount-padding--visible               : $spacer 0 !default;

$toolbar__sorter-width                          : 100% !default;
$toolbar__sorter-width\@medium                  : auto !default;
$toolbar__sorter-select-min-width               : 160px !default;
$toolbar__sorter-border-width                   : 1px 0 0 0 !default;
$toolbar__sorter-border-width\@medium           : 0 !default;
$toolbar__sorter-padding                        : $spacer 0 0 !default;
$toolbar__sorter-padding\@medium                : 0 !default;
$toolbar__sorter-margin                         : $spacer 0 0 !default;
$toolbar__sorter-margin\@medium                 : 0 !default;
$toolbar__sorter-order                          : 2 !default;
$toolbar__sorter-order\@medium                  : 0 !default;
$toolbar__sorter-link-padding                   : 0 $spacer !default;
$toolbar__sorter-link-font-weight               : $font-weight-normal !default;
$toolbar__sorter-link-bg-hover                  : $color-primary !default;

//toolbar--secondary
$toolbar__justify-content--secondary            : space-between !default;
$toolbar__padding--secondary                    : $spacer--semi-medium 0 !default;

$toolbar__amount-order--secondary               : 1 !default;
$toolbar__amount-flex-grow--secondary           : 0 !default;

$toolbar__pager-order--secondary                : 1 !default;
$toolbar__pager-flex-grow--secondary            : 2 !default;
$toolbar__pager-flex-basis--secondary           : 100% !default;
$toolbar__pager-margin--secondary               : 0 0 $spacer--medium !default;

$toolbar__sorter-limiter-order--secondary       : 3 !default;
$toolbar__sorter-limiter-flex-grow--secondary   : 0 !default;
