@import "cart-variables";

.cart {
    flex-direction: column;
    @include mq($screen-m){
        width: $cart_width;
        margin: $cart_margin;
        flex-wrap: nowrap;
    }
    .widget-card {
        padding: $spacer--medium !important;
        @include mq($screen-l) {
            padding: $spacer--semi-medium !important;
        }
     }
    &__summary {
        @include mq($screen-l) {
            order: 1;
        }
    }
    &-grid__header {
        display: none;
    }


    button.checkout {
        width: $cart-totals-btn-width\@small;
        margin: $cart-totals-btn--margin;
        @include mq($screen-l) {
            width: $cart-totals-btn-width;
            margin: initial;
        }
    }
    &-empty {
        display: flex;
        align-items: center;
        flex-direction: column;
        div{
            &:nth-child(1)  {
                picture {
                    display: flex;
                    justify-content: center;
                }
                img {
                    width: 50%;
                    margin: auto;
                }
            }
            &:nth-child(2) {
                font-size: $cart-empty-text-font-size;
                padding: $cart-empty-text-padding;
                text-align: center;
                @include mq($screen-m){
                    font-size: $cart-empty-text-font-size\@medium;
                }
            }
        }

    }
}
