$header-home__background                            : transparent;
$header-home__background--is-sticky                 : $white;
$header-home__border-bottom--is-sticky              : 1px solid $lighter-gray;
$header-home__mega-menu-link--no-sticky-color       : $white;
$header-home__mega-menu-link--no-sticky-color-hover : $color-primary;

.home {
    &:not(.breadcrumbs__item) {
        display: flex;
        flex-direction: column;
    }

    &__banner {
        margin: 0 0 $spacer--medium;

        @include mq($screen-m) {
            margin: 0 0 $spacer--large;
        }

        @include mq($screen-l) {
            margin: 0 0 $spacer--medium;
        }
    }
}
/* Estilo de menú que solo aplica en la home - Transparencia */
.cms-home{
    .header {
        z-index: 2;
        position: fixed;

        &:not(.header--is-sticky){
            background: $header-home__background ;
            border-bottom: unset;

            .logo{
                filter: brightness(0) invert(1);

            }
            .side-menu__trigger{
                background-color: unset;
                transition: unset;
                &-button-text{
                    color: $header-home__mega-menu-link--no-sticky-color;
                }

                .icon{
                    fill: $header-home__mega-menu-link--no-sticky-color;
                }
            }
            .header{
                &__minicart{
                    .button--icon{
                        background-color: unset;
                        transition: unset;
                    }
                }

                &__brief-faqs,
                &__minicart{
                    .icon{
                        fill: $header-home__mega-menu-link--no-sticky-color;
                    }
                    &:hover{
                        .icon{
                            fill: $header-home__mega-menu-link--no-sticky-color-hover;
                        }
                    }
                }
            }

            .mega-menu{
                &__list{
                    background-color: unset;
                }
                &__item{
                    &:not(:hover){
                        .mega-menu__link{
                            color: $header-home__mega-menu-link--no-sticky-color;
                        }
                    }
                }

                &:before{
                    background-color: unset;
                }

            }
            .account-links{
                .logged-in{
                    &:not(:hover){
                        color: $header-home__mega-menu-link--no-sticky-color;
                    }
                }
                .dropdown-list{
                    background-color: unset;
                }

                >.authorization-link a{
                    color: $header-home__mega-menu-link--no-sticky-color;
                    &::before{
                        filter: brightness(0) invert(1);
                    }

                    &:hover,
                    &:focus,
                    &.focus-visible{
                        color: $header-home__mega-menu-link--no-sticky-color-hover;
                        &::before{
                            filter: none/*invert(31%) sepia(86%) saturate(1308%) hue-rotate(357deg) brightness(103%) contrast(102%)*/;
                        }
                    }

                }
            }

         }

        &--is-sticky{
            background: $header-home__background--is-sticky;
            border-bottom: $header-home__border-bottom--is-sticky;
            .logo{
                filter: unset;
            }
        }
    }

    /*.search-block {
        .select-search {
            .label {
                color: $white ;
            }
        }

    }*/
}
