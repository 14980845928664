
#onetrust-pc-btn-handler {
    background-color: $color-primary !important;
}
#onetrust-accept-btn-handler {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
}

#onetrust-pc-sdk{
    .ot-btn-container{
        button {
            background-color: $color-primary !important;
            border-color: $color-primary !important;
        }
    }
}

@include mq($max-screen: $screen-m - 1) {
    #ot-sdk-btn{
        background-color: $color-primary !important;
    }
}
