@import 'content-block-variables';

.content-block {
    padding: $content-block__padding;

    @include mq($screen-m) {
        padding: $content-block__padding\@medium;
    }

    @include mq($screen-l) {
        padding: $content-block__padding\@large;
    }

    @include mq($screen-xl) {
        padding: $content-block__padding\@xl;
    }

    &--secondary {
        padding: $content-block__padding--secondary;

        @include mq($screen-m) {
            padding: $content-block__padding--secondary\@medium;
        }

        @include mq($screen-l) {
            padding: $content-block__padding--secondary\@large;
        }

        .content-block__heading {
            max-width: $content-block__heading-max-width--secondary;
            border: $content-block__heading-border--secondary;
            border-width: $content-block__heading-border-width--secondary;

            @include mq($screen-m) {
                max-width: $content-block__heading-max-width--secondary\@medium;
            }

            @include mq($screen-l) {
                max-width: $content-block__heading-max-width--secondary\@large;
            }
        }

    }

    &__heading {
        padding: $content-block__heading-padding;
        line-height: $content-block__heading-line-height;
    }

    &__introduction {
        margin: $content-block__introduction-margin;
        font-size: $content-block__introduction-font-size;
        font-weight: $content-block__introduction-font-weight;

        @include mq($screen-m) {
            font-size: $content-block__introduction-font-size\@medium;
        }

        @include mq($screen-l) {
            margin: $content-block__introduction-margin\@medium;
        }
    }

    &__description {
        font-size: $content-block__description-font-size;

        @include mq($screen-m) {
            font-size: $content-block__description-font-size\@medium;
        }
    }

    &__link {
        margin: $content-block__link-margin;
        font-size: $content-block__link-font-size;
        font-weight: $content-block__link-font-weight;
        line-height: $content-block__link-line-height;
        text-decoration: $content-block__link-text-decoration;

        &:hover,
        &.focus-visible {
            text-decoration: $content-block__link-text-decoration--hover;
        }
    }
}
