.fieldset {
    & > .control {
        & > .field {
            &._required {
                & > label {
                    & > span {
                        &:after {
                            content: '*';
                            color: #e02b27;
                            font-size: 1.2rem;
                            margin: 0 0 0 3px;
                        }
                    }
                }
            }
        }
    }
}

.fieldset.empresa-customer {
    .name-fieldset {
        border-radius: 10px;
        border: 1px solid $lighter-gray;
        background-color: $lightest-gray;
        padding: $spacer--medium $spacer;

        @include mq($max-screen: $screen-m) {
            margin-top: $spacer--medium;
        }

        .contact p {
            color: $black;
            font-family: $font-family-base;
            font-size: $font-size-base;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: $spacer;
        }

        .lastname-fieldset {
            .field {
                margin-bottom: 0;
            }
        }
    }
}

.checkout-payment-method .fieldset {
    select[name="tipo_usuario"],
    select[name="region_id"],
    select[name="country_id"] {
        width: 100%;
        margin-left: 0;
    }

    .select__field {
        background-position-x: 95%;
    }

    .billing-address-form {
        margin-bottom: $spacer--large;
    }

    .empresa-customer .name-fieldset {
        margin-top: $spacer;
        margin-bottom: $spacer--semi-medium;
    }

    .taxvat-fieldset .mage-error {
        margin-top: 0;
    }

    div.control.mage-error {
        margin-top: 0;
        margin-left: 0;
    }

    &.lastname-fieldset,
    &.taxvat-fieldset {
        div.control.mage.error {
            margin-left: 0;
        }

        div:not(.control).mage-error {
            margin-top: $spacer;
        }
    }

    &.taxvat-fieldset {
        margin-bottom: 20px;
    }

    &.lastname-fieldset {
        & > .field {
            &:first-child {
                margin-right: $spacer--semi-medium;
            }

            &:last-child {
                margin-bottom: 20px;

                label {
                    padding-top: 5px;
                }
            }
        }
    }
}
@include mq($max-screen: $screen-m - 1) {
    .checkout-payment-method .fieldset {
        &.lastname-fieldset > .field:first-child {
            margin-right: 0;
        }

        &.taxvat-fieldset .select__field {
            width: 100%;
            margin-left: 0;
        }
    }
}

@include mq($screen-m, $screen-l - 1) {
    .checkout-payment-method .fieldset .billing-address-form {
        max-width: 90%;
        margin-left: 0;
    }
}

@include mq($screen-m) {
    .fieldset.lastname-fieldset .field {
        width: 50%;
    }

    .fieldset.taxvat-fieldset {
        display: flex;
        justify-content: space-between;
        margin-right: -$spacer;
        margin-left: -$spacer;

        .field {
            flex-basis: 32%;
            margin-right: $spacer;
            margin-left: $spacer;

            &.pais-documento {
                flex-basis: 36%;
            }

            .select__field {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    .fieldset.empresa-customer {
        .fieldset.taxvat-fieldset {
            .field {
                flex-basis: 50%;
            }
        }
    }
}
