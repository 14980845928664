$review__width                          : 100% !default;

$review__wrapper-height                 : $spacer--extra-large !default;
$review__wrapper-margin                 : $spacer--medium 0 !default;
$review-width--summary                  : auto !default;

$review__title-font-weight              : $font-weight-bold !default;
$review__title-text-transform           : capitalize !default;
$review__title-font-family              : $font-family-base !default;
$review__title-product-margin           : $spacer 0 !default;
$review__title-text-transform           : none !default;

$review__author-text-transform          : capitalize !default;
$review__author-font-family             : $font-family-base !default;
$review__author-margin                  : 0 !default;
$review__author-description-color       : $gray-dark !default;
$review__author-description-font-size   : $font-size-small !default;
$review__author-font-weight             : $font-weight-bold !default;

$review__date-color                     : $color-secondary !default;
$review__date-font-size                 : $font-size-small !default;

$review__submit-btn-width               : 100% !default;
$review__submit-btn-width\@medium       : auto !default;

$review__required-info-margin           : $spacer--large 0 0 !default;
$review__required-info-font-size        : $font-size-small !default;

$review__summary-handle-display         : flex !default;
$review__summary-handle-flex-flow       : row wrap !default;
$review__summary-handle-align-items     : center !default;
$review__summary-handle-justify-content : flex-start !default;
$review__summary-handle-width           : auto !default;

$review__amount-text-decoration         : none !default;
$review__amount-letter-spacing          : 2px !default;
$review__amount-margin                  : 0 $spacer 0 0 !default;
$review__amount-margin\@medium          : 0 $spacer 0 0 !default;
$review__amount-padding                 : 0 !default;
$review__amount-color                   : $gray-dark !default;

$review__summary-add-width              : 100% !default;
$review__summary-add-padding            : 0 !default;
$review__summary-add-text-decoration    : underline !default;
$review__summary-add-text-align         : left !default;
$review__summary-add-color              : $gray-dark !default;

$review__details-max-width              : calc(100% - #{$spacer--extra-large}) !default;
$review__details-flex-basis             : calc(100% - #{$spacer--extra-large}) !default;
$review__details-padding                : 0 0 0 $spacer--medium !default;

$review__image-max-width                : $spacer--extra-large !default;
$review__image-width                    : $spacer--extra-large !default;
$review__image-flex-basis               : $spacer--extra-large !default;
$review__image-border-radius            : 50% !default;
$review__image-height                   : auto !default;

$review__rating-legend-color            : $gray-dark !default;

$review__fieldset-padding               : 0 0 $spacer--extra-large * 2 0 !default;
$review__fieldset-margin                : 0 auto !default;
$review__fieldset-max-width             : 650px !default;
$review__field-margin                   : $spacer--semi-medium 0 !default;

$review__rating-margin                  : 0 0 $spacer--medium !default;
$review__description-margin             : 0 0 $spacer--medium !default;
$review__description-paragraph-font-size: $font-size-base !default;
$review__description-paragraph-color    : $gray-dark !default;

$review__links-column-margin-first-child: $spacer--large 0 !default;
$review__links-margin                   : 0 0 $spacer--large !default;
$review__links-btn-color                : $gray-dark !default;
$review__links-btn-text-decoration      : none !default;
$review__links-btn-padding              : 0 $spacer--medium 0 0 !default;
$review__links-btn-icon-fill            : $gray-dark !default;
$review__links-btn-icon-margin          : 0 $spacer 0 0 !default;
$review__links-legend-color             : $gray-dark !default;
$review__links-legend-margin            : 0 $spacer--medium 0 0 !default;

// add variant
$review__padding--add                   : $spacer--semi-large 0 0 !default;
$review__margin--add                    : 0 0 $spacer--medium !default;
$review__border--add                    : $border-base !default;
$review__border-width--add              : 0 0 1px 0 !default;

// summary variant
$review__rating-margin--summary         : 0 $spacer 0 0 !default;
$review__rating-padding--summary        : 0 !default;
