@keyframes animatetop {
    from {
        top: $animation-position-top;
        opacity: 0;
    }
    to {
        top: inherit;
        opacity: 1;
    }
}

@keyframes animateright {
    from {
        right: $animation-position-right;
        opacity: 0;
    }
    to {
        right: 0;
        opacity: 1;
    }
}

@keyframes animateleft {
    from {
        left: $animation-position-left;
        opacity: 0;
    }
    to {
        left: 0;
        opacity: 1;
    }
}

@keyframes gradientBg {
    0% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 100% 0%;
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
