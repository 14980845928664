@import "popup-variables";

.amlocator-popup {
    width: $map-popup-width;
    font-weight: $map-popup-content-font-weight;
    overflow: hidden;
    p, li {
        font-size: $map-popup-content-font-size;
        font-weight: $map-popup-content-font-weight
    }

    &__title {
        font-size: $map-popup-heading-font-size;
    }

    &__subtitle {
        font-size: $map-popup-subheading-font-size;
    }

    &__image {
        max-width: 44px !important;
    }

    &__link {
        color: $color-primary;
        font-size: $font-size-small;
    }

    &-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .button {
            &:first-child {
                padding-right: 0;
            }

            &__text, &__icon {
                color: $map-popup-link-color;
                font-size: $font-size-small;
            }
        }

    }
    .list {
        position: relative;
        left: -$spacer;
        &--horizontal {
            flex-flow: row wrap;
        }
        li:nth-child(6n) {
            padding-right: 0;
        }
    }
}
