$ratio-container-height: 0;
$ratio-container-width: 100%;

.ratio-container {
    position: relative;
    height: $ratio-container-height;
    width: $ratio-container-width;
    overflow: hidden;

    .ratio-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        height: auto;
    }
}
