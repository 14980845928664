$aparcamiento-search__container--font-size                : $font-size-medium;
$aparcamiento-search__container-p--font-size              : $font-size-base;
$aparcamiento-search__container--bg                       : $color-secondary-light;
$aparcamiento-search__container--padding                  : 0 15px;
$aparcamiento-search__container--font-weight              : $font-weight-medium-bold;
$aparcamiento-search__container--title-margin-bottom      : $spacer--medium;
$aparcamiento-search__left--padding                       : $spacer--semi-medium;
$aparcamiento-search__left-circle-size                    : 160px;
$aparcamiento-search__left-circle-padding                 : $spacer--semi-medium auto;
$aparcamiento-search__left-image-size-min-width           : 80px;
$aparcamiento-search__left-image-size-min-width\@medium   : 150px;
$aparcamiento-search__left-image-size-min-height          : $aparcamiento-search__left-image-size-min-width;
$aparcamiento-search__left-image-size-min-height\@medium  : $aparcamiento-search__left-image-size-min-width\@medium;
$aparcamiento-search__left-image-size-width               : 108px;
$aparcamiento-search__left-image-size                     : auto; //108px;
$aparcamiento-search__left-wrapper--padding               : $spacer--medium;
$aparcamiento-search__attr-border                         : 1px solid $lighter-gray;

$aparcamiento-search__modal--padding                      : $spacer--semi-medium - 3 0;
$aparcamiento-search__modal--icon-margin                  : 10px;
$aparcamiento-search__modal-header--color                 : $breadcrumbs__color;

$aparcamiento-search__container-action-button-link--color : $aparcamiento-search__modal-header--color;

$aparcamiento-search__left-store-information-margin-top   : $spacer--extra-small;
