$datepicker__border                              : 2px solid $white !default;
$datepicker__background-color                    : $white !default;

$datepicker__arrow-top                           : 3px !default;

$datepicker__prev-icon-border-color              : transparent $black transparent transparent !default;
$datepicker__next-icon-border-color              : transparent transparent transparent $black !default;

$datepicker__select-background-color             : transparent !default;
$datepicker__select-height                       : 35px !default;
$datepicker__select-margin-right                 : $spacer !default;

$datepicker__calendar-border-collapse            : separate !default;
$datepicker__calendar-border                     : 0 !default;

$datepicker__today-background-color              : $light-gray !default;
$datepicker__today-background-color--active      : $white !default;
$datepicker__today-color                         : $black !default;
$datepicker__today-color--active                 : $black !default;

$datepicker__current-day-border                  : 1px solid $black !default;
$datepicker__current-day-background-color        : $color-secondary !default;
$datepicker__current-day-background-color--active: $color-secondary !default;
$datepicker__current-day-color--active           : $white !default;

$datepicker__ui-state-text-align                 : center !default;
$datepicker__ui-state-background-color--active   : $dark-teal !default;
$datepicker__ui-state-color--active              : #fff !default;

$datepicker__title-display                       : flex !default;
$datepicker__title-justify-content               : center !default;
$datepicker__title-align-items                   : center !default;
$datepicker__title-flex-direction                : row-reverse !default;

$datepicker__width                               : 364px;
$datepicker__icon-border                         : 2px solid $color-primary;
$datepicker__th--color                           : $gray;
$datepicker__today-border                        : 1px solid $color-secondary;
$datepicker__today-border--radius                : 17px;
$datepicker__today--size                         : 29px;
$datepicker__today--padding                      : 2px;
$datepicker__td--padding                         : $spacer--extra-small;

$datepicker__timer--span-border                  : 1px solid $lighter-gray;
$datepicker__timer--span-color                   : $battleship-grey;
$datepicker__timer-padding                       : $spacer 0;
$datepicker__timer--span-font-weight             : normal;

$datepicker__border-radius                       : 10px;