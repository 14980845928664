$yotpo-reviews__base-star-color                            : $color-primary !default;
$yotpo-reviews__base-star-color-secondary                  : $gray-darker !default;
$yotpo-reviews__yotpo-vendor-large-breakpoint              : 961px !default;

$yotpo-reviews__base-styles-font-family                    : $font-family-base !default;
$yotpo-reviews__base-styles-font-size                      : $font-size-base !default;

$yotpo-reviews__base-styles-input-height                   : $spacer--extra-large !default;
$yotpo-reviews__base-styles-input-color                    : $font-color-base !default;

$yotpo-reviews__base-styles-button-font-weight             : $font-weight-bold !default;
$yotpo-reviews__base-styles-button-color                   : $white !default;

$yotpo-reviews__write-question-review-button-text-width    : auto !default;

$yotpo-reviews__action-color                               : $font-color-base !default;
$yotpo-reviews__action-color-hover                         : $blue !default;
$yotpo-reviews__action-text-decoration                     : none !default;
$yotpo-reviews__action-text-decoration-hover               : underline !default;
$yotpo-reviews__action-font-size                           : $font-size-medium !default;
$yotpo-reviews__action-font-family                         : $font-family-base !default;
$yotpo-reviews__action-text-transform                      : capitalize !default;

$yotpo-reviews__action-helpful-order                       : 3 !default;
$yotpo-reviews__action-helpful-width                       : auto !default;
$yotpo-reviews__action-helpful-margin-left                 : auto !default;
$yotpo-reviews__action-helpful-margin-left\@large          : 0 !default;
$yotpo-reviews__action-helpful-text-align                  : right !default;
$yotpo-reviews__action-helpful-text-transform              : initial !default;
$yotpo-reviews__action-helpful-color                       : $font-color-base !default;
$yotpo-reviews__action-helpful-icon-size                   : $font-size-medium !default;
$yotpo-reviews__action-helpful-icon-color                  : $yotpo-reviews__base-star-color !default;
$yotpo-reviews__action-helpful-icon-padding                : $spacer--medium !default;
$yotpo-reviews__action-helpful-vote-font-size              : $font-size-base !default;
$yotpo-reviews__action-helpful-vote-color                  : $font-color-base !default;
$yotpo-reviews__action-helpful-vote-btn-margin             : 0 !default;

$yotpo-reviews__action-font-size                           : $font-size-medium !default;
$yotpo-reviews__action-font-family                         : $font-family-base !default;

$yotpo-reviews__header-icon-color                          : $yellow !default;

$yotpo-reviews__form-input-error-margin                    : $spacer--medium 0 !default;

$yotpo-reviews__footer-add-actions-order                   : 1 !default;
$yotpo-reviews__footer-add-actions-width                   : auto !default;
$yotpo-reviews__footer-add-actions-display                 : block !default;
$yotpo-reviews__footer-add-actions-display\@large          : none !default;

$yotpo-reviews__content-title-font-family                  : $font-family-secondary !default;
$yotpo-reviews__content-title-font-size                    : $font-size-large !default;
$yotpo-reviews__content-title-color                        : $color-primary !default;
$yotpo-reviews__content-title-font-weight                  : $font-weight-bold !default;

$yotpo-reviews__content-review-color                       : $font-color-base !default;
$yotpo-reviews__content-review-font-size                   : $font-size-medium !default;
$yotpo-reviews__content-review-font-family                 : $font-family-base !default;
$yotpo-reviews__content-review-line-height                 : $font-line-height !default;

$yotpo-reviews__filter-list-category-color                 : $color-secondary !default;
$yotpo-reviews__filter-selected-width                      : calc(100% - #{$spacer--semi-medium}) !default;
$yotpo-reviews__filter-selected-color                      : $font-color-base !default;
$yotpo-reviews__filter-selected-font-weight                : $font-weight-normal !default;

$yotpo-reviews__magnifying-glass-icon-left                 : $spacer--small !default;
$yotpo-reviews__magnifying-glass-icon-top                  : $spacer--large !default;

$yotpo-reviews__dropdown-button-padding                    : $spacer--small !default;

$yotpo-reviews__filter-more-filters-icon-margin-right      : $spacer !default;
$yotpo-reviews--filter-more-filters-text-width             : auto !default;

$yotpo-reviews__first-review-icons-display                 : none !default;
$yotpo-reviews__first-review-button-display                : initial !default;

$yotpo-reviews__first-question-button-display              : none !default;

$yotpo-reviews__footer-breakpoint                          : $screen-l !default;

$yotpo-reviews__footer-display                             : flex !default;
$yotpo-reviews__footer-flex-wrap                           : wrap !default;
$yotpo-reviews__footer-border-bottom                       : $border-base !default;
$yotpo-reviews__footer-padding                             : 0 0 $spacer 0 !default;
$yotpo-reviews__footer-justify-content\@large              : flex-end !default;
$yotpo-reviews__footer-border\@large                       : none !default;

$yotpo-reviews__footer-actions-order                       : 1 !default;
$yotpo-reviews__footer-actions-margin-bottom               : $spacer--medium !default;
$yotpo-reviews__footer-actions-margin-right\@large         : auto !default;

$yotpo-reviews__grouping-reference-width                   : 100% !default;
$yotpo-reviews__grouping-reference-margin                  : 0 0 $spacer !default;

$yotpo-reviews__icon-profile-size                          : 48px !default;
$yotpo-reviews__icon-profile-background                    : $color-primary !default;
$yotpo-reviews__icon-profile-letter-font-size              : $font-size-extra-large !default;
$yotpo-reviews__icon-profile-letter-font-family            : $font-family-secondary !default;
$yotpo-reviews__icon-profile-letter-color                  : $white !default;

$yotpo-reviews__icon-btn-width                             : 48px !default;
$yotpo-reviews__icon-btn-height                            : 48px !default;
$yotpo-reviews__icon-btn-margin                            : 0 $spacer 0 0 !default;

$yotpo-reviews__inline-icon-font-size                      : $font-size-medium !default;
$yotpo-reviews__inline-link-font-size                      : $font-size-base !default;
$yotpo-reviews__inline-link-color                          : $color-primary !default;
$yotpo-reviews__inline-link-color-hover                    : $color-secondary !default;
$yotpo-reviews__inline-link-background                     : transparent !default;
$yotpo-reviews__inline-link-background-hover               : transparent !default;


$yotpo-reviews__input-post-background-hover                : $gray-dark !default;

$yotpo-reviews__main-bottom-container-overflow             : hidden !default;
$yotpo-reviews__main-bottom-container-padding              : 0 !default;
$yotpo-reviews__main-bottom-container-margin               : 0 auto !default;
$yotpo-reviews__main-bottom-container-margin\@medium       : 0 $spacer $spacer--medium !default;
$yotpo-reviews__main-bottom-container-text-align           : center !default;
$yotpo-reviews__main-bottom-container-width                : fit-content !default;
$yotpo-reviews__main-bottom-container-width\@medium        : auto !default;
$yotpo-reviews__main-bottom-line-items-position            : relative !default;
$yotpo-reviews__main-bottom-line-items-overflow            : hidden !default;
$yotpo-reviews__main-bottom-line-items-min-height          : 40px !default;
$yotpo-reviews__main-bottom-line-items-margin-bottom       : $spacer--medium !default;
$yotpo-reviews__main-average-score-position                : absolute !default;
$yotpo-reviews__main-average-score-position\@medium        : relative !default;
$yotpo-reviews__main-average-score-left                    : 0 !default;
$yotpo-reviews__main-average-score-top                     : 0 !default;
$yotpo-reviews__main-average-score-font-size               : $font-size-extra-large !default;
$yotpo-reviews__main-average-score-color                   : $color-primary !default;
$yotpo-reviews__main-average-score-height                  : auto !default;
$yotpo-reviews__main-average-score-width                   : auto !default;
$yotpo-reviews__main-average-margin                        : 0 $spacer--large 0 0 !default;
$yotpo-reviews__main-average-margin\@medium                : 0 !default;
$yotpo-reviews__main-average-score-padding                 : 0 !default;
$yotpo-reviews__main-average-score-line-height             : $font-line-height !default;
$yotpo-reviews__main-average-score-icon-size               : $font-size-large !default;
$yotpo-reviews__main-average-score-icon-color              : $yotpo-reviews__base-star-color !default;
$yotpo-reviews__main-average-reviews-count-font-size       : $font-size-large !default;
$yotpo-reviews__main-average-reviews-count-color           : $color-primary !default;
$yotpo-reviews__main-average-reviews-count-line-height     : $font-line-height !default;

$yotpo-reviews__mobile-filter-btn-bg-color                 : $color-primary !default;
$yotpo-reviews__mobile-filter-btn-text-color               : $white !default;
$yotpo-reviews__mobile-filter-btn-font-size                : $font-size-base !default;
$yotpo-reviews__mobile-filter-btn-font-family              : $font-family-base !default;

$yotpo-reviews__mobile-filter-text-color                   : $font-color-base !default;
$yotpo-reviews__mobile-filter-title-font-size              : $font-size-large !default;
$yotpo-reviews__mobile-filter-title-font-family            : $font-family-secondary !default;
$yotpo-reviews__mobile-filter-title-text-color             : $color-primary !default;

$yotpo-reviews__mobile-filter-selected-color               : $blue !default;
$yotpo-reviews__mobile-filter-title-color                  : $color-primary !default;
$yotpo-reviews__mobile-filter-title-font-weight            : $font-weight-bold !default;

$yotpo-reviews__nav-search-input-padding-left              : 48px !default;

$yotpo-reviews__nav-display                                : flex !default;

$yotpo-reviews__nav-display-ul-margin-top                  : -1px !default;

$yotpo-reviews__nav-tab-border-color                       : $border-color-secondary !default;
$yotpo-reviews__nav-tab-color                              : $gray-dark !default;
$yotpo-reviews__nav-tab-color--active                      : $color-primary !default;

$yotpo-reviews__listing-star-clickable-align-items         : center !default;
$yotpo-reviews__listing-review-display                     : none !default;
$yotpo-reviews__listing-icon-size                          : 20px !default;
$yotpo-reviews__listing-review-font-size                   : $font-size-base !default;
$yotpo-reviews__listing-review-line-height                 : $font-line-height !default;
$yotpo-reviews__listing-review-margin-left                 : $spacer !default;

$yotpo-reviews__login-connect-wrapper-width                : 320px !default;
$yotpo-reviews__login-username-top-offset                  : 62px !default;
$yotpo-reviews__login-socialize-wrapper-position           : relative !default;
$yotpo-reviews__login-socialize-wrapper-width              : calc(#{$yotpo-reviews__login-connect-wrapper-width} + 10px) !default;

$yotpo-reviews__login-soc-wrapper-or-margin                : $spacer 0 !default;
$yotpo-reviews__login-soc-wrapper-or-margin\@medium        : 0 0 41px !default; // to make social icons align with email input
$yotpo-reviews__login-socialize-wrapper-or-text-align      : left 0 !default;
$yotpo-reviews__login-soc-wrapper-or-width\@medium         : auto !default;

$yotpo-reviews__soc-wrapper-visible-display                : flex !default;
$yotpo-reviews__soc-wrapper-visible-flex-wrap              : wrap !default;
$yotpo-reviews__soc-wrapper-visible-justify-content        : flex-start !default;
$yotpo-reviews__soc-wrapper-visible-flex-direction         : column !default;
$yotpo-reviews__soc-wrapper-visible-order                  : 3 !default;
$yotpo-reviews__soc-wrapper-visible-margin-top             : $spacer--large !default;
$yotpo-reviews__soc-wrapper-visible-left\@large            : auto !default;
$yotpo-reviews__soc-wrapper-visible-right\@large           : 0 !default;
$yotpo-reviews__soc-wrapper-visible-width\@large           : calc(50% - #{$spacer--semi-medium}) !default;
$yotpo-reviews__soc-wrapper-visible-margin-left\@large     : $spacer--semi-medium !default;
$yotpo-reviews__soc-wrapper-visible-padding-top\@large     : 0 !default;

$yotpo-reviews__soc-wrapper-connected-width                : 100% !default;
$yotpo-reviews__soc-wrapper-connected-margin               : 0 0 $spacer 0 !default;
$yotpo-reviews__soc-wrapper-connected-padding-l\@medium: 48px !default;

$yotpo-reviews__socialize-order                            : 2 !default;
$yotpo-reviews__socialize-margin-bottom                    : 0 !default;
$yotpo-reviews__socialize-padding-top\@medium              : 0 !default;

$yotpo-reviews__connect-wrapper-padding                    : 0 !default;
$yotpo-reviews__connect-wrapper-width\@medium              : 50% !default;

$yotpo-reviews__connect-wrapper-form-el-padding            : 0 !default;
$yotpo-reviews__connect-wrapper-form-el-width              : 100% !default;

$yotpo-reviews__logo-text-color                            : $color-info !default;
$yotpo-reviews__logo-icon-color                            : $color-info !default;

$yotpo-reviews__stars-and-sum-reviews-display              : flex !default;
$yotpo-reviews__stars-and-sum-reviews-align-items          : center !default;
$yotpo-reviews__stars-and-sum-reviews-min-height           : 48px !default;

$yotpo-reviews__sum-reviews-padding                        : 0 !default;

$yotpo-reviews__pager-display                              : flex !default;
$yotpo-reviews__pager-align-items                          : center !default;
$yotpo-reviews__pager-justify-content                      : center !default;

$yotpo-reviews__pager-page-position                        : relative !default;
$yotpo-reviews__pager-page-display                         : inline-flex !default;
$yotpo-reviews__pager-page-align-items                     : center !default;
$yotpo-reviews__pager-page-justify-content                 : center !default;
$yotpo-reviews__pager-page-margin                          : 0 !default;
$yotpo-reviews__pager-page-color                           : $color-primary !default;
$yotpo-reviews__pager-page-color-hover                     : $blue !default;
$yotpo-reviews__pager-page-color-active                    : $color-primary !default;
$yotpo-reviews__pager-page-font-size                       : $font-size-medium !default;
$yotpo-reviews__pager-page-icon-size                       : $spacer--extra-large !default;
$yotpo-reviews__pager-page-text-decoration-hover           : underline !default;
$yotpo-reviews__pager-page-next-element-margin-left        : $spacer !default;
$yotpo-reviews__pager-arrow-icon-color                     : $color-primary !default;
$yotpo-reviews__pager-arrow-icon-color-hover               : $white !default;
$yotpo-reviews__pager-arrow-icon-bg-color-hover            : $color-primary !default;
$yotpo-reviews__pager-arrow-icon-hover-opacity-after       : 1 !default;
$yotpo-reviews__pager-arrow-icon-hover-z-index-after       : 10 !default;
$yotpo-reviews__pager-arrow-font-size                      : $font-size-base !default;
$yotpo-reviews__pager-arrow-font-weight                    : $font-weight-bold !default;
$yotpo-reviews__pager-arrow-size                           : $spacer--extra-large !default;
$yotpo-reviews__pager-arrow-icon-align-items               : center !default;
$yotpo-reviews__pager-arrow-icon-justify-content           : center !default;
$yotpo-reviews__pager-arrow-icon-position                  : relative !default;
$yotpo-reviews__pager-arrow-icon-opacity-before            : 1 !default;
$yotpo-reviews__pager-arrow-icon-z-index-before            : 1 !default;

$yotpo-reviews__pager-icon-left-arrow-margin-right         : $spacer--medium !default;
$yotpo-reviews__pager-icon-right-arrow-margin-left         : $spacer--medium !default;

$yotpo-reviews__regular-box-border                         : none !default;
$yotpo-reviews__regular-box-margin                         : 0 $spacer $spacer--medium !default;

$yotpo-reviews__bottom-line-links-margin-right             : $spacer--small !default;
$yotpo-reviews__bottom-line-stars-margin-right             : $spacer !default;
$yotpo-reviews__bottom-line-double-bubble-margin-right     : $spacer--extra-small !default;

$yotpo-reviews__empty-search-results-display               : grid !default;
$yotpo-reviews__empty-search-results-height                : auto !default;

$yotpo-reviews__empty-search-results-clear-all-btn-position: static !default;
$yotpo-reviews__empty-search-results-clear-all-btn-height  : auto !default;
$yotpo-reviews__empty-search-results-clear-all-btn-margin  : 0 0 $spacer--medium !default;

$yotpo-reviews__desktop-clear-all-btn-position             : absolute !default;

$yotpo-reviews__filter-tag-icon-cross-top                  : 0 !default;
$yotpo-reviews__filter-tag-icon-cross-right                : $spacer--extra-small !default;

$yotpo-reviews__container-margin-left                      : 60px !default;
$yotpo-reviews__container-margin-left\@medium              : $spacer !default;

$yotpo-reviews__reviews-labels-container-position          : relative !default;
$yotpo-reviews__reviews-labels-container-clear             : both !default;
$yotpo-reviews__reviews-labels-container-padding-top       : 8px !default;

$yotpo-reviews__review-button-icon-color                   : $white !default;
$yotpo-reviews__review-icon-color                          : $yellow !default;
$yotpo-reviews__review-icon-size                           : 20px !default;
$yotpo-reviews__review-write-button-icon-not-first         : $spacer--extra-small !default;

$yotpo-reviews__write-review-error-box-bg                  : $color-error !default;

$yotpo-reviews__star-color                                 : $yotpo-reviews__base-star-color !default;
$yotpo-reviews__star-selected-color                        : $yotpo-reviews__base-star-color !default;
$yotpo-reviews__star-non-selected-color                    : $yotpo-reviews__base-star-color-secondary !default;

$yotpo-reviews__thank-you-heart-icon-color                 : $red !default;
$yotpo-reviews__thank-you-text-color                       : $color-primary !default;

$yotpo-reviews__username-font-family                       : $font-family-secondary !default;
$yotpo-reviews__username-font-size                         : $font-size-medium !default;
$yotpo-reviews__username-color                             : $color-primary !default;
$yotpo-reviews__username-text-transform                    : uppercase !default;
$yotpo-reviews__username-font-weight                       : $font-weight-normal !default;

$yotpo-reviews__date-display                               : none !default;
$yotpo-reviews__date-display\@large                        : block !default;
$yotpo-reviews__date-font-family                           : $font-family-base !default;
$yotpo-reviews__date-font-size                             : $font-size-base !default;
$yotpo-reviews__date-color                                 : $color-primary !default;
$yotpo-reviews__date-text-transform                        : uppercase !default;
$yotpo-reviews__date-font-weight                           : $font-weight-normal !default;

%error {
    color                                                  : $color-error;
    font-weight                                            : $font-weight-normal;
}
