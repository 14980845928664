.header-button {
    &__counter {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: $header-button__counter-top;
        right: $header-button__counter-right;
        width: $header-button__counter-size;
        height: $header-button__counter-size;
        border: $header-button__counter-border;
        border-radius: $header-button__counter-size;
        line-height: $header-button__counter-size;
        font-size: $header-button__counter-font-size;
        color: $header-button__counter-color;
        background-color: $header-button__counter-background;
        z-index: $header-button__counter-z-index;

        &--hidden {
            display: none;
        }

        &-dot {
            display: none;
            width: $header-button__counter-dot-size;
            height: $header-button__counter-dot-size;
            border-radius: $header-button__counter-dot-size;
            margin: auto;
            background-color: $header-button__counter-dot-bg-color;
        }
    }

    &__summary-count {
        //@include visually-hidden('');
    }
    &.button--icon-fill {
        &:before {
            background-color: $button__before-background--icon-light;
        }
    }
}
