$search-no-results__icon-size                  : 120px !default;
$search-no-results__icon-fill                  : $color-secondary !default;
$search-no-results__icon-margin                : $spacer--extra-large auto !default;

$search-no-results__description-margin-bottom  : $spacer--extra-large !default;
$search-no-results__description-line-height    : $spacer--large !default;
$search-no-results__description-font-size      : $font-size-medium !default;
$search-no-results__description-font-size--bold: $font-size-large !default;
$search-no-results__description-text-align     : center !default;

$search-no-results__button-width               : 100% !default;
$search-no-results__button-width\@medium       : 320px !default;

.search-no-results {
    &__icon {
        width: $search-no-results__icon-size;
        height: $search-no-results__icon-size;
        fill: $search-no-results__icon-fill;
        margin: $search-no-results__icon-margin;
    }

    &__description {
        margin-bottom: $search-no-results__description-margin-bottom;
        font-size: $search-no-results__description-font-size;
        line-height: $search-no-results__description-line-height;
        text-align: $search-no-results__description-text-align;

        strong {
            font-size: $search-no-results__description-font-size--bold;
        }
    }

    &__link {
        width: $search-no-results__button-width;

        @include mq($screen-m) {
            width: $search-no-results__button-width\@medium;
        }
    }
}

.search-no-result {
    &.page-layout-2columns-left {
        .catalog-list {
            display: flex;

            &__products {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &__sidebar-additional {
                display: none;
            }
        }
    }
}
