$sidebar-block__padding                     : 0 0 $spacer--large !default;
$sidebar-block__min-height                  : 150px !default;
$sidebar-block__min-height--loaded          : auto !default;

$sidebar-block__heading-border              : $border-base !default;
$sidebar-block__heading-border-width        : 0 0 $border-width-base !default;
$sidebar-block__heading-margin              : 0 0 $spacer--medium !default;
$sidebar-block__heading-padding             : 0 0 $spacer !default;

$sidebar-block__title-font-family           : $font-family-base !default;
$sidebar-block__title-font-weight           : $font-weight-bold !default;
$sidebar-block__title-font-size             : $font-size-medium !default;

$sidebar-block__list-margin                 : 0 0 $spacer 0 !default;

$sidebar-block__item-padding                : 0 0 $spacer !default;

$sidebar-block__index-margin                : 0 $spacer !default;
$sidebar-block__index-margin\@large         : 0 $spacer 0 $spacer--extra-small !default;
$sidebar-block__index-font-weight           : $font-weight-bold !default;
$sidebar-block__index-min-width             : $spacer--medium !default;

$sidebar-block__icon-fill                   : $red !default;
$sidebar-block__icon-fill--active           : $white !default;

$sidebar-block__product-image-max-width     : 72px !default;
$sidebar-block__product-image-margin        : 0 $spacer--medium 0 0 !default;

$sidebar-block__price-margin                : 0 !default;
$sidebar-block__price-font-weight           : $font-weight-bold !default;

$sidebra-block__action-margin               : 0 $spacer--small !default;
$sidebra-block__action-margin\@large        : 0 $spacer--small 0 0 !default;
$sidebra-block__action-font-size            : $font-size-medium !default;
$sidebra-block__action-font-weight          : $font-weight-bold !default;
$sidebra-block__action-text-decoration      : underline !default;
$sidebra-block__action-text-decoration-hover: underline !default;
$sidebra-block__action-text-align           : left !default;
