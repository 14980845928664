//sass-lint:disable no-important
@import 'yotpo-reviews-variables';

.page-main {
    .product-view,
    .product-item-info {
        .yotpo {
            &.yotpo-main-widget,
            .yotpo-modal-dialog,
            .yotpo-bottomline {
                .yotpo-icon-star,
                .yotpo-icon-half-star,
                .yotpo-icon-empty-star {
                    color: $yotpo-reviews__star-color !important;
                }
            }
        }
    }
    .product-view {
        .yotpo {
            div,
            span,
            p,
            a,
            img,
            i,
            strong,
            sup,
            ul,
            li,
            form,
            label,
            .yotpo-default-button,
            input[type="button"].yotpo-default-button {
                font-family: $yotpo-reviews__base-styles-font-family !important;
            }

            a {
                color: $yotpo-reviews__inline-link-color !important;
                background: $yotpo-reviews__inline-link-background !important;

                &:hover,
                &:focus {
                    color: $yotpo-reviews__inline-link-color-hover !important;
                    background: $yotpo-reviews__inline-link-background-hover !important;
                }
            }

            .free-text-search-input {
                border-radius: $border-radius !important;
                height: $yotpo-reviews__base-styles-input-height !important;
            }

            .yotpo-pager {
                .goTo {
                    &:hover {
                        color: $yotpo-reviews__pager-page-color-hover !important;
                        text-decoration: $yotpo-reviews__pager-page-text-decoration-hover !important;
                    }

                    &.yotpo-active {
                        color: $yotpo-reviews__pager-page-color-active !important;

                        &:hover {
                            cursor: default !important;
                            color: $yotpo-reviews__pager-page-color-active !important;
                            text-decoration: none !important;
                        }
                    }
                }

                .yotpo-icon {
                    font-size: $yotpo-reviews__pager-arrow-font-size !important;
                    width: $yotpo-reviews__pager-arrow-size !important;
                    height: $yotpo-reviews__pager-arrow-size !important;

                    &:hover {
                        color: $yotpo-reviews__pager-arrow-icon-color-hover !important;
                        background-color: $yotpo-reviews__pager-arrow-icon-bg-color-hover !important;
                    }

                    &.yotpo-icon-left-arrow {
                        &:before {
                            content: url("../images/icons/arrow-left.svg") !important;
                        }

                        &:after {
                            content: url("../images/icons/arrow-left-light.svg") !important;
                        }
                    }

                    &.yotpo-icon-right-arrow {
                        &:before {
                            content: url("../images/icons/arrow-right.svg") !important;
                        }

                        &:after {
                            content: url("../images/icons/arrow-right-light.svg") !important;
                        }
                    }
                }
            }

            .yotpo-regular-box {
                &.bottom-line-items-container {
                    .avg-score {
                        font-size: $yotpo-reviews__main-average-score-font-size !important;
                    }

                    .yotpo-icon-star {
                        font-size: $yotpo-reviews__main-average-score-icon-size !important;
                    }

                    .reviews-qa-label {
                        font-size: $yotpo-reviews__main-average-reviews-count-font-size !important;
                    }
                }
            }

            .yotpo-default-button,
            input[type="button"].yotpo-default-button {
                &:hover {
                    background-color: initial !important;
                    text-decoration: none !important;

                    &:before {
                        display: block !important;
                        transform: scaleX(1) !important;
                    }

                    &:after {
                        display: block !important;
                        left: calc(50% - 12px) !important;
                        opacity: 1 !important;
                    }
                }

                &:disabled,
                &[disabled] {
                    background-color: $gray !important;
                    border-color: $gray !important;
                    color: $white !important;
                    cursor: not-allowed !important;

                    .icon {
                        fill: $white !important;
                    }

                    &:hover {
                        background-color: $gray !important;

                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            .yotpo-default-button .write-question-review-button-text,
            input[type="button"].yotpo-default-button {
                font-weight: $yotpo-reviews__base-styles-button-font-weight !important;
            }

            // here arrow icon won't work as they use input element
            .yotpo-default-button.primary-color-btn.yotpo-submit {
                &:hover {
                    background-color: $yotpo-reviews__input-post-background-hover !important;
                    text-decoration: none !important;
                }
            }

            .yotpo-review,
            .yotpo-comment,
            .yotpo-question,
            .yotpo-onsite-upload {
                .yotpo-header {
                    .yotpo-user-letter {
                        font-family: $yotpo-reviews__icon-profile-letter-font-family !important;
                    }

                    .yotpo-header-element {
                        .y-label.yotpo-user-name {
                            font-family: $yotpo-reviews__username-font-family !important;
                        }

                        .yotpo-review-date {
                            font-family: $yotpo-reviews__date-font-family !important;
                        }
                    }
                }

                .content-review {
                    font-family: $yotpo-reviews__content-review-font-family !important;
                }

                .yotpo-footer {
                    .yotpo-action,
                    .yotpo-action .social-link {
                        color: $yotpo-reviews__action-color !important;
                        font-family: $yotpo-reviews__action-font-family !important;

                        &:hover {
                            color: $yotpo-reviews__action-color-hover !important;

                            .y-label {
                                text-decoration: $yotpo-reviews__action-text-decoration !important;
                            }
                        }
                    }

                    .yotpo-action .social-link:hover {
                        text-decoration: $yotpo-reviews__action-text-decoration !important;
                    }
                }
            }

            .yotpo-review {
                .yotpo-read-more {
                    text-decoration: $yotpo-reviews__action-text-decoration !important;

                    &:hover {
                        text-decoration : $yotpo-reviews__action-text-decoration-hover !important;
                    }
                }
            }

            .write-review {
                .yotpo-footer {
                    @include mq($yotpo-reviews__footer-breakpoint) {
                        display : flex !important;
                    }

                    &.visible {
                        display: flex !important;
                    }

                    .socialize-wrapper.visible {
                        display: $yotpo-reviews__soc-wrapper-visible-display !important;
                    }
                }
            }
        }
    }
}

// styles for modal added before body closing tag
.catalog-product-view {
    .mobile-filters-modal {
        .mobile-filters-footer-btn {
            .footer-text {
                font-family: $yotpo-reviews__mobile-filter-btn-font-family !important;
            }
        }

        .mobile-filters-container {
            .mobile-single-filter {
                .mobile-filter-radio-buttons .mobile-filter-radio-button {
                    .radio-selected-color {
                        font-family: $font-family-base !important;
                    }
                }

                .mobile-filter-title {
                    font-family: $font-family-base !important;
                }
            }
        }
    }

}
