.checkout-index-index {
    .checkout-shipping-address {
        .field.street {
            .additional {
                width: 30%;
                margin-right: 10px;
                float: left;

                span {
                    &:after {
                        content: '*';
                        margin: 0 0 0 5px;
                        color: $color-error;
                        font-size: 1.2rem;
                    }
                }

            }
        }
    }

    .shipping-address__form-shipping,
    .form.payments {

        .control {
            &._with-tooltip {
                .input__field {
                    margin-right: 0;
                    width: 100%;
                }
                .field-tooltip.toggle {
                    display: none;
                }
            }
        }
        [name="shippingAddress.telephone"] {
            label {
                span {
                    &:after {
                        content: '*';
                        margin: 0 0 0 5px;
                        color: $color-error;
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    .modals-wrapper {
        .select__field:not(.select2) {
            background-position-x: 99%;
        }
    }
}
.opc-wrapper {
    .shipping-address-item {

        &__icon {
            top: 10px !important;
        }
        &__wrapper {
            min-height: 160px;
        }
        &__text {
            margin: 0 0 2px !important;
        }
    }
}

