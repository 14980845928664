$compare__border                             : $border-base !default;
$compare__margin                             : 0 0 $spacer--medium !default;

$compare__label-width                        : 100px !default;
$compare__label-width\@medium                : 160px !default;
$compare__label-height                       : 100% !default;
$compare__label-border-width                 : $border-width-base $border-width-base $border-width-base 0 !default;
$compare__label-background                   : $gray-lightest !default;
$compare__label-padding                      : $spacer !default;
$compare__label-padding\@medium              : $spacer--medium !default;

$compare__attributes-margin-bottom           : $spacer !default;

$compare__cell-padding                       : $spacer--medium !default;
$compare__cell-min-width                     : 160px !default;
$compare__cell-min-width\@medium             : 320px !default;
$compare__cell-list-padding-left             : $spacer--medium !default;
$compare__cell-item-line-height              : $font-line-height !default;

$compare__image-max-size                     : 100% !default;

$button__wishlist-after-content              : url("../images/icons/plus-light.svg") !default;
$button__remove-after-content                : url("../images/icons/minus-dark.svg") !default;

$compare__inner-width                        : calc(100% - #{$compare__label-width}) !default;
$compare__inner-width\@medium                : calc(100% - #{$compare__label-width\@medium}) !default;
$compare__inner-margin                       : 0 0 0 $compare__label-width !default;
$compare__inner-margin\@medium               : 0 0 0 $compare__label-width\@medium !default;

$compare__buttons-container-width            : 100% !default;

$compare__secondary-buttons-margin           : 0 0 $spacer !default;
$compare__secondary-buttons-margin\@medium   : 0 !default;

$compare__row-label-border-width--first-child: 0 $border-width-base $border-width-base 0 !default;

$compare__rating-min-height                  : 16px !default;

$compare__link-min-height                    : 44px !default;
$compare__link-image-max-width               : 150px !default;
