$dashboard-items__padding                : $spacer--medium !default;

$dashboard-items__title-font-family      : $font-family-base !default;
$dashboard-items__title-font-size        : $font-size-medium !default; //$font-size-large !default;
$dashboard-items__title-font-weight      : $font-weight-bold !default;
$dashboard-items__title-padding          : $spacer--medium 0 !default; //$spacer--medium !default;
$dashboard-items__title-background       : $bg-color-secondary !default;
$dashboard-items__title-border           : $border-secondary !default;
$dashboard-items__title-border-width     : 0 0 $border-width-secondary !default;
$dashboard-items__title-text-transform   : uppercase !default;

$dashboard-items__subtitle-font-size     : $font-size-medium !default;
$dashboard-items__subtitle-font-weight   : $font-weight-bold !default; // $font-weight-normal !default;
$dashboard-items__subtitle-text-transform: normal !default;

$dashboard-items__item-font-size         : $font-size-base !default; //$font-size-medium !default;

$dashboard-items__collapsible-transform  : rotate(180deg) !default;

$dashboard-items__item-head-font-size     : $font-size-medium !default; //$font-size-medium !default;
$dashboard-items__item-head-font-weight   : $font-weight-medium-bold !default; //$font-size-medium !default;
$dashboard-items__item-padding            : 24px;
$dashboard-items__item-border             : 1px solid $lighter-gray;
$dashboard-items__item-border-radius      : 10px;
$dashboard-items__item-action-color       : $battleship-grey;
$dashboard-items__item-action-font-size   : $font-size-base !default;
$dashboard-items__item-wrapper-header-margin : 0 0 20px 0;

.dashboard-items {
    &__item {
        padding: $dashboard-items__padding;
        font-size: $dashboard-items__item-font-size;
    }

    &__title,
    &__subtitle {
        font-family: $dashboard-items__title-font-family;
    }

    &__title {
        padding: $dashboard-items__title-padding;
        background: $dashboard-items__title-background;
        font-size: $dashboard-items__title-font-size;
        font-weight: $dashboard-items__title-font-weight;
        border: $dashboard-items__title-border;
        border-width: $dashboard-items__title-border-width;
        text-transform: $dashboard-items__title-text-transform;
        &.customer-edit {
            text-align: center;
        }
    }

    &__collapsible {
        &--active {
            .icon {
                transform: $dashboard-items__collapsible-transform;
            }
        }
    }

    &__title-collapsible {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &__subtitle {
        font-size: $dashboard-items__subtitle-font-size;
        font-weight: $dashboard-items__subtitle-font-weight;
        text-transform: $dashboard-items__subtitle-text-transform;
    }

    &__content {
        .item {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: $dashboard-items__item-padding;    
            @include mq($screen-m) {
                align-items: flex-start;
                flex-direction: column;
            }
            &-wrapper {
                border: $dashboard-items__item-border;
                border-radius: $dashboard-items__item-border-radius;
                justify-content: space-between;

                &-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: $dashboard-items__item-wrapper-header-margin;

                    .link {
                        color: $color-primary;
                        font-size: $font-size-base ;
                        text-decoration: none;
                    }
                }
                
            }
            &-head {
                font-size: $dashboard-items__item-head-font-size; 
                font-weight: $dashboard-items__item-head-font-weight;
            }
            &-address {
                .item-body {
                    display: inline-flex;
                    .icon {
                        margin-right: $spacer--small;
                    }
                }
                address {
                    font-style: normal;
                    > strong {
                        text-transform: capitalize;
                    }
                }
                &-action {
                    .item {
                        align-items: end;
                        justify-content: end;
                    }
                }
            }
            &.actions-group__handler {
                align-items: end;
                justify-content: flex-end;
                position: relative;
                text-align: right;
                a {
                    color: $dashboard-items__item-action-color;
                    font-size: $dashboard-items__item-action-font-size;
                    margin: 0;
                    text-decoration: none;
                }

                &--row {
                    flex-direction: row;

                    a:nth-of-type(even) {
                        &::before {
                            content: '|';
                            margin: 0 $spacer--small;
                        }
                    }
                }
            }

            &-phone {
                padding-bottom: 0;
            }
        }

    }
}