@mixin darken-layout-hover($layoutContainerClass) {
    &:hover,
    &:focus-within {
        #{$layoutContainerClass} {
            &:after {
                background-color: $darken-layout-hover;
            }
        }
    }

    #{$layoutContainerClass} {
        position: relative;
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: transparent;
            transition: $transition-base;
        }
    }
}
