.search-form {
    width: $search-form__width;
    align-self: $search-form__alignment;
    line-height: $font-line-height;
    margin: $search-form__margin;
    position: $search-form__position;

    @include mq($screen-m) {
        position: $search-form__position\@medium;
    }

    &__wrapper {
        position: relative;
    }

    &__input-wrapper {
        position: relative;
    }

    &__input {
        width: 100%;
        -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes

        &.focus-visible {
            & + .search-form__button {
                border-color: $search-form__button-border--focus;
            }
        }
    }

    &__button {
        position: absolute;
        top: $search-form__button-top;
        right: $search-form__button-right;
        height: $search-form__button-height;
        min-height: $search-form__button-height;
        margin: $search-form__button-margin;
        border: $search-form__button-border;
        border-width: $search-form__button-border-width;
        transition: $search-form__button-transition;

        &:disabled,
        &[disabled] {
            background: $search-form__button-background--disabled;

            &:hover {
                background: $search-form__button-background--disabled;
            }

            .icon {
                fill: $search-form__button-icon-fill--disabled;
            }
        }

    }
}
