$dashboard-actions-toolbar__item-margin        : $spacer 0 0 !default;
$dashboard-actions-toolbar__item-margin\@medium: 0 $spacer 0 0 !default;

.dashboard-actions-toolbar {
    display: flex;
    flex-direction: column;

    @include mq($screen-m) {
        flex-direction: row;
    }

    &--last-to-right {
        .dashboard-actions-toolbar__item {
            &:last-child {
                @include mq($screen-m) {
                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }
    }

    &__item {
        margin: $dashboard-actions-toolbar__item-margin;

        @include mq($screen-m) {
            margin: $dashboard-actions-toolbar__item-margin\@medium;
        }
    }

    .block-requisition-list {
        @extend .dashboard-actions-toolbar__item;
    }
}
