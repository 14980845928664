$messages__z-index         : $z-index-high + 1 !default;
$messages__background-color: $white !default;
$messages__width           : 100% !default;

.messages {
    &--hidden-icons {
        .message {
            &:after {
                display: none;
            }
        }
    }

    &__wrap {
        display: block;
        position: relative;
        width: $messages__width;
        top: 0;
        background-color: $messages__background-color;

        html:not(.no-js) &[data-aos^='fade'][data-aos^='fade'] {
            pointer-events: all;
            opacity: 1;
            transform: none;
        }

        &.aos-animate {
            position: fixed;
            left: 0;
            z-index: $messages__z-index;
        }
    }
}
