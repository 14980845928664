$dashborad-form__title-font-family  : $font-family-base !default;
$dashborad-form__title-font-size    : $font-size-large !default;
$dashborad-form__title-font-weight  : $font-weight-bold !default;
$dashborad-form__title-padding      : $spacer--medium !default;
$dashborad-form__title-background   : $bg-color-secondary !default;
$dashborad-form__title-border       : $border-secondary !default;
$dashborad-form__title-border-width : 0 0 $border-width-secondary !default;

$dashboard-form__label-font-size    : $font-size-medium !default;

.dashboard-form {
    &__title {
        padding: $dashborad-form__title-padding;
        background: $dashborad-form__title-background;
        font-family: $dashborad-form__title-font-family;
        font-size: $dashborad-form__title-font-size;
        font-weight: $dashborad-form__title-font-weight;
        border: $dashborad-form__title-border;
        border-width: $dashborad-form__title-border-width;
    }

    &--hidden {
        display: none;
    }

    .label,
    .input__label,
    .input__field,
    select {
        font-size: $dashboard-form__label-font-size;
    }
}
