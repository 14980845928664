$gift-options__list-max-width       : 480px !default;

$gift-options__item-max-width       : 150px !default;
$gift-options__item-width           : 50% !default;

$gift-options__preview-max-width    : 300px !default;

$gift-options__item-active-transform: scale(1.03) !default;
$gift-options__item-name-font-size  : $font-size-medium !default;
$gift-options__item-name-margin     : $spacer--medium 0 $spacer !default;

$gift-options__details-border       : 1px solid $gray !default;
