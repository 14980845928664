$my-orders__fieldset-padding        : 0 $spacer !default;

$my-orders__search-min-width        : 50% !default;
$my-orders__search-margin\@medium   : $spacer--medium 0 0 !default;
$my-orders__search-padding\@medium  : 0 $spacer 0 0 !default;

$my-orders__date-padding            : 0 $spacer !default;
$my-orders__date-padding\@medium    : 0 $spacer--medium !default;
$my-orders__date-form-margin\@medium: 0 $spacer--medium 0 0 !default;

$my-orders__total-min-margin\@medium: 0 $spacer--medium 0 0 !default;

$my-orders__field-label-margin      : 0 $spacer 0 0 !default;

$my-orders__summary-list-padding    : 0 $spacer !default;
$my-orders__summary-label-margin    : 0 $spacer--extra-small 0 0 !default;
$my-orders__summary-icon-size       : 24px !default;
$my-orders__summary-icon-fill       : $red !default;
