@import 'forgot-password-variables';

.forgot-password {
    width: $forgot-password__width;
    max-width: $forgot-password__max-width;
    margin: 0 auto $forgot-password__margin-bottom;
    @include mq($screen-m) {
        width: $forgot-password__width\@mediun;
    }
    &__email,
    &__captcha-field {
        margin-bottom: $forgot-password__field-margin-bottom;
    }

    &__captcha-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: $forgot-password__captcha-wrapper-margin-bottom;
    }

    &__image {
        padding-right: $forgot-password__image-padding-right;
        width: $forgot-password__image-width;
        max-width: $forgot-password__image-max-width;
    }

    &__info {
        margin-bottom: $forgot-password__info-margin-bottom;
        color: $forgot-password__info-color;
        font-size: $forgot-password__info-font-size;
        text-align: center;
        @include mq($screen-m) {
            margin-bottom: $forgot-password__info-margin-bottom\@mediun;
        }
    }

    &__actions {
        position: absolute;
        bottom: 0;
        padding: $forgot-password__actions-padding;
        text-align: center;

        @include mq($screen-m) {
            position: static;
        }

        @include mq($max-screen: $screen-xl - 1) {
            flex-flow: column-reverse wrap;
        }
        .link{
            @extend .button;
            @extend .button--subtitle;
            min-width: $forgot-password__button-min-width;
        }



    }

    &__heading {
        margin:  $forgot-password__heading-margin;
    }

    &__captcha {
        padding: $forgot-password__captcha-bottom-padding;
    }
}

.customer-account-forgotpassword {
    .page-main {
        min-height: calc(100vh - 140px);
        @include mq($screen-m) {
            min-height: unset;
        }
    }
}
