$filters__item-margin                : 0 !default;
$filters__item-margin\@large         : $filters__item-margin; //0 0 64px 0 !default;
$filters__item-mobile-margin         : $spacer--semi-medium 0 0 0 !default;

$filters__content-width\@medium      : 67% !default;
$filters__content-width\@large       : 100% !default;
$filters__content-margin\@medium     : $spacer--medium auto !default;
$filters__content-margin\@large      : 0 !default;

$filters__container-padding\@large   : 0 !default;

$filters__trigger-bg-color           : transparent !default;
$filters__trigger-padding            : $spacer--small $spacer--medium $spacer--small 0 !default;
$filters__trigger-padding\@medium    : $spacer--small $spacer--medium !default;
$filters__trigger-text-padding       : 0 !default;
$filters__trigger-text-color         : $color-primary !default;
$filters__trigger-icon-size--open    : 24px !default;
$filters__trigger-icon-fill--open    : $color-secondary !default;
$filters__trigger-border             : $border-base !default;
$filters__trigger-border-width       : 0 0 1px 0 !default;
$filters__trigger-text-transform     : uppercase !default;
$filters__trigger-font-weight        : $font-weight-bold !default;

$filters__heading-font-family        : $font-family-base !default;
$filters__heading-font-size          : $font-size-large !default;
$filters__heading-font-size\@medium  : $font-size-large !default;

$filters__heading-font-weight        : $font-weight-bold !default;
$filters__heading-margin             : 0 !default;
$filters__heading-padding\@large     : 0; //$spacer $spacer $spacer 0 !default;
$filters__heading-padding\@xl        : 0; //$spacer $spacer--medium $spacer !default;

$filters__modal-top-padding          : 0 0 $spacer 0 !default;
// $filters__modal-middle-padding       : $spacer--extra-large 0 !default;
// $filters__modal-middle-padding\@large: 0 !default;
$filters__modal-middle-padding       : $spacer--small $spacer--medium  !default;
$filters__modal-middle-padding\@large: $spacer--small $spacer--medium  !default;

$filters__modal-top-padding\@large   : $filters__modal-top-padding !default;
$filters__border-width\@large        : 0 !default;

$filters__modal-top-margin           : $spacer--semi-medium !default;
// $filters__modal-top-margin\@medium   : $spacer--small !default;
$filters__modal-top-margin\@medium   : 0 !default;
$filters__modal-top-bar-padding      : $spacer--small $spacer--small !default;
$filters__top-bar-size               : $font-size-medium;

$filters__top-bar-filters__heading-padding : 0 !default;

$filters__top-button-min-height            : 30px;
$filters__top-button-min-height\@small     : 45px;
$filters__top-button-min-height\@large     : 45px;
$filters__top-button-min-width             : auto;
$filters__top-button-min-width\@small      : 100px;
$filters__top-button-min-width\@large      : 160px;

$filters--heading-page-margin              : $spacer 0 $spacer--medium 0 !default;
$filters--heading-page-margin\@medium      : $spacer--medium 0 $spacer--semi-large 0 !default;