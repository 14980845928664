$not-found__padding                      : 100px 0 0 !default;
$not-found__margin                       : 0 auto !default;
$not-found__max-width                    : 496px !default;
$not-found__max-width\@large             : 784px !default;

//heading
$not-found__heading-font-weight          : $font-weight-normal !default;
$not-found__heading-margin               : 0 0 72px !default;

//number
$not-found__number-letter-spacing        : 3px !default;
$not-found__number-font-family           : $font-family-secondary !default;
$not-found__number-font-weight           : $font-weight-base !default;
$not-found__number-font-size             : 100px !default;
$not-found__number-line-height           : 104px !default;
$not-found__number-margin                : 0 0 $spacer--semi-large !default;

$not-found__number-letter-spacing\@medium: 6px !default;
$not-found__number-font-size\@medium     : 200px !default;
$not-found__number-line-height\@medium   : 200px !default;

//actions
$not-found__actions-margin               : 0 0 150px !default;
$not-found__actions-margin\@large        : 0 0 100px !default;

//link
$not-found__link-width                   : 100% !default;
$not-found__link-margin                  : 0 0 $spacer--medium !default;
$not-found__link-max-width               : 320px !default;

//text
$not-found__text-color                   : $color-secondary !default;
$not-found__text-font-size               : $font-size-medium !default;
$not-found__text-line-height             : 2 !default;


//no-route

$no-route__title-font-size               : $font-size-super-extra-large\@screen-m !default;
$no-route__title-margin                  : $spacer--semi-medium  0  $spacer--medium 0!default;

$no-route__subtitle-font-weight          : $font-weight-medium-bold !default;

$no-route__content_p-margin              : $spacer 0 0 0!default;

$no-route__actions-margin                : $spacer--semi-large 0 0 0!default;
$no-route__actions-link-color-hover      : $white !default;


//carousel no route

$no-route__carousel-margin               : $spacer--extra-large 0 $spacer--semi-medium 0 !default;
