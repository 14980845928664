@import "footer-variables";

.footer {
    flex-shrink: 0;
    border: $footer__border;
    border-width: $footer__border-width;
    background-color: $footer__background-color;

    &--checkout {
        .footer__bottom-bar-handler {
            padding: $footer__bottom-bar-handler-padding--checkout;

            @include mq($screen-m) {
                padding: $footer__bottom-bar-handler-padding--checkout\@medium;
            }

            @include mq($screen-l) {
                padding: $footer__bottom-bar-handler-padding--checkout\@large;
            }

            @include mq($screen-xl) {
                padding: $footer__bottom-bar-handler-padding--checkout\@extra-large;
            }
        }
    }

    &--email {
        background-color: $footer__background-color--email;
    }

    &__handler {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0;
        background-color: $bsm-green;

        @include mq($screen-m) {
           // padding: $footer__handler-padding\@medium;
            padding: 0;
        }

        @include mq($screen-l) {
            flex-flow: row wrap;
            padding: 0;
        }
    }
    &__top {
        display: flex;
        width: auto;
        flex-direction: column;
        padding: $footer__handler-padding\@medium;
        @include mq(575px) {
            flex-direction: row;
            padding: $footer__top-padding-large;
        }
        @include mq($screen-l) {
            width: 100%;
        }
    }
    &__address-logo {
        display: flex;
        .footer-address {
            @extend .link;
            position: relative;
            font-size: $font-size-small;
            color: $bsm-grey;
            padding: $footer__address-padding;
            margin: $footer__address-margin;
            font-weight: 500;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: $footer__address-after-top;
                width: 1px;
                height: $footer__address-after-height;
                background: $bsm-grey;
            }
            &:hover {
                color: $bsm-grey;
                text-decoration: none;
                cursor: pointer;
            }
            &.mobile {
                display: block;
            }
            &.desktop {
                display: none;
                white-space: nowrap;
            }
            p {
                font-size: $font-size-small;
                color: $bsm-grey;
                margin: 0;
            }
            @include mq(575px) {
                &.mobile {
                    white-space: nowrap;
                }
            }
            @include mq($screen-m) {
                margin: 0;
                padding: $footer__address-padding\@medium;
                &.mobile {
                    display: none;
                }
                &.desktop {
                    display: block;
                }
            }
        }
        .footer-logo {
            padding: $footer__links-dropdown-list-padding-logo;
            align-self: center;
            &::before {
                content: $footer__address-logo-content;
            }
            @include mq($screen-m) {
                padding: $footer__links-dropdown-list-padding-logo\@extra-large;
            }
        }
    }
    &__newsletter {
        background-color: $color-secondary-light ;
        border-top: 1px solid $white ;
        position: relative;
        top: -1px;
        &-content {
            align-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: $spacer--medium  0;
            @include mq($screen-l) {
                padding: $spacer--semi-large  0;
            }
            > p{
                text-align: center;
                &:first-child{
                    font-weight: $font-weight-medium-bold;
                }
            }
            > div {
                width: 100%;
                @include mq($screen-m) {
                    // width: 73%;
                    width: 80%;
                }
                @include mq($screen-l) {
                    width: 67%;
                }
                @include mq($screen-xl) {
                    width: 61%;
                }
            }
            iframe {
                min-height: 295px;
                @include mq($screen-m) {
                    min-height: 260px;
                }
                > input {
                    height: 42px;
                    border-radius: 5px;
                }
            }
        }
    }

    &__links {
        width: $footer__links-width;
        background-color: $footer__links-dropdown-background;


        .dropdown-list {
            margin-top: $spacer--semi-large;
            &__label {
                background-color: $footer__links-label-background;
                color: $footer__links-label-color;

                &:hover,
                &.focus-visible {
                    & > .dropdown-list__icon {
                        fill: $footer__links-dropdown-icon-color-hover;
                    }
                }

                &[aria-expanded="true"] {
                    & > .dropdown-list__icon {
                        fill: $footer__links-dropdown-icon-color-open;
                    }
                }
            }

            &__content {
                margin: $footer__links-content-margin;
            }

            &__list {
                margin: 0;
                background-color: $bsm-grey;
            }

            &__item {
                width: $footer__links-dropdown-list-item-width;
                border-bottom: none;
                .list__item {
                    //@extend .link;
                    display: block;
                    font-size: $font-size-small;
                    padding: 0 0 20px;
                }
                &.list__column {
                    .list__columns {
                        display: flex;
                        flex-direction: column;
                        .list__item {
                            &.list__header {
                                ul.list {
                                    padding: $footer__links-list-padding-small;
                                }
                                &.remove-click {
                                    a:first-of-type {
                                        pointer-events: none;
                                    }
                                }
                                a:first-of-type {
                                    color: $bsm-green;
                                }
                                .list__column-item {
                                    padding: 0;
                                    a {
                                        @extend .link;
                                        font-size: $font-size-small;
                                        pointer-events: all;
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__icon {
                fill: $footer__links-dropdown-icon-color;
            }
        }
    }

    &__links-list {
        padding: $footer__links-list-padding;
        display: flex;
        flex-direction: column;

        li {
            @include mq($screen-l) {
                width: fit-content;
            }
        }
    }

    &__link {
        display: block;
        margin: $footer__link-margin;
        font-size: $font-size-base;
        font-weight: $footer__link-font-weight;
        color: $footer__link-color;
        text-decoration: none;

        @include mq($screen-m) {
            margin: $footer__link-margin\@medium;
        }

        &:hover {
            color: $footer__link-color-hover;
        }
    }

    &__social {
        order: 1;

        @include mq($screen-l) {
            order: 0;
        }
    }

    &__social-handler {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: $footer__social-handler-margin;
        width: 100%;
        justify-content: center;
        p {
            font-size: $footer__social-handler-font-size\@small;
            line-height: $footer__social-handler-font-line-height;
            @extend .heading--third-level;
            color: $bsm-grey;
            padding-right: $footer__bottom-bar-handler-padding--p;
            @include mq($screen-m) {
                font-size: $footer__social-handler-font-size;
            }
        }
        @include mq($screen-l) {
            flex: 1;
            margin: $footer__social-handler-margin\@large;
            padding: $footer__social-handler-padding\@large;
            position: relative;
            justify-content: flex-end;
        }
    }

    &__social-list {
        @include mq($screen-l) {
            align-self: center;
            flex-wrap: wrap;
        }

        .list__item {
            padding: $footer__social-list__item-padding;
            margin: $footer__social-list__item-margin;

            &:last-child {
                margin: $footer__social-list__item-margin-last-child;
            }
        }

        .list__icon-link {
            width: $footer__social-list__icon-link-size;
            height: $footer__social-list__icon-link-size;
            padding: $footer__social-list__icon-link-padding;

            @include mq($screen-l) {
                margin: 0;
            }
        }
    }

    &__social-list-title {
        display: flex;
        align-items: center;
        color: $footer__social-list-title-color;
        font-family: $footer__social-list-title-font-family;
        font-size: $footer__social-list-title-font-size;
        font-weight: $footer__social-list-title-font-weight;
        margin: $footer__social-list-title-margin;

        @include mq($screen-m) {
            margin: $footer__social-list-title-margin\@medium;
        }

        @include mq($screen-l) {
            align-self: flex-start;
            margin: $footer__social-list-title-margin\@large;
        }
    }

    &__bottom-bar {
        color: $footer__bottom-bar-color;
        background-color: $gray-lightest;
    }

    &__bottom-bar-handler {
        display: flex;
        flex-direction: row;
        min-height: $footer__bottom-min-height;
        justify-content: space-between;
        margin: $footer__bottom-bar-handler-margin;
        margin: 0 auto;
        .footer__bottom-bar-logo {
            display: flex;
            padding-top: $spacer--semi-medium;
            &::before {
                // content: $footer__address-bottom-logo-content\@small;
                content: $footer__address-bottom-logo-content;
                margin: 0 $container__margin;
                /* @include mq($screen-m) {
                    content: $footer__address-bottom-logo-content;
                } */

                @include mq($screen-m) {
                    margin: 0 $container__margin\@medium;
                }

                @include mq($screen-l) {
                    margin: 0 $container__margin\@large - $container__margin;
                }

                @include mq($container__max-width + 2 * $container__margin) {
                    margin: 0 auto;
                }
            }
            @include mq($screen-m) {
                padding-top: $spacer;
            }
        }
        @include mq($screen-m) {
            flex-wrap: wrap;
            align-items: center;
            padding: $footer__bottom-padding\@large;
        }
        .footer__link {
            color: $gray;
            font-size: $font-size-small;
        }
        .list {

            &--horizontal {
                flex-flow: column wrap;
                text-align: right;
                padding: $spacer--small;
                .list__item {
                    padding: $spacer--extra-small $spacer;
                }
                @include mq($screen-m) {
                    flex-flow: row wrap;
                    align-items: center;
                    padding: 0;
                }
            }
            &.submenu {
                display: flex;
                justify-content: flex-end;
                .list__item {
                    padding: 0 $spacer;
                    @include mq($screen-m) {
                        padding: 0 $spacer--extra-small;
                    }
                }
            }
        }

    }

    &__copyright {
        display: block;
        order: 2;
        padding: $footer__copywrite-padding;
        text-align: center;
        color: $footer__copywrite-color;

        @include mq($screen-m) {
            order: 1;
            padding: $footer__copywrite-padding\@medium;
        }

        small {
            font-size: $footer__copywrite-font-size;
        }
    }

    &__payments-list {
        justify-content: space-around;
        margin: $footer__payments-list-margin;

        @include mq($screen-m) {
            flex-flow: row wrap;
            margin: $footer__payments-list-margin\@medium;
            order: 2;
        }
    }

    &__payments-list-icon {
        width: $footer__payments-list-icon-width;
        height: $footer__payments-list-icon-height;
        fill: $footer__payments-list-icon-fill;

        &--visa {
            width: $footer__payments-list-icon-width--visa;
        }

        &--paypal {
            width: $footer__payments-list-icon-width--paypal;
        }
    }

    &__scroll-top {
        position: absolute;
        right: $footer__scroll-top-right;
        bottom: $footer__scroll-top-bottom;
        background-color: $footer__scroll-top-bg;
        color: $bsm-grey;
        z-index: 1;
        @include mq($screen-xl) {
            right: $footer__scroll-top-right\@medium;
        }
        &:before {
            transform: $footer__scroll-top-before-transform;
            background: none;
        }

        &:hover,
        &:focus,
        &.focus-visible {
            background-color: $footer__scroll-top-bg-hover;
        }

        .icon {
            fill: $footer__scroll-top-icon-fill;
        }
    }

    &__switchers {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: $footer__switchers-padding;
        order: $footer__switchers-order;

        @include mq($screen-s) {
            flex-direction: row;
            justify-content: center;
            width: $footer__switchers-width\@small;
        }

        @include mq($screen-xl) {
            width: $footer__switchers-width\@xl;
        }
    }

    &__dropdown-switcher {
        width: auto;
        background-color: $footer__switcher-background-color;

        .dropdown-list__label {
            background-color: $footer__switcher-label-background-color;
            padding: $footer__switcher-label-padding;
            color: $footer__switcher-label-color;
            white-space: nowrap;

            &[aria-expanded="true"] {
                background-color: $footer__switcher-label-color;
                color: $footer__switcher-label-background-color;

                &:hover {
                    .dropdown-list__icon {
                        fill: $footer__switcher-label-background-color;
                    }
                }
            }

            &:hover {
                .dropdown-list__icon {
                    fill: $footer__switcher-label-color;
                }
            }
        }

        .dropdown-list__content {
            position: absolute;
            bottom: $footer__switcher-content-bottom;
            width: $footer__switcher-content-width;
            padding: $footer__switcher-content-padding;
            background-color: $footer__switcher-label-color;

            .button {
                font-weight: $footer__switcher-button-font-weight;
                min-height: $footer__switcher-button-min-height;
                text-decoration: $footer__switcher-button-text-decoration;

                &:hover,
                &:focus,
                &.focus-visible {
                    text-decoration: $footer__switcher-button-hover-text-decoration;
                }
            }
        }

        .dropdown-list__icon {
            fill: $footer__switcher-label-color;
        }
    }
}
small.copyright {
    display: none;
}
