@import "catalog-event-variables";

.catalog-event {
    display: $catalog-event__display;
    flex-direction: $catalog-event__flex-direction;
    justify-content: $catalog-event__justify-content;
    align-items: $catalog-event__align-items;
    width: $catalog-event__width;
    max-width: $catalog-event__max-width;
    margin: $catalog-event__margin;
    background-color: $catalog-event__background-color;
    padding: $catalog-event__padding;
    @include darken-layout-hover('.lazyload-wrapper');

    @include mq($screen-m) {
        padding: $catalog-event__padding\@medium;
    }

    &--slider-item {
        margin: $catalog-event__margin--slider-item;
    }

    &__wrapper {
        display: $catalog-event__date-wrapper-display;
        flex-direction: $catalog-event__date-wrapper-flex-direction;
        align-items: $catalog-event__date-wrapper-align-items;
    }

    &__date {
        display: $catalog-event__date-display;
        justify-content: $catalog-event__date-justify-content;
    }

    &__date-item {
        min-width: $catalog-event__date-item-min-width;
        text-align: $catalog-event__date-item-text-align;

        @include mq($screen-m) {
            min-width: $catalog-event__date-item-min-width\@medium;
        }
    }

    &__date-value {
        font-family: $catalog-event__date-value-font-family;
        font-size: $catalog-event__date-value-font-size;

        @include mq($screen-m) {
            font-size: $catalog-event__date-value-font-size\@medium;
        }
    }

    &__date-label {
        color: $catalog-event__date-label-color;
        font-size: $catalog-event__date-label-font-size;
        text-transform: $catalog-event__date-label-text-transform;
    }

    &__button {
        min-width: $catalog-event__button-min-width;
    }
}
