$pager__list-padding                        : 0 $spacer !default;
$pager__list-margin                         : 0 !default;

$pager__item-margin                         : 0 $spacer !default;
$pager__item-first-child-margin             : 0 $spacer--semi-medium 0 0 !default;
$pager__item-last-child-margin              : 0 0 0 $spacer--semi-medium !default;
$pager__item-after-position--current        : absolute !default;
$pager__item-after-display--current         : inline !default;
$pager__item-after-width--current           : 100% !default;
$pager__item-after-height--current          : 2px !default;
$pager__item-after-background-color--current: $color-primary !default;
$pager__item-after-transition--current      : $transition-base !default;
$pager__item-after-transform-origin--current: left !default;
$pager__item-size                           : $spacer--extra-large !default;
$pager__item--current                       : $font-color-base !default;
$pager__item-text-decoration--current       : none !default;
$pager__item-font-size                      : $font-size-medium !default;

$pager__link-width                          : 100% !default;
$pager__link-size                           : $spacer--extra-large !default;
$pager__link-fill                           : $color-secondary !default;
$pager__link-fill-disabled                  : $gray !default;
$pager__link-bg-disabled                    : $white !default;
$pager__link-color                          : $color-secondary !default;
$pager__link-text-decoration                : none !default;

$pager__icon-size                           : 24px !default;
