$google-pay-button__border                          : 0 !default;
$google-pay-button__border-radius                   : $spacer--extra-small !default;
$google-pay-button__box-shadow                      : rgba(60, 64, 67, 0.3) 0 1px 1px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px !default;
$google-pay-button__height                          : 40px !default;
$google-pay-button__padding                         : $spacer--small $spacer--semi-medium !default;

// black
$google-pay-button__black-background-color          : $black !default;
$google-pay-button__black-background-color--active  : #5f6368 !default;
$google-pay-button__black-background-color--hover   : #3c4043 !default;
$google-pay-button__black-background-image--short   : url(https://www.gstatic.com/instantbuy/svg/dark_gpay.svg) !default;
$google-pay-button__black-background-image--long    : url(https://www.gstatic.com/instantbuy/svg/dark/en.svg) !default;
$google-pay-button__black-box-shadow                : none !default;
$google-pay-button__black-padding                   : $spacer--small $spacer--semi-medium $spacer !default;

// white
$google-pay-button__white-background-color          : $white !default;
$google-pay-button__white-background-color--active  : $white !default;
$google-pay-button__white-background-color--hover   : #f8f8f8 !default;
$google-pay-button__white-background-image--short   : url(https://www.gstatic.com/instantbuy/svg/light_gpay.svg) !default;
$google-pay-button__white-min-width--short          : 90px !default;
$google-pay-button__white-width--short              : 160px !default;
$google-pay-button__white-min-width--long           : 150px !default;
$google-pay-button__white-width--long               : 240px !default;
$google-pay-button__white-background-image--long    : url(https://www.gstatic.com/instantbuy/svg/light/en.svg) !default;
$google-pay-button__white-box-shadow--focus         : #e8e8e8 0 1px 1px 0, #e8e8e8 0 1px 3px !default;

// minicart
$google-pay-minicart-logo__margin                   : $spacer--medium 0 0 !default;
$google-pay-minicart-logo__extra-actions-margin     : 0 !default;

// card
$googlepay__card-width                              : 30px !default;
$googlepay__card-field-padding-right                : 80px !default;
$googlepay__card-right--selected                    : $spacer--medium !default;
$googlepay__card-position--selected                 : absolute !default;

// paypal logo
$googlepay__paypal-logo-container-width             : 100% !default;

// field
$googlepay__field-color--required                   : $color-error !default;
$googlepay__field-placeholder-color                 : lightgrey !default;
$googlepay__field-margin--required                  : 0 0 0 $spacer--extra-small !default;
$googlepay__field-control-margin                    : $spacer $spacer 0 0 !default;

// payment-method
$googlepay__payment-method-width                    : 100% !default;
$googlepay__payment-method-width                    : 100% !default;
$googlepay__payment-method-action-margin-bottom     : $spacer--small !default;
$googlepay__payment-method-img-height               : 40px !default;
$googlepay__payment-method-label-font-weight        : $font-weight-medium !default;
$googlepay__payment-method-label-color--after       : $color-error !default;
$googlepay__payment-method-label-margin--after      : 0 0 0 $spacer--extra-small !default;

$googlepay__payment-method-control-background       : $white !default;
$googlepay__payment-method-control-border           : $border-base !default;
$googlepay__payment-method-control-border-radius    : 1px !default;
$googlepay__payment-method-control-font-family      : $font-family-base !default;
$googlepay__payment-method-control-height           : 32px !default;
$googlepay__payment-method-control-padding          : 0 $spacer !default;
$googlepay__payment-method-control-width            : 5rem !default;

$googlepay__payment-method-error-color              : $color-error !default;
$googlepay__payment-method-error-height             : 0 !default;
$googlepay__payment-method-error-margin-top         : $spacer !default;
$googlepay__payment-method-error-opacity            : 0 !default;

.braintree-googlepay-button {
    background-origin: content-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border: $google-pay-button__border;
    border-radius: $google-pay-button__border-radius;
    box-shadow: $google-pay-button__box-shadow;
    cursor: pointer;
    height: $google-pay-button__height;
    padding: $google-pay-button__padding;

    &.black {
        background-color: $google-pay-button__black-background-color;
        box-shadow: $google-pay-button__black-box-shadow;
        padding: $google-pay-button__black-padding;

        &:active {
            background-color: $google-pay-button__black-background-color--active;
        }

        &:hover {
            background-color: $google-pay-button__black-background-color--hover;
        }

        &.short {
            background-image: $google-pay-button__black-background-image--short;
        }

        &.long {
            background-image: $google-pay-button__black-background-image--long;
        }
    }

    &.white {
        background-color: $google-pay-button__white-background-color;

        &:active {
            background-color: $google-pay-button__white-background-color--active;
        }

        &:focus {
            box-shadow: $google-pay-button__white-box-shadow--focus;
        }

        &:hover {
            background-color: $google-pay-button__white-background-color--hover;
        }

        &.short {
            background-image: $google-pay-button__white-background-image--short;
        }

        &.long {
            background-image: $google-pay-button__white-background-image--long;
        }
    }

    &.short {
        min-width: $google-pay-button__white-min-width--short;
        width: $google-pay-button__white-width--short;
    }

    &.long {
        min-width: $google-pay-button__white-min-width--long;
        width: $google-pay-button__white-width--long;
    }
}

.googlepay-minicart-logo {
    margin: $google-pay-minicart-logo__margin;

    .minicart-content__extra-actions & {
        margin: $google-pay-minicart-logo__extra-actions-margin;
    }
}

.checkout-payment-method .credit-card-types.braintree-credit-card-types img {
    width: $googlepay__card-width;
    -webkit-filter: none;
    -webkit-transition: none;
}

.braintree-card-control {
    position: relative;

    .input__field {
        padding-right: $googlepay__card-field-padding-right;
    }
}

.braintree-credit-card-selected {
    position: $googlepay__card-position--selected;
    right: $googlepay__card-right--selected;
}

.braintree-paypal-logo.oneclick-container {
    width: $googlepay__paypal-logo-container-width;
}

#payment_form_braintree_ach_direct_debit {
    .field > .fields {
        .field {
            .label {
                font-weight: $font-weight-medium;
            }

            &.required > .label:after {
                content: '*';
                color: $googlepay__field-color--required;
                margin: $googlepay__field-margin--required;
            }

            .control {
                margin: $googlepay__field-control-margin;

                input[type='text']::placeholder,
                input[type='number']::placeholder {
                    color: $googlepay__field-placeholder-color;
                }
            }
        }
    }
}

#payment-method-braintree-lpm {
    .primary {
        width: $googlepay__payment-method-width;

        .action {
            margin-bottom: $googlepay__payment-method-action-margin-bottom;
            width: $googlepay__payment-method-width;

            img {
                height: $googlepay__payment-method-img-height;
            }
        }
    }
}

#payment-method-braintree-cc-vault {
    .field {
        &.required {
            .label {
                font-weight: $googlepay__payment-method-label-font-weight;

                &:after {
                    content: '*';
                    color: $color-error;
                    margin: $googlepay__payment-method-label-margin--after;
                }
            }
        }

        .hosted-control {
            background: $googlepay__payment-method-control-background;
            background-clip: padding-box;
            border: $googlepay__payment-method-control-border;
            border-radius: $googlepay__payment-method-control-border-radius;
            font-family: $googlepay__payment-method-control-font-family;
            height: $googlepay__payment-method-control-height;
            padding: $googlepay__payment-method-control-padding;
            box-sizing: border-box;
            position: relative;
            width: $googlepay__payment-method-control-width;

            &.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) ~ .hosted-error {
                height: auto;
                opacity: 1;
            }
        }

        .hosted-error {
            clear: both;
            color: $googlepay__payment-method-error-color;
            height: $googlepay__payment-method-error-height;
            margin-top: $googlepay__payment-method-error-margin-top;
            opacity: $googlepay__payment-method-error-opacity;
        }
    }
}
