$email-to-friend__max-width            : 500px !default;
$email-to-friend__margin               : 0 auto !default;
$email-to-friend__legend-font-family   : $font-family-base !default;
$email-to-friend__legend-font-weight   : $font-weight-bold !default;
$email-to-friend__sender-wrapper-margin: 0 0 $spacer--medium !default;
$email-to-friend__fieldset-margin      : 0 0 $spacer--large !default;
$email-to-friend__inner-fieldset-margin: $spacer--large 0 0 !default;
$email-to-friend__remove-margin        : 0 0 $spacer--medium !default;
$email-to-friend__back-margin          : 0 0 0 $spacer--medium !default;
$email-to-friend__submit-margin        : 0 0 $spacer--large !default;
