@import 'customer-contract-variables';

.customer-contract {
    &-index {
        .__design--three {
            .card {
                &__col-sub-content {
                    p {
                        &:first-child,
                        &:last-child {
                            margin: $dashboard__contract-card-design-three-col-sub-content-p-margin;
                        }
                    }
                }
            }
        }
    }
    &-view, &-extrarequests {
        .icon-euro{
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 2px solid;
            padding: 1px 3px 1px 1px;
        }
        .icon-car{
            width: 25px;
            height: 25px;
            padding: 1px 3px 1px 1px;
        }
        .item-body{
            .radio{
                margin: 5px 10px 0px 0px;
                display: flex;
            }
        }
        .modal-popup {
            &:not(.save-parking-modal){
                .modal-inner-wrap {
                    @include mq($screen-xl) {
                        width: 45% !important;
                    }
                }
            }
            &.save-parking-modal {
                .modal-inner-wrap {
                    @include mq($screen-xl) {
                        width: 30%;
                    }
                }
            }
           
            .payment-modal-action,
            .detail-modal-action,
            .modal-footer{
                display: flex;
                justify-content: center;
                flex-direction: column;
                @include mq($screen-m) {
                    flex-direction: row;
                }
                button{
                    width: auto;
                    margin-bottom: $spacer;
                    @include mq($screen-m) {
                        width: 38%;
                    }
                    @include mq($screen-xl) {
                        width: 30%;
                    }
                }
            }
        }

        .detail-payment-modal{
            h2 {
                text-align: center;
                margin-bottom: 1.5em;
            }
            .actions-group{
                justify-content: center;
            }
            .mage-error{
                margin: 0;
            }
        }

        .automatic-entry-modal{

            .automatic-entry-header{
                display: flex;
                justify-content: center;
                .icon{
                    height: 50px;
                    width: 50px;
                }
            }
            .question{
                display: flex;
                justify-content: center;
                font-weight: bold;
            }
            .efective{
                display: flex;
                justify-content: center;
            }

        }

        .dashboard {

            &__contract-info {
                align-items: center;
                background-color: $dashboard__contract-info-bg-color;
                border-radius: $dashboard__contract-info-border-radius;
                display: flex;
                flex-direction: row;
                &-wrapper-text {
                    display: flex;
                    flex-direction: column;
                    @include mq($screen-s) {
                        flex-direction: row;
                    }
                    .label {
                        display: inline;
                        margin: $dashboard__contract-info-wrapper-text-label-margin;
                        text-transform: lowercase;
                    }
                    .divider {
                        margin: $dashboard__contract-info-wrapper-text-divider-margin;
                        @include mq($max-screen: $screen-s - 1)  {
                            display: none;
                        }
                    }
                }
                &-wrapper-icon {
                    background-color: $dashboard__contract-info-wrapper-icon-bg-color;
                    border-radius: $dashboard__contract-info-border-radius;
                    margin-right: $dashboard__contract-info-wrapper-icon-margin-right;
                    padding: $dashboard__contract-info-wrapper-icon-padding;
                    .icon {
                        fill: $dashboard__contract-info-wrapper-icon-icon-fill;
                    }
                }
            }

            &-items__content {
                .actions-group {
                    &__link  {
                        font-size: $dashboard__contract-actions-group__link-font-size ;
                    }
                }
                &.holder-data {
                    .input__label {
                        font-size: $dashboard__contract-holder-data-input__label-font-size ;
                        margin: $dashboard__contract-holder-data-input__label-margin;
                        @include mq($screen-m) {
                            margin: $dashboard__contract-holder-data-input__label-margin\@medium;
                        }
                    }
                }
                &.vehicles-data{
                    .toggle{
                        padding: 0;
                    }
                    .input-container{
                        display: flex;
                        margin-top: $spacer--extra-small ;
                    }

                    .item {
                        &div[class^='col']{
                            justify-content: flex-start;
                            @include mq($screen-m) {
                                justify-content: center;
                            }
                        }
                        &.actions-group__handler{
                            margin-bottom: 0px;
                            @include mq($max-screen: $screen-s - 1) {
                                padding-top: 0 !important;
                            }
                        }
                    }
                }
            }

        }

        .remote-control, .card-duplicate {
            cursor: pointer;
        }

        .remote-control-modal, .card-duplicate-modal {
            .dashboard__contract-info-wrapper-icon {
                background-color: inherit;

                .icon {
                    fill: #000;
                }
            }

            .dashboard__contract-info {
                align-items: start;
                background-color: inherit;
            }

            .dashboard__contract-info-wrapper-text:first-child {
                padding-top: 16px;
                margin: $dashboard__contract-info-wrapper-text-label-margin;
            }

            .remote-control-modal-action, .card-duplicate-modal-action {
                display: flex;
                justify-content: center;

                button {
                    /* width: 20%; */
                }
            }
        }

        .radio-options {
            .item-body {

                &:not(:last-child) {
                    border-bottom: 1px solid #eaeaea;
                    width: 100%;
                }
            }
        }
    }
}
