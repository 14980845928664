@import 'filters-variables';

.filters {
    &__item {
        margin: $filters__item-margin;

        @include mq($screen-l) {
            margin: $filters__item-margin\@large;
        }
        &.mobile {
            display: flex;
            justify-content: space-between;
            margin: $filters__item-mobile-margin;
            @include mq($screen-l) {
                display: none;
            }
        }
        &-top-bar {
            display: flex;

            .filters__button {
                display: flex;
                align-items: center;
                border: none;
                padding: $spacer;
                .icon {
                    fill: $breadcrumbs__color;
                    &.filter {
                        width: $filters__top-bar-size;
                        height: $filters__top-bar-size; 
                    }
                }
                .button__text {
                    color: $breadcrumbs__color;
                    text-transform: capitalize;
                    font-size: $font-size-base;
                    padding-left: $spacer--small;
                }
            }
        }
    }

    &__container {
        .modal & {
            @include mq($screen-l) {
                height: auto;
                animation: none;
                padding: $filters__container-padding\@large;
                max-height: 100%;
            }
        }
    }

    &__modal {
        @include mq($screen-l) {
            display: block;
            position: static;
            visibility: visible;
            opacity: 1;
            height: auto;
            padding: 0;
            background-color: $white;
        }
    }

    &__content {
        position: relative;

        @include mq($screen-m) {
            width: $filters__content-width\@medium;
            margin: $filters__content-margin\@medium;
        }

        @include mq($screen-l) {
            width: $filters__content-width\@large;
            margin: $filters__content-margin\@large;
        }
        
        .bsm_tipo_duracionFilter {
            @include visually-hidden();
        }
    }

    &__trigger {
        width: 100%;
        justify-content: space-between;
        padding: $filters__trigger-padding;
        border: $filters__trigger-border;
        border-width: $filters__trigger-border-width;
        background-color: $filters__trigger-bg-color;

        @include mq($screen-m) {
            padding: $filters__trigger-padding\@medium;
        }

        @include mq($screen-l) {
            display: none;
        }

        > * {
            pointer-events: none;
        }

        .icon {
            width: $filters__trigger-icon-size--open;
            height: $filters__trigger-icon-size--open;
            fill: $filters__trigger-icon-fill--open;
        }

        .button__text {
            color: $filters__trigger-text-color;
            padding: $filters__trigger-text-padding;
            text-transform: $filters__trigger-text-transform;
            font-weight: $filters__trigger-font-weight;
        }
        &--hide {
            display: none;
        }
    }

    &__heading,
    &__trigger {
        font-family: $filters__heading-font-family;
        font-size: $filters__heading-font-size;
        font-weight: $filters__heading-font-weight;
        margin: $filters__heading-margin;

        @include mq($screen-m) {
            font-size: $filters__heading-font-size\@medium;
        }

        @include mq($screen-l) {
            padding: $filters__heading-padding\@large;
        }
    }

    &--heading-page {
        margin: $filters--heading-page-margin;
        @include mq($screen-l) {
            margin: $filters--heading-page-margin\@medium;
        }
    }

    &__content-top {
        padding: $filters__modal-top-padding;
        display: flex;
        justify-content: space-between;
        margin-top: $filters__modal-top-margin;
        @include mq($screen-l) {
            margin-top: $filters__modal-top-margin\@medium;
            flex-direction: column;
        }
        &-bar{
            align-items: center;
            display: flex;
            padding: $filters__modal-middle-padding;
            min-height: 60px;
            @include mq($screen-l) {
                border-bottom: 1px solid $lighter-gray;
                padding: $filters__modal-middle-padding\@large;
            }
            &:nth-child(2) {
                border-bottom: 0;
                @include mq($screen-l) {
                    padding-bottom: 0;
                }
            }
            svg {
                align-self: center;
                &-icon-filter {
                    fill: $color-primary;
                }
            }
            .filters__heading{
                padding: $filters__top-bar-filters__heading-padding;
                &_refresh {
                    padding: $filters__top-bar-filters__heading-padding;
                    color: $breadcrumbs__color;
                    + .icon {
                        fill: $color-primary;
                    }
                }
            }
        }

        @include mq($screen-l) {
            padding: $filters__modal-top-padding\@large;
            border-width: $filters__border-width\@large;
        }
    }

    &__content-middle {
        padding: $filters__modal-middle-padding;
        @include mq($screen-l) {
            padding: $filters__modal-middle-padding\@large;
            overflow: initial;
        }
        .select2 {
            width: 100% !important;
        }
    }

    &__close-button {
        @include mq($screen-l) {
            display: none;
        }
    }
    .active-filters {
        display: none;
    }
    ~.top-filters { 
        @include visually-hidden();
    }
}
.top-filters {
    display: flex;
    flex-direction: row;
    .filters {
        &__item {
            margin: 0;
            width: 100%;
        }

        &__content {
            padding: 0;
        }
    }

    .dropdown-list {
        &__label-wrapper {
            display: none;
        }
        &__label{
            @include visually-hidden();
        }
        &__list {
            width: 100%;
            .filter__content {
                @include mq($max-screen: $screen-xl - 1) {
                    padding: 0;
                }
            }
        }
        &__content {
            ol {
                flex-wrap: nowrap !important;
                @include mq($max-screen: $screen-s - 1) {
                    overflow: auto;
                }
                li {
                    margin-right: 0;
                    width: auto !important;
                    @include mq ($screen-s){
                        margin-right: $spacer--medium;
                    }
                    @include mq ($screen-l){
                        margin-right: $spacer--large;
                    }
                }
                a {
                    @extend .button--secondary-gray;
                    min-height: $filters__top-button-min-height;
                    min-width: $filters__top-button-min-width ;
                    @include mq ($screen-s){
                        min-height: $filters__top-button-min-height\@small;
                        min-width: $filters__top-button-min-width\@small;
                    }
                    @include mq ($screen-l){
                        min-height: $filters__top-button-min-height\@large;
                        min-width: $filters__top-button-min-width\@large;
                    }
                    @include mq($max-screen: $screen-s) {

                    }
                    flex-direction: row;
                    .checkbox__label {
                        display: block;
                    }
                    &.checkbox--link-checked {
                        border-color: $color-primary;
                    }
                }
            }
        }
    }
}
