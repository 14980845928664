$discount-code__margin                    : 0 #{-$spacer} !default;
$discount-code__margin\@medium            : 0 !default;
$discount-code__button-width              : 100% !default;
$discount-code__button-padding            : $spacer--medium 0 !default;
$discount-code__button-border             : $border-base !default;
$discount-code__button-border-width       : 0 0 1px 0 !default;
$discount-code__button-background         : none !default;
$discount-code__button-background--hover  : none !default;
$discount-code__button-text-color         : $color-primary !default;
$discount-code__content                   : $spacer--large 0 !default;
$discount-code__button-icon-transition    : $transition-base !default;
$discount-code__button-text-margin        : 0 $spacer 0 0 !default;
$discount-code__button-text-font-size     : $font-size-large !default;
$discount-code__button-text-font-weight   : $font-weight-normal !default;
$discount-code__button-text-text-transform: uppercase !default;
$discount-code__input-margin              : 0 0 $spacer--large 0 !default;

.discount-code {
    margin: $discount-code__margin;

    @include mq($screen-m) {
        margin: $discount-code__margin\@medium;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: $discount-code__button-width;
        padding: $discount-code__button-padding;
        border: $discount-code__button-border;
        border-width: $discount-code__button-border-width;
        background: $discount-code__button-background;
        cursor: pointer;

        &:hover {
            background: $discount-code__button-background--hover;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    &__content {
        display: none;
        padding: $discount-code__content;
    }

    &__button-icon {
        transition: $discount-code__button-icon-transition;
    }

    &__button-text {
        margin: $discount-code__button-text-margin;
        font-size: $discount-code__button-text-font-size;
        font-weight: $discount-code__button-text-font-weight;
        color: $discount-code__button-text-color;
        text-transform: $discount-code__button-text-text-transform;
    }

    &._active {
        .discount-code__button-icon {
            transform: rotate(180deg);
        }

        .discount-code__content {
            display: block;
        }
    }

    &__input {
        margin: $discount-code__input-margin;
    }

    &__codes-list {
        display: flex;
        flex-flow: column nowrap;
    }

    &__codes-list-item {
        margin: 0 0 $spacer--medium 0;
        padding: 0 0 $spacer 0;
        border: $border-base;
        border-width: 0 0 1px 0;
    }

    &__messages {
        display: none;

        &._active {
            display: block;
        }
    }
}
