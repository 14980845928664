$quote-items__input-max-width  : 80px !default;
$quote-items__input-min-width  : 56px !default;
$quote-items__input-text-align : center !default;

.quote-items {
    .quote-title {
        display: none;
    }

    .quote-table-items {
        .input-text {
            @extend .input__field;
            max-width: $quote-items__input-max-width;
            min-width: $quote-items__input-min-width;
            text-align: $quote-items__input-text-align;
        }

        .table-caption {
            display: none;
        }

        .actions-toolbar {
            &._disabled {
                &:after {
                    content: '-';
                    display: block;
                }

                a {
                    display: none;
                }
            }
        }
    }
}
