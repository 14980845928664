$toggle__button-color--default: $white !default;
$toggle__button-color--success: $white !default;
$toggle__background--success: $color-success !default;
$toggle__background--default: $gray-light !default;
$toggle__background-border: 2px solid $gray !default;
$toggle__transition-timing-function :  cubic-bezier(.29, 1.01, 1, -0.68) !default ;
$toggle__transition: all .5s ease-out !default;
$toggle__width: 48px !default;
$toggle__height: $toggle__width/2 !default;
$toggle__button-width: $toggle__height - 4px !default;
$toggle__button-height: $toggle__height - 4px !default;
$toggle__button-border: unset !default;
$toggle__button-box-shadow: 0 1px 2px rgba(black, .4),0 3px 2px rgba(black,.4) !default;
$toggle__input-padding\@small: 0;