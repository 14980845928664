@import "heading-variables";

.heading {
    display: block;
    margin: $headings-margin;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    letter-spacing: $headings-letter-spacing;
    color: $headings-color;
    text-transform: $headings-text-transform;

    &--first-level {
        font-size: $heading-font-size--first-level;
        font-weight: $heading-font-weight--first-level;
        letter-spacing: $heading-letter-spacing--first-level;
        @include mq($screen-m) {
            font-size: $heading-font-size--first-level\@screen-m;
            font-weight: $heading-font-weight--first-level\@screen-m;
            letter-spacing: $heading-letter-spacing--first-level\@screen-m;
        }
    }

    &--second-level {
        font-size: $heading-font-size--second-level;
        font-weight: $heading-font-weight--second-level;
        letter-spacing: $heading-letter-spacing--second-level;
        @include mq($screen-m) {
            font-size: $heading-font-size--second-level\@screen-m;
            font-weight: $heading-font-weight--second-level\@screen-m;
            letter-spacing: $heading-letter-spacing--second-level\@screen-m;
        }
    }

    &--third-level {
        font-size: $heading-font-size--third-level;
        font-weight: $heading-font-weight--third-level;
        letter-spacing: $heading-letter-spacing--third-level;
        @include mq($screen-m) {
            font-size: $heading-font-size--third-level\@screen-m;
            font-weight: $heading-font-weight--third-level\@screen-m;
            letter-spacing: $heading-letter-spacing--third-level\@screen-m;
        }
    }

    &--fourth-level {
        font-size: $heading-font-size--fourth-level;
        font-weight: $heading-font-weight--fourth-level;
        letter-spacing: $heading-letter-spacing--fourth-level;
    }

    &--fifth-level {
        font-size: $heading-font-size--fifth-level;
        font-weight: $heading-font-weight--fifth-level;
        letter-spacing: $heading-letter-spacing--fifth-level;
    }

    &--sixth-level {
        font-size: $heading-font-size--sixth-level;
        font-weight: $heading-font-weight--sixth-level;
        letter-spacing: $heading-letter-spacing--sixth-level;
    }

    &--font-secondary {
        font-family: $headings-font-family--secondary;
    }

    &--page {
        margin: $headings-margin--page;
        font-size: $heading-font-size--page;
        font-weight: $heading-font-weight--page;
        letter-spacing: $heading-letter-spacing--page;
        text-transform: $headings-text-transform--page;
        @include mq($screen-m) {
            font-size: $heading-font-size--page\@screen-m;
            font-weight: $heading-font-weight--page\@screen-m;
            letter-spacing: $heading-letter-spacing--page\@screen-m;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend .heading;
}

h1 {
    @extend .heading--first-level;
}

h2 {
    @extend .heading--second-level;
}

h3 {
    @extend .heading--third-level;
}

h4 {
    @extend .heading--fourth-level;
}

h5 {
    @extend .heading--fifth-level;
}

h6 {
    @extend .heading--sixth-level;
}
