$swatch__margin                       : 0 !default;
$swatch__margin--catalog              : 0 0 $spacer 0 !default;
$swatch__transition                   : $transition-base !default;

$swatch__wrapper-justify              : flex-start !default;
$swatch__container-min-height         : 100px !default;
$swatch__container-min-height--catalog: 56px !default;

$swatch__title-margin                 : 0 0 $spacer !default;
$swatch__title-font-size              : $font-size-base !default;
$swatch__title-color                  : $color-secondary !default;

$swatch__option-width                 : 40px !default;
$swatch__option-height                : 40px !default;
$swatch__option-width--catalog        : 30px !default;
$swatch__option-height--catalog       : 30px !default;
$swatch__option-width--image          : 40px !default;
$swatch__option-height--image         : 40px !default;
$swatch__option-margin                : 0 $spacer 0 0 !default;
$swatch__option-margin--catalog       : 0 !default;
$swatch__option-padding               : 0 0 1px 0 !default;
$swatch__option-padding--catalog      : $spacer !default;
$swatch__option-background            : $white !default;
$swatch__option-color                 : $color-secondary !default;
$swatch__option-border                : 2px solid $white !default;
$swatch__option-border-width          : 0 0 2px 0 !default;
$swatch__option-border--white         : 2px $border-style-base $gray-lighter !default;
$swatch__option-border--white-catalog : $border-width-base $border-style-base $gray-light !default;
$swatch__option-border-color--active  : $color-primary !default;
$swatch__option-border--size          : $border-width-base $border-style-base $gray-light !default;
