@import 'chat-variables';

.chat {
    padding: $chat__padding;
    background: $chat__background;

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: $chat__list-margin;
        padding: $chat__list-padding;
        max-height: $chat__list-max-height;
        overflow-y: $chat__list-overflow-y;
        list-style: none;
    }

    &__message {
        padding: $chat__message-padding;

        &--sent {
            text-align: $chat__message-text-align--sent;
        }

        &--incoming {
            align-self: $chat__message-align-self--incoming;
        }
    }

    &__message-content {
        padding: $chat__message-content-padding;
        background:  $chat__message-content-background;
        border-radius: $chat__message-content-border-radius;

        &--sent {
            background: $chat__message-content-background--sent;
        }
    }

    &__message-text {
        font-size: $chat__message-text-font-size;
    }

    &__message-date {
        text-align:  $chat__message-date-text-align;
        color: $chat__message-date-color;
        font-size:  $chat__message-date-font-size;
    }

    &__message-send {
        margin: $chat__message-send-margin;
    }

    &__message-field {
        width: $chat__message-field-width;
        margin:  $chat__message-field-margin;
    }

    &__control {
        display: flex;
        align-items: center;
    }

    &__textarea {
        min-height: $chat__textarea-min-height;
    }

    &__username {
        color: $chat__username-color;
    }
}
