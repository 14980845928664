$dashboard-table__header-margin         : 0 0 $spacer--semi-medium !default;
$dashboard-table__header-padding        : $spacer--medium 0 !default; //$spacer--medium !default;
$dashboard-table__header-background     : unset !default; //$bg-color-secondary !default;
$dashboard-table__header-border         : $border-secondary !default;
$dashboard-table__header-border-width   : 0 0 $border-width-secondary !default;

$dashboard-table__content-margin        : 0 0 $spacer--semi-medium !default;

$dashboard-table__title-margin          : 0 $spacer--medium 0 0 !default;
$dashboard-table__title-font-family     : $font-family-base !default;
$dashboard-table__title-font-size       : $font-size-medium !default; //$font-size-large !default;
$dashboard-table__title-font-weight     : $font-weight-bold !default;
$dashboard-table__title-text-transform  : uppercase !default;

$dashboard-table__link-font-size        : $font-size-medium !default;
$dashboard-table__link-font-weight      : $font-weight-medium !default;
$dashboard-table__text-decoration       : underline !default;
