@import 'aparcamiento-list-variables';

.amlocator {
    &-parkings {
        &-map-container {
            margin: $amlocator-parkings-map-margin;
            padding-left: 0 !important;
            padding-right: 0 !important;

            .amlocator-map {
                height: $amlocator-parkings-map-height;
                max-height: $amlocator-parkings-map-max-height;

                .amlocator-popup {
                    .list {
                        position: relative;
                        left: -$spacer;
                    }
                }
            }
        }

        &-grid {
            &-wrapper {
                display: grid;
                gap: $spacer--large;
                grid-auto-rows: auto;
                grid-column: $amlocator-parkings-grid-columns;
                grid-template-columns: repeat($amlocator-parkings-grid-columns, 1fr);
                @include mq($screen-s) {
                    grid-column: $amlocator-parkings-grid-columns\@small;
                    grid-template-columns: repeat($amlocator-parkings-grid-columns\@small, 1fr);
                }
                @include mq($screen-l) {
                    grid-column: $amlocator-parkings-grid-columns\@large;
                    grid-template-columns: repeat($amlocator-parkings-grid-columns\@large, 1fr);
                }
            }

            &-item {
                padding: 0;

                &__image {
                    display: flex;
                    border-top-left-radius: $widget-card--radius;
                    border-top-right-radius: $widget-card--radius;
                    overflow: hidden;
                    .ratio-image {
                        height: 100%;
                        width: auto;
                        min-width: 100%;
                        max-width: unset;
                    }
                    img {
                        height: 100%;
                    }
                }

                &__name {
                    a {
                        font-size: $amlocator-parkings-grid-item-name-font-size;
                        font-weight: $amlocator-parkings-grid-item-name-font-weight;
                        height: $amlocator-parkings-grid-item-name-font-size * 3;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }

                &__description {
                    font-size: $amlocator-parkings-grid-item-description-font-size;
                }

                &__content {
                    justify-content: flex-start;
                    padding: $amlocator-parkings-grid-item-content-padding;
                    @include mq($screen-m) {
                        padding: $amlocator-parkings-grid-item-content-padding\medium;
                    }
                    .list {
                        position: relative;
                        left: -$spacer;
                        &--horizontal {
                            @include mq($screen-m) {
                                flex-flow: row wrap;
                            }
                        }
                    }
                }

                &__subtitle {
                    font-size: $amlocator-parkings-grid-item-subtitle-font-size;
                    font-weight: $amlocator-parkings-grid-item-subtitle-font-weight;
                }
                &__price {
                    .price {
                        flex-direction: row;
                    }
                    .price__label {
                        font-size: $font-size-small;
                        margin-right: $spacer;
                    }
                }

                &.widget-card {
                    &:nth-of-type(even),
                    &:nth-of-type(odd) {
                        margin: 0;
                    }
                }
            }
        }

        &-filter {
        .filter {
            width: 100%;
            ul {
                li {
                    margin-right: $amlocator-parkings-filter-margin-right;
                    width: auto !important;
                    @include mq($screen-s) {
                        margin-right: $amlocator-parkings-filter-margin-right\@small;
                    }
                }
            }
        }
    }
    }
    &-index-index {
        .header {
            margin: 0;
        }
    }
}
