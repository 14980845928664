// title
$bundle-option__title-margin               : $spacer--medium 0 $spacer !default;
$bundle-option__title-font-family          : $font-family-base !default;
$bundle-option__title-text-transform       : capitalize !default;
$bundle-option__title-font-size            : $font-size-base !default;
$bundle-option__title-color                : $gray-dark !default;
$bundle-option__title-font-weight          : $font-weight-normal !default;
$bundle-option__title-color--required      : $color-error !default;
$bundle-option__title-font-weight--required: $font-weight-normal !default;

// select
$bundle-option__select-max-width           : 350px !default;

// qty
$bundle-option__qty-input-max-width        : 100px !default;
$bundle-option__qty-input-color--disabled  : $gray !default;
$bundle-option__qty-input-cursor--disabled : default !default;
