//customer-contract-index
$dashboard__contract-card-design-three-col-sub-content-p-margin    : 5px 0 0 0 ;
//customer-contract-view
$dashboard__contract-info-bg-color                                 :   $color-secondary-light;
$dashboard__contract-info-border-radius                            :   10px;

$dashboard__contract-info-wrapper-icon-bg-color                    :   $color-secondary-30;
$dashboard__contract-info-wrapper-icon-icon-fill                   :   $white;
$dashboard__contract-info-wrapper-icon-padding                     :   $spacer--medium;
$dashboard__contract-info-wrapper-icon-margin-right                :   $spacer--small;

$dashboard__contract-info-wrapper-text-label-margin                :   0 $spacer--extra-small 0 0;

$dashboard__contract-info-wrapper-text-divider-margin              :   0 $spacer--small;

$dashboard__contract-actions-group__link-font-size                 :   $font-size-base;

$dashboard__contract-holder-data-input__label-font-size            :   $font-size-medium;
$dashboard__contract-holder-data-input__label-margin               :   0 $spacer--small;
$dashboard__contract-holder-data-input__label-margin\@medium       :   0 0 $spacer $spacer--semi-medium;

$dashboard__contract-info-wrapper-icon-icon-modal-fill             :   $black;
