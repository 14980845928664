$static-block__main__padding                      : 0; //variable que se usa para definir el padding de los bloques estaticos

$static-block-services-list-max-width             : $max-content-width;

$static-block-services-spacer--extra-large        : 68px;
$static-block-spacer--extra--large                : 94px;
$static-block-padding--extra--large               : $static-block__main__padding; // $spacer--extra-large $static-block-spacer--extra--large;

$static-block-services-bg-color                   : $lightest-gray;
$static-block-services-card-bg-color              : $bsm-green;
$static-block-services-card-gap                   : $spacer--semi-large ;
$static-block-services-left                       : calc(-50vw + 50%);
$static-block-services-wider                      : calc(100vw - 4px);
$static-block-heading-color                       : $bsm-grey;
$static-block-services-spacer-extra-large         : $spacer--semi-large + $spacer--extra-large;

$static-block-services-header-padding             : $static-block-services-spacer-extra-large 0 0 0; //$static-block-services-spacer-extra-large $static-block-services-spacer-extra-large 0;

$static-block-services-header-padding-mobile      : $spacer--extra-large-xl $spacer--medium 0;
$static-block-services-body-margin                : $spacer--semi-medium - 3 0 $spacer--semi-medium + 1;

$static-block-block-image-wrapper-padding         : $static-block__main__padding; //$spacer--extra-large $static-block-spacer--extra--large;

$static-block-block-image-wrapper-padding\@small  : $spacer;
$static-block--wrapper-margin-top                 : $spacer--extra-large-xl;

$static-block-h2-padding                          : 0 $spacer--extra-small;
