$cms-page__max-width        : 100% !default; //88% !default;
$cms-page__max-width\@medium: $cms-page__max-width !default; //85% !default;
$cms-page__max-width\@large : $cms-page__max-width !default; //78% !default;
$cms-page__max-width\@xlarge: $cms-page__max-width !default; //68% !default;

$cms-page__margin           : 0 auto !default;
$cms-contact__max-width     : 100% !default;

$cms-page-contact-wrapper-card-margin       : $spacer--large $spacer--extra-large !default;
$cms-page-contact__max-width\@medium        : 75% !default;
$cms-page-contact-card-active-border-color  : $color-primary !default;

$cms-page-contact-card-icon-size-w          : 64px !default;
$cms-page-contact-card-icon-size-h          : $cms-page-contact-card-icon-size-w !default;

$cms-page-contact-wrapper-form-card-margin-top       : $spacer--large  !default;
$cms-page-contact-wrapper-form-card-title-font-size  : $font-size-medium!default;
$cms-page-contact-wrapper-form-card-title-font-weight: $font-weight-medium-bold !default;



/*PAG SERVICIOS*/
$cms-page-servicios-widget-card-image-size-h: 64px !default;;    
$cms-page-servicios-widget-card-image-size-w: auto !default;