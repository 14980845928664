.search-block-header-info {
    background-color: $lightest-gray;
    position: relative;
    top: -$spacer--extra-small;
    .info {
        &-wrapper {
            align-items: flex-start;
            padding: $spacer--semi-medium 0;
            @include mq($screen-m) {
                align-items: center;
            }
        }

        &-address {
            font-size: $font-size-large;
            font-weight: $font-weight-medium-bold;
        }

        &-action {
            font-weight: $font-weight-medium-bold;
            text-align: right;
            a > span {
                padding-right: $spacer;

            }
            .action-label {
                display: none;
                @include mq($screen-m) {
                    display: block;
                }
            }
        }
    }
}
