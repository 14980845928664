@import 'account-form-variables';

.account-form {
    padding: $account-form__padding;
    width: $account-form__width;
    margin: $account-form__margin;
    @include mq($screen-m) {
        width: $account-form__width\@medium;
    }
    @include mq($screen-xl) {
        width: $account-form__width\@large;
    }

    &__info {
        max-width: $account-form__info-width;
        margin: $account-form__info-margin;
        font-size: $account-form__info-font-size;
        text-align: left;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $account-form__form-margin;

    }

    &__fieldset {
        width: $account-form__fieldset-width;
        margin: $account-form__fieldset-margin;

        @include mq($screen-m) {
            max-width: $account-form__fieldset-max-width\@medium;
        }
        &-head{
            width: $account-form__fieldset-width;
            margin: $account-form__fieldset-margin;
        }
        .field{
            margin-top: $spacer--medium;
            margin-bottom: $spacer
        }
        .input {
            margin-top: 15px;
            margin-bottom: 0;
        }
        .mage-error {
            margin-top: $spacer;
            margin-bottom: $spacer;
        }
        
        &.checkboxes{
            margin: 0;
        }

    }

    &__legend {
        margin: $account-form__legend-margin;
        padding: $account-form__legend-padding;
        width: $account-form__legend-width;
        font-family: $account-form__legend-font-family;
        font-size: $account-form__legend-font-size;
        font-weight: $account-form__legend-font-weight;
        text-transform: $account-form__legend-text-transform;
        text-align: center;

        &--hidden {
            @include visually-hidden();
        }
    }

    &__actions {
        display: block;
        text-align: left;
        width: $account-form__actions-width;
        margin: $account-form__actions-margin;

        @include mq($screen-m) {
            width: $account-form__actions-width\@medium;
        }

        &-extra {
            margin-top: $account-form__actions-extra-margin-top;
            text-align: $account-form__actions-extra-text-align;
            width: $account-form__actions-extra-width;
            span, a {
                display: $account-form__actions-extra-display;
                font-weight: $account-form__actions-extra-font-weight ;
            }
            .action__register {
                color: $account-form__actions-extra-action-color;
            }
        }
    }

    &__button {
        display: block;
        width: 100%;
        min-width: $account-form__button-min-width;
        @include mq($screen-m) {
            width: auto;
        }

    }

    &__forgot-password,
    &__link {
        margin: $account-form__forgot-password-margin;
        color: $account-form__forgot-password-color;
        font-weight: $account-form__forgot-password-font-weight;
        text-decoration: $account-form__forgot-password-text-decoration;
        line-height: $account-form__forgot-password-line-height;

        &.focus-visible,
        &:hover {
            text-decoration: $account-form__forgot-password-text-decoration-hover;
        }
    }

    &__validation-tips {
        width: 100%;
        text-align: left;
        margin: $account-form__validation-tips-margin;
        font-size: $account-form__validation-tips-font-size;
    }

}

.customer-account{
    &-create{
        .account-form {
            width: $account-form__width;
            margin: auto;
            @include mq($screen-m) {
                width: $account-form__width\@medium;
            }
            @include mq($screen-xl) {
                width: $account-form__width\@medium;
            }
            &-register{
                display: block !important;
                border: $account-form-nologin__form-border;
                border-radius: $account-form-nologin__form-border-radius;
                margin: $account-form-nologin__form-margin;
                padding: $account-form-nologin__form-padding;
                @include mq($screen-m) {
                    margin: $account-form-nologin__form-margin\@medium;
                    padding: $account-form-nologin__form-padding\@medium;
                }
            }
        }
    }

    &-forgotpassword{
        .heading--page{
            text-align: center;
            font-size: $font-size-super-extra-large\@screen-m;
        }

    }
}
