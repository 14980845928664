$footer__background-color                                  : $gray-lighter !default;
$footer__border                                            : $border-secondary !default;
$footer__border-width                                      : 1px 0 0 0 !default;
$footer__background-color--email                           : $white !default;

$footer__handler-padding                                   : $spacer--medium $spacer--semi-medium !default;
$footer__handler-padding\@medium                           : 0; //$spacer--medium $spacer--semi-large !default;
$footer__handler-padding\@large                            : $spacer--medium 0 !default;

$footer__newsletter-width\@large                           : 75% !default;
$footer__newsletter-margin                                 : 0 $spacer--medium !default;
$footer__newsletter-padding\@large                         : $spacer--medium $spacer--extra-large $spacer--extra-large $spacer--semi-medium !default;
$footer__newsletter-margin\@large                          : 0 !default;
$footer__newsletter-border                                 : $spacer--extra-small solid $gray-light !default;
$footer__newsletter-border-width                           : 0 0 $spacer--extra-small 0 !default;

$footer__link-font-size                                    : $font-size-medium !default;
$footer__link-color                                        : $bsm-green !default;
$footer__link-color-hover                                  : $bsm-green !default;
$footer__link-font-weight                                  : $font-weight-base !default;
$footer__link-margin                                       : 0 !default;
$footer__link-margin\@medium                               : 0 !default;

$footer__links-width                                       : 100% !default;
$footer__links-padding                                     : 0 !default;
$footer__links-padding\@large                              : 0 0 $spacer--large !default;
$footer__links-dropdown-background                         : $gray-lighter !default;
$footer__links-dropdown-padding\@medium                    :  0 !default;

$footer__links-label-background                            : $gray-lighter !default;
$footer__links-label-color                                 : $color-primary !default;
$footer__links-content-margin                              : 0 !default;

$footer__links-dropdown-list-margin                        : 0 0 $spacer--semi-medium !default;
$footer__links-dropdown-list-padding\@medium               : $spacer--large 0 0 !default;
$footer__links-dropdown-list-padding\@large                : $spacer--large $spacer--extra-large 0 !default;

$footer__links-dropdown-list-padding\@extra-large          : $spacer--large 97px 0 !default;
$footer__links-dropdown-list__list-padding\@extra-large    : $spacer--large 0 0 0 !default;

$footer__links-dropdown-list-border                        : $spacer--extra-small solid $gray-light !default;
$footer__links-dropdown-list-border-width                  : 0 0 $spacer--extra-small 0 !default;
$footer__links-dropdown-list-border-width\@large           : 0 !default;
$footer__links-dropdown-list-item-width                    : 100% !default;
$footer__links-dropdown-list-item-width-first-child\@medium: 50% !default;
$footer__links-dropdown-list-item-border                   : $border-base !default;
$footer__links-dropdown-icon-color                         : $color-secondary !default;
$footer__links-dropdown-icon-color-hover                   : $color-primary !default;
$footer__links-dropdown-icon-color-open                    : $color-primary !default;

$footer__links-list-item-min-width\@medium                 : 204px !default;
$footer__links-list-padding                                : 0 0 $spacer--medium 0 !default;

$footer__social-handler-margin                             : $spacer 0 !default;
$footer__social-handler-margin\@large                      : 0 !default;
$footer__social-handler-padding                            : 0 $spacer--medium !default;
$footer__social-handler-padding\@large                     : 0 ; //56px $spacer--large !default;

$footer__social-list-title-font-size                       : $font-size-large !default;
$footer__social-list-title-font-family                     : $font-family-base !default;
$footer__social-list-title-margin                          : 0 0 $spacer--medium 0 !default;
$footer__social-list-title-margin\@medium                  : 0 $spacer--large 0 0 !default;
$footer__social-list-title-margin\@large                   : 0 0 $spacer--large 0 !default;
$footer__social-list-title-font-weight                     : $font-weight-bold !default;
$footer__social-list-title-color                           : $color-primary !default;

$footer__bottom-bar-background                             : $color-primary !default;
$footer__bottom-bar-color                                  : $gray-lighter !default;
$footer__bottom-min-height                                 : 58px !default;
$footer__bottom-padding\@large                             : 0 !default; //0 97px 0 !default;

$footer__bottom-padding\@xl                                : 0 !default;
$footer__bottom-align                                      : center !default;

$footer__copywrite-padding                                 : $spacer 0 !default;
$footer__copywrite-padding\@medium                         : $spacer--medium 0 !default;
$footer__copywrite-font-size                               : $font-size-medium !default;
$footer__copywrite-color                                   : $gray-lighter !default;

$footer__payments-list-margin                              : 0 $spacer--semi-medium !default;
$footer__payments-list-margin\@medium                      : 0 0 0 $spacer--semi-medium !default;
$footer__payments-list-icon-width                          : 64px !default;
$footer__payments-list-icon-height                         : 48px !default;
$footer__payments-list-icon-width--visa                    : 68px !default;
$footer__payments-list-icon-width--paypal                  : 96px !default;
$footer__payments-list-icon-fill                           : $gray-lighter !default;

$footer__scroll-top-top                                    : -49px !default;
$footer__scroll-top-right                                  : 16px !default;
$footer__scroll-top-right\@medium                          : 15% !default;
$footer__scroll-top-bottom                                 : 30px !default;
$footer__scroll-top-before-transform                       : $button__before-transform-hover !default;
$footer__scroll-top-icon-fill                              : $white !default;
$footer__scroll-top-bg                                     : $bsm-green  !default;
$footer__scroll-top-bg-hover                               : $bsm-green  !default;

$footer__social-list__item-padding                         : 0 !default;
$footer__social-list__item-margin                          : 0 $spacer--medium 0 0 !default;
$footer__social-list__item-margin-last-child               : 0 !default;

$footer__social-list__icon-link-size                       : 24px !default;
$footer__social-list__icon-link-padding                    : 0 !default;
$footer__social-list__icon-link-margin\@large              : 0 0 $spacer !default;

$footer__switchers-padding                                 : $spacer 0 !default;
$footer__switchers-width\@small                            : 100% !default;
$footer__switchers-width\@xl                               : auto !default;
$footer__switchers-order                                   : 3 !default;

$footer__switcher-background-color                         : transparent !default;

$footer__switcher-label-background-color                   : $gray-darkest !default;
$footer__switcher-label-padding                            : $spacer--small $spacer--semi-large $spacer--small $spacer--small !default;
$footer__switcher-label-color                              : $white !default;

$footer__switcher-content-bottom                           : 100% !default;
$footer__switcher-content-width                            : 100% !default;
$footer__switcher-content-padding                          : $spacer--small $spacer--small 0 !default;

$footer__switcher-button-font-weight                       : $font-weight-normal !default;
$footer__switcher-button-min-height                        : 0 !default;
$footer__switcher-button-text-decoration                   : none !default;
$footer__switcher-button-hover-text-decoration             : underline !default;

// Footer Checkout variant
$footer__bottom-bar-handler-padding--checkout              : $spacer--semi-medium 0 0 0 !default;
$footer__bottom-bar-handler-padding--checkout\@medium      : 0 !default;
$footer__bottom-bar-handler-padding--checkout\@large       : 0 !default;
$footer__bottom-bar-handler-padding--checkout\@extra-large : 0 !default;

$footer__bottom-bar-handler-padding--p                     : $spacer--medium;
$footer__social-handler-font-size                          : 28px;
$footer__social-handler-font-size\@small                   : $font-size-medium;
$footer__social-handler-font-line-height                   : 42px;
$footer__links-dropdown-list-padding-logo                  : 0 $spacer--large + 4 0 $spacer--medium;
$footer__links-dropdown-list-padding-logo\@extra-large     : 0 $spacer--large + 4 0 0;
$footer__links-list-padding-small                          : $spacer + 2 0;
$footer__bottom-bar-handler-margin                         : 0 $spacer $spacer 0;

$footer__top-padding-large                                 : 0 ;//0 97px;
$footer__address-width                                     : 324px;
$footer__address-padding                                   : $spacer--large $spacer--medium;
$footer__address-padding\@medium                           : $spacer--extra-large $spacer--large + 4;
$footer__address-after-top                                 : $spacer--large - 2;
$footer__address-after-height                              : calc(100% - 60px);
$footer__address-logo-content                              : url("../images/footer/gray-logo.svg") !default;
$footer__address-margin                                    : 0 0 0 $spacer--semi-medium;
$footer__address-bottom-logo-content                       : url("../images/footer/ajuntament.svg") !default;
$footer__address-bottom-logo-content\@small                : url("../images/footer/ajuntament-mobile.svg");
