$product-review__action-border-bottom       : $border-base !default;
$product-review__action-padding             : 0 0 $spacer--extra-large 0 !default;
$product-review__reviews                    : $spacer--large 0 !default;
$product-review__reviews-review-margin      : 0 0 $spacer--large;
$product-review__reviews-review-border      : $border-base;
$product-review__reviews-review-border-width: 0 0 $border-width-base 0;

.product-review {
    &__action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: $product-review__action-border-bottom;
        padding: $product-review__action-padding;

        .review {
            &__summary-handle {
                flex-wrap: wrap;

                @include mq($screen-m) {
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    width: 100%;
                    max-width: none;
                }

                .review__rating {
                    margin-bottom: 0;
                }
            }

            &__summary-add {
                text-align: left;
                flex-grow: 0;

                @include mq($screen-m) {
                    flex-basis: auto;
                }

                &:focus {
                    @include focus-inline();
                }
            }
        }
    }

    &__write-btn {
        white-space: nowrap;
    }

    &__reviews {
        padding: $product-review__reviews;

        .review {
            margin: $product-review__reviews-review-margin;
            border: $product-review__reviews-review-border;
            border-width: $product-review__reviews-review-border-width;
        }
    }

    .list {
        &__item {
            &:last-child {
                .review {
                    margin-bottom: 0;
                }
            }
        }
    }
}
