$card-border            : $border-base !default;
$card-border-radius     : 10px !default;
$card-padding           : $spacer--medium !default;
$card-margin-bottom     : $spacer--medium !default;
$card-padding\@medium   : $spacer--medium !default;

$card__col-icon-padding             : $spacer--extra-small 0 0 0 !default;
$card__col-icon-padding\@medium     : $spacer--extra-small 0 0 0 !default;
$card__col-wrapper-padding          : 0 $spacer !default;
$card__col-wrapper-padding\@medium  : 0 0 0 $spacer--medium !default;

$card__col-links-a-color            : #637180 !default;
$card__col-links-a-font-size        : $spacer--medium !default;
$card__col-links-a-before-margin    : 0 $spacer--extra-small !default;

$card__col-qr-img-margin-left       : auto !default;
$card__col-qr-img-width             : $spacer--extra-large !default;
$card__col-qr-img-padding           : $spacer--extra-small !default;
$card__col-qr-img-border            : $border-base !default;


//Desgin one
$card-design-one-subtitle-font-weight         : $font-weight-medium-bold !default;
$card-design-one-subtitle-font-size           : $font-size-base !default;
$card-design-one-col-content-font-size        : $font-size-small !default;


//Desgin Two
$card-design-two-border-width                 : 0 0 1px 0 !default;
$card-design-two-font-size                    : $font-size-base !default;
$card-design-two-col-title-font-size          : $font-size-large !default;
$card-design-two-col-title-font-weight        : $font-weight-medium-bold !default;
$card-design-two-col-subtitle-font-size       : $font-size-medium !default;
$card-design-two-col-subtitle-font-weight     : $font-weight-medium-bold !default;

$card-design-two-col-content-padding          : 0 $spacer !default;
$card-design-two-col-content-padding\@medium  : 0 $spacer--medium !default;


//Desgin Three
$card-design-three-font-size                    : $font-size-base !default;
$card-design-three-font-weight                  : $font-weight-normal !default;
$card-design-three-col-title-font-size          : $font-size-base !default;
$card-design-three-col-title-font-weight        : $font-weight-medium-bold !default;

$card-design-three-col-sub-content-margin       : 10px 0 !default;


//Desgin --accordion

$--accordion-li-border-bottom                   : $card-border;
$--accordion-li-margin-bottom                   : 8px;
$--accordion-li-padding                         : 16px 0 24px 0;

$--accordion-wrapper-color                      : #000;
$--accordion-wrapper-font-size                  : 18px;
$--accordion-wrapper-letter-spacing             : 1px;
$--accordion-wrapper-line-height                : 26px;
$--accordion-wrapper-max-height                 : 0;
$--accordion-wrapper-opacity                    : 0;
$--accordion-wrapper-overflow                   : hidden;
$--accordion-wrapper-position                   : relative;
$--accordion-wrapper-transform                  : translate( 0 , 0 );
$--accordion-wrapper-width                      : 100%;

$--accordion-wrapper-margin-top                 : 16px;
$--accordion-wrapper-checked-margin-top         : 46px;

$--accordion-action-font-size                   : 16px;
$--accordion-action-float                       : right;
$--accordion-action-font-weight                 : 700;
$--accordion-action-justify-content             : flex-end;


$--accordion-action-more-color                  : $battleship-grey;
$--accordion-action-less-color                  : $color-primary;


$--accordion-input-height                       : 25px;
$--accordion-input-opacity                      : 0;
$--accordion-input-right                        : 0;
$--accordion-input-width                        : 20%;

$--accordion-input-checked-opacity              : 1;

$--accordion-card-title-font-size               : $font-size-base;
$--accordion-card-title-font-weight             : $font-weight-medium-bold;
$--accordion-card-title-float                   : left;
