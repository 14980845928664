$header__border                               : $border-base !default;
$header__border-width                         : 0 0 $border-width-base 0 !default;
$header__border-color                         : $lighter-gray !default;
$header__border\@large                        : 0 !default;
$header__border-width\@large                  : 0 !default;
$header__background                           : $white !default;
$header__background--email                    : $white !default;

$header__margin\@large                        : 0 0 $spacer--extra-small 0 !default;
$header__padding\@large                       : 0 0 $spacer--extra-small 0 !default;

$header__icons--fill                          : $black !default;

$header__wrapper-padding                      : 0 !default; //0 0 $spacer !default;
$header__wrapper-padding\@medium              : $spacer--extra-small 0 !default; //$spacer--medium 0 !default;
$header__wrapper-padding\@large               : $spacer--extra-small 0 !default; //$spacer--medium 0 !default;

$header__logo-padding                         : $spacer--small 0 $spacer--small $spacer !default;
$header__logo-padding\@medium                 : 0 !default;
$header__logo-margin-right                    : $spacer !default;
$header__logo-margin-right\@large             : $spacer--medium !default;
$header__logo-order\@medium                   : 1 !default;

$header__buttons-width                        : 100% !default; //calc(100% - #{$logo__max-width}) !default;
$header__buttons-width\@medium                : 100% !default; //calc(2 * 48px + 1 * #{$spacer}) !default;
$header__buttons-order\@medium                : 3 !default;
$header__buttons-width\@extra-large           : 100% !default; //calc(2 * 48px + 1 * #{$spacer--medium}) !default;

$header__search-wrapper-z-index               : $z-index-high + 1 !default;
$header__search-wrapper-padding               : 0 0 0 $spacer !default;
$header__search-wrapper-padding\@medium       : 0 24px !default;
$header__search-wrapper-padding\@large        : 0 $spacer--medium !default;
$header__search-wrapper-padding\@extra-large  : 0 $spacer--large !default;
$header__search-wrapper-flex-grow             : 1 !default;
$header__search-wrapper-flex-basis            : 50% !default;
$header__search-wrapper-order                 : 3 !default;
$header__search-wrapper-order\@medium         : 2 !default;
$header__search-wrapper-max-width\@large      : 350px !default;
$header__search-wrapper-max-width\@extra-large: 600px !default;

$header__minicart-border\@medium              : $border-base !default;
$header__minicart-border-width\@medium        : 0 0 0 0px !default;
$header__minicart-padding-left                : 0 !default;
$header__minicart-padding-left\@medium        : $spacer !default;
$header__minicart-modal-z-index               : 101 !default;

$header__button-wishlist-margin-right         : 0 !default;
$header__button-wishlist-margin-right\@medium : $spacer !default;

$header__brief-info-display                   : none !default;
$header__brief-info-display\@large            : flex !default;
$header__brief-info-min-width                 : 220px !default;
$header__brief-info-order                     : 3 !default;
$header__brief-info-subtitle-font-size        : $font-size-small !default;

// Buttons
$header-button__counter-top                   : 0px !default;
$header-button__counter-right                 : -15px !default;
$header-button__counter-font-size             : $font-size-small !default;
$header-button__counter-background            : $color-primary !default;
$header-button__counter-color                 : $white !default;
$header-button__counter-size                  : 24px !default;
$header-button__counter-border                : none !default;
$header-button__counter-z-index               : $z-index-low !default;

$header-button__counter-dot-size              : $spacer !default;
$header-button__counter-dot-bg-color          : $color-error !default;

// Search
$search-form__position                        : static !default;
$search-form__position\@medium                : static !default;
$search-form__width                           : 100% !default;
$search-form__alignment                       : flex-end !default;
$search-form__margin                          : 0 !default;

$search-form__button-margin                   : 0 !default;
$search-form__button-top                      : 1px !default;
$search-form__button-right                    : 0 !default;
$search-form__button-border                   : $border-base !default;
$search-form__button-border-width             : 0 1px !default;
$search-form__button-border--focus            : $color-focus-inline !default;
$search-form__button-height                   : 46px !default;
$search-form__button-background--disabled     : none !default;
$search-form__button-icon-fill--disabled      : $color-primary !default;
$search-form__button-transition               : none !default;

// Checkout variant
$header__wrapper-padding--checkout            : 0 !default;
$header__wrapper-padding--checkout\@medium    : $spacer--medium 0 !default;


// header dropdown customer menu
$header__dropdown-customer-content-position   : absolute !default;
$header__dropdown-customer-content-width      : 100% !default;
$header__dropdown-customer-content-bg         : $white !default;
$header__dropdown-customer-content-shadow     : $shadow !default;
$header__dropdown-customer-content-border     : 10px !default;
$header__dropdown-customer-content-margin     : 2px 0 5px 0 !default;
$header__dropdown-customer-content-top        : 100% !default;
$header__dropdown-customer-li-border          : $border-base !default;
$header__dropdown-customer-li-padding\@medium : $spacer $spacer--small !default;
$header__dropdown-customer-li-padding         : $spacer $spacer--large !default;

$header__dropdown-customer-a-font-weight      : $font-weight-bold !default;
$header__dropdown-customer-a-hover-color      : $color-primary !default;


$header-account-links-initial-padding         : $spacer--extra-small !default;
$header-account-links-initial-radius          : 100px !default;
$header-account-links-initial-min-width       : 35px !default;
$header-account-links-initial-color           : $white !default;
$header-account-links-initial-bg              : $dark-teal !default;

$header-account-links-mobile-boder\@medium    :  0px solid $bg-color-secondary;
$header-account-links-mobile-boder            :  1px solid $bg-color-secondary;

$mega-menu__link-margin                       : 0 $spacer--large !default;
$header__dropdown-customer-a-icon-padding\@medium     : 0 $spacer--large 0 $spacer--extra-large !default;
$header__dropdown-customer-a-icon-padding             : 0 $spacer--large !default;

$header-login__icon-bg-image                  : svg-uri('<svg width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg"><path d="M16 14.483a5 5 0 0 1 5 5v2a1 1 0 0 1-2 0v-2a3 3 0 0 0-3-3H8a3 3 0 0 0-3 3v2a1 1 0 0 1-2 0v-2a5 5 0 0 1 5-5zm-4-12a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6z" fill-rule="evenodd"/></svg>') !default;



//header externo BCN
$bcnbrand-main-max-width                      : $max-content-width;
