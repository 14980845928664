@import 'sales-variables';

.sales-order {

    &-history {

        .container-select {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            text-align: right;
            span.label {
                white-space: nowrap;
            }
            .select2 {
                width: auto;
                &-selection {
                    &__arrow,
                    &--single {
                        border: 0px solid;
                        height: 22px;
                    }
                    &__rendered {
                        line-height: 1.7;
                        padding: 0 48px 0 10px;
                    }
                }

                &-container {
                    .select2-container--open {
                        .select2-selection--single {
                            border: 0px solid;
                        }
                    }
                }
            }
        }
    }
    
}