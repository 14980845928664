@import 'marketing-bar-variables';

.marketing-bar {
    position: relative;
    display: flex;
    align-items: center;
    padding: $marketing-bar__padding;
    min-height: $marketing-bar__min-height;
    background-color: $marketing-bar__background-color;
    overflow: hidden;

    &--closed {
        display: none;
    }

    &__container {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: $marketing-bar__container-max-width;
        margin: $marketing-bar__container-margin;

        @include mq($screen-m) {
            justify-content: center;
        }
    }

    &__text {
        width: $marketing-bar__text-width;
        padding: $marketing-bar__text-padding;
    }

    &__text,
    &__text p {
        font-size: $marketing-bar__text-font-size;
        font-weight: $marketing-bar__text-font-weight;
        line-height: $marketing-bar__text-line-height;
        color: $marketing-bar__text-font-color;
        text-transform: $marketing-bar__text-transform;
        margin: 0;

        @include mq($screen-m) {
            text-align: center;
            font-size: $marketing-bar__text-font-size\@medium;
            line-height: $marketing-bar__text-line-height\@medium;
        }
    }

    &__close {
        position: $marketing-bar__button-position;
        right: $marketing-bar__button-right;
        min-height: $marketing-bar__button-size;
        background-color: $marketing-bar__button-background-color;

        &:hover {
            .marketing-bar__close-icon {
                fill: $marketing-bar__button-icon-fill--hover;
            }
        }

        .marketing-bar__close-icon {
            fill: $marketing-bar__button-icon-fill;
        }
    }
}
