@import 'header-variables';
@import 'button';
@import 'search-form';
.header {
    position: relative;
    border: $header__border;
    border-width: $header__border-width;
    border-color: $header__border-color;
    background: $header__background;
    padding: 0;
    margin: 0;

    width: 100%;

    @include mq($screen-l) {
       // border: none;
        padding: $header__padding\@large ;
        margin: $header__margin\@large ;
    }

    &--is-sticky{
        position: fixed;
        top: 0;
        z-index: 9999;
    }

    .modal-trigger{
        .icon{
            fill: $header__icons--fill;
        }
    }

    &--checkout {
        position: static;

        .header__wrapper {
            padding: $header__wrapper-padding--checkout;

            @include mq($screen-m) {
                padding: $header__wrapper-padding--checkout\@medium;
            }
        }

        .header__minicart {
            border: none;
        }

        @include mq($screen-l) {
            border: $header__border;
            border-width: $header__border-width;
        }
    }

    &--email {
        background-color: $header__background--email;
    }

    &__wrapper {
        position: relative;
        justify-content: space-between;
        display: flex;
        flex-flow: row wrap;
        padding: $header__wrapper-padding;

        @include mq($screen-m) {
            flex-wrap: nowrap;
            padding: $header__wrapper-padding\@medium;
        }

        @include mq($screen-l) {
            border: $header__border\@large;
            border-width: $header__border-width\@large;
            padding: $header__wrapper-padding\@large;
        }

        .block-left,
        .block-right{
            justify-content: space-between;
            display: flex;
            align-content: center;
            align-items: center;
            padding: 0;
        }
        //.block-right{
        //    position: relative;
        //    @include mq($max-screen: $screen-l - 5) {
        //        left: -$spacer--large;
        //    }
        //}
    }

    &__logo {
        padding: $header__logo-padding;
        margin-right: $header__logo-margin-right;
        @include mq($screen-m) {
            order: $header__logo-order\@medium;
            padding: $header__logo-padding\@medium;
        }
        @include mq($screen-l) {
            margin-right: $header__logo-margin-right\@large;
        }
    }

    &__search-wrapper {
        display: flex;
        flex-flow: column nowrap;
        flex-grow: $header__search-wrapper-flex-grow;
        flex-basis: $header__search-wrapper-flex-basis;
        order: $header__search-wrapper-order;
        justify-content: center;
        padding: $header__search-wrapper-padding;
        z-index: $header__search-wrapper-z-index;

        @include mq($screen-m) {
            order: $header__search-wrapper-order\@medium;
            flex-direction: column;
            justify-content: center;
            padding: $header__search-wrapper-padding\@medium;
        }

        @include mq($screen-l) {
            padding: $header__search-wrapper-padding\@large;
            max-width: $header__search-wrapper-max-width\@large;
        }

        @include mq($screen-xl) {
            padding: $header__search-wrapper-padding\@extra-large;
            max-width: $header__search-wrapper-max-width\@extra-large;
        }
    }

    &__minicart {
        position: relative;
        padding-left: $header__minicart-padding-left;

        @include mq($screen-m) {
            border: $header__minicart-border\@medium;
            border-width: $header__minicart-border-width\@medium;
            padding-left: $header__minicart-padding-left\@medium;
        }
    }

    &__minicart-modal {
        z-index: $header__minicart-modal-z-index; // to cover paypal button iframe
    }

    .button--icon-fill {
        margin-right: $header__button-wishlist-margin-right;

        @include mq($screen-m) {
            margin-right: $header__button-wishlist-margin-right\@medium;
        }
    }

    &__buttons {
        display: flex;
        flex: 1 $header__buttons-width;
        max-width: $header__buttons-width;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;

        @include mq($screen-m) {
            order: $header__buttons-order\@medium;
            flex-wrap: nowrap;
            flex: 1 $header__buttons-width\@medium;
            max-width: $header__buttons-width\@medium;
        }

        @include mq($screen-xl) {
            flex: 1 $header__buttons-width\@extra-large;
            max-width: $header__buttons-width\@extra-large;
        }
        .mega-menu{
            order: 1;
        }

        .account-links{
            order: 2;
        }
        .header__brief-info,
        .header__brief-faqs{
            order: 3;
            margin: $mega-menu__link-margin ; //se reusa la variable de los margenes del megamenú
            display: none;
            @include mq($screen-l) {
                display: flex;
            }
        }
        [data-block="minicart"]{
            order: 4;
            .button--icon{
                min-height: auto;
            }

        }
    }

    &__brief-info {
        display: $header__brief-info-display;
        align-items: center;
        order: $header__brief-info-order;
        min-width: $header__brief-info-min-width;

        @include mq($screen-l) {
            display: $header__brief-info-display\@large;
        }

        .brief-info__subtitle {
            font-size: $header__brief-info-subtitle-font-size;
        }
    }
    .account-links-mobile{
        border-bottom: $header-account-links-mobile-boder;
        @include mq($screen-m) {
            border-bottom: $header-account-links-mobile-boder\@medium;
        }

    }
    .account-links-mobile,
    .account-links{
        .customer-welcome{
            padding: 0;
        }
        >.authorization-link {
            a {
                position: relative;
                height: 56px;
                width: 100%;
                display: flex;
                align-items: center;
                border-radius: 20px;
                padding: $header__dropdown-customer-a-icon-padding ; //se reusa la variable de los margenes del megamenú
                @include mq($screen-m) {
                    left: 20px;
                    padding: $header__dropdown-customer-a-icon-padding\@medium;
                }
                &:hover{
                    background-color: $light-gray;
                    color: $header__dropdown-customer-a-hover-color;
                    &::before{
                        filter: none;/*invert(31%) sepia(86%) saturate(1308%) hue-rotate(357deg) brightness(103%) contrast(102%);*/
                    }
                }
                &::before{
                    content: ' ' ;
                    background-image: $header-login__icon-bg-image;
                    position: absolute;
                    height: 24px;
                    width: 24px;
                    left: 0;
                    transition: all 0.2s ease-in-out;
                    @include mq($screen-m) {
                        left: 20px;
                    }
                }

            }
        }
        .authorization-link,
        .authorization-link a,
        .logged-in{
            font-weight: $header__dropdown-customer-a-font-weight;
            text-decoration: unset;
        }

        #customer-menu{
            .dropdown-list{
                &__list{
                    width: 100%;
                    @include mq($screen-l) {
                       width: auto;
                    }
                }
                &__item--collapse{
                    .dropdown-list__label{
                        padding: 0;
                        background-color: transparent;
                        border-radius: 20px;
                        width: 100%;
                        @include mq($screen-m) {
                            padding: $mega-menu__link-margin ; //se reusa la variable de los margenes del megamenú
                            width: auto;
                        }

                    }
                    &:hover{
                        .dropdown-list__label{
                            @include mq($screen-m) {
                                background-color: $light-gray;
                            }
                        }
                        .logged-in{
                            color: $header__dropdown-customer-a-hover-color;
                        }

                    }
                }
                &__icon-arrow{
                    display: flex;
                    @include mq($screen-m) {
                        display: none;
                    }
                }

            }

            #dropdown-customer-content{
                position: relative;
                width: $header__dropdown-customer-content-width;
                background-color: $header__dropdown-customer-content-bg;
                margin: $header__dropdown-customer-content-margin;
                border-radius: $header__dropdown-customer-content-border;
                top: $header__dropdown-customer-content-top;
                box-shadow: unset;
                z-index: 9;
                @include mq($screen-m) {
                    position: $header__dropdown-customer-content-position;
                    box-shadow: $header__dropdown-customer-content-shadow;
                }
                ul.list{
                   li{
                       border-bottom: unset;
                       padding: $header__dropdown-customer-li-padding;
                       @include mq($screen-m) {
                            border-bottom: $header__dropdown-customer-li-border;
                            padding: $header__dropdown-customer-li-padding\@medium;
                       }
                       &:hover{
                           text-decoration: unset;
                       }
                       &:last-child{
                           border-bottom: 0;
                       }
                       a{
                           font-weight: $header__dropdown-customer-a-font-weight;
                           &:hover{
                                color: $header__dropdown-customer-a-hover-color;
                                text-decoration: unset;
                            }
                       }
                   }
                }
            }
        }

    }
    .initials{
        display: inline-flex;
        justify-content: center;
        text-transform: uppercase;
        background-color: $header-account-links-initial-bg ;
        color: $header-account-links-initial-color ;
        padding: $header-account-links-initial-padding ;
        width: $header-account-links-initial-min-width;
        border-radius: $header-account-links-initial-radius ;
    }
    .customer-name{
        &:hover {
            .logged-in{
                color: $header__dropdown-customer-a-hover-color;
            }
        }

    }
}
.lac-notification-sticky {
    display: none;
    @include visually-hidden();
}
