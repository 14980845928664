$badge__padding        : 0 $spacer--medium !default;
$badge__margin         : 0 0 $spacer !default;
$badge__border-radius  : $border-radius !default;
$badge__color          : $white !default;
$badge__color--new     : $font-color-base !default;
$badge__background     : $bg-color-dark !default;
$badge__background--new: $white !default;
$badge__font-size      : $font-size-small !default;
$badge__font-family    : $font-family-base !default;
$badge__font-weight    : $font-weight-bold !default;
$badge__text-transform : uppercase !default;
$badge__line-height    : 16px !default;
$badge__justify-content: center !default;
