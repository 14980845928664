$article-item__margin                           : 0 0 $spacer--semi-medium !default;

//--single
$article-item__margin--single                   : 0 0 72px !default;
$article-item__margin--single\@large            : 0 !default;

//header
$article-item__header-margin                    : 0 0 $spacer--medium !default;

//header--single
$article-item__header-margin--single\@large     : 0 $spacer--medium $spacer--medium 0 !default;

//title
$article-item__title-font-size                  : $font-size-large !default;
$article-item__title-font-weight                : $font-weight-bold !default;
$article-item__title-margin                     : 0 0 $spacer !default;
$article-item__title-text-transform             : none !default;
$article-item__title-text-height                : 48px !default;

//title--single
$article-item__title-font-size--single          : $font-size-extra-large !default;
$article-item__title-font-size--single\@medium  : $font-size-super-extra-large !default;
$article-item__title-font-weight--single        : $font-weight-normal !default;
$article-item__title-margin--single             : 0 0 $spacer !default;
$article-item__title-text-transform--single     : uppercase !default;
$article-item__title-text-height--single        : 48px !default;
$article-item__title-text-height--single\@medium: 48px !default;
$article-item__title-letter-spacing\@medium     : 1px !default;

//subtitle
$article-item__subtitle-font-size               : $font-size-small !default;
$article-item__subtitle-line-height             : 16px !default;
$article-item__subtitle-font-weight             : $font-weight-normal !default;
$article-item__subtitle-margin                  : 0 !default;
$article-item__subtitle-text-transform          : none !default;

//media
$article-item__media-margin                     : 0 0 $spacer--semi-medium !default;
$article-item__media-margin\@large              : 0 !default;

//media--single
$article-item__media-margin--single             : 0 0 $spacer--extra-large !default;
$article-item__media-margin--single\@large      : 0 !default;

//body
$article-item__body-margin                      : 0 !default;
$article-item__body-justify-content\@large      : space-between !default;

//body--single
$article-item__body-padding\@xlarge             : 54px 96px 0 112px !default;

//content
$article-item__content-margin                   : 0 0 $spacer--medium !default;

//content--single
$article-item__content-margin--single           : 0 0 $spacer--extra-large !default;

//content text
$article-item__content-text-margin              : 0 !default;
$article-item__content-text-font-size           : $font-size-medium !default;
$article-item__content-text-line-height         : 2 !default;

//content text--single
$article-item__content-text-margin--single      : 0 0 $spacer--large !default;

//container--single
$article-item__container-margin--single\@large  : 0 $spacer--medium 0 0 !default;

//footer-link
$article-item__footer-link-font-size            : $font-size-base !default;
$article-item__footer-link-font-weight          : $font-weight-bold !default;
$article-item__footer-link-height               : 48px !default;

//links-list
$article-item__links-list-font-size             : $font-size-medium !default;
$article-item__links-list-height                : 48px !default;
$article-item__links-list-padding-right         : $spacer !default;
