.name-fieldset:not(.enterprise) {
    .legend {
        @include visually-hidden()
    }
}

.name-fieldset.enterprise {
    border-radius: $dashboard-items__item-border-radius;
    border: 1px solid $lighter-gray;
    background-color: $lightest-gray;
    padding: $spacer--medium $spacer;

    @include mq($max-screen: $screen-m) {
        margin-top: $spacer--medium;
    }

    .legend p {
        color: $black;
        font-family: $font-family-base;
        font-size: $font-size-base;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.fieldset.account-form__fieldset {
    .mage-error {
        margin-top: 0;
    }

    .select {
        margin-bottom: 0;
    }

    .name-fieldset.enterprise {
        margin-top: 20px;
    }

    &.checkboxes {
        margin-top: $spacer--medium;
        margin-bottom: $spacer--semi-medium;

        .checkbox__label + .mage-error {
            bottom: -20px;
        }

        .field-recaptcha {
            margin-top: $spacer--semi-medium;
        }
    }
}
@include mq($screen-m) {
    .enterprise-fieldset__document,
    .particular-fieldset__document,
    .lastname-fieldset {
        display: flex;
        justify-content: space-between;
        margin-right: -$spacer;
        margin-left: -$spacer;

        > div.input {
            margin-right: $spacer;
            margin-left: $spacer;
        }

        .mage-error {
            margin-top: 0;
        }
    }

    .enterprise-fieldset__document,
    .lastname-fieldset {
        > div.input {
            flex-basis: 50%;
        }
    }
    .particular-fieldset__document > div.input {
        flex-basis: 33.333%;
    }
}
