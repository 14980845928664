$company-structure__action-button-padding     : 0 $spacer--medium !default;

.company-structure {
    &__actions {
        .button {
            padding: $company-structure__action-button-padding;
            &._hidden {
                display: none;
            }
        }
    }
}
