$apple-pay-button__padding                 : $spacer 0 !default;
$apple-pay-button__margin                  : $spacer--medium 0 !default;
$apple-pay-button__width                   : 200px !default;
$apple-pay-button__min-height              : 48px !default;
$apple-pay-button__border                  : 1px solid $black !default;
$apple-pay-button__background-image        : -webkit-named-image(apple-pay-logo-black) !default;
$apple-pay-button__background-color        : $black !default;
$apple-pay-button__background-size         : 100% !default;
$apple-pay-button__background-repeat       : no-repeat !default;
$apple-pay-button__background-position     : 50% 50% !default;
$apple-pay-button__background-border-radius: $spacer--extra-small !default;
$apple-pay-button__background-transition   : background-color 0.3s !default;

.braintree-apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    display: block;
    padding: $apple-pay-button__padding;
    margin: $apple-pay-button__margin;
    width: $apple-pay-button__width;
    min-height: $apple-pay-button__min-height;
    border: $apple-pay-button__border;
    background-image: $apple-pay-button__background-image;
    background-size: $apple-pay-button__background-size;
    background-repeat: $apple-pay-button__background-repeat;
    background-color: $apple-pay-button__background-color;
    background-position: $apple-pay-button__background-position;
    border-radius: $apple-pay-button__background-border-radius;
    transition: $apple-pay-button__background-transition;
    cursor: pointer;
}
