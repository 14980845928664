@import 'teaser-variables';

.teaser {
    display: flex;
    flex-flow: row wrap;
    margin: $teaser__margin;
    background-color: $teaser__background-color;

    &--secondary {
        flex-direction: row-reverse;
    }

    @include mq($screen-m) {
        flex-wrap: nowrap;
        margin: $teaser__margin\@medium;
    }

    &__image {
        width: 100%;

        @include mq($screen-m) {
            width: 50%;
        }
    }

    &__content {
        display: flex;
        width: 100%;
        padding: $teaser__content-padding;

        @include mq($screen-m) {
            width: 50%;
            padding: $teaser__content-padding\@medium;
        }

        @include mq($screen-xl) {
            padding: $teaser__content-padding\@large;
        }
    }

    &__content-block {
        display: flex;
        align-items: center;
        height: 100%;
        background-color: $teaser__content-block-background-color;
    }
}
