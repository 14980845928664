$amgift-card__content-button-color--hover   : $color-primary !default;
$amgift-card__content-button-border--hover  : 2px solid $color-primary !default;
$amgift-card__image-padding                 : 0 $spacer--medium 0 0 !default;
$amgift-card__image-border                  : 2px $border-style-base $white !default;
$amgift-card__image-border-color--active    : $color-primary !default;
$amgift-card__container-margin              : 0 0 $spacer--medium 0 !default;

.amgift-card {
    &__content-button {
        // hover state for <input type="button"> element
        @at-root input#{&} {
            &:hover,
            &.focus-visible {
                color: $amgift-card__content-button-color--hover;
                border: $amgift-card__content-button-border--hover;
            }
        }
    }

    .amcard-image-container {
        padding: $amgift-card__image-padding;
    }

    .amcard-image {
        cursor: pointer;
        border: $amgift-card__image-border;

        &.-active {
            border-color: $amgift-card__image-border-color--active;
        }
    }

    .amcard-form-container.-interior {
        margin: $amgift-card__container-margin;
    }
}
