$active-filters__padding                    : 0 0 $spacer--semi-medium 0 !default;
$active-filters__padding\@large             : 0 0 64px 0 !default;
$active-filters__border                     : $border-base !default;
$active-filters__border-width               : 0 0 1px 0 !default;
$active-filters__border-width\@large        : 0 !default;

$active-filters__heading-font-family        : $font-family-base !default;
$active-filters__heading-font-weight        : $font-weight-bold !default;
$active-filters__headin-margin              : $spacer 0 $spacer--small !default;

$active-filters__remove-size                : 48px !default;
$active-filters__remove-margin              : 0 $spacer 0 0 !default;

$active-filters__item-padding               : 0 0 $spacer 0 !default;
$active-filters__description-width          : calc(100% - #{$active-filters__remove-size}) !default;

$active-filters__icon-fill                  : $red !default;
$active-filters__icon-fill--hover           : $gray-light !default;

$active-filters__label-color                : $color-primary !default;
$active-filters__label-font-weight          : $font-weight-bold !default;
$active-filters__label-text-transform       : uppercase !default;
$active-filters__label-margin               : 0 $spacer--extra-small 0 0 !default;

$active-filters__value-color                : $color-primary !default;
$active-filters__value-font-weight          : $font-weight-normal !default;
$active-filters__value-text-transform       : none !default;

$active-filters__clear-all-max-width\@medium: 300px !default;
