$quicksearch__mq-large                      : 950px !default;
$quicksearch__right\@medium                 : auto !default;
$quicksearch__width                         : 100% !default;
$quicksearch__width\@medium                 : 100% !default;
$quicksearch__width\@large                  : 880px !default;
$quicksearch__width\@xl                     : 880px !default;
$quicksearch__top                           : calc(100% + 1px) !default;
$quicksearch__top\@medium                   : calc(100% - #{$spacer--medium}) !default;
$quicksearch__left                          : 0 !default;
$quicksearch__left\@medium                  : 0 !default;
$quicksearch__left\@large                   : calc((100% - #{$quicksearch__width\@large}) / 2) !default;
$quicksearch__left\@xl                      : calc((100% - #{$quicksearch__width\@large}) / 2) !default;
$quicksearch__padding                       : 0 $spacer--medium !default;
$quicksearch__padding\@medium               : 0 $spacer--medium !default;
$quicksearch__padding\@large                : 0 $spacer--large !default;
$quicksearch__padding\@xl                   : 0 $spacer--large !default;
$quicksearch__background                    : $white !default;
$quicksearch__box-shadow                    : 0 $spacer $spacer--medium 0 rgba(0, 0, 0, 0.16) !default;

$quicksearch__wrapper-position              : static !default;

$quicksearch__results-padding               : $spacer--medium $spacer--extra-large $spacer--medium 0 !default;
$quicksearch__results-padding\@medium       : $spacer 0 !default;
$quicksearch__results-padding\@large        : $spacer 0 !default;
$quicksearch__results-border                : 1px solid $gray-light !default;

$quicksearch__empty-margin                  : $spacer 0 !default;

$quicksearch__results-heading-font-family   : $font-family-base !default;
$quicksearch__results-heading-font-size     : $font-size-medium !default;
$quicksearch__results-heading-font-weight   : $font-weight-normal !default;
$quicksearch__results-heading-text-transform: none !default;

$quicksearch__typed-min-height              : 48px !default;
$quicksearch__typed-font-weight             : $font-weight-bold !default;

$quicksearch__close-position                : absolute !default;
$quicksearch__close-right                   : $spacer--medium !default;
$quicksearch__close-right\@medium           : $spacer--medium !default;
$quicksearch__close-right\@large            : $spacer--medium !default;
$quicksearch__close-right\@xl               : $spacer--medium !default;
$quicksearch__close-top                     : $spacer--medium !default;
$quicksearch__close-top\@medium             : $spacer !default;
$quicksearch__close-top\@large              : $spacer--medium !default;
$quicksearch__close-top\@xl                 : $spacer--medium !default;
$quicksearch__close-fill                    : $color-primary !default;
$quicksearch__close-background              : transparent !default;

$quicksearch__content-padding               : $spacer 0 !default;

$quicksearch__list-margin                   : 0 !default;
$quicksearch__list-padding                  : 0 !default;
$quicksearch__sidebar-list-top-margin       : 0 0 $spacer--medium 0 !default;

$quicksearch__sidebar-item-padding          : $spacer--medium 0 !default;
$quicksearch__sidebar-link-font-size        : $font-size-base !default;
$quicksearch__sidebar-link-font-weight      : $font-weight-base !default;
$quicksearch__sidebar-link-color            : $color-primary !default;
$quicksearch__sidebar-title-font-family     : $font-family-base !default;
$quicksearch__sidebar-title-font-size       : $font-size-base !default;
$quicksearch__sidebar-title-font-weight     : $font-weight-bold !default;
$quicksearch__sidebar-title-text-transform  : none !default;

$quicksearch__sidebar-column-padding        : $spacer--large 0 0 0 !default;
$quicksearch__sidebar-column-padding\@medium: $spacer--semi-medium 0 0 $spacer--medium !default;
$quicksearch__sidebar-column-padding\@large : $spacer--semi-medium 0 0 $spacer--large !default;
$quicksearch__sidebar-column-padding\@xl    : $spacer--semi-medium 0 0 $spacer--large !default;

$quicksearch__product-border                : 1px solid $gray-light !default;
$quicksearch__product-padding               : $spacer--medium 0 !default;
$quicksearch__product-image-size            : 72px !default;
$quicksearch__product-image-margin-right    : $spacer !default;

$quicksearch__name-margin                   : 0 !default;
$quicksearch__name-padding                  : 0 0 $spacer !default;
$quicksearch__name-color                    : inherit !default;
$quicksearch__name-font-family              : $font-family-base !default;
$quicksearch__name-font-size                : $font-size-base !default;
$quicksearch__name-font-size\@medium        : $font-size-base !default;
$quicksearch__name-font-weight              : $font-weight-normal !default;
$quicksearch__name-text-decoration          : none !default;

$quicksearch__more-link-margin              : $spacer--medium 0 !default;
$quicksearch__more-link-margin\@large       : $spacer--medium 0 $spacer--large !default;
$quicksearch__more-link-border-top          : 1px solid $gray-light !default;
