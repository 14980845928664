@import 'auth-variables';

.auth {
    &__links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        @include mq($screen-m) {
            flex-direction: row;
            justify-content: center;
        }

        //&:first-of-type {
            .auth__link {
                border-width: 0 0 $border-width-base;
            }
        //}
    }

    &__link {
        position: relative;
        //justify-content: flex-start;
        justify-content: center;
        white-space: nowrap;

        width: $auth__link-width;
        height: $auth__link-height;
        border: $auth__link-border;
        border-width: $auth__link-border-width;
        color: $auth__link-color;
        font-family: $auth__link-font-family;
        font-size: $auth__link-font-size;
        font-weight: $auth__link-font-weight;
        text-decoration: $auth__link-text-decoration;
        text-transform: $auth__link-text-transform;
        padding: $auth__link-padding;
        @include mq($screen-m) {
            justify-content: center;
            width: $auth__link-width\@medium;
        }

        @include mq($screen-xl) {
            width: $auth__link-width\@large;
        }
        &:hover,
        &.focus-visible,
        &--active {
            color: $auth__link-color--active;
            text-decoration: $auth__link-text-decoration-active;
        }

        &--active {
            background: $auth__link-background--active;
            font-weight: $auth__link-font-weight--active;

            &:hover,
            &.focus-visible {
                background: $auth__link-background--active;
            }

            .auth__icon {
                transform: $auth__icon-transform--active;
            }
        }

        &--customer{
            &.auth__link--active{
                &:after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $bsm-green ;
                }
            }
        }
        &--registration {
            //display: none;

            //@include mq($screen-m) {
                display: flex;
            //}

            &-mobile {
                @include mq($screen-m) {
                    display: none;
                }
            }
        }
    }

    &__icon {
        position: absolute;
        right: $spacer--medium;
        top: 0;
        bottom: 0;
        margin: auto;
        fill: $color-secondary;

        @include mq($screen-m) {
            display: none;
        }
    }

    &__heading {
        margin: $auth__heading-margin;

        @include mq($screen-m) {
            margin: $auth__heading-margin\@medium;
        }
    }

    &__container {
        max-width: $auth__container-max-width;
        margin: $auth__container-margin;
    }

    &--registration {
        .auth__link {
            &--registration-mobile {
                display: none;
            }
        }
    }

    &__boxes {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        @include mq($screen-m) {
            flex-direction: row;
        }
    }
    &__box {
        border: $auth__box-border;
        border-radius: $auth__box-border-radius;
        padding: $auth__box-padding;
        margin: $auth__box-margin;
        width: $auth__box-width;
        @include mq($screen-m) {
            width: $auth__box-width\@medium;
        }
        .input {
            width: 100%;
        }

        .auth__heading {
            margin: 0;
        }
        .account-form {
            width: 100%;
        }

        &.--bg-lightest-blue {
            background-color: $auth__box--bg-lightest-blue-bg;
        }
    }
}
