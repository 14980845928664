@import "post-variables";
.blog-page {

    .sidebar.sidebar-additional {
        display: none;
    }
    .column.main {
        max-width: 100%;
    }
    &.blog-category {
        margin-top: $post-wrapper-margin;
        &-view {
            .heading--page {
                padding: 10px;
                margin: 0;
                @include mq($screen-m) {
                    padding: 30px 0 ;
                }
            }
            .category-content {
                padding: $post-category-content-padding;
            }
            .post-list-wrapper {
                &:not(.blog-widget-recent) {
                    padding: 0;
                }
                .post-list {
                    display: flex;
                    flex-direction: column;

                    .post-holder {
                        border: 0px solid;
                        border-radius: 0;
                        padding-bottom: 0;
                        margin-bottom: $spacer--medium;

                        .post-content {
                            display: flex;
                            flex-direction: column;
                            @include mq($screen-s) {
                                flex-direction: row;
                            }
                            .image {
                                position: relative;
                                width: 100%;
                                @include mq($screen-m) {
                                    width: 320px;
                                }
                                @include mq($screen-l) {
                                    width: 440px;
                                }
                            }
                            .post-read-more {
                                width: 100%;
                                @include mq($screen-m) {
                                    width: auto;
                                }

                            }

                        }
                    }
                }
            }
        }
    }
    &.blog-post-view {
        .post-view {
            display: flex;
            flex-direction: column;
            @include mq($screen-m) {
                flex-direction: row;
            }
            .post-bottom {
                .post-gallery {
                    display: none;
                }
            }
        }
        .post-holder {
            width: 100%;
            @include mq($screen-m) {
                width: 60%;
            }
        }
    }
    .post-bottom-header {
        width: 100%;
        @include mq($screen-m) {
            width: 40%;
        }
        .post-gallery {
            margin: 0 0 $spacer--medium 0;
            @include mq($screen-m) {
                margin: 0 $spacer--medium $spacer--medium 0;
            }
            .gallery-image-hld {
                border: 0px solid;
                float: unset;
                @include mq($max-screen: $screen-s) {
                    margin: 0 0 $spacer--medium 0;
                }
                .gallery-image {
                    width: 100%;
                }
            }

        }

        .post-nextprev-hld {
            display: none;
        }
    }
}
.post-list-wrapper {
    margin-top: $post-card-padding-extra-large;
    &:not(.blog-widget-recent) {
        padding: 10px;
        @include mq($screen-m) {
            padding: $post-card-list-wrapper-padding;
        }
    }
    &.blog-widget-recent {
        .post-list {
            .post-holder {
                &:not(.blog-widget-recent-masonry) {
                    width: 100%;
                }
            }
        }
    }

    h3 {
        padding: 10px;
        margin: 0;
        @extend .heading;
        @include mq($screen-m) {
            //margin: $post-category-heading-padding;
        }
    }
    &.card-list {
        .post-list {
            display: grid;
            grid-column: 3;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: $spacer--medium;
        }
    }
    .post-list {
        display: flex;
        flex-direction: column;
        // flex-flow: wrap;
        width: 100%;
        //justify-content: center;
        justify-content: space-between;
        //align-items: center;
        gap: $spacer--large ;
        @include mq($screen-m) {
            flex-direction: row;
        }
        &-wrapper_content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .view-other-news {
                @extend .button;
                @extend .button--secondary;
                width: $post-card-button-view-other-size;
                margin: $post-card-button-view-other-margin;
            }
        }
        .post-holder {
            width: 100%;
            border: $post-holder-border;
            border-radius: $post-border-radius;
            overflow: hidden;
            @include mq($screen-m) {
                //margin: 18px;
                margin: 0;
            }
            .post-wrapper {
                margin: 0;
                background: $post-card-wrapper-background;
                .post-content {
                    .item,
                    .post-title a,
                    .post-description p {
                        @extend .paragraph;
                        .value {
                            font-style: normal;
                        }
                    }
                    .item,
                    .post-title a {
                        color: $post-heading-color;
                    }
                    .post {
                        &-description,
                        &-header {
                            padding: 0 24px;
                            .post-info {
                                padding: 10px 0;
                            }
                        }
                        &-description {
                            display: block;
                        }
                    }
                    .post-read-more {
                        @extend .button;
                        float: none;
                        width: 100%;
                        margin: $post-read-more-margin;
                        &:hover {
                            //@extend .button--secondary;
                            color: $button__color--secondary;
                            background-color: $button__background--secondary;
                            border: $button__border--secondary;
                        }
                    }
                    .post-text-hld {
                        min-height: auto;
                    }
                    .image {
                        position: relative;
                        .share-icon {
                            position: absolute;
                            right: 0;
                            background: $post-card-wrapper-background;
                            z-index: 1;
                            i {
                                padding: 15px;
                                color: $post-card-icon-color-background;
                            }
                        }
                    }
                }
            }
        }
    }
    // }
}

.toolbar {
    &-blog-posts {
        .pager {
            display: block;
        }
        border: none;
    }
}
.blog-page.blog-page-list {
    .column.main {
        order: 1;
    }
}
