@import 'swatch-variables';

.swatch {
    display: flex;
    flex-wrap: wrap;
    margin: $swatch__margin;

    &[class*="size"] {
        .swatch__option {
            border: $swatch__option-border--size;
        }
    }

    &__container {
        // magento container for loading
        position: relative;
        min-height: $swatch__container-min-height;

        &--catalog {
            width: 100%;
            min-height: $swatch__container-min-height--catalog;

            .swatch {
                margin: $swatch__margin--catalog;

                &__option-container {
                    padding: $swatch__option-padding--catalog;
                    margin: $swatch__option-margin--catalog;

                    &:hover,
                    &:focus,
                    &.selected {
                        .swatch__option--white {
                            border: $swatch__option-border--white-catalog;
                        }
                    }
                }

                &__option {
                    min-width: $swatch__option-width--catalog;
                    min-height: $swatch__option-height--catalog;

                    &--white {
                        border: $swatch__option-border--white-catalog;
                    }
                }
            }

        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: $swatch__wrapper-justify;
    }

    &__option-container {
        box-sizing: border-box;
        border: $swatch__option-border;
        border-width: $swatch__option-border-width;
        margin: $swatch__option-margin;
        padding: $swatch__option-padding;
        transition: $swatch__transition;
        cursor: pointer;

        &:hover,
        &:focus,
        &.selected {
            outline: none;
            border-color: $swatch__option-border-color--active;

            .swatch__option--white {
                border: 0;
            }
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__selected-option {
        display: none;
    }

    &__title {
        flex: 0 0 100%;
        margin: $swatch__title-margin;
        font-size: $swatch__title-font-size;
        color: $swatch__title-color;
    }

    &__option {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: $swatch__option-width;
        min-height: $swatch__option-height;
        background-color: $swatch__option-background;
        color: $swatch__option-color;

        &--image {
            min-height: $swatch__option-height--image;
            min-width: $swatch__option-width--image;
            background-size: cover;
            background-position: top center;
        }

        &--white {
            border: $swatch__option-border--white;
        }
    }

    &__input {
        @include visually-hidden;
    }
}
