@import 'button-variables';

.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    min-height: $button__min-size;
    min-width: $button__min-size;
    padding: $button__padding;

    background-color: $button__background;
    border: $button__border;
    border-radius: $button__border-radius;
    outline: $button__outline;

    font-family: $button__font-family;
    font-size: $button__font-size;
    font-weight: $button__font-weight;
    line-height: $button__line-height;
    color: $button__text-color;

    cursor: pointer;

    text-transform: $button__text-transform;
    text-decoration: $button__text-decoration;
    letter-spacing: $button__letter-spacing;
    transform: $button__transform;
    transition: $button__transition;

    .icon,
    [class*="fa-"],
    .button__icon {
        width: $button__icon-size;
        height: $button__icon-size;
        padding: $icon-padding;
        fill: $button__text-color;
        transition: $button__icon-transition;
        z-index: $button__icon-z-index;
        pointer-events: none;
    }

    .button__text {
        margin: $button__text-margin--icon;
        color: $button__text-color;
        font-weight: $button__font-weight--icon;
        text-transform: $button__text-transform--icon;
        transition: $button__transition;
        z-index: $button__text-z-index;
        pointer-events: none;
    }

    &:hover,
    &:active,
    &.focus-visible {
        background-color: $button__background-hover;
        color: $button__text-color-hover;
        text-decoration: $button__text-decoration-hover;
        border: $button__border-hover;

        &:before {
            display: $button__before-display-hover;
            transform: $button__before-transform-hover;
            z-index: $button__before-z-index-hover;
        }

        &:after {
            display: $button__after-display-hover;
            left: $button__after-position-left-hover;
            opacity: $button__after-opacity-hover;
        }
    }

    &:active {
        background-color: $button__background-active;
    }

    &:disabled,
    &[disabled] {
        background-color: $button__background-disabled;
        border-color: $button__background-disabled;
        color: $button__color-disabled;
        cursor: not-allowed;

        .icon,
        [class*="fa-"] {
            fill: $button__text-color;
            color: $button__text-color;
        }

        &:hover,
        &:active,
        &.focus-visible {
            background-color: $button__background-disabled;

            &:before,
            &:after {
                display: none;
            }
        }
        .button__text {
            color: $button__color-disabled;
        }
    }

    &--light {
        text-transform: $button__text-transform--light;
        font-weight: $button__font-weight--light;
        text-transform: $button__text-transform--light;
    }

    &--secondary {
        color: $button__color--secondary;
        background-color: $button__background--secondary;
        border: $button__border--secondary;

        .icon,
        .button__icon {
            fill: $button__color--secondary;
        }

        [class*="fa-"],
        .button__text {
            color: $button__color--secondary;
        }

        &:hover,
        &:active,
        &.focus-visible {
            color: $button__color-hover--secondary;
            background-color: $button__background-hover--secondary;
            &:before {
                z-index: $button__before-z-index-hover--secondary;
            }
            &:after {
                display: $button__after-display-hover--secondary;
            }
            .icon,
            .button__icon {
                fill: $button__color-hover--secondary;
                color: $button__color-hover--secondary;
            }

            [class*="fa-"],
            .button__text {
                color:  $button__color-hover--secondary;
            }
        }

        &:active {
            background-color: $button__background-active--secondary;
        }

        &.action {
            &:hover,
            &.focus-visible {
                &:after {
                    display: $button__after-display-hover--secondary;
                }
            }
        }
    }

    &--subtitle {
        @extend .button--secondary;
        color: $button__color--subtitle;
        background: $button__background--subtitle;
        border: $button__border--subtitle;
    }

    &--secondary-gray {
        @extend .button--secondary;
        color: $button__color--secondary-gray;
        background: $button__background--subtitle;
        border: $button__border--secondary-gray;
        &.active,
        &:hover,
        &:active,
        &.focus-visible {
            color: $button__color--subtitle;
            background: $button__background--subtitle;
            border: $button__border--secondary;
        }
    }

    &--secondary-light-gray {
        @extend .button--secondary;
        @extend .button--light;
        border: $button__border--secondary-gray;
    }

    &--secondary-light {
        @extend .button--secondary;
        @extend .button--light;
    }

    &--fluid {
        width: 100%;
    }

    &--link {
        color: $button__color--link;
        background: $button__background--link;
        text-transform: $button__text-transform--link;
        text-decoration: $button__text-decoration--link;

        &:hover,
        &:active,
        &.focus-visible {
            color: $button__color-hover--link;
            background: $button__background-hover--link;
            &:before {
                display: $button__before-display-hover--link;
            }
            &:after {
                display: $button__after-display-hover--link;
            }
        }

        &:active {
            color: $button__color-active--link;
        }
        &-gray {
            color: $button__color--secondary-gray;
        }
        &-no-decoration {
            text-decoration: none;
        }
    }

    &--icon {
        min-width: $button__min-size;
        min-height: $button__min-size;
        padding: $button__padding--icon;
        background-color: $button__bg--icon;
        border: $button__border--icon;

        .icon,
        .button__icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            fill: $button__icon-fill;
        }

        [class*="fa-"],
        .button__text {
            color: $button__text-color--icon;
        }

        &:hover,
        &:active,
        &.focus-visible {
            background-color: $button__background-hover--icon;
            border: $button__border-hover--icon;

            &:after {
                display: none;
            }
            .icon,
            .button__icon {
                fill: $button__text-color-hover--icon;
            }
            [class*="fa-"],
            .button__text {
                color: $button__text-color-hover--icon;
            }
        }

        &:active {
            background-color: $button__background-active--icon;
        }
    }

    &--icon-light {
        &:before {
            background-color: $button__before-background--icon-light;
        }
        &:hover,
        &.focus-visible {
            background-color: $button__background-hover--icon-light;
            .icon,
            .button__icon {
                fill: $button__icon-fill-hover--icon-light;
            }
            [class*="fa-"],
            .button__text {
                color: $button__icon-fill-hover--icon-light;
            }
        }
    }

    &--icon-border {
        border: $button__border--icon-border;
        background-color: $button__background--icon-border;

        &:hover,
        &.focus-visible {
            background-color: $button__background-hover--icon-border;
            border: $button__border-hover--icon-border;
        }
    }

    &--rotate-icon {
        &:before {
            background-color: $button__before-background--rotate-icon;
        }
        &:hover,
        &.focus-visible {
            background-color: $button__background-hover--rotate-icon;
            &:after {
                display: none;
            }

            .icon {
                fill: $button__icon-fill-hover--rotate-icon;
                transform: $button__transform-hover--rotate-icon;
            }
        }
    }

    &--icon-fill {
        background: $button__background--icon-fill;

        .icon,
        .button__icon {
            fill: $button__icon-fill--icon-fill;
        }

        [class*="fa-"],
        .button__text {
            color: $button__text-color--icon--icon-fill;
        }

        &:before {
            background-color: $button__before-background--icon-fill;
            z-index: $button__icon-after-z-index--icon-fill;
        }

        &:hover,
        &:active,
        &.focus-visible {
            @extend .button--icon-filled;
            background-color: $button__background-hover--icon-fill;

            .icon,
            .button__icon {
                fill: $button__text-color-hover--icon--icon-fill;
            }
            [class*="fa-"],
            .button__text {
                color: $button__text-color-hover--icon--icon-fill;
            }
        }
    }

    &--icon-filled {
        .icon {
            fill: $button__icon-fill-hover--icon-fill;
        }
        &:after {
            display: block;
            background-position: $button__icon-after-bg-position-hover--icon-fill;
        }
    }

    &--add-to {
        overflow: hidden;
        @include add-to-button(block);

        &:before {
            display: $button__before-display--add-to;
        }

        &:after {
            display: $button__after-display--add-to;
            top: $button__after-position-top--add-to;
            left: $button__after-position-left--add-to;
            transform: $button__after-transform--add-to;
        }

        &:hover,
        &.focus-visible {
            background-color: $button__background-hover--add-to;

            &:before {
                display: $button__before-display-hover--add-to;
            }

            &:after {
                display: $button__after-display-hover--add-to;
                transform: $button__after-transform-hover--add-to;
            }
        }

        &:active {
            background-color: $button__background-active;
        }

        .button__icon {
            fill: $button__icon-fill--add-to;
        }

        @include mq($screen-m) {
            @include add-to-button(none);
        }

        @include mq($screen-l) {
            &:after {
                display: $button__after-display--add-to\@large;
                @if $button__after-display--add-to\@large == block {
                    transform: $button__after-transform-hover--add-to;
                }
            }

            &:hover,
            &.focus-visible {
                &:before {
                    display: $button__before-display-hover--add-to\@large;
                }

                &:after {
                    display: $button__after-display-hover--add-to\@large;
                }
            }
        }
    }
    &.disable-btn {
        pointer-events: none;
    }
}

//use .button-extend for wrappers that contains buttons without .button class that should have .button styles
.button-extend {
    [type="button"],
    [type="submit"] {
        @extend .button;
    }
}

// botón configuración cookies
.ot-sdk-show-settings {
    transform: rotate(270deg);
    transform-origin: bottom right;
    color: $white !important;
    border: 1px solid $white !important;
    background: $color-secondary;
    z-index: 2365;
    position: fixed;
    right: 0;
    bottom: 40%;
    overflow-x: hidden;
    overflow-y: auto;
    &:hover {
        color: $color-primary !important;
        border-color: $color-primary !important;
        background-color: $white !important;
    }
}
