$scrollbar__size               : $spacer !default;
$scrollbar__bg-color           : $gray-light !default;
$scrollbar__thumb-color        : $color-primary !default;
$scrollbar__thumb-border-radius: $spacer--extra-small !default;

::-webkit-scrollbar { // sass-lint:disable-line no-vendor-prefixes
    width: $scrollbar__size;
    height: $scrollbar__size;
    background-color: $scrollbar__bg-color;
    -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
}

::-webkit-scrollbar-thumb { // sass-lint:disable-line no-vendor-prefixes
    background: $scrollbar__thumb-color;
    border-radius: $scrollbar__thumb-border-radius;
}
