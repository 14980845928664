@import 'gift-options-variables';

.gift-options {
    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: $gift-options__list-max-width;
        list-style: none;
    }

    &__item {
        max-width: $gift-options__item-max-width;
        width: $gift-options__item-width;

        &.active {
            transform: $gift-options__item-active-transform;
        }
    }

    &__preview {
        max-width: $gift-options__preview-max-width;
    }

    &__details {
        border-bottom: $gift-options__details-border;
    }

    &__item-name {
        display: block;
        font-size: $gift-options__item-name-font-size;
        margin: $gift-options__item-name-margin;
    }

    .gift-wrapping {
        img {
            max-width: $gift-options__item-max-width;
        }
    }
}
