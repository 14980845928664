@import "cart-variables";

.cart-discount {
    flex-basis: 100%;
    margin: $cart-discount__margin;
    padding: $cart-discount__padding;

    .cart-discount__button-icon {
        display: block;
        fill: $cart-discount__button-bg-color;
        &.active {
            display: none;
        }
    }
    &.active {
        .cart-discount__button-icon {
            display: none;
            &.active {
                display: block;
            }
        }

        .cart-discount__content {
            display: block;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: $cart-discount__button-padding;
        border: none;
        background: $cart-discount__button-background;
        font-family: $cart-discount__button-font-family;
        text-transform: $cart-discount__button-text-transform;
        cursor: pointer;

        &:hover {
            background: $cart-discount__button-background--hover;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    &__button-text {
        margin: $cart-discount__button-text-margin;
        font-size: $cart-discount__button-text-font-size;
        color: $cart-discount__button-text-color;
        font-weight: $cart-discount__button-text-font-weight;
        text-transform: capitalize;
    }

    &__button-icon {
        transition: $cart-discount__button-icon-transition;
        display: block;
        &.active {
            display: none;
        }
    }

    &__content {
        display: none;
        &-field {
           height: $cart-discount__field--height;
        }
    }

    &__form {
        display: block;
    }

    &__controls {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: $cart-discount__controls-margin-bottom;
        padding: $cart-discount__controls-padding;

        @include mq($screen-m){
            flex-direction: row;
        }
    }

    &__content-input {
            width: 100%;
            margin-bottom: $cart-discount__input-margin;
        @include mq($screen-m){
            width: 70%;
            margin: 0;
        }
    }

    &__content-button {
        width: 100%;
        min-width: $cart-discount__button-min-width;
        font-weight: $cart-discount__button-text-font-weight;
        @include mq($screen-m){
            width: 30%;
        }
    }

    &__codes-list {
        display: flex;
        flex-flow: column nowrap;
    }
}
