$marketing-bar__padding                 : 0 !default;
$marketing-bar__background-color        : $color-primary !default;
$marketing-bar__container-margin        : 0 auto !default;
$marketing-bar__container-max-width     : $max-content-width !default;
$marketing-bar__min-height              : 40px !default;
$marketing-bar__button-position         : absolute !default;
$marketing-bar__button-right            : 0 !default;
$marketing-bar__button-size             : 40px !default;
$marketing-bar__button-background-color : transparent !default;
$marketing-bar__button-icon-fill        : $white !default;
$marketing-bar__button-icon-fill--hover : $marketing-bar__background-color !default;

$marketing-bar__text-width              : calc(100% - #{$marketing-bar__button-size}) !default;
$marketing-bar__text-padding            : $spacer--extra-small $spacer !default;
$marketing-bar__text-font-size          : $font-size-extra-small !default;
$marketing-bar__text-font-size\@medium  : $font-size-base !default;
$marketing-bar__text-font-color         : $gray-light !default;
$marketing-bar__text-font-weight        : $font-weight-normal !default;
$marketing-bar__text-line-height        : 14px !default;
$marketing-bar__text-line-height\@medium: 24px !default;
$marketing-bar__text-transform          : uppercase !default;
