$container__max-width     : $max-content-width !default;
$container__margin        : $spacer !default;
$container__margin\@medium: $spacer !default;
$container__margin\@large : $spacer--semi-large !default;

.container {
    max-width: $container__max-width;
    margin: 0 $container__margin;

    @include mq($screen-m) {
        margin: 0 $container__margin\@medium;
    }

    @include mq($screen-l) {
        margin: 0 $container__margin\@large;
    }

    @include mq($container__max-width + 2 * $container__margin) {
        margin: 0 auto;
    }
}
