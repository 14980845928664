@import 'aparcamiento-search-variables';

.aparcamiento-search {
    margin-bottom: 0;

    &#maincontent {
        margin-top: 0;
    }

    .amlocator-main-container {
        .modal__content.filters__content {
            background: $white;

            @include mq($screen-m) {
                background: $aparcamiento-search__container--bg;
            }
        }

        .amlocator-search-container {
            width: 100%;
            background: $aparcamiento-search__container--bg;
            margin-bottom: 0;
            padding: $aparcamiento-search__container--padding;

            .search-block {
                max-width: 100%;
            }

            .amlocator-current-location {
                text-align: left;
            }
        }

        .amlocator-map-container {
            display: flex;
            flex-direction: column;
            max-height: initial;

            .amlocator-block.-storelist {
                flex: auto;

                @include mq($screen-m) {
                    width: 20%;
                    min-width: 360px;
                }
            }

            .amlocator-block.-map {
                width: 100%;
                max-height: initial;
                padding-left: 0;

                @include mq($screen-m) {
                    width: 80%;
                    height: 100vh;
                }
            }

            .amlocator-wrapper {
                padding: $aparcamiento-search__left--padding;
                padding-right: 0;
                .title {
                    font-size: $aparcamiento-search__container--font-size;
                    font-weight: $aparcamiento-search__container--font-weight;
                    margin-bottom: $aparcamiento-search__container--title-margin-bottom;
                }

                .circle {
                    align-items: center;
                    display: flex;
                    background: $aparcamiento-search__container--bg;
                    border-radius: 50%;
                    justify-content: center;
                    height: $aparcamiento-search__left-circle-size;
                    margin: $aparcamiento-search__left-circle-padding;
                    width: $aparcamiento-search__left-circle-size;
                }

                .search-message {
                    font-size: $aparcamiento-search__container-p--font-size;
                    padding-right: $spacer--semi-medium;
                }

                .left-bottom-bar-button {
                    margin-top: $aparcamiento-search__left--padding;
                    margin-right: $spacer--semi-medium;
                }

                .amlocator-stores-wrapper {
                    overflow-x: hidden;
                    padding-right: $spacer--semi-medium;
                    .amlocator-store-desc {
                        background: transparent;

                        > div {
                            margin: 0;
                            padding: $aparcamiento-search__left-wrapper--padding;

                            .amlocator-store-information {
                                @include mq($max-screen: $screen-l){
                                    margin-top: $aparcamiento-search__left-store-information-margin-top;
                                }
                            }
                        }

                        &.-active {
                            color: inherit;
                            border: 1.5px solid $color-primary;
                        }

                        &.widget-card {
                            margin-bottom: $aparcamiento-search__left-wrapper--padding;
                            padding: 0;
                        }

                        .amlocator-image {
                            width: $aparcamiento-search__left-image-size;
                            height: $aparcamiento-search__left-image-size;
                            min-width: $aparcamiento-search__left-image-size-min-width;
                            min-height: $aparcamiento-search__left-image-size-min-height;
                            @include mq($screen-m) {
                                min-width: $aparcamiento-search__left-image-size-min-width\@medium;
                                min-height: $aparcamiento-search__left-image-size-min-height\@medium;
                            }
                        }

                        .amlocator-attributes {
                            border-top: $aparcamiento-search__attr-border;
                            border-bottom: $aparcamiento-search__attr-border;

                            ul {
                                flex-flow: row wrap;
                                padding: $aparcamiento-search__left-wrapper--padding 0;
                            }
                        }

                        .amlocator-collapsible__button {
                            @extend .cart-discount__button;
                            padding: 0;

                            &[aria-expanded="true"] {
                                .icon {
                                    transform: rotate(180deg);
                                }
                            }
                            .icon {
                                fill: $color-primary;
                            }

                            &-text {
                                font-size: $aparcamiento-search__container-p--font-size;
                                font-weight: $font-weight-medium-bold;
                                text-transform: capitalize;
                            }

                        }

                        .amlocator-product {
                            .price-wrapper {
                                align-items: center;
                                display: flex;
                                justify-content: space-between;

                                .button {
                                    flex-basis: 50%;
                                }
                            }
                        }
                        .amlocator-actions {
                            padding-top: 0;
                            padding-bottom: 0;
                            .button--link {
                                align-items: center;
                                color: $aparcamiento-search__container-action-button-link--color;
                                justify-content: space-between;
                                text-decoration: none;
                                svg {
                                    fill: $aparcamiento-search__container-action-button-link--color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.aparcaments-modal._show {
    left: 0;
    z-index: 9999 !important; //sobreescribiendo inline style
    #search-parkings-modal {
        display: block;
    }
    header {
        display: none;
        .logo__image {
            height: $spacer--semi-large;
        }
    }

    .modal-header.custom {
        display: flex;
        align-items: center;
        padding: $aparcamiento-search__modal--padding;
        border-bottom: $aparcamiento-search__attr-border;
        .back {
            flex-basis: 50%;

            .action-close {
                position: relative;
                padding-left: $spacer--semi-medium;
                margin-left: $spacer--semi-medium;
                top: 2px;
            }

            .button__icon {
                margin-right: $aparcamiento-search__modal--icon-margin;
            }

            span {
                color: $aparcamiento-search__modal-header--color;
            }
        }
    }
    .modal-title-medium {
        border-bottom: $aparcamiento-search__attr-border;
        padding: $spacer--semi-medium;
        span {
            font-size: $font-size-super-extra-large\@screen-m;
        }
    }
    .modal-content {
        padding: 0;
        .amlocator-actions {
            display: block;

            .amlocator-select-location {
                overflow: hidden;

                div {
                    width: 100%;
                    height: auto;
                    position: relative;
                    padding: $aparcamiento-search__left--padding;

                    input[type="radio"] {
                        position: absolute;
                        left: -99999px;
                        opacity: 0.011;
                        z-index: 100;
                    }

                    input[type="radio"]:checked + label {
                        @extend .button;
                        .button__icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            + span {
                                padding: 0 $spacer;
                                visibility: hidden;
                                &::before {
                                    content: attr(data-text);
                                    left: $spacer;
                                    position: absolute;
                                    visibility: visible;
                                    width: 100%;
                                }
                            }
                        }

                    }

                    label {
                        cursor: pointer;
                        z-index: 90;
                        @extend .button--secondary;
                        border-color: $gray-light;
                        width: 100%;
                        .button__icon {
                            display: none;
                            &[class*="fa-"] {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        .amlocator-wrapper {
            .title {
                display: none;
            }
        }
        .amlocator-map-container {
            .amlocator-block.-storelist {
                @include mq($screen-m) {
                    width: 25%;
                }
            }
            .amlocator-block.-map {
                @include mq($screen-m) {
                    width: 75%;
                }
            }
        }
    }
}
