$list__margin                                          : 0 !default;
$list__padding                                         : 0 !default;
$list__color                                           : $font-color-base !default;
$list__font-size                                       : $font-size-base !default;
$list__list-style                                      : none !default;
$list__item-padding                                    : 0 0 $spacer--medium !default;
$list__item-last-child-padding                         : inherit inherit 0 !default;
$list__label-padding                                   : 0 $spacer $spacer 0 !default;
$list__label-after-content                             : ': ' !default;
$list__value-padding                                   : 0 $spacer $spacer 0 !default;
$list__content-padding-left                            : $spacer !default;

// Horizontal variant
$list__item-padding--horizontal                        : $spacer !default;

// With Icon variant
$list__item-padding--with-icon                         : 0 !default;
$list__icon-link-padding--with-icon                    : $spacer--medium !default;

// With Image variant
$list__image-width--with-image                         : $spacer--medium !default;
$list__image-height--with-image                        : auto !default;

// Divided variant
$list__item-border-bottom--divider                     : 1px solid $gray-dark !default;
$list__item-padding--divider                           : $spacer 0 !default;
$list__item-last-child-border-bottom--divider          : none !default;
$list__item-border-bottom--divider-horizontal          : none !default;
$list__item-border-right--divider-horizontal           : $border-base !default;
$list__item-last-child-border-right--divider-horizontal: none !default;
$list__item-padding--divider-horizontal                : $spacer !default;
$list__item--divider-horizontal-char                   : '–';

// Native variant
$list__item-width--native                              : $spacer--extra-small !default;
$list__item-height--native                             : $spacer--extra-small !default;
$list__item-padding--native                            : 0 0 $spacer calc(#{$spacer--medium - $list__item-height--native}) !default;
$list__item-background-color--native                   : $color-secondary !default;
$list__item-before-top--native                         : 30% !default;
$list__item-before-left--native                        : 0 !default;

// Columns variant
$list__column-padding                                  : 0 $spacer--small 0 0 !default;
$list__column-last-child-padding                       : 0 !default;
$list__column-item-last-child-padding-bottom           : $spacer !default;
$list__column-item-fist-child-padding-top              : $spacer !default;

// Table
$list__margin--table                                   : 0 0 $spacer !default;
$list__label-font-size--table                          : $font-size-medium !default;
$list__label-font-weight--table                        : $font-weight-bold !default;
$list__value-font-size--table                          : $font-size-medium !default;
$list__item-line-height--table                         : 32px !default;

%bullet:before {
    content: "";
    position: absolute;
    top: $list__item-before-top--native;
    left: $list__item-before-left--native;
    width: $list__item-width--native;
    height: $list__item-height--native;
    background-color: $list__item-background-color--native;
}
